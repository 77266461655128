import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  edit open position 
export const EditOpenPosition = createSlice({
  name: "EditOpenPosition",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    edit_EditOpenPosition: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_edit_EditOpenPosition: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;      
    },
    failure_api_call_edit_EditOpenPosition: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    edit_EditOpenPosition_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_EditOpenPosition_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchEditOpenPosition_response: (state) => {
      state.switchResponse = false;
    },
   
  },
});
export default EditOpenPosition.reducer;
export const {
  edit_EditOpenPosition,
  success_api_call_edit_EditOpenPosition,
  create_EditOpenPosition_complete_api,
  failure_api_call_edit_EditOpenPosition,
  edit_EditOpenPosition_is_success,
  false_switchEditOpenPosition_response
  
} = EditOpenPosition.actions;
