import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list  trade history
export const backtest = createSlice({
  name: "backtest",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_backtest: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_backtest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_backtest: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    backtest_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearDatabacktest: (state) => {
      state.data = [];
    },
  },
});
export default backtest.reducer;
export const {
  list_backtest,
  success_api_call_backtest,
  failure_api_call_backtest,
  backtest_is_first,
  clearDatabacktest,
} = backtest.actions;
