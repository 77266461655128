import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for second dialog open position
export const SecondDialogOpenPosition = createSlice({
  name: "SecondDialogOpenPosition",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    Second_SecondDialogOpenPosition: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_Second_SecondDialogOpenPosition: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;     
    },
    failure_api_call_Second_SecondDialogOpenPosition: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    Second_SecondDialogOpenPosition_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_SecondDialogOpenPosition_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default SecondDialogOpenPosition.reducer;
export const {
  Second_SecondDialogOpenPosition,
  success_api_call_Second_SecondDialogOpenPosition,
  failure_api_call_Second_SecondDialogOpenPosition,
  create_SecondDialogOpenPosition_complete_api,
  Second_SecondDialogOpenPosition_is_success,
} = SecondDialogOpenPosition.actions;
