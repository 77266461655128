import { createSlice } from "@reduxjs/toolkit";

// this code for slice value for list userpermission
export const User_permissionMenuList = createSlice({
  name: "User_permissionMenuList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    list_User_permissionMenuList: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_User_permissionMenuList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_User_permissionMenuList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    User_permissionMenuList_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearUser_permissionMenuList: (state) => {
      state.data = [];
    },
    false_switchlist_UserpermissionDatalist_response: (state) => {
      state.switchResponse = false;
    },
    clearDatasidenav: (state) => {
      state.data = [];
    },
  },
});
export default User_permissionMenuList.reducer;
export const {
  list_User_permissionMenuList,
  success_api_call_User_permissionMenuList,
  failure_api_call_User_permissionMenuList,
  User_permissionMenuList_is_first,
  clearUser_permissionMenuList,
  false_switchlist_UserpermissionDatalist_response,
  clearDatasidenav,
} = User_permissionMenuList.actions;
