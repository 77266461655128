import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../Redux/store/hooks";
import { Switch } from "@mui/material";
import { DeleteStockvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteStock-values-slicer";
import { Stocksvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Stocks-values-slicer";

interface StockMessageprops {
  valuepopup: number;
  editdata: any;
  PermissionBase: any;
}

const StockMessage: FunctionComponent<StockMessageprops> = (props: any) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const pageName = "Manage Stock";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <Switch
          title="Map Startegy Active / Inactive"
          {...label}
          size="small" // Add this line to set the switch size to small
          checked={props.editdata.is_subscribed === true} // Set the checked prop based on the state
          onChange={(e) => {
            dispatcher(
              Stocksvalues_datagrid({
                switchStatus: e.target.checked,
                stock_name: props.editdata,
              })
            );
          }}
        />
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "#F2A618",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteStockvalues_datagrid({
                stock_name: props.editdata,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default StockMessage;
