import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  delete map strategy
export const Deletebacktest = createSlice({
  name: "Deletebacktest",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_backtest: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_backtest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_backtest: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_backtest_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchdeletebacktest_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default Deletebacktest.reducer;
export const {
  delete_backtest,
  success_api_call_delete_backtest,
  failure_api_call_delete_backtest,
  delete_backtest_is_success,
  false_switchdeletebacktest_response
} = Deletebacktest.actions;
