import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   Stocksvalues
export const Stocksvalues = createSlice({
  name: "Stocksvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    Stocksvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_Stocksvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_Stocksvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    Stocksvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_Stocksvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchStocksvalues:(state)=>{
        state.switch = false
    }
  },
});
export default Stocksvalues.reducer;
export const {
  Stocksvalues_datagrid,
  success_api_call_Stocksvalues_datagrid,
  failure_api_call_Stocksvalues_datagrid,
  create_Stocksvalues_datagrid_complete_api,
  Stocksvalues_datagrid_is_success,false_switchStocksvalues
} = Stocksvalues.actions;
