import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddStrategies,
  success_api_call_add_AddStrategies,
} from "./add_Strategies_slicer";
import {
  failure_api_call_EditStrategies,
  success_api_call_EditStrategies,
} from "./edit_Strategies_slicer";
import {
  failure_api_call_StrategiesList,
  success_api_call_StrategiesList,
} from "./list_StrategiesList_slicer";
import {
  failure_api_call_delete_Strategies,
  success_api_call_delete_Strategies,
} from "./delete_Strategies_slicer";

//add Strategies
const AddStrategiesCall = async (a: any) => {
  console.log(a);

  const add = {
    name: a.payload.name,
    algo_id: a.payload.algo_id,
    is_enabled: a.payload.is_enabled,
    start_time: a.payload.start_time,
    segment: a.payload.segment,
    meta_data: a.payload.meta_data,
    // indicators: a.payload.indicators,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/setting/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddStrategiesCall, e);
    yield put(success_api_call_add_AddStrategies(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddStrategies(e));
    yield put(expireSignature(e));
  }
}

//Edit strategies
const editStrategiesCall = async (a: any) => {
  const get_id = localStorage.getItem("filter");
  const get_token = localStorage.getItem("token");
  const edit = {
    name: a.payload.name,
    algo_id: a.payload.algo_id,
    is_enabled: a.payload.is_enabled,
    start_time: a.payload.start_time,
    segment: a.payload.segment,
    meta_data: a.payload.meta_data,
    // indicators: a.payload.indicators,
  };
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/setting/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* editStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editStrategiesCall, e);
    yield put(success_api_call_EditStrategies(response.data));
  } catch (e) {
    yield put(failure_api_call_EditStrategies(e));
    yield put(expireSignature(e));
  }
}

//list strategies
const listStrategiesCall = async (a: any) => {
  let list = {};
  if (a.payload.is_enabled !== "") {
    list = { is_enabled: a.payload.is_enabled };
  } else {
    list = {};
  }
  if (a.payload.algo_id !== 0) {
    list = { ...list, algo_id: a.payload.algo_id };
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/setting`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* listStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listStrategiesCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_StrategiesList(res));
  } catch (e) {
    yield put(failure_api_call_StrategiesList(e));
    yield put(expireSignature(e));
  }
}

// Delete
export function* deleteStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteStrategiesCall, e);
    yield put(success_api_call_delete_Strategies(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_Strategies(error));
  }
}
const deleteStrategiesCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/setting/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
