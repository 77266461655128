import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { useState } from "react";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { list_Order } from "../Redux/Reducers/Order/list_Order_slicer";
import {
  clearfilterOrder,
  data_filterOrder,
} from "../Redux/Reducers/Filtervalue/filterOrder";

type Anchor = "top" | "left" | "bottom" | "right";

export default function OrderFilter(props: any) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const dispatcher = useAppDispatch();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateRange, setDateRange] = React.useState<
    DateRange | null | undefined
  >(null);

  // Calculate the start date (7 days ago) and end date (today)
  React.useEffect(() => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 7);
    // Format the dates as ISO strings (yyyy-MM-dd)
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    // Set the date range
    setDateRange([new Date(formattedStartDate), new Date(formattedEndDate)]);

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "text";
    hiddenInput.style.display = "none";
    hiddenInput.setAttribute("autocomplete", "off");
    hiddenInput.setAttribute("id", "hiddenInput");
    // Append the input to the body or another appropriate container
    document.body.appendChild(hiddenInput);
    // Remove the input when the component is unmounted
    return () => {
      document.body.removeChild(hiddenInput);
    };
  }, [dispatcher]);

  const FilterOrder: any = useAppSelector((state) => state?.filterOrder?.data);
  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <span title="Filter">
            <FilterListIcon
              onClick={toggleDrawer(anchor, true)}
              sx={{
                marginTop: "5px",
                fontSize: "24px",
                marginRight: "5px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
            ></FilterListIcon>
          </span>

          <Drawer anchor={anchor} open={state[anchor]}>
            <Box
              sx={{
                width: {
                  xs: 200,
                  sm: 200,
                  md: 300,
                  lg: 360,
                  xl: 400,
                },
                zIndex: "1",
              }}
              role="presentation"
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#2C8E14",
                  justifyContent: "space-between",
                  height: "64px",
                }}
              >
                <span
                  style={{
                    padding: "17px",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                >
                  Filter
                </span>
                <div style={{ padding: "20px", cursor: "pointer" }}>
                  <CancelIcon
                    sx={{ color: "white" }}
                    onClick={toggleDrawer(anchor, false)}
                  ></CancelIcon>
                </div>
              </div>
              <Formik
                initialValues={{
                  Product: FilterOrder?.Product ?? null,
                  typelist: FilterOrder?.typelist ?? null,
                  dateRange: FilterOrder?.dateRange ?? null,
                  OrderType: FilterOrder?.OrderType ?? null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values) => {
                  if (values.dateRange != null) {
                    const fromDate = new Date(values.dateRange[0]);
                    const year = fromDate.getFullYear();
                    const month = String(fromDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const day = String(fromDate.getDate()).padStart(2, "0");
                    const formattedFromDate = `${year}-${month}-${day}`;
                    const toate = new Date(values.dateRange[1]);
                    const toyear = toate.getFullYear();
                    const tomonth = String(toate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const today = String(toate.getDate()).padStart(2, "0");
                    const formattedToDate = `${toyear}-${tomonth}-${today}`;
                    dispatcher(
                      list_Order({
                        ...values,
                        from_date: formattedFromDate,
                        to_date: formattedToDate,
                        client_id: localStorage.getItem("client_id"),
                        id: localStorage.getItem("id"),
                        account_name: localStorage.getItem("account_name"),
                      })
                    );
                  } else {
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = String(today.getMonth() + 1).padStart(2, "0");
                    const day = String(today.getDate()).padStart(2, "0");
                    dispatcher(
                      list_Order({
                        ...values,
                        from_date: `${year}-${month}-${day}`,
                        to_date: `${year}-${month}-${day}`,
                        client_id: localStorage.getItem("client_id"),
                        id: localStorage.getItem("id"),
                        account_name: localStorage.getItem("account_name"),
                      })
                    );
                  }
                  dispatcher(data_filterOrder(values));
                }}
              >
                {({ values, handleSubmit, handleBlur, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Product
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "Intraday - MIS", value: "MIS" },
                        { label: "Longterm  - CNC", value: "CNC" },
                      ]}
                      variant={"outlined"}
                      name={"Product"}
                      value={values}
                      size={"medium"}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("Product", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Type
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "MARKET", value: "MARKET" },
                        { label: "LIMIT", value: "LIMIT" },
                        { label: "SL-L", value: "SL-L" },
                        { label: "SL-M", value: "SL-M" },
                      ]}
                      variant={"outlined"}
                      name={"typelist"}
                      value={values}
                      size={"medium"}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("typelist", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                        borderBottom: "50px",
                      }}
                    ></Autocomplete>

                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Order Type
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "SELL", value: "SELL" },
                        { label: "BUY", value: "BUY" },
                      ]}
                      variant={"outlined"}
                      name={"OrderType"}
                      value={values}
                      size={"medium"}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("OrderType", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div style={{ padding: "10px 20px 0px 20px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "0px 0px 0px 0px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Trading Dates
                      </div>

                      <div
                        style={{
                          marginTop: "5px",
                          width: "100%",
                          overflowY: isCalendarOpen ? "auto" : "hidden",
                        }}
                        onWheel={(e) => {
                          if (isCalendarOpen) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <DateRangePicker
                          showOneCalendar
                          className="custom-date-range-picker"
                          defaultValue={dateRange}
                          editable={false}
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "0.5px solid gray",
                          }}
                          name="dateRange"
                          value={values.dateRange}
                          onChange={(selectedRange: any) => {
                            setFieldValue("dateRange", selectedRange);
                          }}
                          onFocus={() => {
                            // Focus on the hidden input to prevent autofill
                            const hiddenInput =
                              document.getElementById("hiddenInput");
                            if (hiddenInput) {
                              hiddenInput.focus();
                            }
                          }}
                          onClose={() => {
                            setIsCalendarOpen(false);
                          }}
                          format="yyyy-MM-dd"
                          placeholder="Select Date Range"
                          placement="left"
                        />
                      </div>
                    </div>
                    <div
                      className="bottom_div"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#2C8E14",
                        width: "100%",
                        gap: "1em",
                        height: "60px",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="button"
                            onClick={() => {
                              const today = new Date();
                              const year = today.getFullYear();
                              const month = String(
                                today.getMonth() + 1
                              ).padStart(2, "0");
                              const day = String(today.getDate()).padStart(
                                2,
                                "0"
                              );
                              dispatcher(
                                list_Order({
                                  ...values,
                                  from_date: `${year}-${month}-${day}`,
                                  to_date: `${year}-${month}-${day}`,
                                  client_id: localStorage.getItem("client_id"),
                                  id: localStorage.getItem("id"),
                                  account_name:
                                    localStorage.getItem("account_name"),
                                })
                              );
                              dispatcher(clearfilterOrder());
                              toggleDrawer(anchor, false);
                            }}
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontFamily: "Roboto",
                              border: "1px solid #F2A618",
                            }}
                          >
                            Reset
                          </button>
                        </span>
                      </div>
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="submit"
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              background: !props.isValid
                                ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                                : "linear-gradient(to right, #FFFA02,#F2A618 )",

                              borderRadius: "5px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
