import { createSlice } from "@reduxjs/toolkit";
 // this code for slice value for DeleteAlgo
export const DeleteAlgo = createSlice({
  name: "DeleteAlgo",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_algo: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_algo: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_algo: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_algo_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchdeletealgo_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default DeleteAlgo.reducer;
export const {
  delete_algo,
  success_api_call_delete_algo,
  failure_api_call_delete_algo,
  delete_algo_is_success,
  false_switchdeletealgo_response
} = DeleteAlgo.actions;
