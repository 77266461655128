import { FunctionComponent } from "react";
import "../Page.css";
import { Backdrop, CircularProgress } from "@mui/material";
import Gridsdashboard from "../../Component/Grides_Dashboard";

interface dashboardtableProps {}

const dashboardtable: FunctionComponent<dashboardtableProps> = () => {
  const column = [
    {
      sortable: true,
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Strategy Name
        </p>
      ),
      flex: 1,
      headerClassName: "custom-header",
      headerAlign: "center", // Center the text in the header
      field: "strategyname",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Stock Name
        </p>
      ),
      sortable: true,
      headerClassName: "custom-header",
      headerAlign: "center", // Center the text in the header
      field: "stockname",
      flex: 1,
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          P & L
        </p>
      ),
      field: "pl",
      headerClassName: "custom-header",
      headerAlign: "center", // Center the text in the header
      flex: 1,
      sortable: true,
    },
  ];

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress style={{ color: "rgb(239, 153, 15)" }} />
      </Backdrop>
      <Gridsdashboard row={[]} column={column} opened></Gridsdashboard>
    </div>
  );
};

export default dashboardtable;
