import { createSlice } from "@reduxjs/toolkit";
import { Iaccount } from "../../../pages/accounts/IAccount";
// this code for slice value for EditSwitch
export const EditSwitch = createSlice({
  name: "EditSwitch",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: {} as Iaccount,
  },
  reducers: {
    edit_EditSwitch: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditSwitch: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditSwitch: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditSwitch_is_success: (state) => {
      state.isSuccess = false;
    },
    EditSwitch_complete_api: (state) => {
      state.api_completed = false;
    },
    clearCard: (state) => {
      state.data = {};
    },
    false_switchcard_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditSwitch.reducer;
export const {
  edit_EditSwitch,
  success_api_call_EditSwitch,
  EditSwitch_complete_api,
  failure_api_call_EditSwitch,
  EditSwitch_is_success,
  clearCard,false_switchcard_response
} = EditSwitch.actions;
