import React, { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../Redux/store/hooks";
import { DeleteAlgovalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteAlgo-values-slicer";
// import Algo from "../../Component/DialogForFormAlgoDymics";
// import editimage from "../Image/Edit.svg";

interface MessageProps {
  valuepopup: number;
  editdata: any;
  tradecheck: any;
}

const Message: FunctionComponent<MessageProps> = (props: any) => {
  const dispatcher = useAppDispatch();
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const pageName = "Add Algo";
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];
  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Algo
        Buttondialog={"edit"}
        Dialogtitle={"Edit Input Field"}
        sx={{
          cursor: "pointer",
          backgroundColor: "transparent",
          height: "60%",
          "&:hover": { backgroundColor: "transparent" },
        }}
        edit={{
          ...props.editdata,
        }}
        Buttonname={
          <span title="Edsit">
            <img src={editimage} width="25px" alt={""}></img>
          </span>
        }
      /> */}
      {MenuOfUserpermission?.permission?.delete ? (
        props.tradecheck ? (
          <DeleteIcon
            titleAccess={"Delete"}
            sx={{
              color: "rgb(239, 153, 15)",
              cursor: "pointer",
            }}
          />
        ) : (
          <DeleteIcon
            titleAccess={"Delete"}
            sx={{
              color: "rgb(239, 153, 15)",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatcher(DeleteAlgovalues_datagrid(props.valuepopup));
            }}
          />
        )
      ) : (
        "-"
      )}
    </div>
  );
};

export default Message;
