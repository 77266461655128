import { FunctionComponent } from "react";

import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import Grids from "../../Component/Grides";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";

import Algofilter from "../../Component/Algofilter";

import {
  clearData,
  list_AlgoList,
} from "../../Redux/Reducers/Algo-api/list_AlgoList_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";

import AlgoMessage from "./AgloMessage";
import { false_switchalgo } from "../../Redux/Reducers/Datagrid-values/Algo-values-slicer";
import Dialogs from "../../Component/Dialog";
import Warning from "../svg_image/Waring.gif";
import {
  edit_EditAlgo,
  false_switchalgo_response,
} from "../../Redux/Reducers/Algo-api/edit_Algo_slicer";
import {
  delete_algo,
  false_switchdeletealgo_response,
} from "../../Redux/Reducers/Algo-api/delete_Algo_slicer";
import { false_switchdeletealgo } from "../../Redux/Reducers/Datagrid-values/DeleteAlgo-values-slicer";
import { edit_filteralgo } from "../../Redux/Reducers/Filtervalue/filteralgo";
import { false_switchAddAlgo_response } from "../../Redux/Reducers/Algo-api/add_Algo_slicer";
import { list_TreadSetupList } from "../../Redux/Reducers/Trade-setup/list_Trade-setup_slicer";

interface ViewStrategiesProps {}

const ViewStrategies: FunctionComponent<ViewStrategiesProps> = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Algo
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      View Algo
    </Typography>,
  ];

  const Tradesetupdata = useAppSelector((state) => state?.TreadSetupList?.data);
  const data = useAppSelector((state) => state?.AlgoList?.data);

  const datavaluefilter = data.map((data: any) => data.name);
  const datafilter = Tradesetupdata.map((data: any) => data.algo.name);

  const updatedData = data.map((item: any) => {
    // Check if the item's name is in both datavaluefilter and datafilter
    const isMatched =
      datavaluefilter.includes(item.name) && datafilter.includes(item.name);

    // Return a new object with the matched property set
    return {
      ...item,
      isMatch: isMatched,
    };
  });

  const isLoadingdata = useAppSelector((state) => state?.AlgoList?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddAlgo?.data);
  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddAlgo?.isLoading
  );
  const editData: any = useAppSelector((state) => state?.EditAlgo?.data);
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditAlgo?.isLoading
  );
  const deleteData: any = useAppSelector((state) => state?.DeleteAlgo?.data);
  const switchResponse = useAppSelector(
    (state) => state.EditAlgo.switchResponse
  );
  const Uservalues: any = useAppSelector((state) => state.Algovalues.data);
  const switchOpen = useAppSelector((state) => state.Algovalues.switch);
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteAlgo.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state.DeleteAlgovalues.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.DeleteAlgovalues.switch
  );
  const AddAlgoResponse = useAppSelector(
    (state) => state.AddAlgo.switchResponse
  );
  const pageName = "View Algo";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const column = [
    {
      sortable: true,
      headerName: "Algo Name",
      flex: 1,
      field: "name",
    },
    {
      sortable: true,
      headerName: "Meta Data",
      flex: 1,
      field: "meta_data",
      valueGetter: (params: any) => {
        return params.row.meta_data.map((metaItem: any) => `${metaItem.key},`);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Map meta_data array to a string of key-value pairs
        const metaValues = params.row.meta_data.map(
          (metaItem: any) => `${metaItem.key},`
        );
        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={metaValues.join("\n")}
          >
            {metaValues.join("\n")}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <AlgoMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></AlgoMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  React.useEffect(() => {
    dispatcher(list_AlgoList(""));
    dispatcher(edit_filteralgo({}));
    dispatcher(list_TreadSetupList(""));
    if (switchResponse) {
      dispatcher(false_switchalgo());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchdeletealgo());
    }
    if (editData.message) {
      localStorage.removeItem("editalgodatamassage");
    }

    return () => {
      dispatcher(clearData());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponse,
    switchResponsealgodelete,
  ]);
  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchalgo_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchdeletealgo_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const AddAlgoaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAddAlgo_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  const initialColumnVisibilityModel: any = {
    name: true,
    meta_data: true,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchalgo_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchalgo_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddAlgoResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddAlgo_response())}
        key={`top-right`}
        action={AddAlgoaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddAlgo_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchdeletealgo_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchdeletealgo_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataIsLoading || editDataIsLoading
                ? "block"
                : "none",
          }}
        />
      </div>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Grids
        row={updatedData ?? []}
        column={column}
        // getRowHeight={() => "auto"}
        gridesbutton
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        pagination
        sideButton={<Algofilter></Algofilter>}
        dialog={
          <Button
            disabled={MenuOfUserpermission?.permission?.add === false}
            onClick={() => {
              navigate("/addalgo", {
                state: { editoraddalgo: "add", successMessage: { addData } },
              });
            }}
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              color: "black",
              height: "37px",
              borderRadius: "5px",
              marginLeft: "10px",
              fontFamily: "Roboto",
              textTransform: "capitalize",

              "&:hover": { backgroundColor: "#E10000" },
            }}
          >
            Add New
          </Button>
        }
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        open={switchOpen}
        setOpen={() => dispatcher(false_switchalgo())}
        Dialog_Content={
          Uservalues.is_enabled === true
            ? "Do you want to activate the status?"
            : "Do you want to deactivate the status?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchalgo())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditAlgo(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchdeletealgo())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchdeletealgo())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(delete_algo(Uservaluesalgodelete))}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default ViewStrategies;
