import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../Redux/store/hooks";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { Deletebacktestvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteBacktest-values-slicer";

interface backtestprops {
  valuepopup: number;
  editdata: any;
}

const Backtestview: FunctionComponent<backtestprops> = (props: any) => {
  const dispatcher = useAppDispatch();

  const pageName = "/backtest";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.view ? (
        <>
          {props.editdata.status === "COMPLETED" ? (
            <span title={"View the Back Report"}>
              <RemoveRedEyeIcon
                titleAccess="View the Back Report"
                style={{ color: "#F2A618", cursor: "pointer" }}
                onClick={() => {
                  navigate("/backreport");
                  localStorage.setItem("backreport", props.valuepopup);
                  localStorage.setItem(
                    "backreportvalue",
                    JSON.stringify(props.editdata)
                  );
                }}
              ></RemoveRedEyeIcon>
            </span>
          ) : (
            <span title={"View the Back Report"}>
              <RemoveRedEyeIcon
                titleAccess="View the Back Report"
                style={{ color: "gray", cursor: "not-allowed" }}
              ></RemoveRedEyeIcon>
            </span>
          )}
        </>
      ) : (
        "-"
      )}

      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "#F2A618",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              Deletebacktestvalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default Backtestview;
