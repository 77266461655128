import { FunctionComponent, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import { FormHelperText, Typography } from "@mui/material";
import "./adduser.css";
import { useLocation } from "react-router-dom";
import Autocomplete from "../../Component/Autocomplete";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { add_AddAdmin } from "../../Redux/Reducers/Admin-api/add_Admin_slicer";
import { edit_EditableValue } from "../../Redux/Reducers/Admin-api/editableValu";
import { edit_EditAdmin } from "../../Redux/Reducers/Admin-api/edit_Admin_slicer";
import { useDispatch } from "react-redux";
import PopupMessage from "../../Component/PopupMessage";
import { list_RoleList } from "../../Redux/Reducers/Roles/list_Role_slicer";

interface interfaceAddUser {
  edit?: any;
  successMessage?: any;
}

const AddUser: FunctionComponent<interfaceAddUser> = (props) => {
  const location = useLocation();
  const dataPassed = location.state?.editoradd;
  const navigate = useNavigate();
  const editableValu: any = useAppSelector((state) => state.EditableValue.data);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const pageName = "Add User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Users
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      {dataPassed === "edit" ? "Edit" : "Add"} User
    </Typography>,
  ];

  const [editDialog, seteditDialog] = useState(0);

  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [mobileTouched, setMobileTouched] = useState(false);
  const editOpen = () => {
    seteditDialog(editDialog + 0.001);
  };
  const idFromLocalStorage = localStorage.getItem("idforfilter");
  const defaultId = ""; // Provide a default value here
  const id = idFromLocalStorage !== null ? idFromLocalStorage : defaultId;
  const name =
    dataPassed === "edit"
      ? localStorage.getItem("ProfileDetailsname") || ""
      : "";
  const email_id =
    dataPassed === "edit"
      ? localStorage.getItem("ProfileDetailsemail_id") || ""
      : "";
  const password = editableValu.password || "";
  const mobile_no =
    dataPassed === "edit"
      ? localStorage.getItem("ProfileDetailsmobile_no") || ""
      : "";
  const role =
    dataPassed === "edit"
      ? localStorage.getItem("ProfileDetailsrole") || ""
      : "";

  const initialValues: {
    id: string;
    name: string;
    email_id: string;
    password: string;
    mobile_no: string;
    role: string;
  } = {
    id: id,
    name: name,
    email_id: email_id,
    password: password,
    mobile_no: mobile_no,
    role: role,
  };

  const dispatcher = useAppDispatch();
  let isFieldEnabled = false;

  if (MenuOfUserpermission?.permission?.add) {
    isFieldEnabled = true;
  }
  if (dataPassed === "edit" && MenuOfUserpermission?.permission?.edit) {
    isFieldEnabled = true;
  }

  useEffect(() => {
    dispatcher(edit_EditableValue([]));

    if (isFieldEnabled) {
      dispatcher(list_RoleList(true));
    }
  }, [dispatcher, isFieldEnabled]);

  const dispatch = useDispatch();
  const RoleData = useAppSelector((state) => state?.RoleList?.data);
  const addisLoading = useAppSelector((state) => state?.AddAdmin?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddAdmin?.data);
  const editisLoading = useAppSelector((state) => state?.EditAdmin?.isLoading);
  const editData: any = useAppSelector((state) => state?.EditAdmin?.data);

  const handleApiCall = async (values: any) => {
    try {
      if (dataPassed === "edit") {
        dispatch(edit_EditAdmin(values));
      } else {
        dispatch(add_AddAdmin(values));
      }
    } catch (error) {}
  };

  return (
    <div>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 20px 10px 25px " }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div
        className="userform"
        style={{
          /* Add styles for maximum height and scrolling */
          maxHeight: "calc(100vh - 150px)", // Adjust as needed
          overflowY: "auto", // Enable scrolling when content exceeds max height

          backgroundColor: "white",
          boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.15)",
          height: "50rem",
          padding: "25px",
          borderRadius: "10px",
        }}
      >
        <span style={{ fontFamily: "Roboto", fontSize: "16px" }}>
          Basic Informantion
        </span>

        <br></br>
        <br></br>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please enter a username"),
            email_id: Yup.string()
              .email("Please enter a valid email")
              .required("Please enter an email")
              .matches(/^.+@.+\..+$/, "Please enter a valid email")
              .test("lowercase", "Email must be lowercase", function (value) {
                return value === value.toLowerCase();
              }),
            mobile_no: Yup.string()
              .required("Mobile number is required")
              .matches(/^\d+$/, "Mobile number must contain only digits")
              .length(10, "Mobile number must be exactly 10 digits long"),
            password:
              dataPassed === "edit"
                ? Yup.string().matches(
                    /^(?=.*[a-z])(?=.*[0-7])(?=.*[!@#$%^&*()]).{8,50}$/,
                    "Must contain at least 8 characters"
                  )
                : Yup.string()
                    .matches(
                      /^(?=.*[a-z])(?=.*[0-7])(?=.*[!@#$%^&*()]).{8,50}$/,
                      "Must contain at least 8 characters"
                    )
                    .required("Please enter the valid password"),
            role: Yup.string().required(" Please select the role"),
          })}
          onSubmit={(values) => {
            editOpen();
          }}
        >
          {(props) => {
            return (
              <Form id="form" autoComplete="off">
                <div className="adduserlist">
                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      User Name <span style={{ color: "red" }}>*</span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      name="name"
                      disabled={!isFieldEnabled}
                      sx={{
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      autoComplete="off"
                      helperText={
                        props.errors.name &&
                        props.touched.name &&
                        props.errors.name
                      }
                      error={!!(props.errors.name && props.touched.name)}
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        // Use regex to check if the input contains only characters (letters)
                        if (
                          /^[A-Za-z\s]+$/.test(inputValue) &&
                          inputValue.length < 31
                        ) {
                          props.setFieldValue("name", inputValue);
                        } else {
                          // If validation fails, remove the last character from the input value
                          props.setFieldValue("name", inputValue.slice(0, -1));
                        }
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      style={{
                        width: "100%",
                        height: "50px",
                        margin: " 0px 0px 40px ",
                      }}
                    />
                  </div>

                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Email Id <span style={{ color: "red" }}>*</span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      name="email"
                      disabled={dataPassed === "edit"}
                      autoComplete="off"
                      sx={{
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      value={props.values.email_id}
                      onChange={(e) => {
                        props.setFieldValue("email_id", e.target.value);
                      }}
                      helperText={
                        props.errors.email_id &&
                        (props.touched.email_id || emailTouched) // Use emailTouched here
                          ? props.errors.email_id
                          : " "
                      }
                      error={
                        !!(
                          (
                            props.errors.email_id &&
                            (props.touched.email_id || emailTouched)
                          ) // Use emailTouched here
                        )
                      }
                      onBlur={() => {
                        setEmailTouched(true); // Set emailTouched to true when onBlur is called
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                        margin: " 0px 0px 40px ",
                      }}
                    />
                  </div>

                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Password{" "}
                      <span style={{ color: "red" }}>
                        {dataPassed === "edit" ? "" : "*"}
                      </span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      disabled={!isFieldEnabled}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      sx={{
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      value={props.values.password}
                      onChange={(e) => {
                        props.setFieldValue("password", e.target.value);
                      }}
                      helperText={
                        props.errors.password &&
                        (props.touched.password || passwordTouched)
                          ? props.errors.password
                          : " "
                      }
                      error={
                        !!(
                          props.errors.password &&
                          (props.touched.password || passwordTouched)
                        )
                      }
                      onBlur={() => {
                        setPasswordTouched(true);
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                        margin: "0px 0px 40px",
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    />
                  </div>

                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Mobile Number<span style={{ color: "red" }}> *</span>
                    </div>
                    <TextField
                      inputProps={{ maxLength: 10 }}
                      id="outlined-basic"
                      variant="outlined"
                      disabled={!isFieldEnabled}
                      name="phone"
                      sx={{
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      autoComplete="off"
                      value={props.values.mobile_no}
                      onChange={(e) => {
                        const input = e.target.value;
                        const onlyNumbers = input.replace(/\D/g, ""); // Remove non-numeric characters

                        // Set the field value to the cleaned onlyNumbers
                        props.setFieldValue("mobile_no", onlyNumbers);
                      }}
                      helperText={
                        props.errors.mobile_no &&
                        (props.touched.mobile_no || mobileTouched) // Use emailTouched here
                          ? props.errors.mobile_no
                          : " "
                      }
                      error={
                        !!(
                          (
                            props.errors.mobile_no &&
                            (props.touched.mobile_no || mobileTouched)
                          ) // Use emailTouched here
                        )
                      }
                      onBlur={() => {
                        setMobileTouched(true); // Set emailTouched to true when onBlur is called
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                        margin: "0px 0px 40px",
                      }}
                    />
                  </div>
                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      User Role <span style={{ color: "red" }}>*</span>
                    </div>
                    <Autocomplete
                      Listitem={RoleData?.map((v: any) => ({
                        label: v.name,
                        value: v.id,
                      }))}
                      variant={"outlined"}
                      disabled={!isFieldEnabled}
                      name={"role"}
                      value={props.values}
                      onBlur={props.handleBlur}
                      onchange={(e: any) => {
                        props.setFieldValue("role", e.label);
                      }}
                      style={{
                        paddingBottom: "27px",
                        width: "100%",
                        height: "50px",
                        borderBottom: "50px",
                      }}
                      error={props.errors.role && props.touched.role}
                    ></Autocomplete>
                    {props.errors.role && props.touched.role ? (
                      <FormHelperText
                        style={{ marginLeft: "13px", marginTop: "10px" }}
                        error
                      >
                        {props.errors.role}
                      </FormHelperText>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={() => {
                      navigate("/viewuser");
                    }}
                    style={{
                      width: "100px",
                      height: "40px",
                      border: "1px solid #EF990F",
                      color: "black",
                      marginRight: "2%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={!isFieldEnabled}
                    style={{
                      width: "100px",
                      height: "40px",
                      color: "black",
                      fontFamily: "Roboto",
                      fontSize: "14px",

                      background: !props.isValid
                        ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                        : "linear-gradient(to right, #FFFA02,#F2A618 )",

                      borderRadius: "5px",
                    }}
                  >
                    Save
                  </button>
                </div>
                <PopupMessage
                  subtitle={"Warning"}
                  successMessage={dataPassed === "edit" ? editData : addData}
                  open={editDialog}
                  pathnavigate={"/viewuser"}
                  Dialogtitle={"Are you sure you want to proceed?"}
                  submitFunction={() => handleApiCall(props.values)}
                  isLoading={
                    dataPassed === "edit" ? editisLoading : addisLoading
                  }
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddUser;
