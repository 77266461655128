import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter trade
export const filterBacktest = createSlice({
  name: "filterBacktest",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },
  reducers: {
    edit_filterBacktest: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filterBacktest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filterBacktest: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filterBacktest_is_success: (state) => {
      state.isSuccess = false;
    },
    filterBacktest_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default filterBacktest.reducer;
export const {
  edit_filterBacktest,
  success_api_call_filterBacktest,
  filterBacktest_complete_api,
  failure_api_call_filterBacktest,
  filterBacktest_is_success,
} = filterBacktest.actions;
