import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import {
  clearStrategiesList,
  list_StrategiesList,
} from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { list_MapStrategiesList } from "../Redux/Reducers/MapStrategies/list_MapStrategies_slicer";
import { edit_filtermap } from "../Redux/Reducers/Filtervalue/filtermap";
import { useState } from "react";

type Anchor = "top" | "left" | "bottom" | "right";

export default function MapStrategyfilter(props: any) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const filtermap: any = useAppSelector((state) => state?.filtermap?.data);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const dispatcher = useAppDispatch();

  // filter the aglo name
  const dataStrategies = useAppSelector((state) => state?.StrategiesList?.data);
  const datas = useAppSelector((state) => state?.AdminList?.data);
  const data = useAppSelector((state) => state?.AlgoList?.data);

  const defaultOption1 = { name: "All", id: null };
  const addto = [defaultOption1, ...data];
  const addtoexteroption = [defaultOption1, ...dataStrategies];
  const exteroption = [defaultOption1, ...datas];

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <span title="Filter">
            <FilterListIcon
              onClick={toggleDrawer(anchor, true)}
              sx={{
                marginTop: "5px",
                fontSize: "24px",
                marginRight: "5px",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            ></FilterListIcon>
          </span>

          <Drawer anchor={anchor} open={state[anchor]}>
            <Box
              sx={{
                width: {
                  xs: 200,
                  sm: 200,
                  md: 300,
                  lg: 360,
                  xl: 400,
                },
                zIndex: "1",
              }}
              role="presentation"
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#2C8E14",
                  justifyContent: "space-between",
                  height: "64px",
                }}
              >
                <span
                  style={{
                    padding: "17px",
                    fontSize: "20px",

                    color: "white",
                    fontFamily: "Roboto",
                  }}
                >
                  Filter
                </span>
                <div style={{ padding: "20px", cursor: "pointer" }}>
                  <CancelIcon
                    sx={{ color: "white" }}
                    onClick={toggleDrawer(anchor, false)}
                  ></CancelIcon>
                </div>
              </div>
              <Formik
                initialValues={{
                  algo_id: filtermap?.algo_id ?? null,
                  strategy_id: filtermap?.strategy_id ?? null,
                  user_id: filtermap?.user_id ?? null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values) => {
                  dispatcher(list_MapStrategiesList(values));
                  dispatcher(edit_filtermap(values));
                }}
              >
                {({
                  values,

                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Algo
                    </div>
                    <Autocomplete
                      Listitem={addto.map((v: any) => {
                        return {
                          label: v.name,
                          value: v.id,
                        };
                      })}
                      variant={"outlined"}
                      name={"algo_id"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("algo_id", e.value);
                        dispatcher(
                          list_StrategiesList({
                            algo_id: e.value,
                            is_enabled: true,
                          })
                        );
                        setFieldValue("strategy_id", null);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Strategy
                    </div>
                    <Autocomplete
                      Listitem={addtoexteroption.map((v: any) => {
                        return {
                          label: v.name,
                          value: v.id,
                        };
                      })}
                      variant={"outlined"}
                      name={"strategy_id"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("strategy_id", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select User
                    </div>
                    <Autocomplete
                      Listitem={exteroption.map((v: any) => {
                        return {
                          label: v.name,
                          value: v.id,
                        };
                      })}
                      variant={"outlined"}
                      name={"user_id"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("user_id", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div
                      className="bottom_div"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#2C8E14",
                        width: "100%",
                        height: "60px",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="button"
                            onClick={() => {
                              dispatcher(list_MapStrategiesList({}));
                              dispatcher(
                                edit_filtermap({
                                  algo_id: null,
                                  strategy_id: null,
                                })
                              );
                              dispatcher(clearStrategiesList());

                              toggleDrawer(anchor, false);
                            }}
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              marginRight: "20px",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontFamily: "Roboto",
                              border: "1px solid #F2A618",
                            }}
                          >
                            Reset
                          </button>
                        </span>
                      </div>
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="submit"
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",

                              background: !props.isValid
                                ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                                : "linear-gradient(to right, #FFFA02,#F2A618 )",

                              borderRadius: "5px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
