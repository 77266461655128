import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddGroupAccount,
  success_api_call_add_AddGroupAccount,
} from "./add_GroupAccount_slicer";
import {
  failure_api_call_EditGroupAccount,
  success_api_call_EditGroupAccount,
} from "./edit_GroupAccount_slicer";
import {
  failure_api_call_ListGroupAccount,
  success_api_call_ListGroupAccount,
} from "./list_GroupAccount_slicer";
import {
  failure_api_call_delete_DeleteGroupAccount,
  success_api_call_delete_DeleteGroupAccount,
} from "./delete_GroupAccount_slicer";

//add  group account
const AddGroupAccountCall = async (a: any) => {
  const idforlogin = localStorage.getItem("idforlogin");
  const idfor = localStorage.getItem("idforfilter");
  const accountdata = a.payload.Account;
  const accountarraydata = accountdata.map((account: any) => ({
    id: account.value,
    broker: account.label.split(",")[0],
    client_id: account.label.split(",")[1],
  }));
  const rolename: any = localStorage.getItem("role");
  var add = {};
  if (rolename.includes("ADMIN")) {
    add = {
      name: a.payload.groupname,
      accounts: accountarraydata,
      user_id: idfor,
    };
  } else {
    add = {
      name: a.payload.groupname,
      accounts: accountarraydata,
      user_id: idforlogin,
    };
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/group/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddGroupAccountApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddGroupAccountCall, e);
    yield put(success_api_call_add_AddGroupAccount(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddGroupAccount(e));
    yield put(expireSignature(e));
  }
}

//Edit group account
const EditGroupAccountCall = async (a: any) => {
  const get_id = localStorage.getItem("groupfilter");
  const get_token = localStorage.getItem("token");
  const idfor = localStorage.getItem("idforfilter");
  const idforlogin = localStorage.getItem("idforlogin");
  const accountdata = a.payload.Account;
  const accountarraydata = accountdata.map((account: any) => ({
    id: account.value,
    broker: account.label.split(",")[0],
    client_id: account.label.split(",")[1],
  }));
  const rolename: any = localStorage.getItem("role");
  let edit = {};
  if (rolename.includes("ADMIN")) {
    edit = {
      name: a.payload.groupname,
      accounts: accountarraydata,
      user_id: idfor,
    };
  } else {
    edit = {
      name: a.payload.groupname,
      accounts: accountarraydata,
      user_id: idforlogin,
    };
  }
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/group/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* EditGroupAccountApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditGroupAccountCall, e);
    yield put(success_api_call_EditGroupAccount(response.data));
  } catch (e) {
    yield put(failure_api_call_EditGroupAccount(e));
    yield put(expireSignature(e));
  }
}

//list group account
const ListGroupAccountCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const idfor = localStorage.getItem("idforfilter");
  const idforlogin = localStorage.getItem("idforlogin");
  let url;
  const value: any = localStorage.getItem("role");
  if (value.includes("ADMIN")) {
    url = `${process.env.REACT_APP_api_url}/v1/api/group?user_id=${idfor}`;
  } else {
    url = `${process.env.REACT_APP_api_url}/v1/api/group?user_id=${idforlogin}`;
  }
  return Axios.get(
    url,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* ListGroupAccountApi(e: any) {
  try {
    const response: AxiosResponse = yield call(ListGroupAccountCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,

      field_id: i + 1,
    }));
    yield put(success_api_call_ListGroupAccount(res));
  } catch (e) {
    yield put(failure_api_call_ListGroupAccount(e));
    yield put(expireSignature(e));
  }
}
// Delete  group account
export function* DeleteGroupAccountApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteGroupAccountCall, e);
    yield put(success_api_call_delete_DeleteGroupAccount(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteGroupAccount(error));
  }
}
const DeleteGroupAccountCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/group/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
