import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter stocks 
export const filterstocks = createSlice({
  name: "filterstocks",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: null,
  },
  reducers: {
    edit_filterstocks: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filterstocks: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filterstocks: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filterstocks_is_success: (state) => {
      state.isSuccess = false;
    },
    filterstocks_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default filterstocks.reducer;
export const {
  edit_filterstocks,
  success_api_call_filterstocks,
  filterstocks_complete_api,
  failure_api_call_filterstocks,
  filterstocks_is_success,
} = filterstocks.actions;
