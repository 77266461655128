import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Holdings,
  success_api_call_Holdings,
} from "./list_Holdings_slicer";

//list Holding
const listHoldingsCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const loginid = localStorage.getItem("idforlogin");
  const list = {
    client_id: a.payload.client_id,
    user_id: loginid,
    account_id: a.payload.id,
  };
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/account/holdings`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* HoldingsApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listHoldingsCall, e);
    let res=response?.data?.data
    yield put(success_api_call_Holdings(res));
  } catch (e) {
    yield put(failure_api_call_Holdings(e));
    yield put(expireSignature(e));
  }
}
