import * as React from "react";
import { Formik, Form } from "formik"; // Removed unnecessary import for Field
import * as Yup from "yup";
import {
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Input,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import requied from "../Image/validationimage.png";
import Eye from "../Image/eyeopen.png";
import Eye2 from "../Image/eyecloses.png";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { FunctionComponent } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router";
import "firebase/auth";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./login.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppSelector, useAppDispatch } from "../../Redux/store/hooks";
import {
  false_apiRespone,
  false_compleation,
  login,
} from "../../Redux/Reducers/User-Register&Login/Login_slicer";

import InvestaaLogo from "../Image/InvestaaLogo.png";
interface LoginvalidationProps {}

const Loginvalidation: FunctionComponent<LoginvalidationProps> = () => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [open, setOpen] = React.useState(false);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(false_compleation());
    setOpen(false);
  };

  const fontSize = window.innerWidth < 600 ? "10px" : "14px";
  const log_in: any = useAppSelector((state) => state.Login.data);
  const apiRespone: any = useAppSelector((state) => state.Login.apiRespone);
  const isLoading = useAppSelector(
    (state: { Login: { isLoading: any } }) => state.Login.isLoading
  );

  const auth = getAuth();

  React.useEffect(() => {
    if (log_in.status === true) {
      navigate("/");
        }
      if(apiRespone){
        setOpen(true);
      }
      return ()=>{
        dispatch(false_apiRespone())
      }
  }, [log_in, navigate,apiRespone,dispatch]);

  // Function to encrypt data
  const encryptData = (data: any) => {
    const encryptedData = btoa(data); // Base64 encoding for simplicity, NOT secure for real passwords
    return encryptedData;
  };

  // Function to decrypt data
  const decryptData = (encryptedData: any) => {
    const decryptedData = atob(encryptedData); // Base64 decoding for simplicity, NOT secure for real passwords
    return decryptedData;
  };
  const storedEmail = localStorage.getItem("userEmail");
  const storedPassword = localStorage.getItem("userPassword");
  const decrypt_email = decryptData(storedEmail);
  const decrypt_password = decryptData(storedPassword);
  const checked_box = localStorage.getItem("userIschecked");

  // Validation Schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[0-7])(?=.*[!@#$%^&*()]).{8,50}$/,
        "Must contain at least 8 characters"
      )
      .required("Please enter the valid password"),
  });


  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          {log_in?.message
            ? log_in?.message
            : "Invalid Login Credentials"}
        </Alert>
      </Snackbar>
      <div
        className="login_container"
        style={{ height: "100%", width: "100%", backgroundColor: "white" }}
      >
        <div className="login_form">
          <img
            src={InvestaaLogo}
            alt={"Investaa Logo"}
            className="login_logo"
          />
          <span className="login_data">
            <span className="login_hello"> Hello,</span>
            <span className="login_welcome">Welcome</span>
          </span>
          <span className="login_acc">Login to your existing account</span>
          <div className="login_form_1">
            <Formik
              initialValues={
                checked_box === "true"
                  ? {
                      email: decrypt_email || "",
                      password: decrypt_password || "",
                      isChecked: checked_box || false,
                    }
                  : {
                      email: "",
                      password: "",
                      isChecked: false,
                    }
              }
              onSubmit={async (values: any) => {
                try {
                  const { email, password } = values;
                  signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential: any) => {
                      dispatch(login(userCredential.user.email));
                      if (values.isChecked === true) {
                        const encryptedEmail = encryptData(values.email);
                        const encryptedPassword = encryptData(values.password);
                        localStorage.setItem("userEmail", encryptedEmail);
                        localStorage.setItem("userPassword", encryptedPassword);
                        localStorage.setItem("userIschecked", values.isChecked);
                      } else {
                        localStorage.setItem("userEmail", "");
                        localStorage.setItem("userPassword", "");
                        localStorage.setItem("userIschecked", "");
                      }
                    })
                    .catch((e) => {
                      setOpen(true);
                    });
                } catch (error: any) {
                  setOpen(true);
                }
              }}
              validationSchema={validationSchema} // Apply the validation schema here
            >
              {({ touched, errors, values, handleBlur, setFieldValue }) => (
                <Form autoComplete="off" className="log_form">
                  {/* Email Field */}
                  <TextField
                    name="email"
                    id="email"
                    className="form_email"
                    label="Email"
                    variant="standard"
                    value={values.email}
                    margin="dense"
                    size="small"
                    sx={{
                      "& label": {
                        color: errors.email ? "#d32f2f" : "black !important",
                        fontSize: `${fontSize}`,
                        fontWeight: "250",
                        marginTop: "20px",
                        fontFamily: "Roboto !important",
                      },
                      "& input": {
                        // width: "25rem",
                        fontSize: "14px",
                        color: "black !important",
                        fontWeight: 200,
                      },
                      "& .MuiInputBase-root": {
                        marginTop: 5,
                      },
                    }}
                    type="text"
                    autoComplete="off"
                    error={!!errors.email}
                    helperText={
                      errors.email &&
                      touched.email && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          <img
                            src={requied}
                            alt="Error"
                            style={{
                              marginRight: "5px",
                              verticalAlign: "middle",
                              width: "13px",
                              marginTop: "-4px",
                            }}
                          />
                          {errors.email.toString()}
                        </Typography>
                      )
                    }
                    onChange={(e: any) => {
                      const inputvalue = e.target.value;
                      setFieldValue("email", inputvalue);
                    }}
                    onBlur={handleBlur}
                  />

                  {/* Password Field */}
                  <FormControl
                    variant="standard"
                    margin="dense"
                    className="form_field"
                    size="small"
                    sx={{
                      "& input": {
                        width: "25rem",
                        fontSize: "15px",
                        color: "black !important",
                        fontWeight: 200,
                      },

                      "& label": {
                        color: "black",
                        fontSize: `${fontSize}`,
                        fontWeight: "250",
                        marginTop: "20px",
                        fontFamily: "Roboto !important",
                      },
                      "& .MuiInputBase-root": {
                        marginTop: 5,
                      },
                    }}
                  >
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      style={{
                        color: errors.password ? "#d32f2f" : "black",
                        fontSize: "30px !important",
                        fontWeight: "250",
                      }}
                    >
                      Password
                    </InputLabel>
                    <Input
                      id="password"
                      name="password"
                      value={values.password}
                      type={showPassword ? "text" : "password"}
                      inputProps={{
                        autoComplete: "off",
                      }}
                      error={!!errors.password}
                      onChange={(e: any) => {
                        const inputvalue = e.target.value;
                        setFieldValue("password", inputvalue);
                      }}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            "& input": {
                              color: "white",
                              width: "65%",
                            },
                            "&:hover": {
                              backgroundColor: "transparent", // Set the background color to be transparent on hover
                            },
                            "& .my-custom-class": {
                              fontSize: "16px",
                            },
                          }}
                        >
                          <IconButton
                            sx={{
                              width: "20px",
                              display: "flex",
                              justifyContent: "flex-end",

                              "&:hover": {
                                backgroundColor: "transparent", // Set the background color to be transparent on hover
                              },
                            }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <img
                                src={Eye}
                                alt=""
                                width="15px"
                                height="10px"
                              />
                            ) : (
                              <img
                                src={Eye2}
                                alt=""
                                width="20px"
                                height="20px"
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error={!!errors.password}>
                      {errors.password ? (
                        <span style={{ color: "#d32f2f", fontWeight: "300" }}>
                          {touched.password && ( // Check if the field has been touched
                            <img
                              src={requied}
                              alt="Error"
                              style={{
                                marginRight: "5px",
                                verticalAlign: "middle",
                                width: "13px",
                                marginTop: "-4px",
                              }}
                            />
                          )}
                          {errors.password.toString()}
                        </span>
                      ) : null}
                    </FormHelperText>
                  </FormControl>

                  <div
                    className="checkboxstyle"
                    style={{
                      display: "flex",
                      fontWeight: 200,
                      alignItems: "center",
                      color: "black",
                      padding: "0.6rem 0",
                      fontSize: "14px",
                      marginTop: "-20px",
                      fontFamily: "Roboto !important",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isChecked}
                          name="isChecked"
                          value={values.isChecked}
                          onChange={(e) => {
                            const inputvalue = e.target.checked;
                            setFieldValue("isChecked", inputvalue);
                          }}
                        />
                      }
                      label=""
                      labelPlacement="end"
                      sx={{
                        padding: "2px",
                        marginRight: "0px",
                        borderColor: "#000000",
                      }}
                    ></FormControlLabel>
                    Remember Me
                  </div>

                  {/* Login Button */}
                  <span className="Loginbuttonstyle">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      loadingIndicator={
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      }
                      loading={isLoading}
                      style={{
                        color: isLoading ? "transparent" : "black",
                        background:
                          "linear-gradient(to right, #FFFA02,#F2A618 )",
                        textTransform: "none",
                        boxShadow: "none",
                        height: "40px",
                      }}
                    >
                      Login
                    </LoadingButton>
                  </span>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loginvalidation;
