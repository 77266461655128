import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list userpermission
export const User_permission = createSlice({
  name: "User_permission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_User_permission: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_User_permission: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_User_permission: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    User_permission_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearUser_permission: (state) => {
      state.data = [];
    },
  },
});
export default User_permission.reducer;
export const {
  list_User_permission,
  success_api_call_User_permission,
  failure_api_call_User_permission,
  User_permission_is_first,
  clearUser_permission,
} = User_permission.actions;
