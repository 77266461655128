import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { Switch } from "@mui/material";
import { Rolevalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Role-values-slicer";
import { DeleteRolevalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteRole-values-slicer";
import DialogRole from "../../Component/DialogForFormRole";
import { edit_EditRole } from "../../Redux/Reducers/Roles/edit_Role_slicer";
import editimage from "../Image/Edit.svg";

interface RolesMessageprops {
  valuepopup: number;
  editdata: any;
}

const RolesMessage: FunctionComponent<RolesMessageprops> = (props: any) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const editData: any = useAppSelector((state) => state?.EditRole?.data);
  const editDataIsLoading = useAppSelector(
    (state) => state?.EditRole?.isLoading
  );
  const pageName = "Role";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <Switch
            title="Active / Inactive for Role"
            {...label}
            size="small" // Add this line to set the switch size to small
            checked={props.editdata.status === true} // Set the checked prop based on the state
            onChange={(e) => {
              localStorage.setItem("Rolefilter", props.valuepopup);

              dispatcher(
                Rolevalues_datagrid({
                  is_enabled: e.target.checked,
                })
              );
            }}
          />
          <DialogRole
            successMessage={editData}
            isLoading={editDataIsLoading}
            Buttondialog={["edit"]}
            Dialogtitle={"Edit Role"}
            getValues={(v: any) => {
              dispatcher(edit_EditRole(v));
            }}
            placeholder=""
            sx={{
              backgroundColor: "transparent",
              minWidth: "0",
              padding: "0",
              borderRadius: "50%",
              height: "60%",
              // marginTop: "4%",
              "&:hover": { backgroundColor: "transparent" },
            }}
            edit={{
              ...props.editdata,
            }}
            Buttonname={
              <span
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const dataToPass = props.editdata;

                  localStorage.setItem("Rolefilter", dataToPass.id);
                }}
              >
                <img src={editimage} width="25px" alt={""}></img>
              </span>
            }
          />
        </>
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "rgb(239, 153, 15)",
            cursor: "pointer",
            marginLeft: "10px",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteRolevalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default RolesMessage;
