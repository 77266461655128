import { FunctionComponent } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import Grids from "../../Component/Grides";
import { list_AlgoList } from "../../Redux/Reducers/Algo-api/list_AlgoList_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Algo from "../../Component/DialogForFormAlgoDymics";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import Message from "./Message";
import Dialogs from "../../Component/Dialog";
import { delete_algo } from "../../Redux/Reducers/AlgoDymics";
import { false_switchdeletealgo } from "../../Redux/Reducers/Datagrid-values/DeleteAlgo-values-slicer";

interface ViewAlgoAddProps {
  trade_check: any;
}

const ViewAlgoAdd: FunctionComponent<ViewAlgoAddProps> = (props) => {
  const dispatcher = useAppDispatch();
  const Algodymics = useAppSelector((state) => state?.AlgoDymics?.data);
  const isLoadingdata = useAppSelector((state) => state?.AlgoDymics?.isLoading);
  const strategiesdelete: any = useAppSelector(
    (state) => state.DeleteAlgovalues.data
  );
  const switchOpenaddalgodelete = useAppSelector(
    (state) => state.DeleteAlgovalues.switch
  );
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const pageName = "Add Algo";
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const column = [
    {
      sortable: true,
      headerName: "Field Name",
      flex: 1,
      field: "name",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined

        if (params.row.name === null || params.row.name === undefined) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.name;
      },
    },
    {
      sortable: true,
      headerName: "Key Name",
      flex: 1,
      field: "key",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (params.row.key === null || params.row.key === undefined) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.key;
      },
    },

    {
      sortable: true,
      headerName: "Value",
      flex: 1,
      field: "value",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (params.row.value === null || params.row.value === undefined) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.value;
      },
    },
    {
      sortable: true,
      headerName: "Unit",
      flex: 1,
      field: "unit",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (params.row.unit === null || params.row.unit === undefined) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.unit;
      },
    },
    {
      sortable: true,
      headerName: "Input Type",
      flex: 1,
      field: "inputType",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (
          params.row.inputType === null ||
          params.row.inputType === undefined
        ) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.inputType;
      },
    },
    {
      sortable: true,
      headerName: "Is Mandatory",
      flex: 1,
      field: "is_mandatory",
      renderCell: (params: GridRenderCellParams<any>) => {
        const optionValues =
          params.row.is_mandatory === "true" || params.row.is_mandatory === true
            ? "Active"
            : "Inactive";

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={optionValues}
          >
            {optionValues}
          </div>
        );
      },
    },

    {
      sortable: true,
      headerName: " Min Value",
      flex: 1,
      field: "min_value",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (
          params.row.min_value === null ||
          params.row.min_value === undefined
        ) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.min_value;
      },
    },
    {
      sortable: true,
      headerName: " Max Value",
      flex: 1,
      field: "max_value",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (
          params.row.max_value === null ||
          params.row.max_value === undefined
        ) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.max_value;
      },
    },
    {
      sortable: true,
      headerName: "Min Length",
      flex: 1,
      field: "min_length",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (
          params.row.min_length === null ||
          params.row.min_length === undefined
        ) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.min_length;
      },
    },
    {
      sortable: true,
      headerName: "Max Length",
      flex: 1,
      field: "max_length",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Check if the value is null or undefined
        if (
          params.row.max_length === null ||
          params.row.max_length === undefined
        ) {
          return "-";
        }
        // Render the actual value if it's not null
        return params.row.max_length;
      },
    },

    {
      sortable: true,
      headerName: "Options",
      flex: 1,
      field: "options",
      renderCell: (params: GridRenderCellParams<any>) => {
        const optionValues = params.row.options;

        if (optionValues === null || optionValues.length === 0) {
          return "-";
        }

        const formattedOptions = optionValues.join(", ");

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={formattedOptions}
          >
            {formattedOptions}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <>
                {
                  <Message
                    tradecheck={props.trade_check}
                    valuepopup={params.row.id}
                    editdata={params.row}
                  ></Message>
                }
              </>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  React.useEffect(() => {
    dispatcher(list_AlgoList(""));
  }, [dispatcher]);

  const initialColumnVisibilityModel: any = {
    name: true,
    key: true,
    value: true,
    unit: true,
    inputType: true,
    is_mandatory: true,
    min_value: true,
    max_value: true,
    min_length: true,
    max_length: true,
    options: true,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingdata}
      >
        <CircularProgress style={{ color: "rgb(239, 153, 15)" }} />
      </Backdrop>
      <Grids
        row={Algodymics ?? []}
        column={column}
        showedoutbuttonchack
        Removetheborder={"yes"}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        pagination
        dialog={
          <Algo
            sx={{
              color: "white",
              borderRadius: "5px",
              fontFamily: "Roboto",
              fontSize: "16px",
            }}
            placeholder="Role"
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Input Field"}
            check_trade={props.trade_check}
            Buttonname={
              <Button
                disabled={
                  MenuOfUserpermission?.permission?.add === false ||
                  props.trade_check === true
                }
                sx={{
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                  color: "black",
                  height: "37px",
                  width: {
                    xs: 60,
                    sm: 100,
                    md: 100,
                    lg: 100,
                    xl: 150,
                  },
                  borderRadius: "5px",
                  fontFamily: "Roboto",
                  fontSize: {
                    xs: "10px",
                    sm: "10px",
                    md: "12px",
                    lg: "11px",
                    xl: "15px",
                  },
                  textTransform: "capitalize",
                  "&:hover": { backgroundColor: "#EF990F" },
                }}
              >
                Add Input Field
              </Button>
            }
          />
        }
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        subtitle={"Warning"}
        open={switchOpenaddalgodelete}
        setOpen={() => dispatcher(false_switchdeletealgo())}
        Dialog_Content={"Are you sure you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "#F4A717",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F4A717",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchdeletealgo())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "#F4A717",
                "&:hover": {
                  background: "#F4A717",
                },
                width: "18%",
                color: "white",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatcher(delete_algo(strategiesdelete));
                dispatcher(false_switchdeletealgo());
              }}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default ViewAlgoAdd;
