import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { Switch } from "@mui/material";
import { Menuvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Menu-values-slicer";
import { DeleteMenuvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteMenu-values-slicer";
import DialogMenu from "../../Component/DialogForFormMenu";
import editimage from "../Image/Edit.svg";
import { edit_EditMenu } from "../../Redux/Reducers/Menu/edit_Menu_slicer";
import { list_MenuListFilter } from "../../Redux/Reducers/Menu/list_MenuFilter_slicer";

interface MenuMessageprops {
  valuepopup: number;
  editdata: any;
}

const MenuMessage: FunctionComponent<MenuMessageprops> = (props: any) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const pageName = "Menu";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];
  const editData: any = useAppSelector((state) => state?.EditMenu?.data);
  const editDataIsLoading = useAppSelector(
    (state) => state?.EditMenu?.isLoading
  );
  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <Switch
            title="Active / Inactive for Menu"
            {...label}
            size="small" // Add this line to set the switch size to small
            checked={props.editdata.status === true} // Set the checked prop based on the state
            onChange={(e) => {
              localStorage.setItem("Menufilter", props.valuepopup);

              dispatcher(
                Menuvalues_datagrid({
                  is_enabled: e.target.checked,
                })
              );
            }}
          />
          <DialogMenu
            successMessage={editData}
            isLoading={editDataIsLoading}
            Buttondialog={["edit"]}
            Dialogtitle={"Edit Menu"}
            getValues={(v: any) => {
              dispatcher(edit_EditMenu(v));
            }}
            placeholder=""
            sx={{
              backgroundColor: "transparent",
              minWidth: "0",
              padding: "0",
              borderRadius: "50%",
              height: "60%",
              // marginTop: "4%",
              "&:hover": { backgroundColor: "transparent" },
            }}
            edit={{
              ...props.editdata,
            }}
            editlist={"editlistdata"}
            Buttonname={
              <span
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const dataToPass = props.editdata;
                  localStorage.setItem("Menufilter", dataToPass.id);
                  dispatcher(
                    list_MenuListFilter([
                      { status: true },
                      { Pagemethod: dataToPass.portal },
                    ])
                  );
                }}
              >
                <img src={editimage} width="25px" alt={""}></img>
              </span>
            }
          />
        </>
      ) : null}
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "rgb(239, 153, 15)",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteMenuvalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default MenuMessage;
