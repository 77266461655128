import { createSlice } from "@reduxjs/toolkit";
 // this code for slice value for EditableValueAlgo
interface IpreValue {
  name?:string;
  is_enabled?:boolean
}
export const EditableValueAlgo = createSlice({
  name: "EditableValueAlgo",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data:{},
    preValue:{} as IpreValue
  },
  reducers: {
    edit_EditableValueAlgo: (state, action) => {

      state.data = action.payload;
    },
    success_api_call_EditableValueAlgo: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_EditableValueAlgo: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    EditableValueAlgo_is_success: (state) => {
      state.isSuccess = false;
    },
    EditableValueAlgo_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_EditableValueAlgo: (state) => {
      state.data = {};
      state.preValue = {};
    },
    update_EditableValueAlgo: (state, action) => {  
       state.data = action.payload.map((val:any,i:number)=>({...val,id:i}));
    },
    updata_preValue:(state,action) =>{
      state.preValue = action.payload
    }
  },
});
export default EditableValueAlgo.reducer;
export const {
  edit_EditableValueAlgo,
  success_api_call_EditableValueAlgo,
  EditableValueAlgo_complete_api,
  failure_api_call_EditableValueAlgo,
  EditableValueAlgo_is_success,
  clear_EditableValueAlgo,update_EditableValueAlgo,updata_preValue
} = EditableValueAlgo.actions;
