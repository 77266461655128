import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for Expire  Signature
export const ExpireSignature = createSlice({
  name: "ExpireSignature",
  initialState: {
    data: [],
  },
  reducers: {
    expireSignature: (state, action?) => {
      state.data = action?.payload;
    },
    updateexpireSignature: (state, action?) => {
      state.data = action?.payload;
    },
  },
});
export default ExpireSignature.reducer;
export const { expireSignature, updateexpireSignature } =
  ExpireSignature.actions;
