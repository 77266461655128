import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  Holding
export const Holdings = createSlice({
  name: "Holdings",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    mapStratagiesId: null,
    data: [],
    holding:{}
  },
  reducers: {
    list_Holdings: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Holdings: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;      
    },
    failure_api_call_Holdings: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    Holdings_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearHoldings: (state) => {
      state.data = [];
    },
  
  },
});
export default Holdings.reducer;
export const {
  list_Holdings,
  success_api_call_Holdings,
  failure_api_call_Holdings,
  Holdings_is_first,
  clearHoldings,
} = Holdings.actions;
