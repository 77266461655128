import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list order 
export const Order = createSlice({
  name: "Order",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    mapStratagiesId: null,
    data: [],
  },
  reducers: {
    list_Order: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Order: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_Order: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    Order_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearOrder: (state) => {
      state.data = [];
    },
    mapStratagiesId_update: (state, action) => {
      state.mapStratagiesId = action.payload;
    },
  },
});
export default Order.reducer;
export const {
  list_Order,
  success_api_call_Order,
  failure_api_call_Order,
  Order_is_first,
  clearOrder,
  mapStratagiesId_update,
} = Order.actions;
