import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as firebase from "firebase/app";
import "firebase/auth";
import { store } from "./Redux/store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// this firebase  login method 
const firebaseConfig =JSON.parse(process.env.REACT_APP_Firebase_api_url!)
firebase.initializeApp(firebaseConfig); 

// this overall website run in the code is index
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
