import { FunctionComponent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import Grids from "../../Component/Grides";
import "./Order.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import * as ExcelJS from "exceljs";
import OrderFilter from "../../Component/OrderFilter";
import { Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  clearOrder,
  list_Order,
} from "../../Redux/Reducers/Order/list_Order_slicer";
import RefreshIcon from "@mui/icons-material/Refresh";
import { clearfilterOrder } from "../../Redux/Reducers/Filtervalue/filterOrder";
import React from "react";

interface OrderProps {}

const Order: FunctionComponent<OrderProps> = () => {
  const data: any = useAppSelector((state) => state?.Order?.data);
  const dispatcher = useAppDispatch();

  const length_of_data = data.length;

  const countOrderTypes = (orders: any) => {
    return orders.reduce((count: any, order: any) => {
      count[order.order_type] = (count[order.order_type] || 0) + 1;
      return count;
    }, {});
  };
  const orderCounts = countOrderTypes(data);

  const heading = (
    <div className="ordertitle">
      <div className="orderhanding">
        <div style={{ color: "#B7B7B7" }}>Total Orders </div>
        <div style={{ fontSize: "16px" }}>{length_of_data}</div>
      </div>
      <div className="orderhanding">
        <div style={{ color: "rgb(100 213 100)" }}>Total BUY </div>
        <div style={{ fontSize: "16px", color: "green" }}>
          {orderCounts["BUY"] || 0}
        </div>
      </div>
      <div className="orderhanding">
        <div style={{ color: "rgb(225 118 118)" }}>Total SELL</div>
        <div style={{ fontSize: "16px", color: "red" }}>
          {orderCounts["SELL"] || 0}
        </div>
      </div>
    </div>
  );

  const currentDateTime = new Date().toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const namelist = localStorage.getItem("name");

  const exportToExcel = (data: any) => {
    let sheetName = "Orders.xlsx";
    let headerName = "Orders Data";

    let workbook = new ExcelJS.Workbook();

    let sheet = workbook.addWorksheet(sheetName);

    let columnArr = [];
    for (let i in data[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnArr.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        showFirstColumn: true,
        // width: 200
      },
      columns: [{ name: "Orders List" }],
      rows: [
        [
          `Generated At: ${new Date().toLocaleDateString(
            "en-IN"
          )} ${currentDateTime}`,
        ],
        [`Generated By: ${namelist}`],
      ],
    });

    sheet.addTable({
      name: headerName,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
      },
      columns: columnArr
        ? columnArr.map((col) => ({
            name: col.name,
            filterButton: false,
            style: { alignment: { horizontal: "center" } },
          }))
        : [{ name: "" }],
      rows: data.map((e: any) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("A1").font = { size: 20, bold: true };

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });

    const table = sheet.getTable(headerName);
    if (table && table.columns) {
      for (let i = 0; i < table.columns.length; i++) {
        // Access table columns and rows directly
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d9f1" },
        };

        if (table.rows) {
          for (let j = 0; j < table.rows.length; j++) {
            let rowCell = sheet.getCell(
              `${String.fromCharCode(65 + i)}${j + 6}`
            );
            rowCell.alignment = { horizontal: "center", wrapText: true };
            rowCell.border = {
              bottom: {
                style: "thin",
                color: { argb: "a6a6a6" },
              },
            };
          }
        }
      }
    }

    table.commit();

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      writeFile(sheetName, buffer);
    });
  };

  const column = [
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1,
      field: "stock_name",
    },
    {
      sortable: true,
      headerName: "Execution Type",
      flex: 1,
      field: "execution_type",
    },
    {
      sortable: true,
      headerName: "Trade Type",
      flex: 1,
      field: "product_type",
    },
    {
      sortable: true,
      headerName: "Quantity",
      flex: 1,
      field: "quantity",
    },
    {
      sortable: true,
      headerName: "Order Type",
      flex: 1,
      field: "order_type",
    },

    {
      sortable: true,
      headerName: "Order Status",
      flex: 1,
      field: "order_status",
    },
    {
      sortable: true,
      headerName: "Traded Price",
      flex: 1,
      field: "traded_price",
    },
    {
      headerName: "Executed Time",
      field: "order_time",
      flex: 1,
      headerClassName: "custom-header",
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.order_time);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.order_time === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.order_time * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Terminal Message",
      field: "terminal_message",
      sortable: true,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.terminal_message;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const terminal_message = params.row?.terminal_message;

        if (terminal_message === "" || null) {
          return "-";
        }

        // Render the exit price value if it's not null
        return <span title={terminal_message}>{terminal_message}</span>; // You can adjust the formatting as needed
      },
    },
  ];

  const initialColumnVisibilityModel: any = {
    stock_name: true,
    execution_type: true,
    product_type: true,
    quantity: true,
    order_type: true,
    order_status: true,
    traded_price: true,
    terminal_message: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    dispatcher(
      list_Order({
        from_date: `${year}-${month}-${day}`,
        to_date: `${year}-${month}-${day}`,
        client_id: localStorage.getItem("client_id"),
        id: localStorage.getItem("id"),
        account_name: localStorage.getItem("account_name"),
      })
    );
    dispatcher(clearfilterOrder());
    return () => {
      dispatcher(clearOrder());
    };
  }, [dispatcher]);

  // this code  for excle
  const exportdatalist = data.map((data: any) => {
    const timestampInMilliseconds = data?.order_time * 1000;
    // Create a Date object from the timestamp
    const utcDates = new Date(timestampInMilliseconds);

    const ISTDates = new Date(utcDates.getTime());

    const formattedISTDates = ISTDates.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });

    return {
      StockName: data.stock_name,
      ExecutionType: data.execution_type,
      TradeType: data.product_type,
      Quantity: data.quantity,
      OrderType: data.order_type,
      OrderStatus: data.order_status,
      TradedPrice: data.traded_price,
      ExecutedTime: formattedISTDates === null ? "-" : formattedISTDates,
      TerminalMessage:
        data?.terminal_message === "" || null ? "-" : data?.terminal_message,
    };
  });
  return (
    <div style={{ height: "93%", display: "flex", flexDirection: "column" }}>
      <Grids
        row={data ?? []}
        column={column}
        Removetheborder={"yes"}
        Users={heading}
        swithoutbutton
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        pagination
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              const today = new Date();
              const year = today.getFullYear();
              const month = String(today.getMonth() + 1).padStart(2, "0");
              const day = String(today.getDate()).padStart(2, "0");
              dispatcher(
                list_Order({
                  from_date: `${year}-${month}-${day}`,
                  to_date: `${year}-${month}-${day}`,
                  client_id: localStorage.getItem("client_id"),
                  id: localStorage.getItem("id"),
                  account_name: localStorage.getItem("account_name"),
                })
              );
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        Export={
          <span
            title={
              data.length > 0
                ? "Export"
                : "No data available in the table. Please note that data export is not possible."
            }
            onClick={() => {
              if (data.length > 0) {
                exportToExcel(exportdatalist);
              }
            }}
          >
            <SaveAltIcon
              sx={{
                fontSize: "24px",
                marginTop: "5px",
                marginRight: "0px",
                cursor: "pointer",
              }}
            ></SaveAltIcon>
          </span>
        }
        sideButton={<OrderFilter></OrderFilter>}
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default Order;
