import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for add stock
export const AddStock = createSlice({
  name: "AddStock",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_AddStock: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_AddStock: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_AddStock: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    add_AddStock_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_AddStock_complete_api: (state, action) => {
      state.api_completed = false;
    },
    clear_AddStock_Data: (state) => {
      state.data = [];
    },
    false_switchAddStock_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default AddStock.reducer;
export const {
  add_AddStock,
  success_api_call_add_AddStock,
  failure_api_call_add_AddStock,
  create_AddStock_complete_api,
  add_AddStock_is_success,
  clear_AddStock_Data,
  false_switchAddStock_response
} = AddStock.actions;
