import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import LoginRoute from "./Routers/LoginRouter";
import "./pages/login/login.css";

// this is overall function
function App() {
  // Code for dynamic color apply for overall website  that is theme in mui only
  const theme = createTheme({
    palette: {
      primary: {
        main: "#F2A618",
      },
      secondary: {
        main: "#008000",
      },
    },
  });

  return (
    <div className="app">
      {/* // Code for dynamic color apply for overall website  that is theme in mui only  // */}
      <ThemeProvider theme={theme}>
        <LoginRoute></LoginRoute>
      </ThemeProvider>
    </div>
  );
}

export default App;
