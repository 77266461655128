import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProfileDetails from "../pages/UserDetail/Profile_Details";
import MappedStrategy from "../pages/UserDetail/Mapped_Strategy";
import TradeDetails from "../pages/UserDetail/Trade_Details";
import { NavLink, useLocation } from "react-router-dom";
import { LinearProgress, Typography } from "@mui/material";
import { useAppSelector } from "../Redux/store/hooks";
import ViewGroupaccount from "../pages/GroupAccount/ViewGroupaccount";
import Account from "../pages/accounts/AdminAccounts";
import "../Component/Addalgostyle.css";

export default function LabTabs() {
  const location = useLocation();
  const [value, setValue] = React.useState("1");

  React.useEffect(() => {
    if (location.pathname === "/Userdetails") {
      setValue("1");
    } else if (location.pathname === "/tradingaccount") {
      setValue("3");
    }
  }, [location]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Users
    </Typography>,
    <NavLink
      to="/viewuser"
      style={{ textDecoration: "none", color: "#BDBDBD" }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "#BDBDBD",
          fontFamily: "Roboto",
        }}
      >
        View User
      </Typography>
    </NavLink>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      User Details
    </Typography>,
  ];

  const isLoading = useAppSelector((state) => state?.AccountList?.isLoading);
  const addDataIsLoading = useAppSelector((state) => state.AddCard.isLoading);
  const editDataIsLoading = useAppSelector((state) => state.EditCard.isLoading);
  const DeleteisLoading: any = useAppSelector(
    (state) => state.DeleteCard.isLoading
  );
  const editDataGroupIsLoading = useAppSelector(
    (state) => state?.EditGroupAccount?.isLoading
  );
  const deleteGroupData: any = useAppSelector(
    (state) => state?.DeleteGroupAccount?.isLoading
  );
  const addDataGroupLoading: any = useAppSelector(
    (state) => state?.AddGroupAccount?.isLoading
  );
  const dataGroupLoading: any = useAppSelector(
    (state) => state?.ListGroupAccount?.isLoading
  );
  const TradeListLoading = useAppSelector(
    (state) => state?.TradeList?.isLoading
  );
  const isLoadingdataTreadSetup = useAppSelector(
    (state) => state?.TreadSetupList?.isLoading
  );
  const editDataIsLoadingprofile: any = useAppSelector(
    (state) => state?.EditAdmin?.isLoading
  );

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoading ||
              editDataIsLoadingprofile ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading ||
              editDataGroupIsLoading ||
              deleteGroupData ||
              addDataGroupLoading ||
              dataGroupLoading ||
              TradeListLoading ||
              isLoadingdataTreadSetup
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div
        style={{
          backgroundColor: "white",
          padding: "0px",
          flex: "1",
          height: "90%",
          display: "flex",
          borderRadius: "10px",
          boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            typography: "body1",
          }}
        >
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                width: "100%",
              }}
            >
              <TabList
                onChange={handleChange}
                // sx={{ overflowX: "auto" }}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#338514",
                    height: "2px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <Tab
                  label="Profile Details"
                  value="1"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "1" ? "#338514" : "black" }}
                />
                <Tab
                  label="Trade Setup"
                  value="2"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "2" ? "#338514" : "black" }}
                />
                <Tab
                  label="Trading Accounts"
                  value="3"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "3" ? "#338514" : "black" }}
                />
                <Tab
                  label="Trading Group"
                  value="4"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "4" ? "#338514" : "black" }}
                />
                <Tab
                  label="Trade Details"
                  value="5"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "5" ? "#338514" : "black" }}
                />
              </TabList>
            </Box>

            <TabPanel
              value="1"
              sx={{ flex: "1", height: "97%", padding: "10px" }}
            >
              <ProfileDetails></ProfileDetails>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{ flex: "1", height: "97%", padding: "10px" }}
            >
              <MappedStrategy></MappedStrategy>
            </TabPanel>
            <TabPanel
              value="3"
              sx={{ flex: "1", overflow: "auto", padding: "10px" }}
            >
              <Account></Account>
            </TabPanel>
            <TabPanel
              value="4"
              sx={{ flex: "1", height: "97%", padding: "10px" }}
            >
              <ViewGroupaccount></ViewGroupaccount>
            </TabPanel>
            <TabPanel
              value="5"
              sx={{ flex: "1", height: "90%", padding: "10px" }}
            >
              <TradeDetails></TradeDetails>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}
