import { FunctionComponent, useEffect } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  FormHelperText,
  Menu,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import { list_Stock } from "../Redux/Reducers/Stock/list_Stock_slicer";
import Select from "./Select";
import { list_ListGroupAccount } from "../Redux/Reducers/GroupAccount/list_GroupAccount_slicer";
import { add_AddOpenPosition } from "../Redux/Reducers/Open-Position/add_open_position_slicer";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogOpenPoistion_manualProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogOpenPoistionmanual: FunctionComponent<
  DialogOpenPoistion_manualProps
> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { isLoading }: any = props;

  const initialValues: {
    group: ({ label: string; value: string } | null)[];
    selectstocks: { label: string; value: string }[];
    quantity: number;
    price: number;
    target: number;
    product: string;
    ordertype: string;
    type: string;
    triggerprice: number;
    minutes: string;
    discqty: number;
    validity: string;
    // ordertype: "SELL",
  } = {
    group: props?.edit?.group?.name ?? [],
    selectstocks: props?.edit?.stock_name || [],
    quantity: props?.edit?.quantity,
    price: props?.edit?.entry || 0,
    target: props?.edit?.target || 0,
    product: props?.edit?.product_type || "MIS",
    ordertype: props?.edit?.order_type || "BUY",
    type: props?.edit?.execution_type || "MARKET",
    triggerprice: props?.edit?.sl,
    minutes: "1",
    discqty: 0,
    validity: "DAY",
    // ordertype: "SELL",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    settype("MARKET");
    setstockfilter("0");
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  const [stockfilter, setstockfilter] = React.useState("0");

  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [snackbarOpen]);

  const dispatcher = useAppDispatch();
  const groupname: any = useAppSelector(
    (state) => state?.ListGroupAccount?.data
  );
  React.useEffect(() => {
    dispatcher(
      list_Stock({
        segment: ["ALL"],
      })
    );
    dispatcher(list_ListGroupAccount(""));
  }, [dispatcher]);

  const pageName = "Open Position";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];
  const [type, settype] = React.useState("MARKET");
  const stock: any[] = useAppSelector((state) => state?.Stock?.data || []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openmenu = Boolean(anchorEl);
  const handleClickmenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosemenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={MenuOfUserpermission?.permission?.add === false}
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "1000px",
            width: "1000px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              group: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .min(1, "Please select a group account")
                .required("Please select a  group account"),
              selectstocks: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .min(1, "Please select a stock")
                .required("Please select a stock"),
              quantity: Yup.number().required("Please enter a quantity"),
              type: Yup.string().required("Please enter a type"),
              product: Yup.string().required("Please enter a product"),
              ordertype: Yup.string().required("Please enter a order type"),
              price: Yup.number().required("Please enter a price"),
              target: Yup.number()
                .when("type", {
                  is: (value: string) => ["LIMIT", "SL-L"].includes(value),
                  then: () =>
                    Yup.number()
                      .test(
                        "BUY",
                        "Target value should be lower than price",
                        function (value, context) {
                          if (!value) {
                            return false;
                          }
                          if (
                            context.parent.ordertype === "BUY" &&
                            context.parent.price < value
                          ) {
                            return false;
                          }
                          return true;
                        }
                      )
                      .test(
                        "SELL",
                        "Target value should be higher than price",
                        function (value, context) {
                          if (!value) {
                            return false;
                          }
                          if (
                            context.parent.ordertype === "SELL" &&
                            context.parent.price > value
                          ) {
                            return false;
                          }
                          return true;
                        }
                      ),
                })
                .required("Please enter a target"),

              triggerprice: Yup.number().when("type", {
                is: (value: string) => ["SL-L", "SL-M"].includes(value),
                then: () =>
                  Yup.number()
                    .test(
                      "BUY",
                      "SL-price value should be higher than price",
                      function (value, context) {
                        if (!value) {
                          return false;
                        }
                        if (
                          context.parent.ordertype === "BUY" &&
                          context.parent.price > value
                        ) {
                          return false;
                        }
                        return true;
                      }
                    )
                    .test(
                      "SELL",
                      "SL-price value should be lower than price",
                      function (value, context) {
                        if (!value) {
                          return false;
                        }
                        if (
                          context.parent.ordertype === "SELL" &&
                          context.parent.price < value
                        ) {
                          return false;
                        }
                        return true;
                      }
                    )
                    .required("Please enter a SL-price"),
              }),
            })}
            onSubmit={(values, formikHelpoer) => {
              setOpen(false);
              dispatcher(add_AddOpenPosition(values));
              settype("MARKET");
              setstockfilter("0");
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />

                  <div
                    style={{
                      display: "grid",
                      gap: "1em",
                      gridTemplateColumns:
                        "repeat(auto-fill,minmax(15rem,1fr))",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          width: "100%",
                        }}
                      >
                        Select Group <span style={{ color: "red" }}>*</span>
                      </div>

                      <Select
                        Listitem={groupname?.map((v: any) => {
                          return {
                            label: v.name,
                            value: v.id,
                          };
                        })}
                        variant={"outlined"}
                        name={"group"}
                        disableClearable
                        value={props.values.group[0] ?? null}
                        onBlur={props.handleBlur}
                        onchange={(value: any, newValue: any) => {
                          props.setFieldValue("group", [newValue]);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                        }}
                        error={!!(props.errors.group && props.touched.group)}
                      ></Select>
                      {props.errors.group && props.touched.group ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {/* {"Please select the group"} */}
                          {props.errors.group as string}
                        </FormHelperText>
                      ) : null}
                    </div>

                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Select Stock <span style={{ color: "red" }}>*</span>
                        </div>
                        <div>
                          <span
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickmenu}
                            style={{ cursor: "pointer" }}
                          >
                            <FilterListIcon></FilterListIcon>
                          </span>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openmenu}
                            onClose={handleClosemenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                dispatcher(
                                  list_Stock({
                                    segment: ["ALL"],
                                  })
                                );
                                handleClosemenu();
                              }}
                            >
                              All
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatcher(
                                  list_Stock({
                                    segment: ["EQUITY"],
                                  })
                                );
                                handleClosemenu();
                              }}
                            >
                              Equity
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatcher(
                                  list_Stock({
                                    segment: ["FUTURE"],
                                  })
                                );
                                handleClosemenu();
                              }}
                            >
                              Future
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatcher(
                                  list_Stock({
                                    segment: ["OPTION"],
                                  })
                                );
                                handleClosemenu();
                              }}
                            >
                              Option
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>

                      <Select
                        Listitem={stock?.map((v: any) => {
                          return {
                            label: v.stock_name,
                            value: v.id,
                          };
                        })}
                        variant={"outlined"}
                        name={"selectstocks"}
                        disableClearable
                        value={props?.values?.selectstocks[0] ?? null}
                        onBlur={props?.handleBlur}
                        onchange={(e: any, newValue: any) => {
                          props.setFieldValue("selectstocks", [newValue]);
                          const stock_value = newValue?.value;
                          const filter_stocks = stock?.find((value: any) => {
                            return value.id === stock_value;
                          });
                          setstockfilter(filter_stocks?.lot_size);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          // borderBottom: "50px",
                        }}
                        error={
                          !!(
                            props.errors.selectstocks &&
                            props.touched.selectstocks
                          )
                        }
                      ></Select>
                      {props.errors.selectstocks &&
                      props.touched.selectstocks ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {/* {"Please select the stock"} */}
                          {props.errors.selectstocks as string}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Product <span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <ToggleButtonGroup
                          color={
                            props.values.ordertype === "BUY"
                              ? "secondary"
                              : "primary"
                          }
                          value={props.values.product}
                          sx={{
                            height: "57px",
                            width: "100%",
                          }}
                          exclusive
                          onChange={(
                            event: React.MouseEvent<HTMLElement>,
                            newAlignment: string
                          ) => {
                            if (newAlignment !== null) {
                              props.setFieldValue("product", newAlignment);
                            }
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            value="MIS"
                            sx={{ fontSize: "13px", width: "100%" }}
                          >
                            Intraday - MIS
                          </ToggleButton>
                          <ToggleButton
                            value="CNC"
                            sx={{ fontSize: "13px", width: "100%" }}
                          >
                            Longterm - CNC
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {props.errors.product && props.touched.product ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                            error
                          >
                            {/* {"Please select the product"} */}
                            {props.errors.product as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Type <span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <ToggleButtonGroup
                          color={
                            props.values.ordertype === "BUY"
                              ? "secondary"
                              : "primary"
                          }
                          value={props.values.type}
                          sx={{
                            height: "57px",
                            width: "100%",
                          }}
                          exclusive
                          onChange={(
                            event: React.MouseEvent<HTMLElement>,
                            newAlignment: string
                          ) => {
                            if (newAlignment !== null) {
                              settype(newAlignment);
                              props.setFieldValue("type", newAlignment);
                            }
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton value="MARKET" sx={{ width: "100%" }}>
                            Market
                          </ToggleButton>
                          <ToggleButton value="LIMIT" sx={{ width: "100%" }}>
                            Limit
                          </ToggleButton>
                          <ToggleButton value="SL-L" sx={{ width: "100%" }}>
                            SL-L
                          </ToggleButton>
                          <ToggleButton value="SL-M" sx={{ width: "100%" }}>
                            SL-M
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {props.errors.type && props.touched.type ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                            error
                          >
                            {/* {"Please select the type"} */}
                            {props.errors.type as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          Quantity <span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "gray" }}>
                          Lot Size : {stockfilter}
                        </div>
                      </div>

                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="quantity"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          error={
                            !!(props.errors.quantity && props.touched.quantity)
                          }
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            // Use regex to check if the input contains only characters (letters)
                            if (/^[0-9\s]+$/.test(inputValue)) {
                              props.setFieldValue("quantity", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              props.setFieldValue(
                                "quantity",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.quantity}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />

                        {props.errors.quantity && props.touched.quantity ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                            error
                          >
                            {/* {"Please enter a quantity"} */}
                            {props.errors.quantity as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>

                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Price <span style={{ color: "red" }}> *</span>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="price"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          error={!!(props.errors.price && props.touched.price)}
                          onChange={(e: any) => {
                            const inputValue = e.target.value;

                            if (type === "MARKET" || type === "SL-M") {
                              // If type is "MARKET" or "SL"
                              props.setFieldValue("price", 0);
                            } else {
                              // If type is not "MARKET" or "SL"
                              if (/^[0-9.\s]+$/.test(inputValue)) {
                                props.setFieldValue("price", inputValue);
                              } else {
                                props.setFieldValue(
                                  "price",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={
                            type === "MARKET" || type === "SL-M"
                              ? 0
                              : props.values.price
                          }
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />

                        {props.errors.price && props.touched.price ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                            error
                          >
                            {/* {"Please enter a price"} */}
                            {props.errors.price as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Target <span style={{ color: "red" }}> *</span>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          // required
                          variant="outlined"
                          name="target"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          error={
                            !!(props.errors.target && props.touched.target)
                          }
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            if (/^[0-9.\s]+$/.test(inputValue)) {
                              props.setFieldValue("target", inputValue);
                            } else {
                              props.setFieldValue(
                                "target",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          // onBlur={props.handleBlur}
                          value={props.values.target}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />

                        {props.errors.target && props.touched.target ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                            error
                          >
                            {/* {"Please enter a target"} */}
                            {props.errors.target as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>

                    {type === "SL-L" || type === "SL-M" ? (
                      <div style={{ height: "100px", width: "100%" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          SL Price <span style={{ color: "red" }}> *</span>
                        </div>
                        <div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="triggerprice"
                            sx={{
                              "& fieldset": {
                                borderRadius: "5px",
                              },
                            }}
                            autoComplete="off"
                            error={
                              !!(
                                props.errors.triggerprice &&
                                props.touched.triggerprice
                              )
                            }
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              if (/^[0-9.\s]+$/.test(inputValue)) {
                                props.setFieldValue("triggerprice", inputValue);
                              } else {
                                props.setFieldValue(
                                  "triggerprice",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            // onBlur={props.handleBlur}
                            value={props.values.triggerprice}
                            style={{
                              width: "100%",
                              height: "50px",
                              borderBottom: "50px",
                            }}
                          />

                          {props.errors.triggerprice &&
                          props.touched.triggerprice ? (
                            <FormHelperText
                              style={{
                                marginLeft: "13px",
                                marginTop: "10px",
                              }}
                              error
                            >
                              {/* {"Please enter a SL price"} */}
                              {props.errors.triggerprice as string}
                            </FormHelperText>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("ordertype", "SELL");
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                        border: "1px solid #EF990F",
                        color: "white",
                        marginRight: "1%",
                        backgroundColor: "#F23645",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Sell
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("ordertype", "BUY");
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                        color: "white",
                        background: !props.isValid ? "#089981" : "#089981",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Buy
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogOpenPoistionmanual;
