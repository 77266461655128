import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_backtest,
  success_api_call_backtest,
} from "./list_backtest_slicer";
import {
  failure_api_call_add_addbacktest,
  success_api_call_add_addbacktest,
} from "./add_backtest_slicer";
import {
  failure_api_call_delete_backtest,
  success_api_call_delete_backtest,
} from "./delete_Backtest_slicer";

//list back test
const listbacktestCall = async (a: any) => {
  const getuserid = localStorage.getItem("idforfilter") !== null;
  let list: any = getuserid
    ? { user_id: localStorage.getItem("idforfilter") }
    : {};
  if (a.payload !== "undefined") {
    list = {
      ...list,
      algo_id: a.payload.algo_id,
      strategy_id: a.payload.strategy_id,
      start_date: a.payload.start_date,
      end_date: a.payload.end_date,
      stock_id: a.payload.Stocks,
    };
  }

  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/backtest/list`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* backtestApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listbacktestCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_backtest(res));
  } catch (e) {
    yield put(failure_api_call_backtest(e));
    yield put(expireSignature(e));
  }
}

//add Backtest
const addbacktestCall = async (a: any) => {
  const add = {
    algo_id: a.payload.algo_id,
    strategy_id: a.payload.Strategy,
    stop_loss: a.payload.stoploss,
    target: a.payload.takeprofit,
    quantity: a.payload.quantity,
    trailing_stop_loss: a.payload.TrailingStopLoss,
    stock_id: a.payload.Stock.value,
    start_date: a.payload.from_date,
    end_date: a.payload.to_date,
    commission: a.payload.commission,
    slippage: a.payload.Slippage,
    commission_type: a.payload.commissionPercentage,
    status: a.payload.status,
  };

  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/backtest/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* addbacktestApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addbacktestCall, e);
    yield put(success_api_call_add_addbacktest(response.data));
  } catch (e) {
    yield put(failure_api_call_add_addbacktest(e));
    yield put(expireSignature(e));
  }
}

// Delete back test
export function* DeleteBacktestApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteBacktestCall, e);
    yield put(success_api_call_delete_backtest(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_backtest(error));
  }
}
const DeleteBacktestCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/backtest/delete/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
