import { FunctionComponent, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../App.css";
import "../pages/Page.css";
import "./Grid_dashboard.css";
type FooterStatus = "connected" | "disconnected";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    status: FooterStatus;
    gatpass?: any;
    pagination?: any;
    Userpermissions?: any;
  }
}

interface GridsdashboardProps {
  Userpermissions?: any;
  pagination?: any;
  gatpass?: boolean;
  scrollbarSize?: number;
  column?: any;
  Users?: any;
  dialog?: any;
  dialogopenposition?: any;
  sideButton?: any;
  row?: any;
  serach?: any;
  swithoutbutton?: boolean;
  showedoutbuttonchack?: boolean;
  opened?: boolean;
  open?: boolean;
  opens?: boolean;
  Export?: any;
  refericon?: any;
  paperTrade?: any;
  Camera?: boolean;
  UserPermission?: boolean;
  ServiceStatus?: boolean;
  gridesbutton?: boolean;
  data?: any;
  roleData?: any;
  Submitbutton?: any;
  Gatepassbutton?: any;
  Servicestatusbutton?: any;
  componet?: any;
  height?: any;
  setDate?: any;
  getRowId?: string;
}

const Gridsdashboard: FunctionComponent<GridsdashboardProps> = (props) => {
  const [tableData, setTableData] = useState<any[]>([]);
  useEffect(() => {
    setTableData(props?.row);
  }, [setTableData, props?.row]);

  return (
    <div
      style={{
        flex: "1",
        width: "100%",
        height: "100%",
      }}
    >
      <DataGrid
        hideFooter
        sx={{
          "&::-webkit-scrollbar": { display: "none" },
          borderRadius: "10px !important",
          backgroundColor: "white",
        }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        getRowClassName={(params) => {
          return params.row.shouldHaveBorder
            ? "custom-row"
            : "custom-row no-border";
        }}
        disableColumnMenu
        rows={tableData}
        columns={props.column}
        getRowId={(row) => (row?.field_id ? row?.field_id : row.id)}
      />
    </div>
  );
};

export default Gridsdashboard;
