import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { Switch } from "@mui/material";
import editimage from "../Image/Edit.svg";
import DialogUsageControl from "../../Component/DialogForFormUsage_Control";
import { UsageControlvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/UsageControl-values-slicer";
import { DeleteUsageControlvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteUsageControl-values-slicer";
import { edit_EditUsageControl } from "../../Redux/Reducers/UsageControl/edit_UsageControl_slicer";

interface MapStartegyprops {
  valuepopup: number;
  editdata: any;
}

const UsageControlMessage: FunctionComponent<MapStartegyprops> = (
  props: any
) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const editData: any = useAppSelector(
    (state) => state?.EditUsageControl?.data
  );
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditUsageControl?.isLoading
  );
  const pageName = "Usage Control";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <DialogUsageControl
            successMessage={editData}
            isLoading={editDataIsLoading}
            Buttondialog={["edit"]}
            Dialogtitle={"Edit Usage Control"}
            getValues={(v: any) => {
              dispatcher(edit_EditUsageControl(v));
            }}
            placeholder=""
            sx={{
              backgroundColor: "transparent",
              minWidth: "0",
              height: "60%",
              "&:hover": { backgroundColor: "transparent" },
            }}
            edit={{
              ...props.editdata,
            }}
            Buttonname={
              <span
                title="Edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const dataToPass = props.editdata;
                  localStorage.setItem("UsageControlfilter", dataToPass.id);
                }}
              >
                <img src={editimage} width="25px" alt={""}></img>
              </span>
            }
          />
          <Switch
            title="Status"
            {...label}
            size="small" // Add this line to set the switch size to small
            checked={props.editdata.status === 1} // Set the checked prop based on the state
            onChange={(e) => {
              localStorage.setItem("UsageControlfilter", props.valuepopup);
              dispatcher(
                UsageControlvalues_datagrid({
                  status: props.editdata.status === 1 ? 0 : 1,
                })
              );
            }}
          />
        </>
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "#F2A618",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteUsageControlvalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default UsageControlMessage;
