import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for square_off
export const Squareoff = createSlice({
  name: "Squareoff",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    Square_off: (state, action?) => {
      state.isLoading = true;      
    },
    success_api_call_Squareoff: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.switchResponse = true;
      state.isFirst = true;
    },
    failure_api_call_Squareoff: (state, action) => {
      state.isLoading = false;
      state.switchResponse = false;
    },
    Squareoff_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearSquareoff: (state) => {
      state.data = [];
    },
    false_switchSquareoff_response: (state) => {
      state.switchResponse = false;
    },
    
  },
});
export default Squareoff.reducer;
export const {
  Square_off,
  success_api_call_Squareoff,
  failure_api_call_Squareoff,
  Squareoff_is_first,
  clearSquareoff,
  false_switchSquareoff_response
} = Squareoff.actions;
