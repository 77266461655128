import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  add map strategy
export const addbacktest = createSlice({
  name: "addbacktest",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_addbacktest: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_addbacktest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_addbacktest: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    add_addbacktest_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_addbacktest_complete_api: (state, action) => {
      state.api_completed = false;
    },
    clear_add_Data: (state) => {
      state.data = [];
    },
    false_switchmapadd_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default addbacktest.reducer;
export const {
  add_addbacktest,
  success_api_call_add_addbacktest,
  failure_api_call_add_addbacktest,
  create_addbacktest_complete_api,
  add_addbacktest_is_success,
  clear_add_Data,
  false_switchmapadd_response
} = addbacktest.actions;
