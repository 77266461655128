import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for AddAdmin
export const AddAdmin = createSlice({
  name: "AddAdmin",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_AddAdmin: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_AddAdmin: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_AddAdmin: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    add_AddAdmin_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_AddAdmin_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchAdmin_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default AddAdmin.reducer;
export const {
  add_AddAdmin,
  success_api_call_add_AddAdmin,
  failure_api_call_add_AddAdmin,
  create_AddAdmin_complete_api,
  add_AddAdmin_is_success,
  false_switchAdmin_response
} = AddAdmin.actions;
