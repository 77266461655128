import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for Edit trade setup
export const EditTreadSetup = createSlice({
  name: "EditTreadSetup",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,

    data: [],
  },
  reducers: {
    edit_EditTreadSetup: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditTreadSetup: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;

    },
    failure_api_call_EditTreadSetup: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;

    },
    EditTreadSetup_is_success: (state) => {
      state.isSuccess = false;
    },
    EditTreadSetup_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_edit_Data: (state) => {
      state.data = [];
    },

    false_switchTreadSetup_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditTreadSetup.reducer;
export const {
  edit_EditTreadSetup,
  success_api_call_EditTreadSetup,
  EditTreadSetup_complete_api,
  failure_api_call_EditTreadSetup,
  EditTreadSetup_is_success,
  clear_edit_Data
  ,false_switchTreadSetup_response
} = EditTreadSetup.actions;
