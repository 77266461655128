import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_EditMenu,
  success_api_call_EditMenu,
} from "./edit_Menu_slicer";
import {
  failure_api_call_MenuList,
  success_api_call_MenuList,
} from "./list_Menu_slicer";
import {
  failure_api_call_delete_DeleteMenu,
  success_api_call_delete_DeleteMenu,
} from "./delete_Menu_slicer";
import {
  failure_api_call_add_AddMenu,
  success_api_call_add_AddMenu,
} from "./add_Menu_slicer";
import {
  failure_api_call_MenuListFilter,
  success_api_call_MenuListFilter,
} from "./list_MenuFilter_slicer";

//add menu
const AddMenuCall = async (a: any) => {
  const add = {
    icon: a.payload.Icon,
    name: a.payload.menuname,
    path_name: a.payload.Pathname === "" ? null : a.payload.Pathname,
    status: a.payload.is_enabled,
    main_menu: a.payload.MainMenu === "" ? null : a.payload.MainMenu,
    portal: a.payload.Portal,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/menu/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddMenuApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddMenuCall, e);
    yield put(success_api_call_add_AddMenu(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddMenu(e));
    yield put(expireSignature(e));
  }
}

//Edit Menu
const EditMenuCall = async (a: any) => {
  const get_id = localStorage.getItem("Menufilter");
  let edit = {
    icon: a.payload.Icon,
    name: a.payload.menuname,
    path_name: a.payload.Pathname === "" ? null : a.payload.Pathname,
    status: a.payload.is_enabled,
    main_menu: a.payload.MainMenu === "" ? null : a.payload.MainMenu,
    portal: a.payload.Portal,
  };
  const get_token = localStorage.getItem("token");
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/menu/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* EditMenuApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditMenuCall, e);
    yield put(success_api_call_EditMenu(response.data));
  } catch (e) {
    yield put(failure_api_call_EditMenu(e));
    yield put(expireSignature(e));
  }
}

//list Menu
const MenuListCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const list = {
    portal: a.payload[1].Pagemethod,
    status: a.payload[0].status,
  };
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/menu`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* MenuListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(MenuListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,

      field_id: i + 1,
    }));
    yield put(success_api_call_MenuList(res));
  } catch (e) {
    yield put(failure_api_call_MenuList(e));
    yield put(expireSignature(e));
  }
}

//list filter menu
const MenuListFilterCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const lists = {
    portal: a.payload[1].Pagemethod,
    status: a.payload[0].status,
  };
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/menu`,
    lists,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* MenuListFilterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(MenuListFilterCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,

      field_id: i + 1,
    }));
    yield put(success_api_call_MenuListFilter(res));
  } catch (e) {
    yield put(failure_api_call_MenuListFilter(e));
    yield put(expireSignature(e));
  }
}
// Delete
export function* DeleteMenuApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteMenuCall, e);
    yield put(success_api_call_delete_DeleteMenu(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteMenu(error));
  }
}
const DeleteMenuCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/menu/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
