import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import Dialog from "../../Component/DialogForFormMapStrategies";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  clearData,
  list_MapStrategiesList,
} from "../../Redux/Reducers/MapStrategies/list_MapStrategies_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import MapStrategyfilter from "../../Component/MapStrategyfilter";
import {
  add_AddMapStrategies,
  false_switchmapadd_response,
} from "../../Redux/Reducers/MapStrategies/add_MapStrategies_slicer";
import {
  edit_EditMapStrategies,
  false_switchmap_response,
} from "../../Redux/Reducers/MapStrategies/edit_MapStrategies_slicer";
import MapStrategyMessage from "./MapStartegyMessage";
import Dialogs from "../../Component/Dialog";
import {
  delete_MapStrategies,
  false_switchdeletemap_response,
} from "../../Redux/Reducers/MapStrategies/delete_MapStrategies_slicer";
import { false_switchDeleteMepvalues } from "../../Redux/Reducers/Datagrid-values/DeleteMap-values-slicer";
import { false_switchMapvalues } from "../../Redux/Reducers/Datagrid-values/Map-values-slicer";
import { edit_filtermap } from "../../Redux/Reducers/Filtervalue/filtermap";
import { list_TreadSetupList } from "../../Redux/Reducers/Trade-setup/list_Trade-setup_slicer";
import { list_AlgoList } from "../../Redux/Reducers/Algo-api/list_AlgoList_slicer";
import { list_AdminList } from "../../Redux/Reducers/Admin-api/list_AdminList_slicer";

interface MapStrategiesProps {}

const MapStrategies: FunctionComponent<MapStrategiesProps> = () => {
  const dispatcher = useAppDispatch();
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Strategies
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Map Strategies
    </Typography>,
  ];

  // Selector to get data from the Redux store
  const Tradesetupdata = useAppSelector((state) => state?.TreadSetupList?.data);
  const data: any = useAppSelector((state) => state?.MapStrategiesList?.data);
  const datavaluefilter = data.map((data: any) => data.algo.name);
  const datafilter = Tradesetupdata.map((data: any) => data.algo.name);

  const strategiesfilter = data.map((data: any) => data.strategy);
  const strategedatafilter = Tradesetupdata.map(
    (data: any) => data.strategy.name
  );

  function checkAllSubArray(subArray: any, names: any) {
    return subArray.every((item: any) => names.includes(item.name));
  }

  // Loop through the map and add the status based on the check
  const updatedMap = strategiesfilter.map((subArray: any, index: any) => {
    const statuss = checkAllSubArray(subArray, strategedatafilter);
    const isMatched =
      datavaluefilter.includes(data[index]?.algo?.name) &&
      datafilter.includes(data[index]?.algo?.name);
    const overallStatus = statuss && isMatched;
    return [{ status: overallStatus }, ...subArray];
  });

  const joinedData = updatedMap.map((item: any, index: any) => ({
    ...data[index], // Merging corresponding data item

    strategies: item, // Adding the strategies (with status)
  }));

  const addData: any = useAppSelector((state) => state?.AddMapStrategies?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddMapStrategies?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteMapStrategies?.data
  );
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteMapStrategies?.isLoading
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.MapStrategiesList?.isLoading
  );
  const AddResponse = useAppSelector(
    (state) => state?.AddMapStrategies?.switchResponse
  );
  const switchResponse = useAppSelector(
    (state) => state?.EditMapStrategies?.switchResponse
  );
  const Uservalues: any = useAppSelector((state) => state?.Mapvalues?.data);
  const switchOpen = useAppSelector((state) => state?.Mapvalues?.switch);
  const switchResponsealgodelete = useAppSelector(
    (state) => state?.DeleteMapStrategies?.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state?.DeleteMepvalues?.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state?.DeleteMepvalues?.switch
  );
  const editData: any = useAppSelector(
    (state) => state?.EditMapStrategies?.data
  );
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditMapStrategies?.isLoading
  );

  const column = [
    {
      sortable: true,
      headerName: "Algo Name",
      flex: 1,
      field: "algo",
      valueGetter: (params: any) => {
        return params?.row?.algo?.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const algoName = params?.row?.algo?.name;

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      headerName: "Strategy Name",
      sortable: true,
      valueGetter: (params: any) => {
        return params?.row?.strategy?.map((strategy: any) => strategy.name);
      },
      field: "strategy", // Assuming "strategy" is the property in your data
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "strategy" object
        const strategyNames =
          params.row.strategy
            .map((strategy: any) => strategy.name)
            .join(", ") ?? "-";

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={strategyNames}
          >
            {strategyNames}
          </div>
        );
      },
      flex: 1,
    },
    {
      sortable: true,
      headerName: "User ",
      flex: 1,
      field: "user",
      valueGetter: (params: any) => {
        return params?.row?.user?.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const userName = params?.row?.user?.name;
        const userid = params?.row?.user?.app_id;
        return (
          <div title={`User Name : ${userName} , App Id : ${userid}`}>
            {userid} <span></span>| <span></span>
            {userName}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <MapStrategyMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></MapStrategyMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    algo: true,
    strategy: true,
    user: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(edit_filtermap({}));
    dispatcher(list_MapStrategiesList(""));
    dispatcher(list_TreadSetupList(""));
    dispatcher(list_AlgoList(""));
    dispatcher(list_AdminList({ status: 1, role: "USER" }));
    if (switchResponse) {
      dispatcher(false_switchMapvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteMepvalues());
    }
    return () => {
      dispatcher(clearData());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponse,
    switchResponsealgodelete,
  ]);

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchmap_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchdeletemap_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchmapadd_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchmapadd_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchmapadd_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchmap_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchmap_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchdeletemap_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchdeletemap_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={joinedData}
        column={column}
        gridesbutton
        pagination
        sideButton={<MapStrategyfilter></MapStrategyfilter>}
        dialog={
          <Dialog
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              marginTop: "0px",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            placeholder="Role"
            getValues={(v: any) => {
              dispatcher(add_AddMapStrategies(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Map Strategies"}
            Buttonname={"Add New"}
          />
        }
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpen}
        setOpen={() => dispatcher(false_switchMapvalues())}
        Dialog_Content={
          Uservalues.status === 1
            ? "Do you want to de-activate map strategies?"
            : "Do you want to activate the map strategies?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchMapvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditMapStrategies(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpenalgodelete}
        setOpen={() => dispatcher(false_switchDeleteMepvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteMepvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_MapStrategies(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default MapStrategies;
