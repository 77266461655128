import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   DeleteHolidayvalues
export const DeleteHolidayvalues = createSlice({
  name: "DeleteHolidayvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    DeleteHolidayvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_DeleteHolidayvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_DeleteHolidayvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    DeleteHolidayvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_DeleteHolidayvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchDeleteHolidayvalues:(state)=>{
        state.switch = false
    }
  },
});
export default DeleteHolidayvalues.reducer;
export const {
  DeleteHolidayvalues_datagrid,
  success_api_call_DeleteHolidayvalues_datagrid,
  failure_api_call_DeleteHolidayvalues_datagrid,
  create_DeleteHolidayvalues_datagrid_complete_api,
  DeleteHolidayvalues_datagrid_is_success,false_switchDeleteHolidayvalues
} = DeleteHolidayvalues.actions;
