import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   DeleteGroupAccountvalues
export const DeleteGroupAccountvalues = createSlice({
  name: "DeleteGroupAccountvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    DeleteGroupAccountvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true;
    },
    success_api_call_DeleteGroupAccountvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_DeleteGroupAccountvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    DeleteGroupAccountvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_DeleteGroupAccountvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchDeleteGroupAccountvalues: (state) => {
      state.switch = false;
    },
  },
});
export default DeleteGroupAccountvalues.reducer;
export const {
  DeleteGroupAccountvalues_datagrid,
  success_api_call_DeleteGroupAccountvalues_datagrid,
  failure_api_call_DeleteGroupAccountvalues_datagrid,
  create_DeleteGroupAccountvalues_datagrid_complete_api,
  DeleteGroupAccountvalues_datagrid_is_success,
  false_switchDeleteGroupAccountvalues,
} = DeleteGroupAccountvalues.actions;
