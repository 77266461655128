import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import editimage from "../Image/Edit.svg";
import { DeleteOpenPositionvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Delete_openpositon-values-slicer";
import { edit_EditOpenPosition } from "../../Redux/Reducers/Open-Position/edit_open_position_slicer";
import DialogOpenPoistionmanualeditoption from "../../Component/DialogForFormOpenPositionEditoption_manual";

interface MapStartegyprops {
  valuepopup: number;
  editdata: any;
}

const EditOpenPositionMessage: FunctionComponent<MapStartegyprops> = (
  props: any
) => {
  const dispatcher = useAppDispatch();

  const pageName = "/manualopenposition";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const editopenPosition = useAppSelector(
    (state) => state.EditOpenPosition.data
  );
  const editopenPositionLoading = useAppSelector(
    (state) => state.EditOpenPosition.isLoading
  );
  return (
    <>
      {MenuOfUserpermission?.permission?.edit
        ? props.editdata.is_exit === false && (
            <DialogOpenPoistionmanualeditoption
              successMessage={editopenPosition}
              isLoading={editopenPositionLoading}
              Buttondialog={["edit"]}
              Dialogtitle={"Edit Order"}
              getValues={(v: any) => {
                dispatcher(edit_EditOpenPosition(v));
              }}
              placeholder=""
              sx={{
                backgroundColor: "transparent",
                minWidth: "0",
                padding: "0",
                borderRadius: "50%",
                height: "60%",
                "&:hover": { backgroundColor: "transparent" },
              }}
              edit={{
                ...props.editdata,
              }}
              Buttonname={
                // Button with an image for editing
                <span
                  title="Edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // Save the ID in localStorage when clicking on the edit button
                    const dataToPass = props.editdata;
                    localStorage.setItem("OpenPositionfilter", dataToPass.id);
                  }}
                >
                  <img src={editimage} width="20px" alt={""}></img>
                </span>
              }
            />
          )
        : "-"}

      {MenuOfUserpermission?.permission?.delete
        ? props.editdata.is_entry === false && (
            <DeleteIcon
              titleAccess="Delete"
              sx={{
                color: "#F2A618",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={(e) => {
                dispatcher(
                  DeleteOpenPositionvalues_datagrid({
                    id: props.valuepopup,
                  })
                );
              }}
            ></DeleteIcon>
          )
        : "-"}
    </>
  );
};

export default EditOpenPositionMessage;
