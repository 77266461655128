import { createSlice } from "@reduxjs/toolkit";
// this code for slice   mean value get and pass  the value
export const AlgoDymics = createSlice({
  name: "AlgoDymics",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [] as any,
  },
  reducers: {
    edit_AlgoDymics: (state, action) => {
      const copyState = state.data;
      copyState.push(action.payload);
      state.data = copyState.map((add: any, i: number) => ({
        ...add,
        id: i,
      }));
    },

    edit_reducer: (state, action) => {
      const listCopy = state.data;
      listCopy[action.payload.id] = action.payload;
      state.data = listCopy;
    },
    success_api_call_AlgoDymics: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_AlgoDymics: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    AlgoDymics_is_success: (state) => {
      state.isSuccess = false;
    },
    AlgoDymics_complete_api: (state) => {
      state.api_completed = false;
    },
    delete_algo: (state, action) => {
      const copyState = state.data.filter(
        (data: any) => data.id !== action.payload
      );
      state.data = copyState;
    },
    update: (state, action) => {
      state.data = action.payload.map((val: any, i: number) => ({
        ...val,
        id: i,
      }));
    },
    clearDataAlgoDymics: (state) => {
      state.data = [];
    },
  },
});
export default AlgoDymics.reducer;
export const {
  edit_AlgoDymics,
  success_api_call_AlgoDymics,
  AlgoDymics_complete_api,
  failure_api_call_AlgoDymics,
  AlgoDymics_is_success,
  delete_algo,
  clearDataAlgoDymics,
  update,
  edit_reducer,
} = AlgoDymics.actions;
