import * as React from "react";
import { Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import { ReactComponent as Editiconforic } from "../pages/svg_image/editforcard.svg";
import Dialog from "./DialogForFormaccount";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { edit_EditCard } from "../Redux/Reducers/Card_api/edit_Card_slicer";
import { ReactComponent as Menuicons } from "../pages/svg_image/Vector.svg";
import SuccessPopUpDelete from "./SuccessPopUpDelete";
import Warning from "../pages/svg_image/Waring.gif";
import { ReactComponent as Deleteiconforic } from "../pages/svg_image/Vector (1).svg";

interface MenuUserListprops {
  editdataid?: any;
}
const MenuUserList: React.FunctionComponent<MenuUserListprops> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const pageName = "View User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const editData = useAppSelector((state) => state.EditCard.data);
  const editDataIsLoading = useAppSelector((state) => state.EditCard.isLoading);
  return (
    <div>
      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ cursor: "pointer", padding: "12px" }}
      >
        <Menuicons></Menuicons>
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {MenuOfUserpermission?.permission?.edit ? (
          <>
            <MenuItem style={{ padding: "0px" }}>
              <Dialog
                successMessage={editData}
                isLoading={editDataIsLoading}
                Buttondialog={["edit"]}
                Dialogtitle={"Edit Account"}
                getValues={(v: any) => {
                  dispatch(edit_EditCard(v));
                }}
                placeholder=""
                sx={{
                  minWidth: "0 !important",
                  padding: "6px",
                  height: "60%",
                  width: "100%",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                edit={props.editdataid}
                Buttonname={
                  <Tooltip title="Edit" arrow>
                    <span>
                      <div
                        style={{
                          transform: "scale(1.2)",
                          display: "inline-block",
                          marginTop: "4px",
                        }}
                      >
                        <Editiconforic style={{ color: "#545454" }} />
                      </div>
                      <span
                        style={{
                          marginLeft: "10px",
                          textTransform: "capitalize",
                          color: "#545454",
                        }}
                      >
                        Edit
                      </span>
                    </span>
                  </Tooltip>
                }
              />
            </MenuItem>
            <Divider className="no-margin-divider" />
          </>
        ) : (
          <>
            <MenuItem onClick={handleClose}>
              <Tooltip title="Edit" arrow>
                <>
                  <div
                    style={{
                      transform: "scale(1.1)",
                      display: "inline-block",
                      marginTop: "4px",
                    }}
                  >
                    <Editiconforic style={{ width: "200px" }} />
                  </div>

                  <span
                    style={{
                      marginLeft: "10px",
                      textTransform: "capitalize",
                      color: "#545454",
                    }}
                  >
                    Edit
                  </span>
                </>
              </Tooltip>
            </MenuItem>
            <Divider className="no-margin-divider" />
          </>
        )}
        <MenuItem style={{ padding: "0px" }}>
          <SuccessPopUpDelete
            subtitle={"Warning"}
            Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
            button={
              <Tooltip title="Delete" arrow>
                <>
                  <div
                    style={{
                      transform: "scale(1.2)",
                      display: "inline-block",
                    }}
                  >
                    <Deleteiconforic
                      style={{ color: "rgb(255, 86, 48)" }}
                    ></Deleteiconforic>
                  </div>
                  <span
                    style={{
                      marginLeft: "10px",
                      textTransform: "capitalize",
                      color: "rgb(255, 86, 48)",
                      fontSize: "15px",
                    }}
                  >
                    Delete
                  </span>
                </>
              </Tooltip>
            }
            dialogContentText={"Do you want to delete?"}
            successButtonName={props.editdataid.id}
            errorButtonName={<div className="cancel-button">No</div>}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
export default MenuUserList;
