// this code for once login website  mean  allow the  website
function PrivateRoute({ children }: any) {
  const token = localStorage.getItem("token");
  if (token !== null && token !== "undefined") {
    return children;
  } else {

    window.location.pathname = "/login";
  }
}
export default PrivateRoute;
