import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for edit stock
export const EditStock = createSlice({
  name: "EditStock",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    edit_EditStock: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditStock: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditStock: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditStock_is_success: (state) => {
      state.isSuccess = false;
    },
    EditStock_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_EditStock_Data: (state) => {
      state.data = [];
    },
    false_switchEditStock_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditStock.reducer;
export const {
  edit_EditStock,
  success_api_call_EditStock,
  EditStock_complete_api,
  failure_api_call_EditStock,
  EditStock_is_success,
  clear_EditStock_Data,
  false_switchEditStock_response,
} = EditStock.actions;
