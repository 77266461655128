import { FunctionComponent, useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { edit_EditableValue } from "../../Redux/Reducers/Admin-api/editableValu";
import { list_AdminList } from "../../Redux/Reducers/Admin-api/list_AdminList_slicer";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  edit_EditAdmin,
  false_switch_response,
} from "../../Redux/Reducers/Admin-api/edit_Admin_slicer";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import CallIcon from "@mui/icons-material/Call";
import {
  Livevalues_datagrid,
  false_switchLivevalues,
} from "../../Redux/Reducers/Datagrid-values/Live-values-slicer";
import { Alert, Button, Snackbar } from "@mui/material";
import Dialogs from "../../Component/Dialog";
import {
  Tabvalues_datagrid,
  false_switchTabvalues,
} from "../../Redux/Reducers/Datagrid-values/Tab-values-slicer";

const Profile_Details: FunctionComponent = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  // Retrieve the stored data from local storage
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();
  const datapass: any = useAppSelector((state) => state?.ProfileValue?.data);
  const editData: any = useAppSelector((state) => state?.EditAdmin?.data);
  const Uservalues: any = useAppSelector((state) => state.Livevalues.data);
  const switchOpen = useAppSelector((state) => state.Livevalues.switch);
  const switchResponse = useAppSelector(
    (state) => state.EditAdmin.switchResponse
  );
  const Uservaluestab: any = useAppSelector((state) => state.Tabvalues.data);
  const switchOpentab = useAppSelector((state) => state.Tabvalues.switch);
  const switchResponsetab = useAppSelector(
    (state) => state.EditAdmin.switchResponse
  );

  const filterId = localStorage.getItem("idforfilter");

  const data = useAppSelector((state) => state?.AdminList?.data);

  const filterData: any = data.filter((item: any) => item.id === filterId);

  // two data pass
  const pageName = "View User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const [profiledataupdate, setprofiledataupdate] = useState("");
  const [updateStatus, setupdateStatus] = useState("");

  useEffect(() => {
    if (filterData.length !== 0) {
      dispatcher(list_AdminList(""));
    }
    if (switchResponse) {
      dispatcher(false_switchLivevalues());
    }
    if (switchResponsetab) {
      dispatcher(false_switchTabvalues());
    }
  }, [
    editData,
    dispatcher,
    switchResponsetab,
    switchResponse,
    filterData.length,
  ]);
  // }, [editData, dispatcher, switchResponsetab, filterData, switchResponse]);

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switch_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switch_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switch_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsetab} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switch_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switch_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>

      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0.5%",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Roboto",
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Name</div>
            <div style={{ marginRight: "10px" }}>:</div>
          </div>

          <div
            style={{
              color: "#202020",
              backgroundColor: "#F5F5F5",
              width: "35%",
              height: "40px",
              borderRadius: "5px",
              display: "flex",
            }}
          >
            <div>
              <AccountCircleOutlinedIcon
                sx={{ color: "green", margin: "6px 0px 0px 5px " }}
              />
            </div>
            <div
              style={{
                border: "1px solid #C5C5C5",
                height: "23px",
                margin: "10px 5px 0px 5px",
              }}
            ></div>
            <div
              style={{
                margin: "10px 0px 0px 5px",
                fontFamily: "Roboto",
                fontSize: "15px",
              }}
            >
              {localStorage.getItem("ProfileDetailsname")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0.5%",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Roboto",
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Email</div>
            <div style={{ marginRight: "10px" }}>:</div>
          </div>
          <div
            style={{
              color: "#202020",
              backgroundColor: "#F5F5F5",
              width: "35%",
              height: "15%",
              borderRadius: "5px",
              display: "flex",
            }}
          >
            <div>
              <MarkunreadIcon
                sx={{ color: "green", margin: "5px 0px 0px 5px " }}
              />
            </div>
            <div
              style={{
                border: "1px solid #C5C5C5",
                height: "23px",
                margin: "10px 5px 0px 5px",
              }}
            ></div>
            <div
              style={{
                margin: "10px 0px 0px 5px",
                fontFamily: "Roboto",
                fontSize: "15px",
              }}
            >
              {localStorage.getItem("ProfileDetailsemail_id")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0.5%",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Roboto",
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Phone</div>
            <div style={{ marginRight: "10px" }}>:</div>
          </div>
          <div
            style={{
              color: "#202020",
              backgroundColor: "#F5F5F5",
              width: "35%",
              height: "15%",
              borderRadius: "5px",
              display: "flex",
            }}
          >
            <div>
              <CallIcon sx={{ color: "green", margin: "5px 0px 0px 5px " }} />
            </div>
            <div
              style={{
                border: "1px solid #C5C5C5",
                height: "23px",
                margin: "10px 5px 0px 5px",
              }}
            ></div>

            <div
              style={{
                fontSize: "15px",
                color: "black",
                fontFamily: "Roboto",
                margin: "10px 0px 0px 5px",
                width: "40%",
              }}
            >
              {localStorage.getItem("ProfileDetailsmobile_no")}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0.5%",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Roboto",
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Allow Trade</div>
            <div style={{ marginRight: "10px" }}>:</div>
          </div>
          <p style={{ color: "green" }}>
            <Switch
              {...label}
              title="Allow Trade Active or Inactive"
              size="small" // Add this line to set the switch size to small
              disabled={MenuOfUserpermission?.permission?.edit === false}
              checked={localStorage.getItem("ProfileDetailsis_live") === "true"}
              onChange={(e) => {
                setprofiledataupdate(e.target.checked.toString());
                dispatcher(
                  Livevalues_datagrid({
                    id: localStorage.getItem("idforfilter"),
                    is_live: e.target.checked,
                  })
                );
              }}

              // Set the checked prop based on the state
            />
          </p>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0.5%",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Roboto",
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>User Status</div>
            <div style={{ marginRight: "10px" }}>:</div>
          </div>
          <p style={{ color: "green" }}>
            <Switch
              title="User status Active or Inactive"
              disabled={MenuOfUserpermission?.permission?.edit === false}
              {...label}
              size="small" // Add this line to set the switch size to small
              checked={
                localStorage.getItem("ProfileDetailsstatus") === "1" ||
                localStorage.getItem("ProfileDetailsstatus") === "true"
              }
              onChange={(e) => {
                setupdateStatus(e.target.checked.toString());
                dispatcher(
                  Tabvalues_datagrid({
                    id: localStorage.getItem("idforfilter"),
                    status: e.target.checked,
                  })
                );
              }}
            />
          </p>
        </div>

        <button
          disabled={MenuOfUserpermission?.permission?.edit === false}
          style={{
            width: "100px",
            height: "30px",
            borderRadius: "5px",
            background: "linear-gradient(to right, #FFFA02,#F2A618 )",
            color: "black",
          }}
          onClick={() => {
            dispatcher(edit_EditableValue(datapass));

            navigate("/adduser", { state: { editoradd: "edit" } });
          }}
        >
          Edit
        </button>
      </div>
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpen}
        setOpen={() => dispatcher(false_switchLivevalues())}
        Dialog_Content={
          Uservalues.is_live === true
            ? "Are you sure want to allow trade ?"
            : "Are you sure want to stop the trade ?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchLivevalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatcher(edit_EditAdmin(Uservalues));
                localStorage.setItem(
                  "ProfileDetailsis_live",
                  profiledataupdate
                );
              }}
            >
              yes
            </Button>
          </>
        }
      />

      <Dialogs
        maxWidth={"xs"}
        open={switchOpentab}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchTabvalues())}
        Dialog_Content={
          Uservaluestab.status === true
            ? "Are you sure you want to unblocked the user?"
            : "Are you sure you want to blocked the user?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchTabvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatcher(edit_EditAdmin(Uservaluestab));
                localStorage.setItem("ProfileDetailsstatus", updateStatus);
              }}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Profile_Details;
