import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { DeleteMepvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteMap-values-slicer";
import { Switch } from "@mui/material";
import { Mapvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Map-values-slicer";
import editimage from "../Image/Edit.svg";
import Dialog from "../../Component/DialogForFormMapStrategies";
import { edit_EditMapStrategies } from "../../Redux/Reducers/MapStrategies/edit_MapStrategies_slicer";
import { mapStratagiesId_update } from "../../Redux/Reducers/Stock/list_Stock_slicer";
import { list_StrategiesList } from "../../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";

interface MapStartegyprops {
  valuepopup: number;
  editdata: any;
}

const MapStrategyMessage: FunctionComponent<MapStartegyprops> = (
  props: any
) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const editData: any = useAppSelector(
    (state) => state?.EditMapStrategies?.data
  );
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditMapStrategies?.isLoading
  );
  const pageName = "Map Strategies";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <span
            title={
              props.editdata.strategies[0].status === true
                ? "Algo or strategy is currently used in tradesetup"
                : "Map Startegy Active / Inactive"
            }
          >
            <Switch
              size="small" // Add this line to set the switch size to small
              disabled={props.editdata.strategies[0].status === true}
              {...label}
              checked={props.editdata.status === 0} // Set the checked prop based on the state
              onChange={(e) => {
                localStorage.setItem("mapstrategiesfilter", props.valuepopup);
                dispatcher(
                  Mapvalues_datagrid({
                    status: props.editdata.status === 0 ? 1 : 0,
                  })
                );
              }}
            />
          </span>
          <span
            title={
              props.editdata.strategies[0].status === true
                ? "Algo or strategy is currently used in tradesetup"
                : "Edit"
            }
          >
            <Dialog
              successMessage={editData}
              isLoading={editDataIsLoading}
              Buttondialog={["edit"]}
              disabled={props.editdata.strategies[0].status}
              Dialogtitle={"Edit Map Strategies"}
              getValues={(v: any) => {
                dispatcher(edit_EditMapStrategies(v));
              }}
              placeholder=""
              sx={{
                backgroundColor: "transparent",
                height: "60%",
                "&:hover": { backgroundColor: "transparent" },
              }}
              edit={{
                ...props.editdata,
              }}
              Buttonname={
                <span
                  title={
                    props.editdata.strategies[0].status === true
                      ? "Algo or strategy is currently used in tradesetup"
                      : "Edit"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const dataToPass = props.editdata;
                    localStorage.setItem("mapstrategiesedit", "edit");
                    localStorage.setItem(
                      "mapstrategiespermission",
                      props?.editdata.strategies[0].status
                    );
                    localStorage.setItem("mapstrategiesfilter", dataToPass.id);
                    dispatcher(
                      mapStratagiesId_update(props.editdata.strategy_id)
                    );

                    dispatcher(
                      list_StrategiesList({
                        algo_id: props.editdata.algo_id,
                        is_enabled: true,
                      })
                    );
                  }}
                >
                  <img src={editimage} width="25px" alt={""}></img>
                </span>
              }
            />
          </span>
        </>
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "#F2A618",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteMepvalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default MapStrategyMessage;
