import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  edit menu 
export const EditMenu = createSlice({
  name: "EditMenu",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    edit_EditMenu: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditMenu: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditMenu_is_success: (state) => {
      state.isSuccess = false;
    },
    EditMenu_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_EditMenu_Data: (state) => {
      state.data = [];
    },
    false_switchEditMenu_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditMenu.reducer;
export const {
  edit_EditMenu,
  success_api_call_EditMenu,
  EditMenu_complete_api,
  failure_api_call_EditMenu,
  EditMenu_is_success,
  clear_EditMenu_Data
  ,false_switchEditMenu_response
} = EditMenu.actions;
