import { FunctionComponent } from "react";
import "./Backtestsummary.css";
import { useAppSelector } from "../../Redux/store/hooks";

interface BacktestsummaryProps {}

const Backtestsummary: FunctionComponent<BacktestsummaryProps> = () => {
  const storedValue: string | null = localStorage.getItem("backreportvalue");
  const editData = storedValue ? JSON.parse(storedValue) : null; // Null check
  const startDate = editData?.start_date;
  const [year, month, day] = startDate?.split("-");
  const formattedstartDate = new Date(year, month - 1, day);
  const formattedstartDateString = `${formattedstartDate.getDate()}-${
    formattedstartDate.getMonth() + 1
  }-${formattedstartDate.getFullYear()}`;
  const endDate = editData?.end_date;
  const [years, months, days] = endDate?.split("-");
  const formattedendDate = new Date(years, months - 1, days);
  const formattedendDateString = `${formattedendDate.getDate()}-${
    formattedendDate.getMonth() + 1
  }-${formattedendDate.getFullYear()}`;

  const backtestsummary: any = localStorage.getItem("backreportsummary");

  let summary: any;

  if (backtestsummary) {
    // Check if backtestsummary is not null, undefined, or an empty string
    try {
      summary = JSON.parse(backtestsummary);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
  } else {
    console.log("No data found in localStorage for 'backreportsummary'");
  }

  const win_loss_value = summary?.Win_Loss_Ratio;
  const stringValue = win_loss_value?.toString();
  const slicedValue: any = stringValue?.slice(0, stringValue?.indexOf(".") + 5);

  const gross_profit_value = summary?.gross_profit.toFixed(0);
  const gross_loss_value = summary?.gross_loss.toFixed(0);

  const Expectancy_ratio = summary?.Expectancy_ratio;
  const Expectancy_ratio_Value = Expectancy_ratio?.toString();
  const Expectancy_Value: any = Expectancy_ratio_Value?.slice(
    0,
    Expectancy_ratio_Value?.indexOf(".") + 3
  );
  const DataReport: any = localStorage.getItem("dataReport");
  const textColor = DataReport < 0 ? "red" : "green";
  const datas: any[] = useAppSelector((state) => state?.backreport?.data);

  return (
    <div className="bodysummary">
      <div className="summarytitle">Profile Summary</div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          padding: "10px 10px 0px 25px",
        }}
      >
        <span
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "normal",
          }}
        >
          Algo :
          <span
            style={{ paddingRight: "10px", color: "gray", paddingLeft: "6px" }}
          >
            {editData?.algo?.name}
          </span>
          | Strategy :
          <span
            style={{ color: "green", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.strategy?.name}
          </span>
          | Stock:
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.stock?.stock_name}
          </span>
          | Back Test Duration :
          <span
            style={{
              paddingRight: "10px",
              paddingLeft: "6px",
              color: "gray",
            }}
          >
            {`${formattedstartDateString} to ${formattedendDateString}`}
          </span>
          | Commission :
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.commission}
            {editData?.commission_type === "percentage fee"
              ? "%"
              : editData?.commission_type}
          </span>
          | Slippage :
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.slippage === null ? "-" : editData?.slippage}
          </span>
          | Target :
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.target === null || "" ? "-" : editData?.target}
          </span>
          | Stop Loss :
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.stop_loss === null || "" ? "-" : editData?.stop_loss}
          </span>
          |Trailing Stop Loss :
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {editData?.trailing_stop_loss === null || ""
              ? "-"
              : editData?.trailing_stop_loss}
          </span>
          | Quantiy :
          <span
            style={{ color: "blue", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {datas && datas.length > 0
              ? datas[0].quantity === "" || datas[0].quantity === null
                ? "-"
                : datas[0].quantity
              : "-"}
          </span>
          | OverAll P/L :
          <span
            style={{
              color: textColor,
              paddingRight: "10px",
              paddingLeft: "6px",
            }}
          >
            {localStorage.getItem("dataReport") === "undefined"
              ? "-"
              : localStorage.getItem("dataReport")}
          </span>
        </span>
      </div>
      <div className="summarybody">
        <div className="summary_first_sub">
          <div className="first_sub">
            <div className="first">
              <div>Total Trades</div>
              <div style={{ color: "blue" }}>{summary?.total_trades}</div>
            </div>
            <div className="first">
              <div>Open Trades</div>
              <div style={{ color: "green" }}>{summary?.open_trades}</div>
            </div>
            <div className="first">
              <div>Closed Trades</div>
              <div style={{ color: "red" }}>{summary?.closed_trades}</div>
            </div>

            <div className="first">
              <div>Gross Profit</div>
              <div style={{ color: "green" }}>{gross_profit_value}</div>
            </div>
            <div className="first">
              <div>Gross Loss</div>
              <div style={{ color: "red" }}>{gross_loss_value}</div>
            </div>
          </div>
        </div>

        <div className="summary_second_sub">
          <div className="second_sub">
            <div className="first">
              <div>Winning Trades </div>
              <div style={{ color: "green" }}>{summary?.winning_trades}</div>
            </div>
            <div className="first">
              <div>Losing Trades </div>
              <div style={{ color: "red" }}>{summary?.losing_trades}</div>
            </div>
            <div className="first">
              <div>Expectancy Ratio </div>
              <div style={{ color: "blue" }}>{Expectancy_Value}</div>
            </div>
            <div className="first">
              <div> Win Loss Ratio </div>
              <div style={{ color: "blue" }}>{slicedValue}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backtestsummary;
