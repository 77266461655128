import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list live trade
export const OpenPositionList = createSlice({
  name: "OpenPositionList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_OpenPositionList: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_OpenPositionList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_OpenPositionList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    OpenPositionList_is_first: (state, action) => {
      state.isFirst = false;
    },
    clear_OpenPositionList: (state) => {
      state.data = [];
    },
  },
});
export default OpenPositionList.reducer;
export const {
  list_OpenPositionList,
  clear_OpenPositionList,
  success_api_call_OpenPositionList,
  failure_api_call_OpenPositionList,
  OpenPositionList_is_first,
} = OpenPositionList.actions;
