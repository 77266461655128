import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Stock,
  success_api_call_Stock,
} from "./list_Stock_slicer";
import {
  failure_api_call_delete_DeleteStock,
  success_api_call_delete_DeleteStock,
} from "./delete_Stock_slicer";
import {
  failure_api_call_EditStock,
  success_api_call_EditStock,
} from "./edit_Stock_slicer";
import {
  failure_api_call_add_AddStock,
  success_api_call_add_AddStock,
} from "./add_Stock_slicer";

//list stock
const listStockCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/stock/`,
    a.payload,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* StockApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listStockCall, e);
    let res = (response?.data?.data ?? []).map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_Stock(res));
  } catch (e) {
    yield put(failure_api_call_Stock(e));
    yield put(expireSignature(e));
  }
}
//add stock
const AddStockCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/stock/sync-data/${a.payload}`,
    null,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddStockApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddStockCall, e);
    yield put(success_api_call_add_AddStock(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddStock(e));
    yield put(expireSignature(e));
  }
}

//Edit Stock
const editEditStockCall = async (a: any) => {
  const edit = {
    segment: a.payload.stock_name.segment,
    id: a.payload.stock_name.id,
    is_subscribed: a.payload.switchStatus,
  };
  const get_token = localStorage.getItem("token");
  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/stock/`, edit, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* editEditStockApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editEditStockCall, e);
    yield put(success_api_call_EditStock(response.data));
  } catch (e) {
    yield put(failure_api_call_EditStock(e));
    yield put(expireSignature(e));
  }
}

// Delete stock
const deleteDeleteStockCall = (a: any) => {
  const Deleteoption = {
    segment: a.payload.stock_name.segment,
    id: a.payload.stock_name.id,
  };
  const get_token = localStorage.getItem("token");
  return Axios.delete(`${process.env.REACT_APP_api_url}/v1/api/stock/`, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
    data: Deleteoption,
  });
};
export function* DeleteStockApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteDeleteStockCall, e);
    yield put(success_api_call_delete_DeleteStock(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteStock(error));
  }
}
