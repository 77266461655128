import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";

interface MultiSelectProps {
  defaultValue?: {
    label: string | number;
    value: string | number;
  }[];
  options: {
    label: string | number;
    value: string | number;
  }[];
  label?: string | number;
  name?: string;
  value?: {
    label: string | number;
    value: string | number;
  }[];
  getValue: (value: any) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  sx?: React.CSSProperties;
  inputSx?: React.CSSProperties | any;
  helperText?: React.ReactNode | any;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  fixedOptions?: {
    label: string | number;
    value: string | number;
  }[];
  dataPassed?: string;
}

const Multiselectfixed: FunctionComponent<MultiSelectProps> = (props) => {
  const {
    defaultValue,
    options,
    label,
    name,
    value,
    getValue,
    onBlur,
    sx,
    helperText,
    error,
    inputSx,
    placeholder,
    disabled,
    fixedOptions = [],
    dataPassed,
  } = props;

  const [selectedValues, setSelectedValues] = useState<any[]>([]);

  useEffect(() => {
    if (dataPassed === "edit") {
      // Combine fixedOptions with the initial value
      setSelectedValues((prevValues) => {
        const merged = [...fixedOptions];
        prevValues.forEach((item) => {
          if (!merged.some((mergedItem) => mergedItem.value === item.value)) {
            merged.push(item);
          }
        });
        return merged;
      });
    } else {
      setSelectedValues(value || []);
    }
  }, [dataPassed, fixedOptions, value]);

  const handleOnChange = (_: any, newValue: any) => {
    let updatedValues: any;

    if (dataPassed === "edit") {
      // Ensure fixedOptions are always included when in edit mode
      updatedValues = [...fixedOptions];
      newValue.forEach((item: any) => {
        if (
          !updatedValues.some(
            (fixedItem: any) => fixedItem.value === item.value
          )
        ) {
          updatedValues.push(item);
        }
      });
    } else {
      // Allow removing items, including fixedOptions, in add mode
      updatedValues = newValue;
    }

    setSelectedValues(updatedValues);
    getValue(updatedValues);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      limitTags={2}
      filterSelectedOptions
      sx={sx}
      disabled={disabled}
      options={options?.filter((option) => {
        return !selectedValues
          .map(
            (selected: { label: string | number; value: string | number }) =>
              selected.value
          )
          .includes(option.value);
      })}
      getOptionLabel={(option: any) => option.label}
      defaultValue={defaultValue}
      value={selectedValues}
      onChange={handleOnChange}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          sx={inputSx}
        />
      )}
    />
  );
};

export default React.memo(Multiselectfixed);
