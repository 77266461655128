import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import StockMessage from "./StockMessage";
import Dialogs from "../../Component/Dialog";
import { edit_filtermap } from "../../Redux/Reducers/Filtervalue/filtermap";
import {
  clearStock,
  list_Stock,
} from "../../Redux/Reducers/Stock/list_Stock_slicer";
import {
  delete_DeleteStock,
  false_switchDeleteStock_response,
} from "../../Redux/Reducers/Stock/delete_Stock_slicer";
import { false_switchDeleteStockvalues } from "../../Redux/Reducers/Datagrid-values/DeleteStock-values-slicer";
import { false_switchStocksvalues } from "../../Redux/Reducers/Datagrid-values/Stocks-values-slicer";
import {
  edit_EditStock,
  false_switchEditStock_response,
} from "../../Redux/Reducers/Stock/edit_Stock_slicer";
import {
  add_AddStock,
  false_switchAddStock_response,
} from "../../Redux/Reducers/Stock/add_Stock_slicer";
import {
  StockSyncvalues_datagrid,
  false_switchStockSyncvalues,
} from "../../Redux/Reducers/Datagrid-values/StockSync-values-slicer";

interface StockProps {}

const Stock: FunctionComponent<StockProps> = () => {
  const dispatcher = useAppDispatch();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Stock
    </Typography>,
  ];
  const openstock = useAppSelector((state) => state.StockSyncvalues.switch);
  const data = useAppSelector((state) => state?.Stock?.data);
  const addData: any = useAppSelector((state) => state?.AddStock?.data);
  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddStock?.isLoading
  );
  const editData: any = useAppSelector((state) => state?.EditStock?.data);
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditStock?.isLoading
  );
  const deleteData: any = useAppSelector((state) => state?.DeleteStock?.data);
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteStock?.isLoading
  );
  const isLoadingdata = useAppSelector((state) => state?.Stock?.isLoading);
  const switchResponse = useAppSelector(
    (state) => state?.EditStock?.switchResponse
  );
  const switchResponseadd = useAppSelector(
    (state) => state.AddStock.switchResponse
  );
  const Uservalues: any = useAppSelector((state) => state?.Stocksvalues?.data);
  const switchOpen = useAppSelector((state) => state?.Stocksvalues?.switch);
  const switchResponsealgodelete = useAppSelector(
    (state) => state?.DeleteStock?.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state?.DeleteStockvalues?.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state?.DeleteStockvalues?.switch
  );
  const [alignment, setAlignment] = React.useState("NFO");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const [options, setoptions] = React.useState("FUTURE");

  const handleChangeOption = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setoptions(newAlignment);
    }
  };
  const heading = (
    <>
      <ToggleButtonGroup
        color="primary"
        sx={{ marginRight: "10px" }}
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton
          value="NSE"
          title="NSE Stock"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 75,
              xl: 80,
            },
          }}
        >
          NSE
        </ToggleButton>
        <ToggleButton
          value="NFO"
          title="NFO Stock"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 80,
            },
          }}
        >
          NFO
        </ToggleButton>
        <ToggleButton
          value="MCX"
          title="MCX Stock"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 80,
            },
          }}
        >
          MCX
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        color="primary"
        sx={{ marginRight: "10px" }}
        value={options}
        exclusive
        onChange={handleChangeOption}
        aria-label="Platform"
      >
        <ToggleButton
          value="EQUITY"
          title="EQUITY"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 75,
              xl: 80,
            },
          }}
        >
          EQUITY
        </ToggleButton>
        <ToggleButton
          value="FUTURE"
          title="FUTURE"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 80,
            },
          }}
        >
          FUTURE
        </ToggleButton>
        <ToggleButton
          value="OPTION"
          title="OPTION"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 80,
            },
          }}
        >
          OPTION
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );

  const pageName = "Manage Stock";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const column = [
    {
      sortable: true,
      headerName: "Segment",
      flex: 1,
      field: "segment",
      valueGetter: (params: any) => {
        return params?.row?.segment;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const segmentName = params?.row?.segment;

        return <div title={segmentName}>{segmentName}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Exchange",
      flex: 1,
      field: "exchange",
      valueGetter: (params: any) => {
        return params?.row?.exchange;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const exchangeName = params?.row?.exchange;

        return <div title={exchangeName}>{exchangeName}</div>;
      },
    },

    {
      sortable: true,
      headerName: "Symbol",
      flex: 1,
      field: "symbol",
      valueGetter: (params: any) => {
        return params?.row?.symbol;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const symbolName = params?.row?.symbol;
        return <div title={symbolName}>{symbolName}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1,
      field: "stock_name",
      valueGetter: (params: any) => {
        return params?.row?.stock_name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const stock_name = params?.row?.stock_name;
        return <div title={stock_name}>{stock_name}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Fyers Stock Name",
      flex: 1,
      field: "fyers_stock_name",
      valueGetter: (params: any) => {
        return params?.row?.fyers_stock_name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params?.row?.fyers_stock_name === null) {
          return "-";
        }
        const fyers_stock_name = params?.row?.fyers_stock_name;
        return <div title={fyers_stock_name}>{fyers_stock_name}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Fyers Token",
      flex: 1,
      field: "fyToken",
      valueGetter: (params: any) => {
        return params?.row?.fyToken;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params?.row?.fyToken === null) {
          return "-";
        }
        const fyToken = params?.row?.fyToken;
        return <div title={fyToken}>{fyToken}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Exchange Code",
      flex: 1,
      field: "exchange_code",
      valueGetter: (params: any) => {
        return params?.row?.exchange_code;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const exchange_code = params?.row?.exchange_code;
        return <div title={exchange_code}>{exchange_code}</div>;
      },
    },

    {
      headerName: "Expiry Date",
      flex: 1,
      field: "expiry_date",
      type: "dateTime",
      sortable: true,
      valueGetter: (params: any) => {
        return new Date(params.row.expiry_date);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (options === "EQUITY") {
          return "-";
        }
        if (params.row.expiry_date === null) {
          return "-";
        }

        const date = new Date(params?.row?.expiry_date);
        const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
        const minutes = date.getMinutes();
        const amPm = date.getHours() >= 12 ? "PM" : "AM";

        // Format the date and time
        const formattedDateTime = `${date.toLocaleDateString()} ${hours}:${minutes.toLocaleString(
          "en-US",
          { minimumIntegerDigits: 2 }
        )} ${amPm}`;

        return (
          <Tooltip title={formattedDateTime}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedDateTime}
            </div>
          </Tooltip>
        );
      },
    },
    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <StockMessage
                valuepopup={params.row.id}
                editdata={params.row}
                PermissionBase={MenuOfUserpermission?.permission}
              ></StockMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  React.useEffect(() => {
    dispatcher(edit_filtermap({}));
    dispatcher(list_Stock({ exchange: alignment, segment: [options] }));
    if (switchResponseadd) {
      dispatcher(false_switchStockSyncvalues());
    }
    if (switchResponse) {
      dispatcher(false_switchStocksvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteStockvalues());
    }
    return () => {
      dispatcher(clearStock());
    };
  }, [
    addData,
    editData,
    options,
    deleteData,
    alignment,
    dispatcher,
    switchResponse,
    switchResponsealgodelete,
    switchResponseadd,
  ]);

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchEditStock_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteStock_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAddStock_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  const initialColumnVisibilityModel: any = {
    segment: true,
    exchange: true,
    symbol: true,
    stock_name: true,
    fyers_stock_name: true,
    exchange_code: true,
    expiry_date: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponseadd} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddStock_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddStock_response())}
          severity="success"
        >
          {addData?.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditStock_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditStock_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteStock_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteStock_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data ?? []}
        Users={heading}
        column={column}
        gridesbutton
        pagination
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        dialog={
          <Button
            disabled={MenuOfUserpermission?.permission?.edit === false}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              fontSize: "12px",
              marginTop: "0px",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
            onClick={() => {
              dispatcher(StockSyncvalues_datagrid({}));
            }}
          >
            Sync Stocks
          </Button>
        }
        Userpermissions={undefined}
      ></Grids>

      <Dialogs
        maxWidth={"xs"}
        open={openstock}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchStockSyncvalues())}
        Dialog_Content={"Do you want to sync stocks ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchStockSyncvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatcher(add_AddStock(alignment));
                dispatcher(false_switchStockSyncvalues());
              }}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        open={switchOpen}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchStocksvalues())}
        Dialog_Content={
          Uservalues.switchStatus === true
            ? "Do you want to turn on stock?"
            : "Do you want to turn off stock?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchStocksvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditStock(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpenalgodelete}
        setOpen={() => dispatcher(false_switchDeleteStockvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteStockvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_DeleteStock(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Stock;
