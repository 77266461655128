import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   DeleteMenuvalues
export const DeleteMenuvalues = createSlice({
  name: "DeleteMenuvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    DeleteMenuvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_DeleteMenuvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_DeleteMenuvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    DeleteMenuvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_DeleteMenuvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchDeleteMenuvalues:(state)=>{
        state.switch = false
    }
  },
});
export default DeleteMenuvalues.reducer;
export const {
  DeleteMenuvalues_datagrid,
  success_api_call_DeleteMenuvalues_datagrid,
  failure_api_call_DeleteMenuvalues_datagrid,
  create_DeleteMenuvalues_datagrid_complete_api,
  DeleteMenuvalues_datagrid_is_success,false_switchDeleteMenuvalues
} = DeleteMenuvalues.actions;
