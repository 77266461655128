import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  LinearProgress,
  Slide,
  Snackbar,
  SnackbarCloseReason,
  Toolbar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RouterPages from "../Routers/Routerpages";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupIcon from "@mui/icons-material/Group";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TimelineIcon from "@mui/icons-material/Timeline";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import LayersIcon from "@mui/icons-material/Layers";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import KeyIcon from "@mui/icons-material/Key";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonIcon from "@mui/icons-material/Person";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WalletIcon from "@mui/icons-material/Wallet";
import InvestaLogo1 from "../pages/Image/InvestaLogo1.png";
import InvestaIcon from "../pages/Image/InvestaIcon.png";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { expireSignature } from "../Redux/Reducers/ExpireSignature/ExpireSignature_slicer";
import { clear_data } from "../Redux/Reducers/User-Register&Login/Login_slicer";
import HistoryIcon from "@mui/icons-material/History";
import { list_User_permissionMenuList } from "../Redux/Reducers/User_permission/list_User_permissionMenuList_slicer";
const drawerWidth = 225;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [activeActive, setactiveActive] = React.useState(true);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleResize = React.useCallback(() => {
    const newWindowWidth = window.innerWidth;
    setWindowWidth(newWindowWidth);

    if (windowWidth > 1000) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [windowWidth]);

  //    Menu  list
  const getMuiIcon = (iconName: any) => {
    switch (iconName) {
      case "DashboardIcon":
        return <DashboardIcon />;
      case "GroupIcon":
        return <GroupIcon />;
      case "AutoGraphIcon":
        return <AutoGraphIcon />;
      case "TimelineIcon":
        return <TimelineIcon />;
      case "AddCircleOutlineIcon":
        return <AddCircleOutlineIcon />;
      case "VisibilityIcon":
        return <VisibilityIcon />;
      case "LogoutOutlinedIcon":
        return <LogoutOutlinedIcon />;
      case "TrendingUpIcon":
        return <TrendingUpIcon />;
      case "ManageAccountsIcon":
        return <ManageAccountsIcon />;
      case "MenuOpenIcon":
        return <MenuOpenIcon />;
      case "AssignmentIndIcon":
        return <AssignmentIndIcon />;
      case "CalendarMonthIcon":
        return <CalendarMonthIcon />;
      case "KeyIcon":
        return <KeyIcon />;
      case "LayersIcon":
        return <LayersIcon />;
      case "PersonIcon":
        return <PersonIcon />;
      case "AnalyticsIcon":
        return <AnalyticsIcon />;
      case "WalletIcon":
        return <WalletIcon />;
      case "SettingsIcon":
        return <SettingsIcon />;
      case "CandlestickChartIcon":
        return <CandlestickChartIcon />;
      case "KeyboardCommandKeyIcon":
        return <KeyboardCommandKeyIcon />;
      case "HistoryIcon":
        return <HistoryIcon />;
      default:
        return null; // Return null for unknown icons
    }
  };

  const [openAlert, setOpenAlert] = React.useState<boolean>(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const menuItems: any = useAppSelector(
    (state) => state.User_permissionMenuList.data
  );
  localStorage.setItem("permission", JSON.stringify(menuItems));
  sessionStorage.setItem("permission", JSON.stringify(menuItems));

  const menuItemsWithIcons = menuItems?.map((menuItem: any) => ({
    ...menuItem,
    icon: getMuiIcon(menuItem.icon),
    sub_menus: menuItem.sub_menus.map((subMenu: any) => ({
      ...subMenu,
      icon: getMuiIcon(subMenu.icon),
    })),
  }));

  const log_in: any = useAppSelector((state) => state.Login.data);
  const valueornot: any = sessionStorage.getItem("role");
  console.log("ssssss", valueornot);
  let value: any = "";
  let datalist: any = "";

  if (valueornot === null) {
    value = localStorage.getItem("role");
    datalist = localStorage.getItem("roleIDfordatalist");
  } else {
    value = sessionStorage.getItem("role");
    datalist = sessionStorage.getItem("roleIDfordatalist");
  }

  const ExpireSignature: any = useAppSelector(
    (state) => state.ExpireSignature.data
  );
  const isLoadingdata = useAppSelector(
    (state) => state.User_permissionMenuList.isLoading
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);
  React.useEffect(() => {
    if (ExpireSignature?.response?.status === 401) {
      navigate("/ErrorDialog");
      dispatch(expireSignature(null));
    }
    log_in?.message?.length !== undefined &&
      log_in?.status &&
      setOpenAlert(true);
    return () => {
      dispatch(clear_data());
    };
  }, [
    dispatch,
    log_in?.message?.length,
    log_in?.status,
    datalist,
    value,
    ExpireSignature,
    navigate,
  ]);

  React.useLayoutEffect(() => {
    if (value?.includes("ADMIN")) {
      dispatch(
        list_User_permissionMenuList([{ portal: "Admin", roleId: datalist }])
      );
    } else {
      dispatch(
        list_User_permissionMenuList([{ portal: "User", roleId: datalist }])
      );
    }
  }, [datalist, dispatch, value]);

  return (
    <>
      <LinearProgress
        style={{
          color: "black",
          width: "100%",
          marginTop: "-15px",
          display: isLoadingdata ? "block" : "none",
        }}
      />
      <Box sx={{ display: "flex", height: "100%" }}>
        <CssBaseline />
        <Snackbar
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{
              width: "100%",
              fontSize: "14px",
              color: "#338514",
              backgroundColor: "#EEFCE9",
              boxShadow: "none",
            }}
          >
            {localStorage.getItem("name")} login successfully
          </Alert>
        </Snackbar>
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "#2C8E14",
            zIndex: "auto",
          }}
        >
          <Toolbar sx={{ height: "10px" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
            <img
              src={InvestaLogo1}
              alt="InvestaLogo"
              style={{
                height: "100%",
                width: "auto",
                objectFit: "cover",
                display: open ? "none" : "block", // Corrected display property
              }}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          onMouseEnter={() => {
            setactiveActive(true);
            setOpen(true);
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader
            sx={{
              width: "100%",
              padding: "0 !important",
              backgroundColor: "#2C8E14",
            }}
          >
            <span
              style={{
                width: "100%",
              }}
            >
              {activeActive ? (
                <img
                  src={InvestaLogo1}
                  alt={"InvestaLogo"}
                  style={{
                    height: "100%",
                    width: "165px",
                    objectFit: "cover",
                    marginLeft: "25px",
                  }}
                />
              ) : (
                <img
                  src={InvestaIcon}
                  alt={"InvestaIcon"}
                  style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "cover",
                    marginLeft: "16px",
                  }}
                />
              )}
            </span>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon sx={{ color: "white" }} />
              ) : (
                <ChevronRightIcon sx={{ color: "white" }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List sx={{ color: "white", paddingTop: "0px" }}>
            {menuItemsWithIcons?.map((value: any, key: number) => {
              return (
                <Children value={value} key={key} activeActive={activeActive} />
              );
            })}
          </List>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <List sx={{ width: "100%" }}>
              {props.setting.map((value: any, key: number) => {
                return (
                  <Children
                    value={value}
                    key={key}
                    activeActive={activeActive}
                  />
                );
              })}
            </List>
          </div>
        </Drawer>
        <Main open={open} sx={{ backgroundColor: "#F5FEF6", height: "100%" }}>
          <DrawerHeader />
          <RouterPages></RouterPages>
        </Main>
      </Box>
    </>
  );
}

const Children = ({
  value,
  activeActive,
}: {
  value: any;
  activeActive: boolean;
}) => {
  const [active, inactive] = React.useState(false);
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <>
      <Link
        to={value?.path_name}
        style={{
          textDecoration: "none",
          fontSize: "15px !important",
        }}
        onClick={() => {
          value?.sub_menus && inactive(!active);
        }}
      >
        <ListItem
          disablePadding
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ListItemButton
            sx={{
              backgroundColor: isActive(value.path_name)
                ? "#F5FEF6 !important"
                : "",
            }}
            selected={isActive(value.path_name)}
          >
            <ListItemIcon
              style={{
                color: isActive(value.path_name) ? "green" : "#000000",
                minWidth: "30px",
                marginTop: "-5px",
              }}
            >
              {value?.icon}
            </ListItemIcon>

            {activeActive ? (
              <ListItemText
                primary={
                  value?.permission?.beta === true ? (
                    <>
                      <span style={{ fontSize: "15px", fontWeight: "400" }}>
                        {value.name}
                        <sub style={{ fontSize: "10px" }}> (Beta)</sub>
                      </span>
                    </>
                  ) : (
                    <span style={{ fontSize: "15px", fontWeight: "400" }}>
                      {value.name}
                    </span>
                  )
                }
                style={{
                  color:
                    activeActive && isActive(value.path_name)
                      ? "green"
                      : "#000000", // Set green color for the active layer
                }}
              />
            ) : (
              ""
            )}
            {value?.path_name === null ? (
              active ? (
                <KeyboardArrowDownIcon style={{ color: "black" }} />
              ) : (
                <ChevronRightIcon style={{ color: "black" }} />
              )
            ) : undefined}

            {value?.icon2}
          </ListItemButton>
        </ListItem>
      </Link>

      {active &&
        value?.sub_menus?.map((e: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Link
                key={e.id}
                to={e.path_name}
                style={{
                  textDecoration: "none",
                  color: "#000000",
                }}
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "&.active": {
                      color: "#2C8E14", // Set green color for the active layer
                    },
                  }}
                  className={`list ${isActive(e.path_name) ? "active" : ""}`}
                >
                  <ListItemButton
                    sx={{
                      margin: "0 0 0 17px",
                      backgroundColor: isActive(e.path_name)
                        ? "#F5FEF6 !important"
                        : "",
                    }}
                    selected={isActive(e.path_name)}
                  >
                    <ListItemIcon
                      sx={{
                        color: isActive(e.path_name) ? "#2C8E14" : "#000000", // Set green color for the active layer
                        minWidth: "30px !important",
                        marginTop: "-2px",
                      }}
                    >
                      {e.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        e?.permission?.beta === true ? (
                          <>
                            <span
                              style={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              {e.name}
                              <sub style={{ fontSize: "10px" }}> (Beta)</sub>
                            </span>
                          </>
                        ) : (
                          <span style={{ fontSize: "15px", fontWeight: "400" }}>
                            {e.name}
                          </span>
                        )
                      }
                      style={{
                        color: isActive(e.path_name) ? "#2C8E14" : "#000000",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            </React.Fragment>
          );
        })}
    </>
  );
};
