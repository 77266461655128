import {
  Alert,
  Button,
  Checkbox,
  Snackbar,
  Switch,
  Tooltip,
} from "@mui/material";
import Success from "../pages/svg_image/Success.gif";
import Fyersicons from "../pages/svg_image/Fyper.png";
import { ReactComponent as Zerodhaicons } from "../pages/svg_image/image 1.svg";
import { FunctionComponent, useEffect, useState } from "react";
import "../pages/accounts/Adminaccounts.css";
import AlertDialog from "./AlertDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { Token } from "../Redux/Reducers/Token/add_Token_slicer";
import {
  edit_EditSwitch,
  false_switchcard_response,
} from "../Redux/Reducers/Card_api/switch_slicer";
import { edit_EditCheckbox } from "../Redux/Reducers/Card_api/checkbox_slicer";
import MenuUserList from "./MenuUser";
import CheckIcon from "@mui/icons-material/Check";
import Dialogs from "./Dialog";
import {
  Cardvalues_datagrid,
  false_switchCardvalues,
} from "../Redux/Reducers/Datagrid-values/Card-values-slicer";
import { list_AccountList } from "../Redux/Reducers/Card_api/list_slicer";
interface CardProps {
  open?: boolean;
  setOpen?: any;
}

const AdminCard: FunctionComponent<CardProps> = (props) => {
  const dispatch = useAppDispatch();
  const pageName = "View User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const data: any = useAppSelector((state) => state.AccountList.data);
  const list: any = useAppSelector((state) => state?.Tokens?.data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const Response = useAppSelector((state) => state.EditSwitch.switchResponse);
  const addData = useAppSelector((state) => state.AddCard.data);
  const editData = useAppSelector((state) => state.EditCard.data);
  const editswitch = useAppSelector((state) => state.EditSwitch.data);
  const EditCheckbox = useAppSelector((state) => state.EditCheckbox.data);
  const deleteData: any = useAppSelector((state) => state.DeleteCard.data);
  const isLoading = useAppSelector((state) => state?.AccountList?.isLoading);
  const Uservalues: any = useAppSelector((state) => state?.Cardvalues?.data);
  const switchOpen = useAppSelector((state) => state?.Cardvalues?.switch);
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  useEffect(() => {
    dispatch(list_AccountList(""));
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const authCode: any = urlParams.get("auth_code");
    if (authCode != null) {
      dispatch(Token(authCode));
      setIsDialogOpen(true);

      const updatedUrlParams = new URLSearchParams(urlParams.toString());
      updatedUrlParams.delete("auth_code");
      const newUrl = `/accounts`;
      window.history.replaceState(null, "", newUrl);
    }
    if (Response) {
      dispatch(false_switchCardvalues());
    }
  }, [
    addData,
    editData,
    editswitch,
    deleteData,
    EditCheckbox,
    dispatch,
    snackbarOpen,
    Response,
  ]);

  const generatehandleClick = (item: any) => {
    item.broker === "Fyers"
      ? (window.location.href = `https://api-t1.fyers.in/api/v3/generate-authcode?client_id=${item.api_key}&redirect_uri=${process.env.REACT_APP_Token_api_url}/tradingaccount&response_type=code&state=authcode`)
      : (window.location.href = `https://kite.zerodha.com/connect/login?v=3&api_key=${item.api_key}`);
  };

  const Action = (
    <button
      onClick={() => {
        dispatch(false_switchcard_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Response}
        autoHideDuration={2000}
        onClose={() => dispatch(false_switchcard_response())}
        key={`top-right`}
        action={Action}
      >
        <Alert
          onClose={() => dispatch(false_switchcard_response())}
          severity="success"
        >
          {editswitch.message}
        </Alert>
      </Snackbar>
      {data?.data?.length > 0 ? (
        data.data.map((item: any, index: number) => (
          <div className="card-content" key={index}>
            <div
              style={{
                width: "100%",
                boxShadow:
                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                backgroundColor: "white",
                margin: "-6px",
                borderRadius: "5px",
              }}
            >
              <div className="icon-alert">
                <AlertDialog />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 25px 15px 20px",
                  justifyContent: "space-between",
                  backgroundColor: "#338514",
                  borderRadius: "5px 5px  0px  0px",
                }}
              >
                <Tooltip title="Set as feed Account" arrow>
                  {item.is_feed_data === true ? (
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        padding: "4px",
                      }}
                    >
                      <div
                        style={{ padding: "4px 10px 0px 0px" }}
                        onClick={(e: any) => {
                          dispatch(
                            edit_EditCheckbox({
                              id: item.id,
                              is_feed_data: e.target.checked,
                            })
                          );
                        }}
                      >
                        <CheckIcon
                          sx={{
                            color: item.is_feed_data === true ? "white" : "red",
                            cursor: "pointer",
                          }}
                        ></CheckIcon>
                      </div>
                      {item.account_name}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "white",
                      }}
                    >
                      <Checkbox
                        {...label}
                        disabled={
                          MenuOfUserpermission?.permission?.edit === false
                        }
                        sx={{ color: "white" }}
                        checked={item.is_feed_data === true}
                        onChange={(e: any) => {
                          dispatch(
                            edit_EditCheckbox({
                              id: item.id,
                              is_feed_data: e.target.checked,
                            })
                          );
                        }}
                      />
                      {item.account_name}
                    </div>
                  )}
                </Tooltip>

                <div title={"Action"}>
                  <MenuUserList editdataid={item}></MenuUserList>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 15px 20px 20px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div>
                    {item.broker === "Zerodha" ? (
                      <Zerodhaicons />
                    ) : (
                      <img src={Fyersicons} alt={"Fyers"} />
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      padding: "5px 0px 0px 15px ",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        fontSize: "15px",
                      }}
                    >
                      {item.broker}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        color: "green",
                        fontSize: "12px",
                      }}
                    >
                      {item.client_id}
                    </div>
                  </div>
                </div>
                <Tooltip
                  title={`Trade Status is ${
                    item.trade_status === true ? "Live Trade" : "Paper Trade"
                  } `}
                  arrow
                >
                  <Switch
                    size="small" // Add this line to set the switch size to small
                    checked={item.trade_status === true}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      dispatch(
                        Cardvalues_datagrid({
                          id: item.id,
                          trade_status: item.trade_status,
                        })
                      );
                    }}
                  />
                </Tooltip>
              </div>
              {item.token_generated_at === null ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 25px 20px 25px",
                  }}
                >
                  <Button
                    className="token_btn"
                    sx={{
                      textTransform: "capitalize",
                      width: "100%",
                      borderRadius: "10px",
                      border: "2px solid #F4A717",
                      color: "#F4A717 !important",
                      height: {
                        xs: "40px",
                        sm: "40px",
                        md: "40px",
                        lg: "40px",
                        xl: "43px",
                      },
                      fontSize: {
                        xs: "13px",
                        sm: "13px",
                        md: "13px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                    onClick={() => {
                      generatehandleClick(item);
                      localStorage.setItem("client_id", item.client_id);
                    }}
                    disabled={
                      item.token_generated_at != null ||
                      MenuOfUserpermission?.permission?.edit === false
                    }
                  >
                    Generate Token
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#FFE5B5",
                    borderRadius: "0px 0px 5px 5px",
                    height: "3.5rem",
                    color: "#575757",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Token Generated
                  <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                    :
                  </span>
                  {new Date(item.token_generated_at).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    hour12: true,
                    year: "2-digit", // This will display the last two digits of the year
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div>
          <span>{isLoading ? "" : "No data found"}</span>
        </div>
      )}

      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          maxWidth={"xs"}
          PaperProps={{
            sx: {
              borderRadius: "10px",
              width: "40rem",
              "&::-webkit-scrollbar": { display: "none" },
            },
          }}
        >
          <DialogTitle style={{ textAlign: "center" }}>
            <img src={Success} alt="Success..." height="100px" />
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              {list.message}
            </div>
          </DialogTitle>

          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              style={{
                color: "black",
                width: "80px",
                height: "35px",
                borderRadius: "5px",
                textTransform: "capitalize",
              }}
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpen}
        setOpen={() => dispatch(false_switchCardvalues())}
        Dialog_Content={`Do you want to change the ${
          Uservalues.trade_status === false ? "Live Trade" : "Paper Trade"
        } ?`}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatch(false_switchCardvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatch(edit_EditSwitch(Uservalues));
                // dispatch(list_AccountList(""));
              }}
            >
              yes
            </Button>
          </>
        }
      />
    </>
  );
};

export default AdminCard;
