import { FunctionComponent, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import Warning from "../pages/svg_image/Waring.gif";
import { useState } from "react";

import "../pages/accounts/accounts.css";
import { delete_Card } from "../Redux/Reducers/Card_api/delete_Card_slicer";
import { useAppDispatch } from "../Redux/store/hooks";
interface SuccessPopUpDeleteProps {
  button?: any;
  Dialog_Title?: any;
  dialogContentText?: any;
  successButtonName?: any;
  errorButtonName?: any;
  isLoading?: boolean;
  subtitle?: any;
}

const SuccessPopUpDelete: FunctionComponent<SuccessPopUpDeleteProps> = (
  props
) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
  };
  const handleCloses = () => {
    dispatch(
      delete_Card({
        id: props.successButtonName,
      })
    );
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(props.isLoading!);
  }, [props.isLoading]);

  const pageName = "View User";
  const menuList = JSON.parse(sessionStorage?.getItem("permission") as string);

  let MenuOfUserpermission: any;

  const foundPage = menuList
    .map((data: any) =>
      data.sub_menus.find((child: any) => child.name === pageName)
    )
    .filter((page: any) => page);

  if (foundPage.length > 0) {
    MenuOfUserpermission = foundPage[0];
  } else {
    const defaultPageName = "Accounts";
    const index = menuList.findIndex(
      (item: any) => item.name === defaultPageName
    );

    if (index !== -1) {
      MenuOfUserpermission = menuList[index];
    }
  }

  return (
    <div style={{ borderRadius: "50px" }}>
      <div
        style={{ padding: "9px" }}
        onClick={
          MenuOfUserpermission?.permission?.delete === false
            ? undefined
            : handleClickOpen
        }
      >
        {props.button}
      </div>

      <Dialog
        // maxWidth={"xs"}
        open={open}
        PaperProps={{
          sx: {
            width: "33%",
            borderRadius: "10px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: {
              xs: "14px",
              sm: "19px",
              md: "19px",
              lg: "19px",
              xl: "19px",
            },
            textAlign: "center",
          }}
        >
          <img src={Warning} alt="Success..." className="DialogTitleimg" />
          <div style={{ textAlign: "center" }}>{props.subtitle}</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              textAlign: "center",
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            {props.dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "4%",
          }}
        >
          <Button
            autoFocus
            onClick={handleClose}
            sx={{
              width: "18%",
              color: "black",
              borderRadius: "5px",
              textTransform: "capitalize",
              border: "1px solid #F2A618",
              padding: "5px 0",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            {props.errorButtonName}
          </Button>
          <Button
            autoFocus
            onClick={handleCloses}
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
              width: "18%",
              color: "black",
              borderRadius: "5px",
              textTransform: "capitalize",
              marginLeft: "5%",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessPopUpDelete;
