import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  Add market holidays 
export const AddMarketholiday = createSlice({
  name: "AddMarketholiday",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_AddMarketholiday: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_AddMarketholiday: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_AddMarketholiday: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;     
    },
    add_AddMarketholiday_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_AddMarketholiday_complete_api: (state, action) => {
      state.api_completed = false;
    },
    clear_AddMarketholiday_Data: (state) => {
      state.data = [];
    },
    false_switchAddMarketholiday_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default AddMarketholiday.reducer;
export const {
  add_AddMarketholiday,
  success_api_call_add_AddMarketholiday,
  failure_api_call_add_AddMarketholiday,
  create_AddMarketholiday_complete_api,
  add_AddMarketholiday_is_success,
  clear_AddMarketholiday_Data,
  false_switchAddMarketholiday_response
} = AddMarketholiday.actions;
