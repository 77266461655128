import { FunctionComponent, useEffect, useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  Alert,
  FormHelperText,
  Snackbar,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppDispatch } from "../Redux/store/hooks";
import { list_AlgoList } from "../Redux/Reducers/Algo-api/list_AlgoList_slicer";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import { list_AccountList } from "../Redux/Reducers/Card_api/list_slicer";
import Multiselect from "./Multiselect";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import CancelIcon from "@mui/icons-material/Cancel";

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogMarketHolidayProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;

  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const format = "YYYY-MM-DD";
const today = new Date(); // Get today's date

const DialogMarketHoliday: FunctionComponent<DialogMarketHolidayProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);

  const vertical = "top";
  const horizontal = "right";

  const { getValues, successMessage, isLoading }: any = props;
  const segmentOptions = [
    { label: "NSE", value: "NSE" },
    { label: "NFO", value: "NFO" },
    { label: "MCX", value: "MCX" },
  ];
  const initialValues: any = {
    Datemultiselect: [],
    Segmentdate: [],
    reason: "",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [snackbarOpen]);
  const snackbarhandleClose = () => {
    setAlertOpen(false);
    setSnackBarOpen(false);
  };

  const action = (
    <button
      onClick={() => {
        snackbarhandleClose();
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (alertOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement != null) {
        descriptionElement.focus();
      }
    }
  }, [alertOpen]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatcher = useAppDispatch();

  React.useEffect(() => {
    dispatcher(list_AlgoList(""));
    dispatcher(list_AccountList(""));
  }, [dispatcher]);

  const pageName = "Market Holiday";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        disabled={MenuOfUserpermission?.permission?.add === false}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: "5px",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minWidth: "200px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              Datemultiselect: Yup.array()
                .of(Yup.date().required("Please enter a valid date"))
                .min(1, "select date")
                .required("Please select date"),
              reason: Yup.string().required("Please enter a reason"),
              Segmentdate: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .min(1, "Please select a segment")
                .required("Please select a segment"),
            })}
            onSubmit={(values) => {
              getValues(values);
              handleClose();
              setAlertOpen(true);
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />

                  <div style={{ height: "90px" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        padding: "0px 0px 0px 0px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Dates <span style={{ color: "red" }}>*</span>
                    </div>
                    <DatePicker
                      name="Datemultiselect" // Make sure this matches the field name in validation schema
                      value={props.values.Datemultiselect}
                      onChange={(value) => {
                        props.setFieldValue("Datemultiselect", value);
                      }}
                      multiple
                      sort
                      style={{
                        width: "25rem",
                        height: "50px",
                        border:
                          props.errors.Datemultiselect &&
                          props.touched.Datemultiselect
                            ? "1px solid #e35454"
                            : "1px solid #b9b4b4",
                      }}
                      format={format}
                      minDate={today} // Set minDate to today's date
                      calendarPosition="bottom-center"
                      plugins={[<DatePanel />]}
                    />

                    {props.errors.Datemultiselect &&
                      props.touched.Datemultiselect && (
                        <FormHelperText
                          style={{
                            marginLeft: "13px",
                            // marginTop: "10px",
                          }}
                          error
                        >
                          {"Please select date"}
                        </FormHelperText>
                      )}
                  </div>
                  <div style={{ marginTop: "10px", height: "90px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Segment <span style={{ color: "red" }}>*</span>
                    </div>

                    <Multiselect
                      options={segmentOptions}
                      name={"Segmentdate"}
                      value={props.values.Segmentdate}
                      onBlur={props.handleBlur}
                      getValue={(value: any) => {
                        props.setFieldValue("Segmentdate", value);
                      }}
                      sx={{
                        width: "25rem",
                        borderRadius: "0",
                      }}
                      inputSx={{
                        borderRadius: "0",
                        "& fieldset": {
                          borderRadius: "5px",
                          zIndex: 1,
                        },
                      }}
                      helperText={
                        props.errors.Segmentdate &&
                        props.touched.Segmentdate &&
                        props.errors.Segmentdate
                      }
                      error={
                        !!(
                          props.errors.Segmentdate && props.touched.Segmentdate
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      height: "90px",
                      width: "100%",
                      marginTop: "13px",
                    }}
                  >
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Reason <span style={{ color: "red" }}> *</span>
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="reason"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={!!(props.errors.reason && props.touched.reason)}
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          if (/^[a-z A-Z 0-9 , .\s]+$/.test(inputValue)) {
                            props.setFieldValue("reason", inputValue);
                          } else {
                            props.setFieldValue(
                              "reason",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.reason}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                      />

                      {props.errors.reason && props.touched.reason ? (
                        <FormHelperText
                          style={{
                            marginLeft: "13px",
                            marginTop: "10px",
                          }}
                          error
                        >
                          {"Please enter a reason"}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>

                  <DialogActions
                    style={{
                      justifyContent: "center",
                      margin: "20px 0px 0px 0px",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>

      {!isLoading ? (
        // <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={alertOpen} // Use alertOpen to control the Snackbar's visibility
          autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
          onClose={snackbarhandleClose}
          key={`${vertical}-${horizontal}`}
          action={action}
        >
          <Alert onClose={snackbarhandleClose} severity="success">
            {successMessage?.message}
          </Alert>
        </Snackbar>
      ) : (
        // </div>
        ""
      )}
    </>
  );
};

export default DialogMarketHoliday;
