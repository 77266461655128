import { FunctionComponent } from "react";
import Autocompletes from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

//  Autocomplete component

type variant = "outlined" | "standard" | "filled" | undefined;
type color = "secondary" | "success" | "error" | "info" | "warning";

type size = "small" | "medium" | undefined;
interface AutocompleteProps {
  Listitem: any;
  width?: any;
  variant: variant;
  disabled?: boolean;
  url?: string;
  color?: color;
  size?: size;
  style?: React.CSSProperties;
  label?: any;
  multiple?: any;
  defaultValue?: any;
  onchange?: any;
  filter?: boolean;
  required?: boolean;
  name?: string;
  value?: any;
  error?: any;
  onBlur?: any;
  widthsize?: any;
}

const Autocomplete: FunctionComponent<AutocompleteProps> = (props: any) => {
  return (
    <div style={{ width: props.widthsize }}>
      <Autocompletes
        onChange={(e, value) => {
          props.onchange(value || "");
        }}
        disableClearable
        id="combo-box-demo"
        value={
          props?.Listitem?.length !== 0
            ? props?.Listitem?.filter((e: any) => {
                return e?.value === props?.value[props?.name];
              }).length !== 0
              ? props?.Listitem?.filter((e: any) => {
                  return e?.value === props?.value[props?.name];
                })[0].label
              : props?.value[props?.name]
            : props?.value[props?.name]
        }
        renderOption={(
          props: React.HTMLAttributes<HTMLLIElement>,
          option: Partial<any>
        ) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        disabled={props.disabled}
        options={props.Listitem}
        sx={{ width: props.width }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            label={props.label}
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
            }}
            variant={props.variant}
            color={props.color}
            disabled={props.disabled}
            size={props.size}
            required={props.required}
            style={props.style}
            error={props.error}
            onBlur={props.onBlur}
          />
        )}
      />
    </div>
  );
};

export default Autocomplete;
