import { FunctionComponent, useEffect, useState } from "react";
import "./Adminaccounts.css";
import { useMediaQuery } from "@mui/material";
import AdminCard from "../../Component/AdminCard";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  clearDataaccount,
  list_AccountList,
} from "../../Redux/Reducers/Card_api/list_slicer";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { add_AddCard } from "../../Redux/Reducers/Card_api/add_Card_slicer";
import { edit_EditableValue } from "../../Redux/Reducers/Card_api/editableValue";
import Dialog from "../../Component/DialogForFormaccount";

interface AccountProps {}

const Account: FunctionComponent<AccountProps> = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    dispatch(edit_EditableValue([]));

    setOpen(!open);
  };

  const matches = useMediaQuery("(max-width:425px)");
  const dispatch = useAppDispatch();
  const addData = useAppSelector((state) => state.AddCard.data);
  const editData = useAppSelector((state) => state.EditCard.data);
  const deleteData = useAppSelector((state) => state.DeleteCard.data);
  const EditCheckbox = useAppSelector((state) => state.EditCheckbox.data);
  const addDataIsLoading = useAppSelector((state) => state?.AddCard?.isLoading);
  const data: any = useAppSelector((state) => state.AccountList.data);

  useEffect(() => {
    dispatch(list_AccountList(""));
    return () => {
      dispatch(clearDataaccount());
    };
  }, [addData, editData, deleteData, EditCheckbox, dispatch]);

  const pageName = "View User";
  const menuList = JSON.parse(sessionStorage?.getItem("permission") as string);

  let MenuOfUserpermission: any;

  const foundPage = menuList
    .map((data: any) =>
      data.sub_menus.find((child: any) => child.name === pageName)
    )
    .filter((page: any) => page);

  if (foundPage.length > 0) {
    MenuOfUserpermission = foundPage[0];
  } else {
    const defaultPageName = "Accounts";
    const index = menuList.findIndex(
      (item: any) => item.name === defaultPageName
    );

    if (index !== -1) {
      MenuOfUserpermission = menuList[index];
    }
  }
  return (
    <div style={{ height: "100%" }}>
      <div className="scrollbar" style={{ height: "100%" }}>
        <div className="accountcard">
          <div
            style={{
              height: data.status === false ? "100%" : "-1px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                overflowX: "hidden",
                display: data.status === false ? "flex" : "grid",
                gridTemplateColumns:
                  data.data?.length > 0
                    ? "repeat(auto-fill,minmax(300px,1fr))"
                    : "repeat(auto-fill,minmax(502px,1fr))",
                gap: "2em",
                width: "100%",
                padding: "1em",
                height: "100%",
                alignItems: data.status === false ? "center" : undefined, // Conditionally set alignItems
                justifyContent: data.status === false ? "center" : undefined, // Conditionally set justifyContent
              }}
            >
              <AdminCard setOpen={setOpen} open={open} />
            </div>
          </div>
        </div>
        <div>
          <div
            className="accountfab-btn"
            style={{ position: "fixed", bottom: "50px", right: "35px" }}
          >
            <div
              className="fab-btn"
              style={{ display: matches ? "flex" : "none" }}
            >
              <Fab
                onClick={handleClick}
                sx={{
                  size: "medium",
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "20px",
                  width: "50px !important",
                  height: "50px ! important",
                  position: "fixed",
                  marginBottom: "240px !important",
                  marginRight: "280px !important",
                }}
              >
                <AddIcon />
              </Fab>
            </div>
          </div>
        </div>
      </div>
      <span className="account-contain">
        {MenuOfUserpermission?.permission?.add ? (
          <Dialog
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{ marginBottom: "20px" }}
            placeholder="Role"
            getValues={(v: any) => {
              dispatch(add_AddCard(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Account"}
            Buttonname={
              <Fab
                sx={{
                  size: "medium",
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                  color: "white",
                  borderRadius: "30px",
                  width: "50px !important",

                  height: "50px ! important",
                  "&:hover": {
                    backgroundColor:
                      "linear-gradient(to right, #FFFA02,#F2A618 )",
                  },
                }}
              >
                <AddIcon />
              </Fab>
            }
          />
        ) : (
          <Fab
            disabled={MenuOfUserpermission?.permission?.add === false}
            sx={{
              size: "medium",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "white",
              borderRadius: "30px",
              width: "50px !important",
              height: "50px ! important",
              "&:hover": {
                backgroundColor: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </span>
    </div>
  );
};

export default Account;
