import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Warning from "../pages/svg_image/Waring.gif";
import { FunctionComponent } from "react";
import "../pages/Page.css";
interface DialogsProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  Dialog_Title?: any;
  subtitle?: any;
  Dialog_Content?: any;
  Dialog_Actions?: any;
  DialogBody?: any;
  height?: string;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs";
}

const Dialogs: FunctionComponent<DialogsProps> = (props) => {
  const {
    open,
    Dialog_Title,
    Dialog_Content,
    Dialog_Actions,
    DialogBody,
    subtitle,
  } = props;
  return (
    <div>
      {DialogBody}
      {!DialogBody && (
        <Dialog
          // maxWidth={maxWidth}
          open={open || false}
          PaperProps={{
            sx: {
              width: "33%",
              borderRadius: "10px",
              "&::-webkit-scrollbar": { display: "none" },
            },
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id={Dialog_Title}
            sx={{
              fontSize: {
                xs: "14px",
                sm: "19px",
                md: "19px",
                lg: "19px",
                xl: "19px",
              },
              textAlign: "center",
            }}
          >
            <img src={Warning} alt="Success..." className="DialogTitleimg" />
            <div style={{ textAlign: "center" }}>{subtitle}</div>
          </DialogTitle>
          <DialogContent
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              textAlign: "center",
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            {Dialog_Content}
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "4%",
            }}
          >
            {Dialog_Actions}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Dialogs;
