import { FunctionComponent } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import editimage from "../Image/Edit.svg";
import Grids from "../../Component/Grides";
import { Alert, Button, Snackbar } from "@mui/material";
import Dialogs from "../../Component/Dialog";
import DialogGroupaccount from "../../Component/DialogForFormGroupaccount";
import GroupAccountMessage from "./GroupAccountMessage";
import {
  clearData_group,
  list_ListGroupAccount,
} from "../../Redux/Reducers/GroupAccount/list_GroupAccount_slicer";
import {
  delete_DeleteGroupAccount,
  false_switchDeleteGroupAccount_response,
} from "../../Redux/Reducers/GroupAccount/delete_GroupAccount_slicer";
import { false_switchDeleteGroupAccountvalues } from "../../Redux/Reducers/Datagrid-values/DeletegroupAccount-values-slicer";
import {
  add_AddGroupAccount,
  false_switchGroupadd_response,
} from "../../Redux/Reducers/GroupAccount/add_GroupAccount_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  edit_EditGroupAccount,
  false_switchEditGroupAccount_response,
} from "../../Redux/Reducers/GroupAccount/edit_GroupAccount_slicer";
import { list_AccountList } from "../../Redux/Reducers/Card_api/list_slicer";

interface ViewGroupaccountProps {}

const ViewGroupaccount: FunctionComponent<ViewGroupaccountProps> = () => {
  const dispatcher = useAppDispatch();
  const AddResponse = useAppSelector(
    (state) => state.AddGroupAccount.switchResponse
  );
  const addData: any = useAppSelector((state) => state?.AddGroupAccount?.data);
  const addDataLoading: any = useAppSelector(
    (state) => state?.AddGroupAccount?.isLoading
  );
  const editData: any = useAppSelector(
    (state) => state?.EditGroupAccount?.data
  );
  const editResponse = useAppSelector(
    (state) => state.EditGroupAccount.switchResponse
  );
  const editDataIsLoading = useAppSelector(
    (state) => state?.EditGroupAccount?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteGroupAccount?.data
  );
  const switchRespondelete = useAppSelector(
    (state) => state?.DeleteGroupAccount?.switchResponse
  );
  const data: any = useAppSelector((state) => state?.ListGroupAccount?.data);

  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state.DeleteGroupAccountvalues.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.DeleteGroupAccountvalues.switch
  );
  const pageName = "View User";
  const menuList = JSON.parse(sessionStorage?.getItem("permission") as string);

  let MenuOfUserpermission: any;

  const foundPage = menuList
    .map((data: any) =>
      data.sub_menus.find((child: any) => child.name === pageName)
    )
    .filter((page: any) => page);

  if (foundPage.length > 0) {
    MenuOfUserpermission = foundPage[0];
  } else {
    const defaultPageName = "Accounts";
    const index = menuList.findIndex(
      (item: any) => item.name === defaultPageName
    );

    if (index !== -1) {
      MenuOfUserpermission = menuList[index];
    }
  }

  // Now you can use MenuOfUserpermission as needed

  const column = [
    {
      sortable: true,
      headerName: "Group Name",
      flex: 1,
      field: "name",
    },
    {
      sortable: true,
      headerName: "Accounts",
      field: "accounts",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.accounts[0].client_id;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "accounts" array
        const accounts = params.row.accounts;

        // Use the map function to extract the "broker" and "client_id" properties
        const accountDetails = accounts.map(
          (account: any) => ` ${account.broker} : ${account.client_id} | `
        );
        // .join(","); // Map and join the "broker" and "client_id" properties

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={accountDetails}
          >
            {accountDetails}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  // flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {MenuOfUserpermission?.permission?.edit ? (
                  <DialogGroupaccount
                    successMessage={editData}
                    isLoading={editDataIsLoading}
                    Buttondialog={["edit"]}
                    Dialogtitle={"Edit Group"}
                    getValues={(v: any) => {
                      dispatcher(edit_EditGroupAccount(v));
                    }}
                    placeholder=""
                    sx={{
                      backgroundColor: "transparent",
                      minWidth: "0",
                      padding: "0",
                      borderRadius: "50%",
                      height: "60%",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    edit={{
                      ...params.row,
                    }}
                    Buttonname={
                      <span
                        onClick={() => {
                          const dataToPass = params.row;
                          localStorage.setItem("groupfilter", dataToPass.id);
                        }}
                      >
                        <img src={editimage} width="25px" alt={""}></img>
                      </span>
                    }
                  />
                ) : (
                  "-"
                )}
                {MenuOfUserpermission?.permission?.delete ? (
                  <GroupAccountMessage
                    valuepopup={params.row.id}
                  ></GroupAccountMessage>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    name: true,
    accounts: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(list_ListGroupAccount(""));
    return () => {
      dispatcher(clearData_group());
    };
  }, [addData, editData, deleteData, dispatcher]);

  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteGroupAccount_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchGroupadd_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const editaction = (
    <button
      onClick={() => {
        dispatcher(false_switchEditGroupAccount_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchGroupadd_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchGroupadd_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {addData.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={editResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditGroupAccount_response())}
        key={`top-right`}
        action={editaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditGroupAccount_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchRespondelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteGroupAccount_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteGroupAccount_response())}
          severity="success"
        >
          {deleteData.message}
        </Alert>
      </Snackbar>
      <Grids
        row={data ?? []}
        column={column}
        Removetheborder={"yes"}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        gridesbutton
        pagination
        dialog={
          <DialogGroupaccount
            successMessage={addData}
            isLoading={addDataLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              marginTop: "0px",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
            getValues={(v: any) => {
              dispatcher(add_AddGroupAccount(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Group"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
      ></Grids>

      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchDeleteGroupAccountvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteGroupAccountvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatcher(delete_DeleteGroupAccount(Uservaluesalgodelete));
                dispatcher(false_switchDeleteGroupAccountvalues());
              }}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default ViewGroupaccount;
