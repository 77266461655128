import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialogs from "../../Component/Dialog";
import { false_switchDeleteTreadSetupvalues } from "../../Redux/Reducers/Datagrid-values/DeleteTrade-setup-values-slicer";
import { false_switchTreadSetupvalues } from "../../Redux/Reducers/Datagrid-values/Tradesetup-values-slicer";
import {
  clearData,
  list_TreadSetupList,
} from "../../Redux/Reducers/Trade-setup/list_Trade-setup_slicer";
import {
  edit_EditTreadSetup,
  false_switchTreadSetup_response,
} from "../../Redux/Reducers/Trade-setup/edit_Trade-setup_slicer";
import {
  delete_TreadSetup,
  false_switchdeleteTreadSetup_response,
} from "../../Redux/Reducers/Trade-setup/delete_Trade-setup_slicer";
import {
  add_AddTreadSetup,
  false_switchTreadSetupadd_response,
} from "../../Redux/Reducers/Trade-setup/add_Trade-setup_slicer";
import TradesetupMessage from "./TradesetupMessage";
import DialogTradesetup from "../../Component/DialogForFormTradesetup";
import Tradesetupfilter from "../../Component/Tradesetupfilter";
import { edit_filtermap } from "../../Redux/Reducers/Filtervalue/filtermap";
import Warning from "../svg_image/Waring.gif";
import RefreshIcon from "@mui/icons-material/Refresh";
import { list_StrategiesList } from "../../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { list_AddorEditList } from "../../Redux/Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
import { list_AccountList } from "../../Redux/Reducers/Card_api/list_slicer";
import { list_ListGroupAccount } from "../../Redux/Reducers/GroupAccount/list_GroupAccount_slicer";

interface MapStrategiesProps {}

const TreadSetup: FunctionComponent<MapStrategiesProps> = () => {
  const dispatcher = useAppDispatch();
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Trade Setup
    </Typography>,
  ];
  const data = useAppSelector((state) => state?.TreadSetupList?.data);
  const addData: any = useAppSelector((state) => state?.AddTreadSetup?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddTreadSetup?.isLoading
  );
  const editData: any = useAppSelector((state) => state?.EditTreadSetup?.data);
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditTreadSetup?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteTreadSetup?.data
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.TreadSetupList?.isLoading
  );
  const AddResponse = useAppSelector(
    (state) => state.AddTreadSetup.switchResponse
  );
  const switchResponse = useAppSelector(
    (state) => state.EditTreadSetup.switchResponse
  );
  const Uservalues: any = useAppSelector(
    (state) => state.TreadSetupvalues.data
  );
  const switchOpen = useAppSelector((state) => state.TreadSetupvalues.switch);
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteTreadSetup.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state.DeleteTreadSetupvalues.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.DeleteTreadSetupvalues.switch
  );

  const pageName = "/trade-setup";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const column = [
    {
      sortable: true,
      headerName: " Algo Name",
      flex: 1,
      field: "algo",
      valueGetter: (params: any) => {
        return params.row.algo.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const algoName = params.row.algo.name;

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Strategy Name ",
      field: "strategy",
      valueGetter: (params: any) => {
        return params.row.strategy?.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const strategyName = params.row.strategy?.name ?? "-";

        return <div title={strategyName}>{strategyName}</div>;
      },

      flex: 1,
    },
    {
      sortable: true,

      headerName: "Stock Name ",
      field: "stocknumber",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.stocks[0].name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "stocks" array

        const stocks = params.row.stocks; // Access the "stocks" array
        const algoName = stocks
          .map((stock: any) => `${stock.name} `)
          .join(", "); // Map and join the "name" property

        return (
          <div
            title={algoName}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {algoName}
          </div>
        );
      },
    },
    {
      sortable: true,

      headerName: "Accounts",
      field: "accounts",
      flex: 1,

      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "accounts" array
        const accounts = params.row.accounts;
        if (!accounts) {
          return (
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title="-"
            >
              -
            </div>
          );
        }

        // Use the map function to extract the "broker" and "client_id" properties
        const accountDetails = accounts
          .map((account: any) => `${account.broker} : ${account.client_id}  `)
          .join(","); // Map and join the "broker" and "client_id" properties

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={accountDetails}
          >
            {accountDetails}
          </div>
        );
      },
    },
    {
      sortable: true,

      headerName: "Group",
      field: "groups",
      flex: 1,
      // valueGetter: (params: any) => {
      //   return params.row.groups[0].name;
      // },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "stocks" array

        const groups = params.row.groups; // Access the "stocks" array
        if (!groups) {
          return (
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title="-"
            >
              -
            </div>
          );
        }
        const algoName = groups
          .map((groups: any) => `${groups.name} `)
          .join(", "); // Map and join the "name" property

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      headerName: "Expiry Date",
      flex: 1,
      field: "expiry_date",
      type: "dateTime",
      sortable: true,
      valueGetter: (params: any) => {
        return new Date(params.row.expiry_date);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.expiry_date === null) {
          return "-";
        }

        const date = new Date(params?.row?.expiry_date);

        // Format the date and time
        const formattedDateTime = `${date.toLocaleDateString()}`;

        return (
          <Tooltip title={formattedDateTime}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedDateTime}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Last Execution At",
      flex: 1,
      field: "last_order_time",
      valueGetter: (params: any) => {
        return new Date(params.row.expiry_date);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.last_order_time === null) {
          return "-";
        }
        // Assuming params?.row?.last_order_time is the epoch/unix timestamp
        const timestamp = params?.row?.last_order_time;
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Format the date and time
        const options: any = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Use 24-hour time format
          timeZone: "Asia/Kolkata", // Specify Indian Standard Time
        };

        const formattedDateTime = date.toLocaleString("en-IN", options);
        return (
          <Tooltip title={formattedDateTime}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedDateTime}
            </div>
          </Tooltip>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <TradesetupMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></TradesetupMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    algo: true,
    strategy: true,
    stocknumber: true,
    accounts: true,
    groups: true,
    expiry_date: true,
    last_order_time: true,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(list_TreadSetupList(""));
    dispatcher(edit_filtermap({}));
    dispatcher(list_StrategiesList(""));
    dispatcher(list_AddorEditList(""));
    dispatcher(list_AccountList(""));
    dispatcher(list_ListGroupAccount(""));
    if (switchResponse) {
      dispatcher(false_switchTreadSetupvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteTreadSetupvalues());
    }
    return () => {
      dispatcher(clearData());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponse,
    switchResponsealgodelete,
  ]);

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchTreadSetup_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchdeleteTreadSetup_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchTreadSetupadd_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetupadd_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchTreadSetupadd_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetup_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchTreadSetup_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchdeleteTreadSetup_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchdeleteTreadSetup_response())}
          severity="success"
        >
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataIsLoading || editDataIsLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data ?? []}
        column={column}
        open
        pagination
        // getRowHeight={() => "auto"}
        sideButton={<Tradesetupfilter></Tradesetupfilter>}
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(list_TreadSetupList(""));
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        dialog={
          <DialogTradesetup
            successMessage={addData}
            isLoading={addDataIsLoading}
            disable={MenuOfUserpermission?.permission?.add === false}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "7px",
              marginTop: "0px",
              marginRight: "-18px",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            placeholder="Role"
            getValues={(v: any) => {
              dispatcher(add_AddTreadSetup(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Trade Setup"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        open={switchOpen}
        subtitle={"Warning"}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        setOpen={() => dispatcher(false_switchTreadSetupvalues())}
        Dialog_Content={
          Uservalues.status === 0
            ? "Do you want to activate tradesetup ?"
            : "Do you want to de-activate tradesetup ?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                padding: "15px",
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchTreadSetupvalues())}
            >
              No
            </Button>
            <Button
              style={{
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "20px",
              }}
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
              }}
              onClick={() => dispatcher(edit_EditTreadSetup(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchDeleteTreadSetupvalues())}
        Dialog_Content={"Do you want to delete ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteTreadSetupvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_TreadSetup(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default TreadSetup;
