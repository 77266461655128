import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for edit userpermission
export const Edit_User_permission = createSlice({
  name: "Edit_Userpermission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    edit_Userpermission: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_Edit_Userpermission: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true
    },
    failure_api_call_Edit_Userpermission: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    Edit_Userpermission_is_success: (state) => {
      state.isSuccess = false;
    },
    Edit_Userpermission_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_Edit_Userpermission_Data: (state) => {
      state.data = [];
    },

    false_switchEdit_Userpermission_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default Edit_User_permission.reducer;
export const {
  edit_Userpermission,
  success_api_call_Edit_Userpermission,
  Edit_Userpermission_complete_api,
  failure_api_call_Edit_Userpermission,
  Edit_Userpermission_is_success,
  clear_Edit_Userpermission_Data
  ,false_switchEdit_Userpermission_response
} = Edit_User_permission.actions;
