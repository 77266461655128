import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { clearStrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { useState } from "react";
import { list_Stock } from "../Redux/Reducers/Stock/list_Stock_slicer";
import { list_TradeList } from "../Redux/Reducers/Trade-details/list_TradeList_slicer";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import {
  clearfilterTradeHistory_manual,
  data_filterTradeHistory_manual,
} from "../Redux/Reducers/Filtervalue/filterTradeHistory_Manual";
import { Paper } from "@mui/material";

type Anchor = "top" | "left" | "bottom" | "right";

export default function HistoryTrademanual(props: any) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const dispatcher = useAppDispatch();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateRange, setDateRange] = React.useState<
    DateRange | null | undefined
  >(null);

  // Calculate the start date (7 days ago) and end date (today)
  React.useEffect(() => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 7);
    // Format the dates as ISO strings (yyyy-MM-dd)
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    // Set the date range
    setDateRange([new Date(formattedStartDate), new Date(formattedEndDate)]);

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "text";
    hiddenInput.style.display = "none";
    hiddenInput.setAttribute("autocomplete", "off");
    hiddenInput.setAttribute("id", "hiddenInput");
    // Append the input to the body or another appropriate container
    document.body.appendChild(hiddenInput);
    // Remove the input when the component is unmounted
    return () => {
      document.body.removeChild(hiddenInput);
    };
  }, [dispatcher]);

  const FilterTradeHistory: any = useAppSelector(
    (state) => state?.filterTradeHistory_maual?.data
  );
  const stock: any = useAppSelector((state) => state?.Stock?.data);
  const segmentOptions = [
    { label: "NFO Future", value: "NFO,FUTURE" },
    { label: "NFO Option", value: "NFO,OPTION" },
  ];

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <span title="Filter">
            <FilterListIcon
              onClick={toggleDrawer(anchor, true)}
              sx={{
                marginTop: "5px",
                fontSize: "24px",
                marginRight: "5px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            ></FilterListIcon>
          </span>
          <Drawer anchor={anchor} open={state[anchor]}>
            <Paper
              sx={{
                width: {
                  xs: 200,
                  sm: 200,
                  md: 300,
                  lg: 360,
                  xl: 400,
                },
                zIndex: "1",
                height: "100%",
              }}
              role="presentation"
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#2C8E14",
                  justifyContent: "space-between",
                  height: "64px",
                }}
              >
                <span
                  style={{
                    padding: "17px",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                >
                  Filter
                </span>
                <div style={{ padding: "20px", cursor: "pointer" }}>
                  <CancelIcon
                    sx={{ color: "white" }}
                    onClick={toggleDrawer(anchor, false)}
                  ></CancelIcon>
                </div>
              </div>

              <Formik
                initialValues={{
                  Segmentopenposition:
                    FilterTradeHistory?.Segmentopenposition ?? null, // Set the initial value here
                  Stocks: FilterTradeHistory?.Stocks ?? null,
                  Product: FilterTradeHistory?.Product ?? null,
                  typelist: FilterTradeHistory?.typelist ?? null,
                  status: FilterTradeHistory?.status ?? null,
                  dateRange: FilterTradeHistory?.dateRange ?? null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values) => {
                  console.log("tthhtht", values);

                  if (values.dateRange != null) {
                    const fromDate = new Date(values.dateRange[0]);
                    const year = fromDate.getFullYear();
                    const month = String(fromDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const day = String(fromDate.getDate()).padStart(2, "0");
                    const formattedFromDate = `${year}-${month}-${day}`;
                    const toate = new Date(values.dateRange[1]);
                    const toyear = toate.getFullYear();
                    const tomonth = String(toate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const today = String(toate.getDate()).padStart(2, "0");
                    const formattedToDate = `${toyear}-${tomonth}-${today}`;
                    dispatcher(
                      list_TradeList({
                        ...values,

                        from_date: formattedFromDate,
                        to_date: formattedToDate,
                        type: props.filtertype,
                        entry_type: props.entry_type,
                      })
                    );
                    console.log(values);
                  } else {
                    dispatcher(
                      list_TradeList({
                        ...values,
                        from_date: null,
                        to_date: null,
                        type: props.filtertype,
                        entry_type: props.entry_type,
                      })
                    );
                    console.log(values);
                  }
                  dispatcher(data_filterTradeHistory_manual(values));
                }}
              >
                {({ values, handleSubmit, handleBlur, setFieldValue }) => {
                  return (
                    <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Segment
                      </div>

                      <Autocomplete
                        Listitem={segmentOptions}
                        variant={"outlined"}
                        name={"Segmentopenposition"}
                        value={values}
                        onBlur={props.handleBlur}
                        onchange={(value: any, newValue: any) => {
                          setFieldValue("Segmentopenposition", [value.value]);
                          setFieldValue("Stocks", null);

                          dispatcher(
                            list_Stock({
                              segment: [value?.value],
                            })
                          );
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>

                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Stock
                      </div>
                      <Autocomplete
                        Listitem={stock?.map((v: any) => {
                          return {
                            label: v.stock_name,
                            value: v.id,
                          };
                        })}
                        variant={"outlined"}
                        name={"Stocks"}
                        value={values}
                        onBlur={handleBlur}
                        onchange={(e: any, newValue: any) => {
                          setFieldValue("Stocks", e.value);
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>
                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Product
                      </div>
                      <Autocomplete
                        Listitem={[
                          { label: "Intraday - MIS", value: "MIS" },
                          { label: "Longterm  - CNC", value: "CNC" },
                        ]}
                        variant={"outlined"}
                        name={"Product"}
                        value={values}
                        size={"medium"}
                        onBlur={handleBlur}
                        onchange={(e: any, newValue: any) => {
                          setFieldValue("Product", e.value);
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>

                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Type
                      </div>
                      <Autocomplete
                        Listitem={[
                          { label: "MARKET", value: "MARKET" },
                          { label: "LIMIT", value: "LIMIT" },
                          { label: "SL-L", value: "SL-L" },
                          { label: "SL-M", value: "SL-M" },
                        ]}
                        variant={"outlined"}
                        name={"typelist"}
                        value={values}
                        size={"medium"}
                        onBlur={handleBlur}
                        onchange={(e: any, newValue: any) => {
                          setFieldValue("typelist", e.value);
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>

                      {props.protal !== "open_position" ? (
                        <>
                          <div
                            style={{
                              color: "#000000",
                              padding: "10px 0px 0px 20px",
                              fontSize: "14px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Select Status
                          </div>
                          <Autocomplete
                            Listitem={[
                              { label: "Traget", value: 1 },
                              { label: "SL", value: 2 },
                              { label: "TSL", value: 3 },
                              { label: "Manual Exit", value: 4 },
                              { label: "Square Off", value: 5 },
                            ]}
                            variant={"outlined"}
                            name={"status"}
                            value={values}
                            size={"medium"}
                            onBlur={handleBlur}
                            onchange={(e: any, newValue: any) => {
                              setFieldValue("status", e.value);
                            }}
                            style={{
                              padding: "5px 20px 0px 20px ",
                              width: "100%",
                            }}
                          ></Autocomplete>
                        </>
                      ) : null}
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "5px 20px 0px 20px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Trading Dates
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "20px",
                          marginRight: "20px",
                          overflowY: isCalendarOpen ? "auto" : "hidden",
                        }}
                        onWheel={(e) => {
                          if (isCalendarOpen) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <DateRangePicker
                          showOneCalendar
                          className="custom-date-range-picker"
                          defaultValue={dateRange}
                          editable={false}
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "0.5px solid gray",
                          }}
                          name="dateRange"
                          value={values.dateRange}
                          onChange={(selectedRange: any) => {
                            setFieldValue("dateRange", selectedRange);
                          }}
                          onFocus={() => {
                            // Focus on the hidden input to prevent autofill
                            const hiddenInput =
                              document.getElementById("hiddenInput");
                            if (hiddenInput) {
                              hiddenInput.focus();
                            }
                          }}
                          onClose={() => {
                            setIsCalendarOpen(false);
                          }}
                          format="yyyy-MM-dd"
                          placeholder="Select Date Range"
                          placement="autoVertical"
                        />
                      </div>
                      <div
                        className="bottom_div"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#2C8E14",
                          width: "100%",
                          gap: "1em",
                          height: "60px",
                          padding: "10px",
                        }}
                      >
                        <div>
                          <span onClick={toggleDrawer(anchor, false)}>
                            <button
                              type="button"
                              onClick={() => {
                                dispatcher(
                                  list_TradeList({
                                    type: props.filtertype,
                                    entry_type: props.entry_type,
                                  })
                                );
                                dispatcher(clearfilterTradeHistory_manual());
                                dispatcher(clearStrategiesList());
                                toggleDrawer(anchor, false);
                              }}
                              style={{
                                width: "100px",
                                height: "40px",
                                color: "black",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                fontFamily: "Roboto",
                                border: "1px solid #F2A618",
                              }}
                            >
                              Reset
                            </button>
                          </span>
                        </div>
                        <div>
                          <span onClick={toggleDrawer(anchor, false)}>
                            <button
                              type="submit"
                              style={{
                                width: "100px",
                                height: "40px",
                                color: "black",
                                background: !props.isValid
                                  ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                                  : "linear-gradient(to right, #FFFA02,#F2A618 )",

                                borderRadius: "5px",
                                fontFamily: "Roboto",
                              }}
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
