import { createSlice } from "@reduxjs/toolkit";
import { Iaccount } from "../../../pages/accounts/IAccount";
 // this code for slice value for EditCheckbox
export const EditCheckbox = createSlice({
  name: "EditCheckbox",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: {} as Iaccount,
  },
  reducers: {
    edit_EditCheckbox: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditCheckbox: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditCheckbox: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditCheckbox_is_success: (state) => {
      state.isSuccess = false;
    },
    EditCheckbox_complete_api: (state) => {
      state.api_completed = false;
    },
    clearCard: (state) => {
      state.data = {};
    },
    false_EditCheckbox_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditCheckbox.reducer;
export const {
  edit_EditCheckbox,
  success_api_call_EditCheckbox,
  EditCheckbox_complete_api,
  failure_api_call_EditCheckbox,
  EditCheckbox_is_success,
  clearCard,false_EditCheckbox_response
} = EditCheckbox.actions;
