import { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Grids from "../../Component/Grides";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as ExcelJS from "exceljs";
import {
  clear_OpenPositionList,
  list_OpenPositionList,
} from "../../Redux/Reducers/Open-Position/list_OpenPosition_slicer";
import Action from "./Action_for_manual";
import {
  Square_off,
  false_switchSquareoff_response,
} from "../../Redux/Reducers/Square-off/Square-off_slicer";
import Dialogs from "../../Component/Dialog";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Squareoffvalues_datagrid,
  false_switchSquareoffvalues,
} from "../../Redux/Reducers/Datagrid-values/Squareoff-values-slicer";
import { edit_filtertrade } from "../../Redux/Reducers/Filtervalue/filtertrade";
import { list_TradeListGroup } from "../../Redux/Reducers/Trade-details/list_TradeList_grouplist_slicer";
import DialogOpenPoistionmanual from "../../Component/DialogForFormOpenPosition_manual";
import LiveTradefiltermanual from "../../Component/LiveTradefilter_manual";
import DialogOpenposition from "../../Component/Dialog_for_view_data_openposition";
import EditOpenPositionMessage from "./Edit_Delete_OpenManual";
import {
  delete_DeleteOpenPosition,
  false_switchDeleteOpenPosition_response,
} from "../../Redux/Reducers/Open-Position/delete_open_position_manual_slicer";
import { false_switchDeleteOpenPositionvalues } from "../../Redux/Reducers/Datagrid-values/Delete_openpositon-values-slicer";
import { false_switchEditOpenPosition_response } from "../../Redux/Reducers/Open-Position/edit_open_position_slicer";
import Warning from "../svg_image/Waring.gif";
import { false_switchAddOpenPosition_response } from "../../Redux/Reducers/Open-Position/add_open_position_slicer";
interface OpenPositionProps {}

const OpenPositionmanual: FunctionComponent<OpenPositionProps> = () => {
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manual Trading
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Open Position
    </Typography>,
  ];

  const dispatcher = useAppDispatch();

  //    datas of Trade
  const datas = useAppSelector((state) => state?.OpenPositionList?.data);
  const squareoff: any = useAppSelector((state) => state?.Squareoff?.data);
  const switchResponsesquareoff = useAppSelector(
    (state) => state.Squareoff.switchResponse
  );
  const AddOpenPositionadd: any = useAppSelector(
    (state) => state.AddOpenPosition.data
  );
  const AddOpenPositionLoading = useAppSelector(
    (state) => state.AddOpenPosition.isLoading
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.Squareoffvalues.switch
  );
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteOpenPosition?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteOpenPosition?.data
  );
  const switchResponsealgodelete = useAppSelector(
    (state) => state?.DeleteOpenPosition?.switchResponse
  );
  const Responsealgoedit = useAppSelector(
    (state) => state?.EditOpenPosition?.switchResponse
  );
  const ResponsealgoAdd = useAppSelector(
    (state) => state?.AddOpenPosition?.switchResponse
  );
  const Squareoffvalues: any = useAppSelector(
    (state) => state?.Squareoffvalues?.data
  );
  const switchResponse = useAppSelector(
    (state) => state.Squareoff.switchResponse
  );
  const isLoading = useAppSelector(
    (state) => state?.OpenPositionList?.isLoading
  );
  const LiveList_data_date: any = useAppSelector(
    (state) => state?.filterlive?.data
  );
  const valuesalgodelete: any = useAppSelector(
    (state) => state?.DeleteOpenPositionvalues?.data
  );
  const Openalgodelete = useAppSelector(
    (state) => state?.DeleteOpenPositionvalues?.switch
  );
  const editData: any = useAppSelector(
    (state) => state?.EditOpenPosition?.data
  );

  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditOpenPosition?.isLoading
  );

  const pageName = "/manualopenposition";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  React.useEffect(() => {
    dispatcher(
      list_OpenPositionList({
        type: "Group",
        entry_type: "Manual",
        strategy_id: LiveList_data_date.strategy_id,
        algo_id: LiveList_data_date.algo_id,
        Status: LiveList_data_date.Status,
        typelist: LiveList_data_date.typelist,
        Stocks: LiveList_data_date.Stocks,
        Product: LiveList_data_date.Product,
      })
    );
    dispatcher(edit_filtertrade({}));
    if (switchResponsesquareoff) {
      dispatcher(false_switchSquareoffvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteOpenPositionvalues());
    }
    return () => {
      dispatcher(clear_OpenPositionList());
    };
  }, [
    dispatcher,
    switchResponsesquareoff,
    LiveList_data_date,
    AddOpenPositionadd,
    editData,
    deleteData,
    switchResponsealgodelete,
  ]);

  const currentDateTime = new Date().toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const namelist = localStorage.getItem("name");

  const exportToExcel = (data: any) => {
    let sheetName = "open_opsition.xlsx";
    let headerName = "Open Position Data";

    let workbook = new ExcelJS.Workbook();

    let sheet = workbook.addWorksheet(sheetName);

    let columnArr = [];
    for (let i in data[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnArr.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        showFirstColumn: true,
      },
      columns: [{ name: "Open Position List" }],
      rows: [
        [
          `Generated At: ${new Date().toLocaleDateString(
            "en-IN"
          )} ${currentDateTime}`,
        ],
        [`Generated By: ${namelist}`],
      ],
    });

    sheet.addTable({
      name: headerName,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        // width: 200
      },
      columns: columnArr
        ? columnArr.map((col) => ({
            name: col.name,
            filterButton: false,
            style: { alignment: { horizontal: "center" } },
          }))
        : [{ name: "" }],
      rows: data.map((e: any) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("A1").font = { size: 20, bold: true };

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });

    const table = sheet.getTable(headerName);
    if (table && table.columns) {
      for (let i = 0; i < table.columns.length; i++) {
        // Access table columns and rows directly
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d9f1" },
        };

        if (table.rows) {
          for (let j = 0; j < table.rows.length; j++) {
            let rowCell = sheet.getCell(
              `${String.fromCharCode(65 + i)}${j + 6}`
            );
            rowCell.alignment = { horizontal: "center", wrapText: true };
            rowCell.border = {
              bottom: {
                style: "thin",
                color: { argb: "a6a6a6" },
              },
            };
          }
        }
      }
    }

    table.commit();

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      writeFile(sheetName, buffer);
    });
  };

  const column = [
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1,
      field: "stock_name",
    },
    {
      sortable: true,
      headerName: "Group Name",
      field: "group",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const group = params?.row?.group;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={group === null ? "-" : group?.name}
          >
            {group === null ? "-" : group?.name}
          </div>
        );
      },
    },
    {
      headerName: "Execution Type",
      field: "execution_type",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Trade Type",
      field: "paper_trade",
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row?.order?.paper_trade;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row?.order === null) {
          return "-";
        }
        const paper_trade = params.row?.order?.paper_trade;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={paper_trade === true ? "Paper Trade" : "Live Trade"}
          >
            {paper_trade === true ? "Paper Trade" : "Live Trade"}
          </div>
        );
      },
    },

    {
      headerName: "Quantity",
      field: "quantity",
      flex: 0.5,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.quantity;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const quantity = params.row.quantity;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={quantity}
          >
            {quantity}
          </div>
        );
      },
    },
    {
      headerName: "Order Type",
      field: "order_type",
      flex: 0.5,
      valueGetter: (params: any) => {
        return params.row.order_type;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={params.row.order_type}
          >
            {params.row.order_type}
          </div>
        );
      },
      sortable: true,
    },

    {
      headerName: "Expires In",
      field: "expires_in",
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.expires_in;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.expires_in === null) {
          return "-";
        }
        if (params.row.expires_in === -1) {
          return "Expired";
        }
        if (params.row.expires_in === -2) {
          return "Today";
        }
        const quantity = params.row.expires_in;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "red",
              fontWeight: "600",
            }}
            title={quantity}
          >
            {quantity} {quantity === 0 ? "day" : "days"}
          </div>
        );
      },
    },

    {
      headerName: "Entry Price",
      field: "entry_price",
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row?.entry_price;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.entry_price === null) {
          return "-";
        }
        const entry_price = params?.row?.entry_price;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={entry_price}
          >
            {entry_price}
          </div>
        );
      },
    },
    {
      headerName: "Entry At",
      field: "entry_at",
      flex: 1,
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.entry_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.entry_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.entry_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Exit At",
      field: "exit_at",
      flex: 1,
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.exit_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.exit_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.exit_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    true
      ? {
          headerName: " Action",
          field: "Action",
          flex: 1.5,
          type: "actions",
          renderCell: (params: GridRenderCellParams<any>) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {params.row.is_entry === true || params.row.is_exit === true ? (
                  <DialogOpenposition
                    Buttondialog={["edit"]}
                    Dialogtitle={"Trade History"}
                    sx={{
                      backgroundColor: "transparent",
                      minWidth: "0",
                      padding: "0",
                      borderRadius: "50%",
                      height: "60%",
                      marginTop: "4%",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    edit={{
                      ...params.row,
                    }}
                    Buttonname={
                      <span
                        title="View"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatcher(
                            list_TradeListGroup({
                              strategy_id: params.row.id,
                              group_id: params.row.group.id,
                            })
                          );
                          const group = params.row.group.name;
                          localStorage.setItem("Openpositiongroup", group);
                        }}
                      >
                        <RemoveRedEyeIcon sx={{ fontSize: "20px" }} />
                      </span>
                    }
                  />
                ) : null}

                <EditOpenPositionMessage
                  valuepopup={params.row.id}
                  editdata={params.row}
                ></EditOpenPositionMessage>

                {params.row.is_entry === true &&
                params.row.is_exit === false ? (
                  <Action
                    valuepopup={params?.row?.id}
                    editdata={params?.row}
                  ></Action>
                ) : null}
              </div>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    stock_name: true,
    group: false,
    execution_type: false,
    paper_trade: false,
    quantity: true,
    order_type: true,
    expires_in: true,
    entry_price: true,
    entry_at: true,
    exit_at: false,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  // this code  for excle
  const exportdatalist = datas?.map((data: any) => {
    console.log(data);

    const timestampInMillisecond = data?.exit_at * 1000;
    // Create a Date object from the timestamp
    const utcDate = new Date(timestampInMillisecond);

    const ISTDate = new Date(utcDate.getTime());

    const formattedISTDate = ISTDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });

    const timestampInMilliseconds = data?.entry_at * 1000;
    // Create a Date object from the timestamp
    const utcDates = new Date(timestampInMilliseconds);
    const ISTDates = new Date(utcDates.getTime());
    const formattedISTDates = ISTDates.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });
    const isIndividualAlignment = "Group";

    return {
      // Strategy_Name: data.strategy_name,
      Stock_Name: data.stock_name,
      [isIndividualAlignment ? "Account_Name" : "Group_Name"]:
        isIndividualAlignment
          ? `${data.account?.broker} : ${data.account?.client_id}`
          : data.group?.name || "-",
      Execution_Type: data.execution_type,
      Trade_Type:
        data?.order === null
          ? "-"
          : data?.order?.paper_trade === true
          ? "Paper Trade"
          : "Live Trade",
      Quantity: data.quantity,
      Order_Type: data?.order_type,
      ExpiresIn: data?.expires_in === null ? "-" : data?.expires_in,
      Entry_Price: data.entry_price === null ? "-" : data.entry_price,
      Entry_At:
        "1/1/1970, 5:30:00 am" === formattedISTDates
          ? "-"
          : formattedISTDates === null
          ? "-"
          : formattedISTDates,
      Exit_at:
        "1/1/1970, 5:30:00 am" === formattedISTDate
          ? "-"
          : formattedISTDate === null
          ? "-"
          : formattedISTDate,
    };
  });

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchSquareoff_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchEditOpenPosition_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actionsadd = (
    <button
      onClick={() => {
        dispatcher(false_switchAddOpenPosition_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoading ||
              AddOpenPositionLoading ||
              DeleteisLoading ||
              editDataIsLoading
                ? "block"
                : "none",
          }}
        />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchSquareoff_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchSquareoff_response())}
          severity="success"
        >
          {squareoff?.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Responsealgoedit} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditOpenPosition_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditOpenPosition_response())}
          severity="success"
        >
          {editData?.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={ResponsealgoAdd} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddOpenPosition_response())}
        key={`top-right`}
        action={actionsadd}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddOpenPosition_response())}
          severity="success"
        >
          {AddOpenPositionadd?.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteOpenPosition_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteOpenPosition_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData?.message}
        </Alert>
      </Snackbar>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Grids
        row={datas ?? []}
        column={column}
        opens
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        // getRowHeight={() => "auto"}
        pagination
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(
                list_OpenPositionList({
                  type: "Group",
                  entry_type: "Manual",
                  strategy_id: LiveList_data_date.strategy_id,
                  algo_id: LiveList_data_date.algo_id,
                  Status: LiveList_data_date.Status,
                  typelist: LiveList_data_date.typelist,
                  Stocks: LiveList_data_date.Stocks,
                })
              );
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        Export={
          <span
            title={
              datas.length > 0
                ? "Export"
                : "No data available in the table. Please note that data export is not possible."
            }
            onClick={() => {
              if (datas.length > 0) {
                exportToExcel(exportdatalist);
              }
            }}
          >
            <SaveAltIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></SaveAltIcon>
          </span>
        }
        sideButton={
          <LiveTradefiltermanual
            filtertype={"Group"}
            entry_type={"Manual"}
            protal={"open_position"}
          ></LiveTradefiltermanual>
        }
        dialogopenposition={
          <DialogOpenPoistionmanual
            successMessage={AddOpenPositionadd}
            isLoading={AddOpenPositionLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Place Order"}
            Buttonname={"Add New"}
          />
        }
        dialog={
          <Button
            disabled={MenuOfUserpermission?.permission?.edit === false}
            sx={{
              backgroundColor: "#E10000",
              color: "white",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              textTransform: "capitalize",
              marginLeft: {
                xs: "5px",
                sm: "5px",
                md: "5px",
                lg: "5px",
                xl: "7px",
              },
              "&:hover": { backgroundColor: "#E10000" },
              fontFamily: "Roboto",
            }}
            onClick={() => {
              dispatcher(Squareoffvalues_datagrid({}));
            }}
          >
            Square Off
          </Button>
        }
        Userpermissions={undefined}
      ></Grids>

      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchSquareoffvalues())}
        Dialog_Content={"Do you want to square off ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchSquareoffvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(
                  Square_off({
                    Squareoffvalues,
                    trade_type: "Group",
                    trade_from: "Manual",
                  })
                )
              }
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"md"}
        open={Openalgodelete}
        subtitle={"Warning"}
        Dialog_Title={
          <img src={Warning} className="DialogTitleimg" alt="Success..." />
        }
        setOpen={() => dispatcher(false_switchDeleteOpenPositionvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                padding: "15px",
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteOpenPositionvalues())}
            >
              No
            </Button>
            <Button
              style={{
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "20px",
              }}
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
              }}
              onClick={() =>
                dispatcher(delete_DeleteOpenPosition(valuesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default OpenPositionmanual;
