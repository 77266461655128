import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for edit role
export const EditRole = createSlice({
  name: "EditRole",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    edit_EditRole: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditRole: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditRole_is_success: (state) => {
      state.isSuccess = false;
    },
    EditRole_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_EditRole_Data: (state) => {
      state.data = [];
    },

    false_switchEditRole_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditRole.reducer;
export const {
  edit_EditRole,
  success_api_call_EditRole,
  EditRole_complete_api,
  failure_api_call_EditRole,
  EditRole_is_success,
  clear_EditRole_Data
  ,false_switchEditRole_response
} = EditRole.actions;
