import { FunctionComponent } from "react";

import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import Grids from "../../Component/Grides";

import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";

import Mapfilter from "../../Component/Mapfilter";
import {
  clearStrategiesList,
  list_StrategiesList,
} from "../../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import StrategyMessage from "./StrategiesMessage";
import Dialogs from "../../Component/Dialog";
import {
  delete_Strategies,
  false_switchdeletestrategies_response,
} from "../../Redux/Reducers/Strategies_api/delete_Strategies_slicer";
import { false_switchDeleteStrategiesvalues } from "../../Redux/Reducers/Datagrid-values/DeleteStrategies-values-slicer";
import { false_switchStrategies } from "../../Redux/Reducers/Datagrid-values/Strategies-values-slicer";
import {
  edit_EditStrategies,
  false_switchstrategies_response,
} from "../../Redux/Reducers/Strategies_api/edit_Strategies_slicer";
import { edit_Selectvalue } from "../../Redux/Reducers/Strategies_api/selectvalue";
import { false_switchStrategies_response } from "../../Redux/Reducers/Strategies_api/add_Strategies_slicer";
import { list_TreadSetupList } from "../../Redux/Reducers/Trade-setup/list_Trade-setup_slicer";

interface ViewStrategiesProps {}

const ViewStrategies: FunctionComponent<ViewStrategiesProps> = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Strategies
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      View Strategies
    </Typography>,
  ];
  const Tradesetupdata = useAppSelector((state) => state?.TreadSetupList?.data);
  const data = useAppSelector((state) => state?.StrategiesList?.data);

  const datavaluefilter = data?.map((data: any) => data?.algo?.name);
  const datafilter = Tradesetupdata?.map((data: any) => data?.algo?.name);
  const strategiesfilter = data?.map((data: any) => data?.name);
  const strategedatafilter = Tradesetupdata?.map(
    (data: any) => data?.strategy?.name
  );

  const updatedData = data.map((item: any) => {
    // Check if the item's name is in both datavaluefilter and datafilter
    const isMatchedstragies =
      strategiesfilter.includes(item.name) &&
      strategedatafilter.includes(item.name);

    const isMatched =
      datavaluefilter.includes(item.algo.name) &&
      datafilter.includes(item.algo.name);

    // Return a new object with the matched property set
    return {
      ...item,
      isMatch: isMatched && isMatchedstragies,
    };
  });

  const isLoadingdata = useAppSelector(
    (state) => state?.StrategiesList?.isLoading
  );
  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddStrategies?.isLoading
  );
  const addData: any = useAppSelector((state) => state?.AddStrategies?.data);
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditStrategies?.isLoading
  );
  const editData: any = useAppSelector((state) => state?.EditStrategies?.data);
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteStrategies?.data
  );
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteStrategies?.isLoading
  );
  const switchResponse = useAppSelector(
    (state) => state.EditStrategies.switchResponse
  );
  const strategiesvalues: any = useAppSelector(
    (state) => state.Strategiesvalues.data
  );
  const switchOpenstrategies = useAppSelector(
    (state) => state.Strategiesvalues.switch
  );
  const switchResponsestrategiesdelete = useAppSelector(
    (state) => state.DeleteStrategies.switchResponse
  );
  const strategiesdelete: any = useAppSelector(
    (state) => state.DeleteStrategiesvalues.data
  );
  const switchOpenstrategiesdelete = useAppSelector(
    (state) => state.DeleteStrategiesvalues.switch
  );
  const AddStrategiesResponse = useAppSelector(
    (state) => state.AddStrategies.switchResponse
  );

  const pageName = "View Strategies";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const column = [
    {
      sortable: true,
      headerName: "Strategy Name",
      flex: 1,
      field: "name",
    },
    {
      sortable: true,
      headerName: "Algo Name",
      flex: 1,
      field: "algo",
      valueGetter: (params: any) => {
        return params.row.algo.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const algoName = params.row.algo.name;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={algoName}
          >
            {algoName}
          </div>
        );
      },
    },
    {
      sortable: true,
      headerName: "Segment",
      flex: 1,
      field: "segment",
      valueGetter: (params: any) => {
        return params.row.segment.map((metaItem: any) => {
          return `${metaItem}`;
        });
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Map meta_data array to a string of key-value pairs
        const segment = params.row.segment || [];

        // Map 'segment' array to a string of key-value pairs
        const values = segment
          .map((metaItem: any) => {
            return `${metaItem.replace(",", " ")} |`;
          })
          .join(" "); // Join array elements into a single string

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={values}
          >
            {values}
          </div>
        );
      },
    },

    {
      sortable: true,
      headerName: "Meta Data",
      flex: 1,
      field: "meta_data",
      valueGetter: (params: any) => {
        return params.row.meta_data.map((metaItem: any) => {
          return `${metaItem.key} : ${metaItem.value} |`;
        });
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Map meta_data array to a string of key-value pairs
        const metaValues = params.row.meta_data.map((metaItem: any) => {
          return `${metaItem.key} : ${metaItem.value} |`;
        });

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={metaValues.join("\n")}
          >
            {metaValues.join("\n")}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <StrategyMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></StrategyMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    name: true,
    algo: true,
    segment: true,
    meta_data: true,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(edit_Selectvalue({}));
    dispatcher(list_StrategiesList(""));
    dispatcher(list_TreadSetupList(""));
    if (switchResponse) {
      dispatcher(false_switchStrategies());
    }
    if (switchResponsestrategiesdelete) {
      dispatcher(false_switchDeleteStrategiesvalues());
    }
    return () => {
      dispatcher(clearStrategiesList());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponse,
    switchResponsestrategiesdelete,
  ]);
  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchstrategies_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchdeletestrategies_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const AddStratrgiesaction = (
    <button
      onClick={() => {
        dispatcher(false_switchStrategies_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddStrategiesResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchStrategies_response())}
        key={`top-right`}
        action={AddStratrgiesaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchStrategies_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchstrategies_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchstrategies_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsestrategiesdelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchdeletestrategies_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchdeletestrategies_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={updatedData ?? []}
        column={column}
        gridesbutton
        pagination
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        // getRowHeight={() => "auto"}
        sideButton={<Mapfilter></Mapfilter>}
        dialog={
          <Button
            disabled={MenuOfUserpermission?.permission?.add === false}
            onClick={() => {
              navigate("/addstrategies", {
                state: { editoradd: "add", successMessage: { addData } },
              });
            }}
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              marginLeft: "10px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
          >
            Add New
          </Button>
        }
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        open={switchOpenstrategies}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchStrategies())}
        Dialog_Content={
          strategiesvalues.is_enabled === true
            ? "Do you want to activate  status?"
            : "Do you want to de-activate  status?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchStrategies())}
            >
              No
            </Button>
            <Button
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              autoFocus
              onClick={() => dispatcher(edit_EditStrategies(strategiesvalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpenstrategiesdelete}
        setOpen={() => dispatcher(false_switchDeleteStrategiesvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteStrategiesvalues())}
            >
              No
            </Button>
            <Button
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              autoFocus
              onClick={() => dispatcher(delete_Strategies(strategiesdelete))}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default ViewStrategies;
