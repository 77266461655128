import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for add role 
export const AddRole = createSlice({
  name: "AddRole",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_AddRole: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_AddRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_AddRole: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    add_AddRole_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_AddRole_complete_api: (state, action) => {
      state.api_completed = false;
    },
    clear_AddRole_Data: (state) => {
      state.data = [];
    },
    false_switchAddRole_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default AddRole.reducer;
export const {
  add_AddRole,
  success_api_call_add_AddRole,
  failure_api_call_add_AddRole,
  create_AddRole_complete_api,
  add_AddRole_is_success,
  clear_AddRole_Data,
  false_switchAddRole_response
} = AddRole.actions;
