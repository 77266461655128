import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter holdings
export const filterHoldings = createSlice({
  name: "filterHoldings",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },
  reducers: {
    data_filterHoldings: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filterHoldings: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filterHoldings: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filterHoldings_is_success: (state) => {
      state.isSuccess = false;
    },
    filterHoldings_complete_api: (state) => {
      state.api_completed = false;
    },
    clearfilterHoldings: (state) => {
      state.data = [];
    },
  },
});
export default filterHoldings.reducer;
export const {
  data_filterHoldings,
  success_api_call_filterHoldings,
  filterHoldings_complete_api,
  failure_api_call_filterHoldings,
  filterHoldings_is_success,
  clearfilterHoldings
} = filterHoldings.actions;
