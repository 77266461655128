import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   Rolevalues
export const Rolevalues = createSlice({
  name: "Rolevalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    Rolevalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_Rolevalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_Rolevalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    Rolevalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_Rolevalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchRolevalues:(state)=>{
        state.switch = false
    }
  },
});
export default Rolevalues.reducer;
export const {
  Rolevalues_datagrid,
  success_api_call_Rolevalues_datagrid,
  failure_api_call_Rolevalues_datagrid,
  create_Rolevalues_datagrid_complete_api,
  Rolevalues_datagrid_is_success,false_switchRolevalues
} = Rolevalues.actions;
