import { FunctionComponent, useEffect } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { SxProps, Theme, Tooltip } from "@mui/material";
import "../App.css";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Gridsdashboard from "./Grides_Dashboard";
import { useAppSelector } from "../Redux/store/hooks";

// Dialog Component
type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogTradeHistoryProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogTradeHistory: FunctionComponent<DialogTradeHistoryProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("TradeListgroup");
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [snackbarOpen]);
  const datas = useAppSelector((state) => state?.TradeListGroup?.data);

  const column = [
    {
      sortable: true,
      headerClassName: "custom-header",
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Account Name
        </p>
      ),
      field: "account",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const account = params.row.account;

        if (!account) {
          return (
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title="-"
            >
              -
            </div>
          );
        }

        const content = `${account?.broker} : ${account?.client_id}`;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={content}
          >
            {content}
          </div>
        );
      },
    },

    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Execution Type
        </p>
      ),
      headerClassName: "custom-header",
      field: "execution_type",
      sortable: true,
      flex: 1,
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Trade Type
        </p>
      ),
      field: "paper_trade",
      sortable: true,
      headerClassName: "custom-header",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.paper_trade;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const paper_trade = params.row.paper_trade;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={paper_trade === true ? "Paper Trade" : "Live Trade"}
          >
            {paper_trade === true ? "Paper Trade" : "Live Trade"}
          </div>
        );
      },
    },

    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Quantity
        </p>
      ),
      field: "quantity",
      flex: 1,
      headerClassName: "custom-header",
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.quantity;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const quantity = params.row.quantity;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={quantity}
          >
            {quantity}
          </div>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Order Type
        </p>
      ),
      field: "order_type",
      sortable: true,
      headerClassName: "custom-header",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.order_type;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const order_type = params.row.order_type;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={order_type}
          >
            {order_type}
          </div>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Status
        </p>
      ),
      field: "status",
      sortable: true,
      headerClassName: "custom-header",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.status;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        switch (params.row.status) {
          case 0:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Entry"}
              >
                {"Entry"}
              </div>
            );

          case 1:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Target"}
              >
                {"Target"}
              </div>
            );

          case 2:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"SL"}
              >
                {"SL"}
              </div>
            );
          case 5:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Exit"}
              >
                {"Exit"}
              </div>
            );

          default:
            return "Unknown";
        }
      },
    },

    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Order Status
        </p>
      ),
      field: "order_status",
      flex: 1,
      headerClassName: "custom-header",
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.order_status;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const order_status = params.row.order_status;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={order_status}
          >
            {order_status}
          </div>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Traded Price
        </p>
      ),
      field: "traded_price",
      sortable: true,
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.traded_price === null) {
          return "-";
        }
        const traded_price = params.row.traded_price;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={traded_price}
          >
            {traded_price}
          </div>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Executed Time
        </p>
      ),
      field: "order_time",
      flex: 1,
      headerClassName: "custom-header",
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.order_time);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.order_time === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.order_time * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },

    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Terminal Message
        </p>
      ),
      field: "terminal_message",
      sortable: true,
      headerClassName: "custom-header",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.terminal_message;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const terminal_message = params.row?.terminal_message;

        if (terminal_message === "" || null) {
          return "-";
        }

        // Render the exit price value if it's not null
        return <span title={terminal_message}>{terminal_message}</span>; // You can adjust the formatting as needed
      },
    },
  ];

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        // fullScreen={true}
        fullWidth={true}
        maxWidth="xl"
        PaperProps={{
          sx: {
            borderRadius: "5px",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "white",
            color: "green",
            fontFamily: "Roboto",
          }}
        >
          Group Name:
          <span style={{ color: "gray", marginLeft: "10px" }}>
            {localStorage.getItem("TradeListgroup")}
          </span>
          <ClearOutlinedIcon
            className="clear"
            onClick={handleClose}
            sx={{
              color: "green",
              float: "right",
              "&:hover": { color: "red", backgroundColor: "white" },
            }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{ width: "100%", height: "45rem", padding: "0px" }}
        >
          <Gridsdashboard
            row={datas ?? []}
            column={column}
            opened
          ></Gridsdashboard>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogTradeHistory;
