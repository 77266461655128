import { FunctionComponent } from "react";
import "./Backtest_result.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as React from "react";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Tooltip, Typography, LinearProgress } from "@mui/material";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as ExcelJS from "exceljs";
import { NavLink, useNavigate } from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  clearDatabackreport,
  list_backreport,
} from "../../Redux/Reducers/back-report/list_backreport_slicer";
import Backtestsummary from "./Backtestsummary";
// import Chartsforreport from "./Backtestchart";
import "./Backtestsummary.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dialogs from "../../Component/Dialog";
import MyChart from "./Backtest_echarts";

interface backresultProps {}
declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => void;
  }
}

declare module "jspdf-autotable";

const Backresult: FunctionComponent<backresultProps> = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Algo Trading
    </Typography>,
    <NavLink
      to="/backtest"
      style={{ textDecoration: "none", color: "#BDBDBD" }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "#BDBDBD",
          fontFamily: "Roboto",
        }}
      >
        Back Test
      </Typography>
    </NavLink>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Back Test Report
    </Typography>,
  ];

  //    datas of Trade
  const datas: any[] = useAppSelector((state) => state?.backreport?.data);
  const message: any = localStorage.getItem("backreportmessage");
  const messagerespones = JSON.parse(message);
  const [open, setOpen] = React.useState(false);

  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    dispatcher(list_backreport({}));

    const timer = setTimeout(() => {
      if (
        messagerespones?.data?.message ===
        "Backtest Report Fetched Successfully"
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }, 4000); // 3-second delay

    return () => {
      clearTimeout(timer); // Clean up the timer on unmount

      dispatcher(clearDatabackreport());
    };
  }, [dispatcher, messagerespones?.data?.message]);
  const data_report = localStorage.getItem("backreportvalue");
  const ReportData = data_report ? JSON.parse(data_report) : null; // Null check

  const isLoadingdata = useAppSelector((state) => state?.backreport?.isLoading);
  localStorage.setItem("backreportdatachart", JSON.stringify(datas));
  const pl = datas?.map((data: any) => data?.profit_loss);
  const alltotal = pl?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  localStorage.setItem("dataReport", alltotal);
  const heading = <div>List of Trades Table</div>;

  // Function to format the date as dd-mm-yyyy
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const currentDateTime = new Date().toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const namelist = localStorage.getItem("name");
  const exportToExcel = (data: any) => {
    let sheetName = `${datas[0].stock_name} - ${formatDate(new Date())}`;
    let headerName = "Back Report Data";

    let workbook = new ExcelJS.Workbook();

    let sheet = workbook.addWorksheet(sheetName);

    let columnArr = [];
    for (let i in data[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnArr.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        showFirstColumn: true,
      },
      columns: [{ name: "Back Report List" }],
      rows: [
        [
          `Generated At: ${new Date().toLocaleDateString(
            "en-IN"
          )} ${currentDateTime}`,
        ],
        [`Generated By: ${namelist}`],
      ],
    });

    sheet.addTable({
      name: headerName,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
      },
      columns: columnArr
        ? columnArr.map((col) => ({
            name: col.name,
            filterButton: false,
            style: { alignment: { horizontal: "center" } },
          }))
        : [{ name: "" }],
      rows: data.map((e: any) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("A1").font = { size: 20, bold: true };

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });
    const table = sheet.getTable(headerName);
    if (table && table.columns) {
      for (let i = 0; i < table.columns.length; i++) {
        // Access table columns and rows directly
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d9f1" },
        };

        if (table.rows) {
          for (let j = 0; j < table.rows.length; j++) {
            let rowCell = sheet.getCell(
              `${String.fromCharCode(65 + i)}${j + 6}`
            );
            rowCell.alignment = { horizontal: "center", wrapText: true };
            rowCell.border = {
              bottom: {
                style: "thin",
                color: { argb: "a6a6a6" },
              },
            };
          }
        }
      }
    }

    table.commit();

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      writeFile(sheetName, buffer);
    });
  };

  const column = [
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1,
      field: "stock_name",
    },

    {
      headerName: "Execution Type",
      field: "execution_type",
      sortable: true,
      flex: 0.7,
    },

    {
      headerName: "Order Type",
      field: "order_type",
      sortable: true,
      flex: 0.7,
      valueGetter: (params: any) => {
        return params.row.order_type;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const order_type = params.row.order_type;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={order_type}
          >
            {order_type}
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      flex: 0.7,
      valueGetter: (params: any) => {
        return params.row.status;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        switch (params.row.status) {
          case 0:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Entry"}
              >
                {"Entry"}
              </div>
            );
          case 1:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Signal"}
              >
                {"Signal"}
              </div>
            );

          case 2:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"SL"}
              >
                {"SL"}
              </div>
            );
          case 3:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Target"}
              >
                {"Target"}
              </div>
            );
          case 4:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"TSL"}
              >
                {"TSL"}
              </div>
            );

          case 5:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Exit"}
              >
                {"Exit"}
              </div>
            );
          default:
            return "Unknown";
        }
      },
    },

    {
      headerName: "P/L",
      field: "profit_loss",
      sortable: true,

      flex: 0.7,
    },
    {
      headerName: "Entry Price",
      field: "entry_price",
      flex: 0.7,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row?.entry_price;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const entry_price = params.row?.entry_price;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={entry_price}
          >
            {entry_price}
          </div>
        );
      },
    },
    {
      headerName: "Entry At",
      field: "entry_at",
      sortable: true,
      flex: 1,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.entry_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.entry_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.entry_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());

        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Exit Price",
      field: "exit_price",
      sortable: true,
      flex: 0.5,
      valueGetter: (params: any) => {
        return params.row?.exit_price;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const exitPriceValue = params.row?.exit_price;

        if (exitPriceValue === null) {
          return "-";
        }

        // Render the exit price value if it's not null
        return <span title={exitPriceValue}>{exitPriceValue}</span>; // You can adjust the formatting as needed
      },
    },

    {
      headerName: "Exit At",
      field: "exit_at",
      sortable: true,
      flex: 1,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.exit_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.exit_at === null) {
          return "-";
        }

        const timestampInMilliseconds = params.row?.exit_at * 1000;
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());

        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const initialColumnVisibilityModel: any = {
    stock_name: true,
    execution_type: true,
    quantity: true,
    order_type: true,
    status: true,
    profit_loss: true,
    entry_price: true,
    entry_at: true,
    exit_price: true,
    exit_at: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );

  // this code  for excle
  const exportdatalist = datas?.map((data: any) => {
    let statusText;
    switch (data?.status) {
      case 0:
        statusText = "Entry";
        break;
      case 1:
        statusText = "Signal";
        break;
      case 2:
        statusText = "SL";
        break;
      case 3:
        statusText = "Target";
        break;
      case 4:
        statusText = "TSL";
        break;
      case 5:
        statusText = "Exit";
        break;
      default:
        statusText = "Unknown";
    }
    const timestampInMilliseconds = data?.entry_at * 1000;
    // Create a Date object from the timestamp
    const utcDates = new Date(timestampInMilliseconds);

    const ISTDates = new Date(utcDates.getTime());

    const formattedISTDates = ISTDates.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });
    // Create a Date object from the timestamp
    const timestampInMillisecond = data?.exit_at * 1000;

    const utcDate = new Date(timestampInMillisecond);

    const ISTDate = new Date(utcDate.getTime());

    const formattedISTDate = ISTDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });

    return {
      StockName: data.stock_name,
      ExecutionType: data.execution_type,
      Quantity: data.quantity,
      OrderType: data.order_type,
      Status: statusText,
      ProfitLoss: data.profit_loss,
      Entry_Price: data?.entry_price,
      Entry_At:
        "1/1/1970, 5:30:00 am" === formattedISTDates
          ? "-"
          : formattedISTDates === null
          ? "-"
          : formattedISTDates,
      Exit_Price: data?.exit_price === null ? "-" : data?.exit_price,
      Exit_At:
        "1/1/1970, 5:30:00 am" === formattedISTDate
          ? "-"
          : formattedISTDate === null
          ? "-"
          : formattedISTDate,
    };
  });
  const columns = [
    { field: "stock_name", headerName: "Stock Name", width: 130 },
    { field: "execution_type", headerName: "Execution Type", width: 130 },
    { field: "quantity", headerName: "Quantity", width: 130 },
    { field: "order_type", headerName: "Order Type", width: 150 },
    { field: "status", headerName: "Status", width: 130 },
    { field: "profit_loss", headerName: "Profit Loss", width: 50 },
    { field: "entry_price", headerName: "Entry Price", width: 130 },
    { field: "entry_at", headerName: "Entry At", width: 130 },
    { field: "exit_price", headerName: "Exit Price", width: 130 },
    { field: "exit_at", headerName: "Exit At", width: 130 },
  ];

  const strategyName = ReportData?.strategy?.name;
  const stockName = ReportData?.stock?.stock_name;
  const createdDate = ReportData?.created_date.split(" ")[0]; // Extracting date part only

  const formattedFileName = `${strategyName} - ${stockName} - ${createdDate}.pdf`;

  // const pdfRef = React.useRef();
  const pdfRef = React.useRef<HTMLDivElement | null>(null);

  const handleExportPDF = () => {
    const backtestSummaryElement = document.getElementById("combinedContent1");
    const myChartElement = document.getElementById("combinedContent2");

    if (backtestSummaryElement && myChartElement) {
      const pdf = new jsPDF("landscape", "mm", "a4", true); // Set orientation to landscape

      // Function to capture and add each section to the PDF
      const captureAndAddSection = (
        element: any,
        callback: any,
        alignment = "top"
      ) => {
        html2canvas(element).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const margin = 10; // 10mm margin on each side

          const adjustedPdfWidth = pdfWidth - 2 * margin;
          const adjustedPdfHeight = pdfHeight - 2 * margin;
          const ratio = Math.min(
            adjustedPdfWidth / imgWidth,
            adjustedPdfHeight / imgHeight
          );

          let imgX, imgY;

          if (alignment === "center") {
            imgX = (pdfWidth - imgWidth * ratio) / 2; // Center horizontally
            imgY = (pdfHeight - imgHeight * ratio) / 2; // Center vertically
          } else if (alignment === "top") {
            imgX = (pdfWidth - imgWidth * ratio) / 2; // Center horizontally
            imgY = margin; // Position at the top
          }

          pdf.addImage(
            imgData,
            "PNG",
            imgX ?? 0, // Ensure imgX is not undefined
            imgY ?? 0, // Ensure imgY is not undefined
            imgWidth * ratio,
            imgHeight * ratio
          );

          callback();
        });
      };

      // Capture and add Backtest Summary at the top
      captureAndAddSection(
        backtestSummaryElement,
        () => {
          pdf.setFontSize(13); // Set the font size (adjust as needed)
          pdf.text("Back Test Report", 15, 8);
          pdf.addPage();

          // Capture and add MyChart centered
          captureAndAddSection(
            myChartElement,
            () => {
              pdf.addPage();

              // Add a new page for the table
              pdf.setFontSize(13); // Set the font size (adjust as needed)
              pdf.text("List of Trade in Table", 15, 10);

              pdf.autoTable({
                head: [columns.map((column) => column.headerName)],
                body: datas.map((row) => {
                  let statusText: any;
                  switch (row.status) {
                    case 0:
                      statusText = "Entry";
                      break;
                    case 1:
                      statusText = "Signal";
                      break;
                    case 2:
                      statusText = "SL";
                      break;
                    case 3:
                      statusText = "Target";
                      break;
                    case 4:
                      statusText = "TSL";
                      break;
                    case 5:
                      statusText = "Exit";
                      break;
                    default:
                      statusText = "Unknown";
                  }

                  const entryTimestamp = row.entry_at * 1000;
                  const entryDate = new Date(entryTimestamp);
                  const formattedEntryDate = entryDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                  });

                  const exitTimestamp = row.exit_at * 1000;
                  const exitDate = new Date(exitTimestamp);
                  const formattedExitDate = exitDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                  });

                  return columns.map((column) => {
                    if (column.field === "entry_at") {
                      return formattedEntryDate;
                    } else if (column.field === "exit_at") {
                      return formattedExitDate;
                    } else if (column.field === "status") {
                      return statusText;
                    } else {
                      return row[column.field];
                    }
                  });
                }),
                headStyles: {
                  fillColor: [44, 142, 20],
                  textColor: [255, 255, 255],
                },
                styles: {
                  lineColor: [192, 192, 192],
                  lineWidth: 0.5,
                },
              });

              // Add disclaimer text in red at the bottom of the table page
              pdf.setFontSize(13);
              pdf.setTextColor(255, 0, 0);
              pdf.text(
                "Disclaimer: The information in this backtest report is hypothetical and does not reflect actual trading results. Past performance is not\n" +
                  "indicative of future results, and investing involves risks, including potential loss of principal.",
                15,
                pdf.internal.pageSize.height - 20
              );

              // Add page numbers
              const pageCount = pdf.getNumberOfPages();
              for (let i = 1; i <= pageCount; i++) {
                pdf.setPage(i);
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(9);
                pdf.text(
                  `Page ${i} of ${pageCount}`,
                  pdf.internal.pageSize.width - 50,
                  pdf.internal.pageSize.height - 10
                );
              }

              pdf.save(formattedFileName); // Use your formattedFileName variable
            },
            "center"
          ); // Center MyChart
        },
        "top"
      ); // Position BacktestSummary at the top
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display: isLoadingdata ? "block" : "none",
          }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Stack spacing={10} sx={{ margin: "0px 20px 10px 20px " }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Button
          title={
            datas?.length > 0
              ? "Download PDF"
              : "No data available in the table. Please note that data export is not possible."
          }
          onClick={() => {
            if (datas?.length > 0) {
              handleExportPDF();
            }
          }}
          sx={{
            margin: "0px 0px 10px 0px",
            padding: "15px",
            background: "linear-gradient(to right, #FFFA02,#F2A618 )",
            color: "black",
            width: {
              xs: 60,
              sm: 100,
              md: 100,
              lg: 100,
              xl: 150,
            },
            height: "37px",

            borderRadius: "5px",

            fontSize: {
              xs: "10px",
              sm: "10px",
              md: "10px",
              lg: "10px",
              xl: "12px",
            },
            fontFamily: "Roboto",
            textTransform: "capitalize",
            "&:hover": { backgroundColor: "#EF990F" },
          }}
        >
          Download pdf
        </Button>
      </div>
      <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
        <div ref={pdfRef}>
          <div id="combinedContent1" style={{ marginBottom: "2%" }}>
            <Backtestsummary></Backtestsummary>
          </div>
          {/* <div id="combinedContent2" style={{ marginBottom: "2%" }}>
            <Chartsforreport dataforcharts={datas}></Chartsforreport>
          </div> */}
          <div id="combinedContent2" style={{ marginBottom: "2%" }}>
            <MyChart dataforcharts={datas}></MyChart>
          </div>
        </div>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Grids
            row={datas ?? []}
            column={column}
            Users={heading}
            swithoutbutton
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            pagination
            refericon={
              <span
                title="Refresh"
                onClick={() => {
                  dispatcher(list_backreport({}));
                }}
              >
                <RefreshIcon
                  sx={{
                    fontSize: "24px",
                    marginTop: "5px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                ></RefreshIcon>
              </span>
            }
            Export={
              <span
                title={
                  datas?.length > 0
                    ? "Export"
                    : "No data available in the table. Please note that data export is not possible."
                }
                onClick={() => {
                  if (datas?.length > 0) {
                    exportToExcel(exportdatalist);
                  }
                }}
              >
                <SaveAltIcon
                  sx={{
                    fontSize: "24px",
                    marginTop: "5px",
                    marginRight: "0px",
                    cursor: "pointer",
                  }}
                ></SaveAltIcon>
              </span>
            }
            Userpermissions={undefined}
          ></Grids>
        </div>
      </div>
      <div style={{ color: "red" }}>
        Disclaimer: The information in this backtest report is hypothetical and
        does not reflect actual trading results. Past performance is not
        indicative of future results, and investing involves risks, including
        potential loss of principal.
      </div>

      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={open}
        Dialog_Content={
          "No signals are triggered within the selected date range"
        }
        Dialog_Actions={
          <Button
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
              width: "18%",
              color: "black",
              borderRadius: "5px",
              textTransform: "capitalize",
              marginLeft: "5%",
            }}
            onClick={() => {
              setOpen(false);
              navigate("/backtest");
            }}
          >
            ok
          </Button>
        }
      />
    </div>
  );
};

export default Backresult;
