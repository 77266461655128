import { FunctionComponent } from "react";
import "./tradeHistory.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as React from "react";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Grids from "../../Component/Grides";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as ExcelJS from "exceljs";
import {
  clear_Trade_List,
  list_TradeList,
} from "../../Redux/Reducers/Trade-details/list_TradeList_slicer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DialogTradeHistory from "../../Component/Dialog_for_view_data_tradeHistory";
import { GridCellParams } from "@mui/x-data-grid";
import { list_TradeListGroup } from "../../Redux/Reducers/Trade-details/list_TradeList_grouplist_slicer";
import { edit_filterlive } from "../../Redux/Reducers/Filtervalue/filterlive";
import TradeDetailsfiltermanual from "../../Component/TradeDetailsfilter_manual";

interface TradeHistoryProps {}

const TradeHistory: FunctionComponent<TradeHistoryProps> = () => {
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Algo Trading
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Trade History
    </Typography>,
  ];

  //    datas of Trade
  const datas = useAppSelector((state) => state?.TradeList?.data);
  const isLoading = useAppSelector((state) => state?.TradeList?.isLoading);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddTreadSetup?.isLoading
  );
  const editDataIsLoading = useAppSelector(
    (state) => state?.EditTreadSetup?.isLoading
  );
  const DeleteisLoading = useAppSelector(
    (state) => state?.DeleteTreadSetup?.isLoading
  );

  const TradeList_data_date: any = useAppSelector(
    (state) => state?.filtertrade?.data
  );
  const [alignment, setAlignment] = React.useState("Individual");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  // const filteredData = datas?.filter((data: any) => data.status === 1);
  // const filtered = datas?.filter((data: any) => data.status === 0);
  const pl = datas?.map((data: any) => data.profit_loss);

  const alltotal = pl?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue,
    0
  );
  const textColor = alltotal < 0 ? "red" : "green";

  const heading = (
    <>
      <div>
        <ToggleButtonGroup
          sx={{ marginRight: "10px" }}
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value="Individual"
            title="Individual Account"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 60,
                sm: 60,
                md: 60,
                lg: 75,
                xl: 80,
              },
            }}
            onClick={() => {
              if (TradeList_data_date.dateRange != null) {
                const fromDate = new Date(TradeList_data_date.dateRange[0]);
                const year = fromDate.getFullYear();
                const month = String(fromDate.getMonth() + 1).padStart(2, "0");
                const day = String(fromDate.getDate()).padStart(2, "0");
                const formattedFromDate = `${year}-${month}-${day}`;
                const toate = new Date(TradeList_data_date.dateRange[1]);
                const toyear = toate.getFullYear();
                const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
                const today = String(toate.getDate()).padStart(2, "0");
                const formattedToDate = `${toyear}-${tomonth}-${today}`;
                if (alignment !== "Individual") {
                  dispatcher(clear_Trade_List());
                  dispatcher(
                    list_TradeList({
                      from_date: formattedFromDate,
                      to_date: formattedToDate,
                      type: "Individual",
                      algo_id: TradeList_data_date.algo_id,
                      status: TradeList_data_date.status,
                      strategy_id: TradeList_data_date.strategy_id,
                      entry_type: "Strategy",
                    })
                  );
                }
              }
            }}
          >
            Individual
          </ToggleButton>
          <ToggleButton
            value="Group"
            title="Group Accounts"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 60,
                sm: 60,
                md: 60,
                lg: 60,
                xl: 80,
              },
            }}
            onClick={() => {
              if (TradeList_data_date.dateRange != null) {
                const fromDate = new Date(TradeList_data_date.dateRange[0]);
                const year = fromDate.getFullYear();
                const month = String(fromDate.getMonth() + 1).padStart(2, "0");
                const day = String(fromDate.getDate()).padStart(2, "0");
                const formattedFromDate = `${year}-${month}-${day}`;
                const toate = new Date(TradeList_data_date.dateRange[1]);
                const toyear = toate.getFullYear();
                const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
                const today = String(toate.getDate()).padStart(2, "0");
                const formattedToDate = `${toyear}-${tomonth}-${today}`;
                if (alignment !== "Group") {
                  dispatcher(clear_Trade_List());
                  dispatcher(
                    list_TradeList({
                      from_date: formattedFromDate,
                      to_date: formattedToDate,
                      algo_id: TradeList_data_date.algo_id,
                      status: TradeList_data_date.status,
                      strategy_id: TradeList_data_date.strategy_id,
                      type: "Group",
                      entry_type: "Strategy",
                    })
                  );
                }
              }
            }}
          >
            Group
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{ marginTop: "10px" }}>
        <span
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "normal",
          }}
        >
          Total No Trades :
          <span
            style={{ paddingRight: "10px", color: "gray", paddingLeft: "6px" }}
          >
            {datas?.length}
          </span>
          {/* | Target :
          <span
            style={{ color: "green", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {filteredData?.length}
          </span>
          | SL :
          <span
            style={{ color: "red", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {filtered?.length}
          </span> */}
          {/* <div></div> */}| OverAll P/L :
          <span
            style={{
              color: textColor,
              paddingRight: "10px",
              paddingLeft: "6px",
            }}
          >
            {alltotal}
          </span>
        </span>
      </div>
    </>
  );

  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    if (TradeList_data_date.dateRange != null) {
      const fromDate = new Date(TradeList_data_date.dateRange[0]);
      const year = fromDate.getFullYear();
      const month = String(fromDate.getMonth() + 1).padStart(2, "0");
      const day = String(fromDate.getDate()).padStart(2, "0");
      const formattedFromDate = `${year}-${month}-${day}`;
      const toate = new Date(TradeList_data_date.dateRange[1]);
      const toyear = toate.getFullYear();
      const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
      const today = String(toate.getDate()).padStart(2, "0");
      const formattedToDate = `${toyear}-${tomonth}-${today}`;
      dispatcher(
        list_TradeList({
          from_date: formattedFromDate,
          to_date: formattedToDate,
          type: alignment,
          algo_id: TradeList_data_date.algo_id,
          status: TradeList_data_date.status,
          strategy_id: TradeList_data_date.strategy_id,
          entry_type: "Strategy",
        })
      );
    } else {
      dispatcher(
        list_TradeList({
          from_date: null,
          to_date: null,
          algo_id: TradeList_data_date.algo_id,
          status: TradeList_data_date.status,
          strategy_id: TradeList_data_date.strategy_id,
          type: alignment,
          entry_type: "Strategy",
        })
      );
    }
    dispatcher(edit_filterlive({}));
    return () => {
      dispatcher(clear_Trade_List());
    };
  }, [
    dispatcher,
    alignment,
    TradeList_data_date.algo_id,
    TradeList_data_date.strategy_id,
    TradeList_data_date.status,
    TradeList_data_date.dateRange,
  ]);

  const currentDateTime = new Date().toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const namelist = localStorage.getItem("name");

  const exportToExcel = (data: any) => {
    let sheetName = "Trade_history.xlsx";
    let headerName = "Trade History Data";

    let workbook = new ExcelJS.Workbook();

    let sheet = workbook.addWorksheet(sheetName);

    let columnArr = [];
    for (let i in data[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnArr.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        showFirstColumn: true,
        // width: 200
      },
      columns: [{ name: "Trade History List" }],
      rows: [
        [
          `Generated At: ${new Date().toLocaleDateString(
            "en-IN"
          )} ${currentDateTime}`,
        ],
        [`Generated By: ${namelist}`],
      ],
    });

    sheet.addTable({
      name: headerName,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
      },
      columns: columnArr
        ? columnArr.map((col) => ({
            name: col.name,
            filterButton: false,
            style: { alignment: { horizontal: "center" } },
          }))
        : [{ name: "" }],
      rows: data.map((e: any) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("A1").font = { size: 20, bold: true };

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });

    const table = sheet.getTable(headerName);
    if (table && table.columns) {
      for (let i = 0; i < table.columns.length; i++) {
        // Access table columns and rows directly
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d9f1" },
        };

        if (table.rows) {
          for (let j = 0; j < table.rows.length; j++) {
            let rowCell = sheet.getCell(
              `${String.fromCharCode(65 + i)}${j + 6}`
            );
            rowCell.alignment = { horizontal: "center", wrapText: true };
            rowCell.border = {
              bottom: {
                style: "thin",
                color: { argb: "a6a6a6" },
              },
            };
          }
        }
      }
    }

    table.commit();

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      writeFile(sheetName, buffer);
    });
  };

  const column = [
    {
      headerName: "Strategy Name",
      field: "strategy_name",
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.strategy_name === null) {
          return "-";
        }
        const strategy_name = params?.row?.strategy_name;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={strategy_name}
          >
            {strategy_name}
          </div>
        );
      },
    },
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1,
      field: "stock_name",
    },
    {
      sortable: true,
      headerName: (
        <>{alignment === "Individual" ? "Account Name" : "Group Name"}</>
      ),
      field: alignment === "Individual" ? "account" : "group",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const group = params?.row?.group;
        const account = params?.row?.account;

        const content =
          alignment === "Individual"
            ? `${account?.broker} : ${account?.client_id}`
            : group?.name;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={content === undefined ? "-" : content}
          >
            {content === undefined ? "-" : content}
          </div>
        );
      },
    },
    {
      headerName: "Execution Type",
      field: "execution_type",
      sortable: true,
      flex: 1,
    },
    {
      headerName: "Trade Type",
      field: "paper_trade",
      sortable: true,
      flex: 1,
      valueGetter: (params: any) => {
        return params?.row?.order?.paper_trade;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row?.order === null) {
          return "-";
        }
        const paper_trade = params?.row?.order?.paper_trade;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={paper_trade === true ? "Paper Trade" : "Live Trade"}
          >
            {paper_trade === true ? "Paper Trade" : "Live Trade"}
          </div>
        );
      },
    },

    {
      headerName: "Quantity",
      field: "order",
      flex: 0.5,
      sortable: true,
      valueGetter: (params: any) => {
        return params?.row?.order?.quantity;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params?.row?.order === null) {
          return "-";
        }
        const quantity = params?.row?.order?.quantity;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={quantity}
          >
            {quantity}
          </div>
        );
      },
    },
    {
      headerName: "Order Type",
      field: "order_type",
      sortable: true,
      flex: 0.5,
      valueGetter: (params: any) => {
        return params?.row?.order?.order_type;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params?.row?.order === null) {
          return "-";
        }
        const order_type = params?.row?.order?.order_type;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={order_type}
          >
            {order_type}
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      flex: 0.5,
      valueGetter: (params: any) => {
        return params?.row?.status;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        switch (params?.row?.status) {
          case 0:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Entry"}
              >
                {"Entry"}
              </div>
            );

          case 1:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Signal"}
              >
                {"Signal"}
              </div>
            );

          case 2:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"SL"}
              >
                {"SL"}
              </div>
            );
          case 3:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Target"}
              >
                {"Target"}
              </div>
            );
          case 4:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"TSL"}
              >
                {"TSL"}
              </div>
            );
          case 5:
            return (
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={"Exit"}
              >
                {"Exit"}
              </div>
            );
          default:
            return "Unknown";
        }
      },
    },
    {
      headerName: "Order Status",
      field: "order_status",
      sortable: true,
      flex: 1,
      valueGetter: (params: any) => {
        return params?.row?.order?.order_status;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.order === null) {
          return "PENDING";
        }
        const order_type = params?.row?.order?.order_status;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={order_type}
          >
            {order_type}
          </div>
        );
      },
    },
    {
      headerName: "P/L",
      field: "profit_loss",
      sortable: true,

      flex: 0.7,
    },
    {
      headerName: "Entry Price",
      field: "entry_price",
      flex: 0.7,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row?.entry_price;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const entry_price = params.row?.entry_price;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={entry_price}
          >
            {entry_price}
          </div>
        );
      },
    },
    {
      headerName: "Entry At",
      field: "entry_at",
      sortable: true,
      flex: 1.2,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.entry_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.entry_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.entry_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());

        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Exit Price",
      field: "exit_price",
      sortable: true,
      flex: 0.7,
      valueGetter: (params: any) => {
        return params.row?.exit_price;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const exitPriceValue = params.row?.exit_price;

        if (exitPriceValue === null) {
          return "-";
        }

        // Render the exit price value if it's not null
        return <span title={exitPriceValue}>{exitPriceValue}</span>; // You can adjust the formatting as needed
      },
    },

    {
      headerName: "Exit At",
      field: "exit_at",
      sortable: true,
      flex: 1.2,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.exit_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.exit_at === null) {
          return "-";
        }

        const timestampInMilliseconds = params.row?.exit_at * 1000;
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());

        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },

    alignment === "Group"
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: GridCellParams) => {
            return (
              <DialogTradeHistory
                Buttondialog={["edit"]}
                Dialogtitle={"Trade History"}
                sx={{
                  backgroundColor: "transparent",
                  minWidth: "0",
                  padding: "0",
                  borderRadius: "50%",
                  height: "60%",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                edit={{
                  ...params.row,
                }}
                Buttonname={
                  <span
                    title="View"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatcher(
                        list_TradeListGroup({
                          strategy_id: params?.row?.id,
                          group_id: params?.row?.group?.id,
                        })
                      );
                      const group = params?.row?.group?.name;
                      localStorage.setItem("TradeListgroup", group);
                    }}
                  >
                    <RemoveRedEyeIcon />
                  </span>
                }
              />
            );
          },
        }
      : {
          type: "actions",
          hide: true,
          flex: 0.1,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    strategy_name: true,
    stock_name: true,
    execution_type: false,
    paper_trade: false,
    order: true,
    order_type: true,
    status: true,
    order_status: true,
    profit_loss: true,
    entry_price: true,
    entry_at: true,
    exit_price: true,
    exit_at: true,
    actions: true,
  };

  // Add the conditional key to the initialColumnVisibilityModel object
  if (alignment === "Individual") {
    initialColumnVisibilityModel["account"] = false;
  } else {
    initialColumnVisibilityModel["group"] = false;
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );

  // this code  for excle
  const exportdatalist = datas.map((data: any) => {
    let statusText;
    switch (data.status) {
      case 0:
        statusText = "Entry";
        break;
      case 1:
        statusText = "Signal";
        break;
      case 2:
        statusText = "SL";
        break;
      case 3:
        statusText = "Target";
        break;
      case 4:
        statusText = "TSL";
        break;
      case 5:
        statusText = "Exit";
        break;
      default:
        statusText = "Unknown";
    }

    const timestampInMilliseconds = data?.entry_at * 1000;
    // Create a Date object from the timestamp
    const utcDates = new Date(timestampInMilliseconds);

    const ISTDates = new Date(utcDates.getTime());

    const formattedISTDates = ISTDates.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });
    // Create a Date object from the timestamp
    const timestampInMillisecond = data?.exit_at * 1000;

    const utcDate = new Date(timestampInMillisecond);

    const ISTDate = new Date(utcDate.getTime());

    const formattedISTDate = ISTDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });

    const isIndividualAlignment = "Group";

    return {
      StrategyName: data?.strategy_name,
      [isIndividualAlignment ? "Account_Name" : "Group_Name"]:
        isIndividualAlignment
          ? `${data?.account?.broker} : ${data.account?.client_id}`
          : data.group?.name || "-",
      StockName: data?.stock_name,
      ExecutionType: data?.execution_type,
      Trade_Type:
        data?.order === null
          ? "-"
          : data?.order?.paper_trade === true
          ? "Paper Trade"
          : "Live Trade",
      Quantity: data?.order?.quantity,
      OrderType: data?.order?.order_type,
      Status: statusText,
      OrderStatus: data?.order?.order_status,
      ProfitLoss: data?.profit_loss,
      EntryPrice: data?.entry_price,
      EntryAt: formattedISTDates === null ? "-" : formattedISTDates,
      ExitPrice: data?.exit_price === null ? "-" : data?.exit_price,
      Exit_at:
        "1/1/1970, 5:30:00 am" === formattedISTDate
          ? "-"
          : formattedISTDate === null
          ? "-"
          : formattedISTDate,
    };
  });

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoading ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Grids
        row={datas ?? []}
        column={column}
        Users={heading}
        height={"90%"}
        swithoutbutton
        pagination
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              if (TradeList_data_date.dateRange != null) {
                const fromDate = new Date(TradeList_data_date.dateRange[0]);
                const year = fromDate.getFullYear();
                const month = String(fromDate.getMonth() + 1).padStart(2, "0");
                const day = String(fromDate.getDate()).padStart(2, "0");
                const formattedFromDate = `${year}-${month}-${day}`;
                const toate = new Date(TradeList_data_date.dateRange[1]);
                const toyear = toate.getFullYear();
                const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
                const today = String(toate.getDate()).padStart(2, "0");
                const formattedToDate = `${toyear}-${tomonth}-${today}`;
                dispatcher(
                  list_TradeList({
                    from_date: formattedFromDate,
                    to_date: formattedToDate,
                    type: alignment,
                    algo_id: TradeList_data_date.algo_id,
                    status: TradeList_data_date.status,
                    strategy_id: TradeList_data_date.strategy_id,
                    entry_type: "Strategy",
                  })
                );
              } else {
                dispatcher(
                  list_TradeList({
                    from_date: null,
                    to_date: null,
                    algo_id: TradeList_data_date.algo_id,
                    status: TradeList_data_date.status,
                    strategy_id: TradeList_data_date.strategy_id,
                    type: alignment,
                    entry_type: "Strategy",
                  })
                );
              }
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        Export={
          <span
            title={
              datas?.length > 0
                ? "Export"
                : "No data available in the table. Please note that data export is not possible."
            }
            onClick={() => {
              if (datas?.length > 0) {
                exportToExcel(exportdatalist);
              }
            }}
          >
            <SaveAltIcon
              sx={{
                fontSize: "24px",
                marginTop: "5px",
                marginRight: "0px",
                cursor: "pointer",
              }}
            ></SaveAltIcon>
          </span>
        }
        sideButton={
          <TradeDetailsfiltermanual
            filtertype={alignment}
            entry_type={"Strategy"}
          ></TradeDetailsfiltermanual>
        }
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default TradeHistory;
