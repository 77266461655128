import { FunctionComponent } from "react";
import { useAppDispatch } from "../../Redux/store/hooks";
import { Switch } from "@mui/material";
import { TreadSetupvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Tradesetup-values-slicer";

interface MappedMessageprops {
  valuepopup: number;
  editdata: any;
}

const MappedMessage: FunctionComponent<MappedMessageprops> = (props: any) => {
  const dispatcher = useAppDispatch();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const pageName = "View User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <div>
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <Switch
            size="small" // Add this line to set the switch size to small
            title="Trade Setup Active / Inactive"
            {...label}
            checked={props.editdata.status === 0} // Set the checked prop based on the state
            onChange={(e) => {
              localStorage.setItem("tradesetupfilter", props.valuepopup);
              dispatcher(
                TreadSetupvalues_datagrid({
                  status: props.editdata.status === 0 ? 1 : 0,
                })
              );
            }}
          />
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export default MappedMessage;
