import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list  trade
export const TradeList = createSlice({
  name: "TradeList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_TradeList: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_TradeList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_TradeList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    TradeList_is_first: (state, action) => {
      state.isFirst = false;
    },
    clear_Trade_List: (state) => {
      state.data = [];
    },
  },
});
export default TradeList.reducer;
export const {
  list_TradeList,
  success_api_call_TradeList,
  failure_api_call_TradeList,
  clear_Trade_List,
  TradeList_is_first,
} = TradeList.actions;
