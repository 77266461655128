import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  delete role 
export const DeleteRole = createSlice({
  name: "DeleteRole",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_DeleteRole: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_DeleteRole: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_DeleteRole: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_DeleteRole_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchDeleteRole_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default DeleteRole.reducer;
export const {
  delete_DeleteRole,
  success_api_call_delete_DeleteRole,
  failure_api_call_delete_DeleteRole,
  delete_DeleteRole_is_success,
  false_switchDeleteRole_response,
} = DeleteRole.actions;
