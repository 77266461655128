import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for filter open position for  manual
export const Filteropenpositionmaunal = createSlice({
  name: "Filteropenpositionmaunal",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_Filteropenpositionmaunal: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Filteropenpositionmaunal: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_Filteropenpositionmaunal: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    Filteropenpositionmaunal_is_first: (state, action) => {
      state.isFirst = false;
    },
    clear_open_position: (state) => {
      state.data = [];
    },
  },
});
export default Filteropenpositionmaunal.reducer;
export const {
  list_Filteropenpositionmaunal,
  clear_open_position,
  success_api_call_Filteropenpositionmaunal,
  failure_api_call_Filteropenpositionmaunal,
  Filteropenpositionmaunal_is_first,
} = Filteropenpositionmaunal.actions;
