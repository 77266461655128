import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../Redux/store/hooks";
import { DeleteHolidayvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteHoliday-values-slicer";

interface MarketholidayMessageprops {
  valuepopup: number;
}

const MarketholidayMessage: FunctionComponent<MarketholidayMessageprops> = (
  props: any
) => {
  const dispatcher = useAppDispatch();

  const pageName = "Market Holiday";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <>
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "#F2A618",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteHolidayvalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </>
  );
};

export default MarketholidayMessage;
