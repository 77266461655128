import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Edit_Userpermission,
  success_api_call_Edit_Userpermission,
} from "./edit_User_permission_slicer";
import {
  failure_api_call_User_permission,
  success_api_call_User_permission,
} from "./list_User_permission_slicer";
import {
  failure_api_call_User_permissionMenuList,
  success_api_call_User_permissionMenuList,
} from "./list_User_permissionMenuList_slicer";

//Edit user permission
const Edit_User_permissionCall = async (a: any) => {
  const userpermissionloop = a.payload.map((value: any) => {
    return {
      menu_id: value.id,
      role_id: localStorage.getItem("roleIdforuserpermission"),
      view: value.permission.view === true ? value.permission.view : false,
      add: value.permission.add === true ? value.permission.add : false,
      edit: value.permission.edit === true ? value.permission.edit : false,
      delete:
        value.permission.delete === true ? value.permission.delete : false,
      beta: value.permission.beta === true ? value.permission.beta : false,
    };
  });

  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/permission`,
    userpermissionloop,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* Edit_UserpermissionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(Edit_User_permissionCall, e);
    yield put(success_api_call_Edit_Userpermission(response.data));
  } catch (e) {
    yield put(failure_api_call_Edit_Userpermission(e));
    yield put(expireSignature(e));
  }
}

//list Menu
const User_permissionCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/permission?portal=${a.payload[0].portal}&role_id=${a.payload[0].roleId}&is_active=${a.payload[0].status}`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* User_permissionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(User_permissionCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_User_permission(res));
  } catch (e) {
    yield put(failure_api_call_User_permission(e));
    yield put(expireSignature(e));
  }
}

//list Menulist
const User_permissionMenuListCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/menu/${a.payload[0].portal}/${a.payload[0].roleId}`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* User_permissionMenuListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(User_permissionMenuListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_User_permissionMenuList(res));
  } catch (e) {
    yield put(failure_api_call_User_permissionMenuList(e));
    yield put(expireSignature(e));
  }
}
