import { FunctionComponent, useEffect } from "react";
import "./profile.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import BadgeIcon from "@mui/icons-material/Badge";
import CallIcon from "@mui/icons-material/Call";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  Uservalues_datagrid,
  false_switch,
} from "../../Redux/Reducers/Datagrid-values/User-values";
import Dialogs from "../../Component/Dialog";
import { list_LoginforprofileList } from "../../Redux/Reducers/Login.api/list_Loginforprofile_slicer";
import {
  edit_EditAdmin,
  false_switch_response,
} from "../../Redux/Reducers/Admin-api/edit_Admin_slicer";
interface ProfileProps {}

const label = { inputProps: { "aria-label": "Switch demo" } };

const Profile: FunctionComponent<ProfileProps> = () => {
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      My Profile
    </Typography>,
  ];

  const dispatcher = useAppDispatch();
  const Uservalues: any = useAppSelector((state) => state.Uservalues.data);
  const values: any = useAppSelector((state) => state.LoginforprofileList.data);
  const editData: any = useAppSelector((state) => state?.EditAdmin?.data);
  const editData_isLoading: any = useAppSelector(
    (state) => state?.EditAdmin?.isLoading
  );
  const switchOpen = useAppSelector((state) => state.Uservalues.switch);
  const switchResponse = useAppSelector(
    (state) => state.EditAdmin.switchResponse
  );

  useEffect(() => {
    dispatcher(list_LoginforprofileList({}));
    if (switchResponse) {
      dispatcher(false_switch());
    }
  }, [switchResponse, dispatcher]);
  const action = (
    <button
      onClick={() => {
        dispatcher(false_switch_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switch_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switch_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display: editData_isLoading ? "block" : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 20px 20px 14px " }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div className="profile_data">
        <div
          style={{
            fontSize: "16px",
            backgroundColor: "#338514",
            color: "white",
            height: "6.5%",
            borderRadius: "5px 5px 0px 0px",
            padding: "1% 1% 1% 1%",
          }}
        >
          Basic Details
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "1%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>App Id</div>
              <div style={{ marginRight: "10px" }}>:</div>
            </div>

            <div
              style={{
                color: "#202020",
                backgroundColor: "#F5F5F5",
                width: "40%",
                height: "40px",
                borderRadius: "5px",
                display: "flex",
              }}
            >
              <div>
                <BadgeIcon
                  sx={{ color: "green", margin: "6px 0px 0px 5px " }}
                />
              </div>
              <div
                style={{
                  border: "1px solid #C5C5C5",
                  height: "23px",
                  margin: "10px 5px 0px 5px",
                }}
              ></div>
              <div
                style={{
                  margin: "10px 0px 0px 5px",
                  fontFamily: "Roboto",
                  fontSize: "15px",
                }}
              >
                {values?.data?.app_id}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "1%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Name</div>
              <div style={{ marginRight: "10px" }}>:</div>
            </div>
            <div
              style={{
                color: "#202020",
                backgroundColor: "#F5F5F5",
                width: "40%",
                height: "15%",
                borderRadius: "5px",
                display: "flex",
              }}
            >
              <div>
                <AccountCircleOutlinedIcon
                  sx={{ color: "green", margin: "5px 0px 0px 5px " }}
                />
              </div>
              <div
                style={{
                  border: "1px solid #C5C5C5",
                  height: "23px",
                  margin: "10px 5px 0px 5px",
                }}
              ></div>
              <div
                style={{
                  margin: "10px 0px 0px 5px",
                  fontFamily: "Roboto",
                  fontSize: "15px",
                }}
              >
                {values?.data?.name}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "1%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Email</div>
              <div style={{ marginRight: "10px" }}>:</div>
            </div>
            <div
              style={{
                color: "#202020",
                backgroundColor: "#F5F5F5",
                width: "40%",
                height: "15%",
                borderRadius: "5px",
                display: "flex",
              }}
            >
              <div>
                <MarkunreadIcon
                  sx={{ color: "green", margin: "5px 0px 0px 5px " }}
                />
              </div>
              <div
                style={{
                  border: "1px solid #C5C5C5",
                  height: "23px",
                  margin: "10px 5px 0px 5px",
                }}
              ></div>

              <div
                style={{
                  fontSize: "15px",
                  color: "black",
                  fontFamily: "Roboto",
                  margin: "10px 0px 0px 5px",
                  width: "40%",
                }}
              >
                {values?.data?.email_id}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "1%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Phone</div>
              <div style={{ marginRight: "10px" }}>:</div>
            </div>
            <div
              style={{
                color: "#202020",
                backgroundColor: "#F5F5F5",
                width: "40%",
                height: "40px",
                borderRadius: "5px",
                display: "flex",
              }}
            >
              <div>
                <CallIcon sx={{ color: "green", margin: "5px 0px 0px 5px " }} />
              </div>
              <div
                style={{
                  border: "1px solid #C5C5C5",
                  height: "23px",
                  margin: "10px 5px 0px 5px",
                }}
              ></div>

              <div
                style={{
                  fontSize: "15px",
                  margin: "10px 0px 0px 5px",
                  color: "black",
                  fontFamily: "Roboto",
                }}
              >
                {values?.data?.mobile_no}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "1%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Allow Trade</div>
              <div style={{ marginRight: "10px" }}>:</div>
            </div>
            <p>
              <Switch
                {...label}
                title="Allow Trade Active or Inactive"
                size="small" // Add this line to set the switch size to small
                checked={values?.data?.is_live === true ? true : false}
                onChange={(e) => {
                  dispatcher(
                    Uservalues_datagrid({
                      id: localStorage.getItem("idforlogin"),
                      is_live: e.target.checked,
                    })
                  );
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <Dialogs
        open={switchOpen}
        setOpen={() => dispatcher(false_switch())}
        subtitle={"Warning"}
        Dialog_Content={
          Uservalues?.is_live === true
            ? "Do you want to allow trade ?"
            : "Do you want to stop the trade ?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switch())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditAdmin(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
    </>
  );
};

export default Profile;
