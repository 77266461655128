import { FunctionComponent } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Grids from "../../Component/Grides";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  clear_Trade_List,
  list_TradeList,
} from "../../Redux/Reducers/Trade-details/list_TradeList_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as ExcelJS from "exceljs";
import TradeDetailsfilter from "../../Component/TradeDetailsfilter";
import RefreshIcon from "@mui/icons-material/Refresh";
import { list_TradeListGroup } from "../../Redux/Reducers/Trade-details/list_TradeList_grouplist_slicer";
import DialogTradeHistory from "../../Component/Dialog_for_view_data_tradeHistory";
import { GridCellParams } from "@mui/x-data-grid";
import {
  clear_open_position,
  list_LiveTrade,
} from "../../Redux/Reducers/Open-Position/list_LiveTrade_slicer";

interface TradeDetailsProps {}

const TradeDetails: FunctionComponent<TradeDetailsProps> = () => {
  const TradeList_data_date: any = useAppSelector(
    (state) => state?.filtertrade?.data
  );
  const [alignment, setAlignment] = React.useState("Individual");
  const [Group, setGroup] = React.useState("Strategy");
  const [openposition, setopenposition] = React.useState("openposition");
  const dispatcherFunction =
    openposition === "openposition" ? list_LiveTrade : list_TradeList;

  const datasTradeList = useAppSelector((state) => state?.TradeList?.data);
  const datasforLivetrade = useAppSelector((state) => state?.LiveTrade?.data);
  const datas =
    openposition === "openposition" ? datasforLivetrade : datasTradeList;

  const handleChangeopenposition = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setopenposition(newAlignment);
    }
  };

  const handleChangeaddgroup = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setGroup(newAlignment);
    }
  };
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  // const filteredData = datas?.filter((data: any) => data.status === 1);
  // const filtered = datas?.filter((data: any) => data.status === 0);
  const pl = datas?.map((data: any) => data.profit_loss);

  const alltotal = pl?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const textColor = alltotal < 0 ? "red" : "green";

  const heading = (
    <>
      <div>
        <ToggleButtonGroup
          sx={{ marginRight: "10px" }}
          color="primary"
          value={openposition}
          exclusive
          onChange={handleChangeopenposition}
          aria-label="Platform"
        >
          <ToggleButton
            value="openposition"
            title="Open Position"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 120,
                sm: 120,
                md: 120,
                lg: 120,
                xl: 120,
              },
            }}
          >
            Open Position
          </ToggleButton>
          <ToggleButton
            value="tradehistory"
            title="Trade History"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 120,
                sm: 120,
                md: 120,
                lg: 120,
                xl: 120,
              },
            }}
          >
            Trade History
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          sx={{ marginRight: "10px" }}
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value="Individual"
            title="Individual Account"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 60,
                sm: 60,
                md: 60,
                lg: 75,
                xl: 80,
              },
            }}
          >
            Individual
          </ToggleButton>
          <ToggleButton
            value="Group"
            title="Group Accounts"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 60,
                sm: 60,
                md: 60,
                lg: 60,
                xl: 80,
              },
            }}
          >
            Group
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          color="primary"
          value={Group}
          exclusive
          onChange={handleChangeaddgroup}
          aria-label="Platform"
        >
          <ToggleButton
            value="Strategy"
            title="Algo Trades"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 60,
                sm: 60,
                md: 60,
                lg: 60,
                xl: 80,
              },
            }}
          >
            Algo
          </ToggleButton>
          <ToggleButton
            value="Manual"
            title="Manual Trades"
            sx={{
              textTransform: "capitalize",
              height: "39px",
              marginTop: "3px",
              width: {
                xs: 60,
                sm: 60,
                md: 60,
                lg: 60,
                xl: 80,
              },
            }}
          >
            Manual
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{ marginTop: "10px" }}>
        <span
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "normal",
          }}
        >
          Total No Trades :
          <span
            style={{ paddingRight: "10px", color: "gray", paddingLeft: "6px" }}
          >
            {datas?.length}
          </span>
          {/* | Target :
          <span
            style={{ color: "green", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {filteredData?.length}
          </span>
          | SL :
          <span
            style={{ color: "red", paddingRight: "10px", paddingLeft: "6px" }}
          >
            {filtered?.length}
          </span> */}
          | OverAll P/L :
          <span
            style={{
              color: textColor,
              paddingRight: "10px",
              paddingLeft: "6px",
            }}
          >
            {alltotal}
          </span>
        </span>
      </div>
    </>
  );

  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    if (TradeList_data_date.dateRange != null) {
      const fromDate = new Date(TradeList_data_date.dateRange[0]);
      const year = fromDate.getFullYear();
      const month = String(fromDate.getMonth() + 1).padStart(2, "0");
      const day = String(fromDate.getDate()).padStart(2, "0");
      const formattedFromDate = `${year}-${month}-${day}`;
      const toate = new Date(TradeList_data_date.dateRange[1]);
      const toyear = toate.getFullYear();
      const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
      const today = String(toate.getDate()).padStart(2, "0");
      const formattedToDate = `${toyear}-${tomonth}-${today}`;
      if (openposition === "openposition") {
        dispatcher(clear_Trade_List());

        dispatcher(
          list_LiveTrade({
            from_date: formattedFromDate,
            to_date: formattedToDate,
            type: alignment,
            algo_id: TradeList_data_date.algo_id,
            status: TradeList_data_date.status,
            strategy_id: TradeList_data_date.strategy_id,
            entry_type: Group,
          })
        );
      }
    } else {
      if (openposition === "openposition") {
        dispatcher(clear_Trade_List());

        dispatcher(
          list_LiveTrade({
            from_date: null,
            to_date: null,
            algo_id: null,
            status: null,
            strategy_id: null,
            type: alignment,
            entry_type: Group,
          })
        );
      }
    }

    if (TradeList_data_date.dateRange != null) {
      const fromDate = new Date(TradeList_data_date.dateRange[0]);
      const year = fromDate.getFullYear();
      const month = String(fromDate.getMonth() + 1).padStart(2, "0");
      const day = String(fromDate.getDate()).padStart(2, "0");
      const formattedFromDate = `${year}-${month}-${day}`;
      const toate = new Date(TradeList_data_date.dateRange[1]);
      const toyear = toate.getFullYear();
      const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
      const today = String(toate.getDate()).padStart(2, "0");
      const formattedToDate = `${toyear}-${tomonth}-${today}`;
      if (openposition === "tradehistory") {
        dispatcher(clear_Trade_List());

        dispatcher(
          list_TradeList({
            from_date: formattedFromDate,
            to_date: formattedToDate,
            type: alignment,
            algo_id: TradeList_data_date.algo_id,
            status: TradeList_data_date.status,
            strategy_id: TradeList_data_date.strategy_id,
            entry_type: Group,
          })
        );
      }
    } else {
      if (openposition === "tradehistory") {
        dispatcher(clear_Trade_List());

        dispatcher(
          list_TradeList({
            from_date: null,
            to_date: null,
            algo_id: null,
            status: null,
            strategy_id: null,
            type: alignment,
            entry_type: Group,
          })
        );
      }
    }
    return () => {
      dispatcher(clear_open_position());
    };
  }, [
    dispatcher,
    alignment,
    TradeList_data_date.algo_id,
    TradeList_data_date.strategy_id,
    TradeList_data_date.status,
    TradeList_data_date.dateRange,
    Group,
    openposition,
  ]);

  const currentDateTime = new Date().toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const namelist = localStorage.getItem("name");
  const exportToExcel = (data: any) => {
    let sheetName = "Trade_Details.xlsx";
    let headerName = "Trade Details";

    let workbook = new ExcelJS.Workbook();

    let sheet = workbook.addWorksheet(sheetName);

    let columnArr = [];
    for (let i in data[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnArr.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        showFirstColumn: true,
      },
      columns: [{ name: "Trade Details List" }],
      rows: [
        [
          `Generated At: ${new Date().toLocaleDateString(
            "en-IN"
          )} ${currentDateTime}`,
        ],
        [`Generated By: ${namelist}`],
      ],
    });

    sheet.addTable({
      name: headerName,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
      },
      columns: columnArr
        ? columnArr.map((col) => ({
            name: col.name,
            filterButton: false,
            style: { alignment: { horizontal: "center" } },
          }))
        : [{ name: "" }],
      rows: data.map((e: any) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("A1").font = { size: 20, bold: true };

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });
    const table = sheet.getTable(headerName);
    if (table && table.columns) {
      for (let i = 0; i < table.columns.length; i++) {
        // Access table columns and rows directly
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d9f1" },
        };

        if (table.rows) {
          for (let j = 0; j < table.rows.length; j++) {
            let rowCell = sheet.getCell(
              `${String.fromCharCode(65 + i)}${j + 6}`
            );
            rowCell.alignment = { horizontal: "center", wrapText: true };
            rowCell.border = {
              bottom: {
                style: "thin",
                color: { argb: "a6a6a6" },
              },
            };
          }
        }
      }
    }

    table.commit();

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      writeFile(sheetName, buffer);
    });
  };

  const column = [
    {
      headerName: "Strategy Name",
      field: "strategy_name",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.strategy_name === null) {
          return "-";
        }
        const strategy_name = params.row.strategy_name;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={strategy_name}
          >
            {strategy_name}
          </div>
        );
      },
    },
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1,
      field: "stock_name",
    },
    {
      sortable: true,
      headerName: (
        <>{alignment === "Individual" ? "Account Name" : "Group Name"}</>
      ),
      field: alignment === "Individual" ? "account" : "group",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const isIndividualAlignment = alignment === "Individual";
        const group = params.row.group;
        const account = params.row.account;

        if (!group && !account) {
          return (
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title="-"
            >
              -
            </div>
          );
        }

        const content = isIndividualAlignment
          ? `${account?.broker} : ${account?.client_id}`
          : group?.name || "-";

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={content}
          >
            {content}
          </div>
        );
      },
    },
    {
      headerName: "Execution Type ",
      field: "execution_type",
      flex: 1,
    },
    {
      headerName: "Trade Type",
      field: "paper_trade",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const paper_trade = params.row.paper_trade;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={paper_trade === true ? "Paper Trade" : "Live Trade"}
          >
            {paper_trade === true ? "Paper Trade" : "Live Trade"}
          </div>
        );
      },
    },
    {
      headerName: "Quantity",
      field: "order",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const quantity = params.row.quantity;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={quantity}
          >
            {quantity}
          </div>
        );
      },
    },
    {
      headerName: "Order Type",
      field: "order_type",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const order_type = params.row.order_type;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={order_type}
          >
            {order_type}
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (Group === "Strategy") {
          switch (params.row.status) {
            case 0:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Entry"}
                >
                  {"Entry"}
                </div>
              );

            case 1:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Signal"}
                >
                  {"Signal"}
                </div>
              );

            case 2:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"SL"}
                >
                  {"SL"}
                </div>
              );
            case 3:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Target"}
                >
                  {"Target"}
                </div>
              );
            case 4:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"TSL"}
                >
                  {"TSL"}
                </div>
              );
            case 5:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Exit"}
                >
                  {"Exit"}
                </div>
              );
            default:
              return "Unknown";
          }
        } else {
          switch (params.row.status) {
            case 0:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Entry"}
                >
                  {"Entry"}
                </div>
              );

            case 1:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Target"}
                >
                  {"Target"}
                </div>
              );

            case 2:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"SL"}
                >
                  {"SL"}
                </div>
              );
            case 5:
              return (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={"Exit"}
                >
                  {"Exit"}
                </div>
              );
            default:
              return "Unknown";
          }
        }
      },
    },
    {
      headerName: "P/L",
      field: "profit_loss",
      flex: 1,
    },
    {
      headerName: "Expires In",
      field: "expires_in",
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.expires_in;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.expires_in === null) {
          return "-";
        }
        if (params.row.expires_in === -1) {
          return "Expired";
        }
        if (params.row.expires_in === -2) {
          return "Today";
        }
        const quantity = params.row.expires_in;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "red",
              fontWeight: "600",
            }}
            title={quantity}
          >
            {quantity} {quantity === 0 ? "day" : "days"}
          </div>
        );
      },
    },
    {
      headerName: "Entry Price",
      field: "entry_price",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const entry_price = params.row.entry_price;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={entry_price}
          >
            {entry_price}
          </div>
        );
      },
    },
    {
      headerName: "Entry At",
      field: "entry_at",
      flex: 1,
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.entry_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.entry_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.entry_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Exit Price ",
      field: "exit_price",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const exitPriceValue = params.row.exit_price;

        if (exitPriceValue === null) {
          return "-";
        }

        // Render the exit price value if it's not null
        return <span title={exitPriceValue}>{exitPriceValue}</span>; // You can adjust the formatting as needed
      },
    },

    {
      headerName: "Exit At",
      field: "exit_at",
      flex: 1,
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.exit_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.exit_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.exit_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    alignment === "Group"
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: GridCellParams) => {
            return (
              <DialogTradeHistory
                // successMessage={editData}
                // isLoading={editDataIsLoading}
                Buttondialog={["edit"]}
                Dialogtitle={"Trade History"}
                sx={{
                  backgroundColor: "transparent",
                  minWidth: "0",
                  padding: "0",
                  borderRadius: "50%",
                  height: "60%",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                edit={{
                  ...params.row,
                }}
                Buttonname={
                  <span
                    title="View"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatcher(
                        list_TradeListGroup({
                          strategy_id: params.row.id,
                          group_id: params.row.group.id,
                        })
                      );
                      const group = params.row.group.name;
                      localStorage.setItem("TradeListgroup", group);
                    }}
                  >
                    <RemoveRedEyeIcon />
                  </span>
                }
              />
            );
          },
        }
      : {
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    strategy_name: true,
    stock_name: true,
    execution_type: false,
    paper_trade: false,
    order: true,
    order_type: true,
    status: true,
    profit_loss: true,
    expires_in: true,
    entry_price: true,
    entry_at: true,
    exit_price: true,
    exit_at: false,
    Action: true,
  };

  // Add the conditional key to the initialColumnVisibilityModel object
  if (alignment === "Individual") {
    initialColumnVisibilityModel["account"] = false;
  } else {
    initialColumnVisibilityModel["group"] = false;
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  // Filter out the "Expires In" column if openposition is not "tradehistory"
  const filteredColumns =
    openposition === "tradehistory"
      ? column
      : column.filter((column: any) => column.field !== "expires_in");

  // this code  for excle
  const exportdatalist = datas.map((data: any) => {
    let statusText;
    if (Group === "Strategy") {
      switch (data.status) {
        case 0:
          statusText = "Entry";
          break;
        case 1:
          statusText = "Signal";
          break;
        case 2:
          statusText = "SL";
          break;
        case 3:
          statusText = "Target";
          break;
        case 4:
          statusText = "TSL";
          break;
        case 5:
          statusText = "Exit";
          break;
        default:
          statusText = "Unknown";
      }
    } else {
      switch (data.status) {
        case 0:
          statusText = "Entry";
          break;
        case 1:
          statusText = "Target";
          break;
        case 2:
          statusText = "SL";
          break;
        case 5:
          statusText = "Exit";
          break;
        default:
          statusText = "Unknown";
      }
    }
    const timestampInMilliseconds = data.entry_at * 1000;
    // Create a Date object from the timestamp
    const utcDates = new Date(timestampInMilliseconds);

    const ISTDates = new Date(utcDates.getTime());

    const formattedISTDates = ISTDates.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });
    // Create a Date object from the timestamp
    const timestampInMillisecond = data.exit_at * 1000;

    const utcDate = new Date(timestampInMillisecond);

    const ISTDate = new Date(utcDate.getTime());

    const formattedISTDate = ISTDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });
    const isIndividualAlignment = alignment === "Individual";

    return {
      [isIndividualAlignment ? "Account_Name" : "Group_Name"]:
        isIndividualAlignment
          ? `${data.account?.broker} : ${data.account?.client_id}`
          : data.group?.name || "-",
      StockName: data.stock_name,
      ExecutionType: data.execution_type,
      PaperTrade: data.paper_trade === true ? "Paper Trade" : "Live Trade",
      Quantity: data.quantity,
      OrderType: data.order_type,
      Status: statusText,
      ProfitLoss: data.profit_loss,
      ExpiresIn: data?.expires_in === null ? "-" : data?.expires_in,
      EntryPrice: data.entry_price,
      EntryAt:
        "1/1/1970, 5:30:00 am" === formattedISTDates
          ? "-"
          : formattedISTDates === null
          ? "-"
          : formattedISTDates,
      ExitePrice: data.exit_price === null ? "-" : data.exit_price,
      ExitAt:
        "1/1/1970, 5:30:00 am" === formattedISTDate
          ? "-"
          : formattedISTDate === null
          ? "-"
          : formattedISTDate,
    };
  });
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Grids
        row={datas ?? []}
        column={filteredColumns}
        Users={heading}
        Removetheborder={"yes"}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        opened
        pagination
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              if (TradeList_data_date.dateRange != null) {
                const fromDate = new Date(TradeList_data_date.dateRange[0]);
                const year = fromDate.getFullYear();
                const month = String(fromDate.getMonth() + 1).padStart(2, "0");
                const day = String(fromDate.getDate()).padStart(2, "0");
                const formattedFromDate = `${year}-${month}-${day}`;
                const toate = new Date(TradeList_data_date.dateRange[1]);
                const toyear = toate.getFullYear();
                const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
                const today = String(toate.getDate()).padStart(2, "0");
                const formattedToDate = `${toyear}-${tomonth}-${today}`;
                dispatcher(
                  dispatcherFunction({
                    from_date: formattedFromDate,
                    to_date: formattedToDate,
                    type: alignment,
                    algo_id: TradeList_data_date.algo_id,
                    status: TradeList_data_date.status,
                    strategy_id: TradeList_data_date.strategy_id,
                    entry_type: Group,
                  })
                );
              } else {
                dispatcher(
                  dispatcherFunction({
                    from_date: null,
                    to_date: null,
                    algo_id: TradeList_data_date.algo_id,
                    status: TradeList_data_date.status,
                    strategy_id: TradeList_data_date.strategy_id,
                    type: alignment,
                    entry_type: Group,
                  })
                );
              }
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        Export={
          <span
            title={
              datas.length > 0
                ? "Export"
                : "No data available in the table. Please note that data export is not possible."
            }
            onClick={() => {
              if (datas.length > 0) {
                exportToExcel(exportdatalist);
              }
            }}
          >
            <SaveAltIcon
              sx={{
                fontSize: "24px",
                marginTop: "5px",
                cursor: "pointer",
                marginRight: "10px",
                marginLeft: "5px",
              }}
            ></SaveAltIcon>
          </span>
        }
        sideButton={
          <TradeDetailsfilter
            filtertype={alignment}
            entry_type={Group}
          ></TradeDetailsfilter>
        }
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default TradeDetails;
