import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  FormHelperText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import {
  clearStrategiesList,
  list_StrategiesList,
} from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import Multiselect from "./Multiselectfixed";
import { clearStock } from "../Redux/Reducers/Stock/list_Stock_slicer";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  dataPassed?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  // check_trade?:any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const Dialog: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { getValues, isLoading }: any = props;
  const dataStrategies = useAppSelector((state) => state?.StrategiesList?.data);
  const userlist = useAppSelector((state) => state?.AdminList?.data);

  const initialValues: {
    user: string;
    algo_id: string;
    Stock: { label: string; value: string }[];
  } = {
    user: props?.edit?.user?.id || "",
    algo_id: props?.edit?.algo_id || "",
    Stock:
      props?.edit?.strategy?.map((editvalue: any) => {
        return {
          label: editvalue.name,
          value: editvalue.id,
          segment: editvalue.segment,
        };
      }) ?? [],
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("mapstrategiespermission");
    localStorage.removeItem("mapstrategiesedit");
    dispatcher(clearStock());
    dispatcher(clearStrategiesList());
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatcher = useAppDispatch();
  const data = useAppSelector((state) => state?.AlgoList?.data);

  const addto = [...data];

  const pageName = "Map Strategies";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];
  const datapass: any = localStorage.getItem("mapstrategiesedit");
  const mapstrategiespermission: any = localStorage.getItem(
    "mapstrategiespermission"
  );
  const trueorfalse = mapstrategiespermission;

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        disabled={
          MenuOfUserpermission?.permission?.add === false ||
          MenuOfUserpermission?.permission?.edit === false
          // props.disabled
        }
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minWidth: "200px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              user: Yup.string().required("Please select the  user"),
              algo_id: Yup.string().required("Please select the algo"),
              Stock: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .min(1, "Please select the strategy")
                .required("Please select the strategy"),
            })}
            onSubmit={(values) => {
              getValues(values);
              handleClose();
              localStorage.removeItem("mapstrategiespermission");
              localStorage.removeItem("mapstrategiesedit");
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                      }}
                    >
                      Select User
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <Autocomplete
                      Listitem={userlist.map((v: any) => {
                        return {
                          label: `${v.app_id} | ${v.name}`,
                          value: v.id,
                        };
                      })}
                      disabled={trueorfalse === "true" ? true : false}
                      width={200}
                      variant={"outlined"}
                      name={"user"}
                      value={props.values}
                      onBlur={props.handleBlur}
                      onchange={(e: any, newValue: any) => {
                        props.setFieldValue("user", e.value);
                      }}
                      style={{
                        width: "25rem",
                        height: "50px",
                        borderBottom: "50px",
                      }}
                      error={props.errors.user && props.touched.user}
                    ></Autocomplete>
                    {props.errors.user && props.touched.user ? (
                      <FormHelperText
                        style={{ marginLeft: "13px", marginTop: "10px" }}
                        error
                      >
                        {props.errors.user}
                      </FormHelperText>
                    ) : null}
                  </div>
                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                      }}
                    >
                      Select Algo <span style={{ color: "red" }}>*</span>
                    </div>
                    <div style={{ height: "80px" }}>
                      <Autocomplete
                        Listitem={addto.map((v: any) => {
                          return {
                            label: v.name,
                            value: v.id,
                          };
                        })}
                        width={200}
                        variant={"outlined"}
                        disabled={trueorfalse === "true" ? true : false}
                        name={"algo_id"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any, newValue: any) => {
                          props.setFieldValue("algo_id", e.value);

                          dispatcher(
                            list_StrategiesList({
                              algo_id: e.value,
                              is_enabled: true,
                            })
                          );
                          props.setFieldValue("Stock", []);
                        }}
                        style={{
                          width: "25rem",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={
                          !!(props.errors.algo_id && props.touched.algo_id)
                        }
                      ></Autocomplete>

                      {props.errors.algo_id && props.touched.algo_id ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {props.errors.algo_id}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                      }}
                    >
                      Select Strategy <span style={{ color: "red" }}>*</span>
                    </div>
                    <Multiselect
                      options={dataStrategies.map((v: any) => {
                        return {
                          label: v.name,
                          value: v.id,
                          segment: v.segment,
                        };
                      })}
                      name={"Stock"}
                      value={props.values.Stock}
                      onBlur={props.handleBlur}
                      getValue={(value: any) => {
                        props.setFieldValue("Stock", value);
                        props.setFieldValue("segment", value.segment);
                      }}
                      sx={{
                        width: "25rem",
                        height: "100%",
                        borderRadius: "0",
                        marginBottom: "10px",
                      }}
                      inputSx={{
                        borderRadius: "0",
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      helperText={
                        props.errors.Stock &&
                        props.touched.Stock &&
                        props.errors.Stock
                      }
                      error={!!(props.errors.Stock && props.touched.Stock)}
                      // Conditionally include fixedOptions and dataPassed based on trueorfalse
                      {...(trueorfalse === "true" && {
                        fixedOptions: props.values.Stock,
                        dataPassed: datapass,
                      })}
                    />
                  </div>

                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                      }}
                    >
                      Save
                    </button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default Dialog;
