import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_LiveTrade,
  success_api_call_LiveTrade,
} from "./list_LiveTrade_slicer";
import {
  failure_api_call_add_AddOpenPosition,
  success_api_call_add_AddOpenPosition,
} from "./add_open_position_slicer";
import {
  failure_api_call_edit_EditOpenPosition,
  success_api_call_edit_EditOpenPosition,
} from "./edit_open_position_slicer";
import {
  failure_api_call_delete_DeleteOpenPosition,
  success_api_call_delete_DeleteOpenPosition,
} from "./delete_open_position_manual_slicer";
import {
  failure_api_call_OpenPositionList,
  success_api_call_OpenPositionList,
} from "./list_OpenPosition_slicer";

//list Live Trade
const listLiveTradeCall = async (a: any) => {
  const listfilter = {
    entry_type: a.payload.entry_type,
    trade_type: a.payload.type,
    algo_id: a.payload.algo_id,
    strategy_id: a.payload.strategy_id,
    stock_id: a.payload.Stocks,
    product_type: a.payload.Product,
    execution_type: a.payload.typelist,
    status: a.payload.Status,
    user_id: localStorage.getItem("idforfilter"),
  };
  const listType = {
    trade_type: a.payload.type,
    entry_type: a.payload.entry_type,
    user_id: localStorage.getItem("idforfilter"),
  };
  const data_for_open_position =
    a.payload.algo_id ||
    a.payload.Stocks ||
    a.payload.Product ||
    a.payload.typelist
      ? listfilter
      : listType;
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/`,
    data_for_open_position,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* LiveTradeApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listLiveTradeCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_LiveTrade(res));
  } catch (e) {
    yield put(failure_api_call_LiveTrade(e));
    yield put(expireSignature(e));
  }
}

//list Live Trade
const OpenPositionListCall = async (a: any) => {
  const listfilter = {
    entry_type: a.payload.entry_type,
    trade_type: a.payload.type,
    algo_id: a.payload.algo_id,
    strategy_id: a.payload.strategy_id,
    stock_id: a.payload.Stocks,
    product_type: a.payload.Product,
    execution_type: a.payload.typelist,
    status: a.payload.Status,
    user_id: localStorage.getItem("idforfilter"),
  };
  const listType = {
    trade_type: a.payload.type,
    entry_type: a.payload.entry_type,
    user_id: localStorage.getItem("idforfilter"),
  };
  const data_for_open_position =
    a.payload.algo_id ||
    a.payload.Stocks ||
    a.payload.Product ||
    a.payload.typelist
      ? listfilter
      : listType;
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/`,
    data_for_open_position,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* OpenPositionListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(OpenPositionListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_OpenPositionList(res));
  } catch (e) {
    yield put(failure_api_call_OpenPositionList(e));
    yield put(expireSignature(e));
  }
}

//open position add
const AddOpenPositionCall = async (a: any) => {
  const add = {
    stock_id: a.payload?.selectstocks[0].value,
    group_id: a.payload.group[0].value,
    account_id: null,
    order_type: a.payload.ordertype,
    execution_type: a.payload.type,
    product_type: a.payload.product,
    validity: a.payload.validity,
    quantity: a.payload.quantity,
    entry: a.payload.price === "" ? 0 : a.payload.price,
    target: a.payload.target === 0 ? null : a.payload.target,
    sl: a.payload.triggerprice,
    tsl: a.payload.triggerprice,
    dis_quantity: a.payload.discqty,
    minutes: a.payload.minutes,
  };
  const get_token = localStorage.getItem("token");

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/order/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddOpenPositionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddOpenPositionCall, e);

    yield put(success_api_call_add_AddOpenPosition(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddOpenPosition(e));
    yield put(expireSignature(e));
  }
}

// edit open position
const EditOpenPositionCall = async (a: any) => {
  const edit = {
    stock_id: a.payload?.stock_id,
    quantity: a.payload.quantity,
    entry: a.payload.price === 0 ? null : a.payload.price,
    target: a.payload.target,
    sl: a.payload.triggerprice,
    tsl: a.payload.triggerprice,
  };
  const get_token = localStorage.getItem("token");
  const openEditAdd = localStorage.getItem("OpenPositionfilter");
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/order/edit/${openEditAdd}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* EditOpenPositionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditOpenPositionCall, e);
    yield put(success_api_call_edit_EditOpenPosition(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_EditOpenPosition(e));
    yield put(expireSignature(e));
  }
}

// Delete open position
export function* DeleteOpenPositionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteOpenPositionCall, e);
    yield put(success_api_call_delete_DeleteOpenPosition(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteOpenPosition(error));
  }
}
const DeleteOpenPositionCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/order/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
