import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list live trade
export const LiveTrade = createSlice({
  name: "LiveTrade",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_LiveTrade: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_LiveTrade: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_LiveTrade: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    LiveTrade_is_first: (state, action) => {
      state.isFirst = false;
    },
    clear_open_position: (state) => {
      state.data = [];
    },
  },
});
export default LiveTrade.reducer;
export const {
  list_LiveTrade,
  clear_open_position,
  success_api_call_LiveTrade,
  failure_api_call_LiveTrade,
  LiveTrade_is_first,
} = LiveTrade.actions;
