import React, { FunctionComponent, useEffect, useRef } from "react";
import * as echarts from "echarts";
import "./Backtestsummary.css";

interface BackchartdProps {
  dataforcharts?: any;
}

const MyChart: FunctionComponent<BackchartdProps> = (props: any) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const myChartRef = useRef<any>(null);

  useEffect(() => {
    const initChart = () => {
      if (chartRef.current) {
        myChartRef.current = echarts.init(chartRef.current);
      }
    };

    const updateChart = () => {
      if (props?.dataforcharts?.length > 0 && myChartRef.current) {
        const updatedData = props.dataforcharts.map((item: any) => {
          const exitAtNumber = parseInt(item.exit_at);
          const exitDate = new Date(exitAtNumber * 1000);

          const day = exitDate.getDate().toString().padStart(2, "0");
          const month = (exitDate.getMonth() + 1).toString().padStart(2, "0");
          const year = exitDate.getFullYear().toString().slice(-2);

          let hours = exitDate.getHours();
          const minutes = exitDate.getMinutes().toString().padStart(2, "0");
          hours = hours % 12;
          hours = hours ? hours : 12;
          const formattedHours = hours.toString().padStart(2, "0");

          const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes}`;

          return {
            ...item,
            exit_date: formattedDate,
          };
        });

        const dataslinechart = updatedData.map((data: any) => {
          return {
            name: data.exit_date,
            Profit_Loss: data.profit_loss,
          };
        });

        let cumulativeProfitLoss = 0;
        const Cumulative = updatedData.map((data: any) => {
          cumulativeProfitLoss += data.profit_loss;
          return {
            name: data.exit_date,
            Profit_Loss: cumulativeProfitLoss,
          };
        });

        const xAxisData = dataslinechart.map((item: any) => item.name);
        const pl = dataslinechart.map((item: any) => item.Profit_Loss);
        const cpl = Cumulative.map((item: any) => item.Profit_Loss);

        const positivePl = pl.map((value: number) =>
          value >= 0 ? value : null
        );
        const negativePl = pl.map((value: number) =>
          value < 0 ? value : null
        );

        const option = {
          color: ["rgb(136 187 123)", "green", "#EE6666"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          grid: {
            right: "20%",
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true, name: "backtestchart" },
            },
          },
          legend: {
            data: [
              "Profit/Loss (Positive)",
              "Profit/Loss (Negative)",
              "Cumulative P&L",
            ],
          },
          xAxis: [
            {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              data: xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "Profit/Loss",
              position: "left",
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "gray",
                },
              },
              axisLabel: {
                formatter: "{value}",
              },
            },
            {
              type: "value",
              name: "Cumulative P&L",
              position: "right",
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "green",
                },
              },
              axisLabel: {
                formatter: "{value}",
              },
            },
          ],
          series: [
            {
              name: "Profit/Loss (Positive)",
              type: "bar",
              data: positivePl,
              yAxisIndex: 0,
              itemStyle: {
                color: "rgb(136 187 123)",
              },
            },
            {
              name: "Profit/Loss (Negative)",
              type: "bar",
              data: negativePl,
              yAxisIndex: 0,
              itemStyle: {
                color: "red",
              },
            },
            {
              name: "Cumulative P&L",
              type: "line",
              data: cpl,
              yAxisIndex: 1,
              itemStyle: {
                color: "green",
              },
            },
          ],
        };

        myChartRef.current.setOption(option);
      }
    };

    if (!myChartRef.current) {
      initChart();
    }
    updateChart();

    const handleResize = () => {
      myChartRef.current?.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (myChartRef.current) {
        myChartRef.current.dispose();
        myChartRef.current = null;
      }
    };
  }, [props.dataforcharts]);

  return (
    <div style={{ width: "100%" }} className="bodysummary">
      <div className="summarytitle">Cumulative P&L</div>
      <br />
      <div
        id="main"
        ref={chartRef}
        style={{ width: "100%", height: "100vh" }}
      />
    </div>
  );
};

export default MyChart;
