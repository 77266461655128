import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list  trade setup
export const TreadSetupList = createSlice({
  name: "TreadSetupList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_TreadSetupList: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_TreadSetupList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_TreadSetupList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    TreadSetupList_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearData: (state) => {
      state.data = [];
    },
  },
});
export default TreadSetupList.reducer;
export const {
  list_TreadSetupList,
  success_api_call_TreadSetupList,
  failure_api_call_TreadSetupList,
  TreadSetupList_is_first,
  clearData,
} = TreadSetupList.actions;
