import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../Redux/store/hooks";
import editimage from "../Image/Edit.svg";
import { edit_EditableValueAlgo } from "../../Redux/Reducers/Algo-api/editableValuAlgo";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { Algovalues_datagrid } from "../../Redux/Reducers/Datagrid-values/Algo-values-slicer";
import { DeleteAlgovalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeleteAlgo-values-slicer";
import { update } from "../../Redux/Reducers/AlgoDymics";

interface AlgoMessageProps {
  valuepopup: number;
  editdata: any;
}

const AlgoMessage: FunctionComponent<AlgoMessageProps> = (props: any) => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const pageName = "View Algo";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <span
          title={
            props.editdata.isMatch === true
              ? "Algo is currently used in trade setup"
              : "Trade status Active / Inactive"
          }
        >
          <Switch
            title="Trade status Active / Inactive"
            {...label}
            size="small" // Add this line to set the switch size to small
            disabled={props.editdata.isMatch === true}
            checked={props.editdata.is_enabled} // Set the checked prop based on the state
            onChange={(e) => {
              const dataToPass = props.editdata; // Replace with your actual data
              localStorage.setItem("filter", dataToPass.id);
              dispatcher(
                Algovalues_datagrid({
                  is_enabled: e.target.checked,
                })
              );
            }}
          />
        </span>
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.edit ? (
        <span
          title={
            props.editdata.isMatch === true
              ? "Algo is currently used in trade setup"
              : "Edit"
          }
          style={{ cursor: "pointer" }}
          onClick={() => {
            const dataToPass = props.editdata; // Replace with your actual data
            localStorage.setItem("filter", dataToPass.id);
            localStorage.setItem("editalgodatamassage", "edit");

            dispatcher(edit_EditableValueAlgo(props.editdata));
            dispatcher(update(props.editdata.meta_data));
            navigate("/addalgo", {
              state: {
                editoraddalgo: "edit",
                Tradecheck: props.editdata.isMatch,
              },
            });
          }}
        >
          <img src={editimage} width="25px" alt={""}></img>
        </span>
      ) : (
        "-"
      )}
      {MenuOfUserpermission?.permission?.delete ? (
        <DeleteIcon
          titleAccess="Delete"
          sx={{
            color: "#F2A618",
            cursor: "pointer",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteAlgovalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      ) : (
        "-"
      )}
    </div>
  );
};

export default AlgoMessage;
