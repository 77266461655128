import { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Grids from "../../Component/Grides";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as ExcelJS from "exceljs";
import {
  clear_open_position,
  list_LiveTrade,
} from "../../Redux/Reducers/Open-Position/list_LiveTrade_slicer";
import LiveTradefilter from "../../Component/LiveTradefilter";
import Action from "./Action";
import {
  Square_off,
  false_switchSquareoff_response,
} from "../../Redux/Reducers/Square-off/Square-off_slicer";
import Dialogs from "../../Component/Dialog";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Squareoffvalues_datagrid,
  false_switchSquareoffvalues,
} from "../../Redux/Reducers/Datagrid-values/Squareoff-values-slicer";
import { edit_filtertrade } from "../../Redux/Reducers/Filtervalue/filtertrade";
import DialogOpenposition from "../../Component/Dialog_for_view_data_openposition";
import { list_TradeListGroup } from "../../Redux/Reducers/Trade-details/list_TradeList_grouplist_slicer";

interface OpenPositionProps {}

const OpenPosition: FunctionComponent<OpenPositionProps> = () => {
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Algo Trading
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Open Position
    </Typography>,
  ];
  const dispatcher = useAppDispatch();

  //    datas of Trade
  const datas = useAppSelector((state) => state?.LiveTrade?.data);
  const squareoff: any = useAppSelector((state) => state?.Squareoff?.data);
  const switchResponsesquareoff = useAppSelector(
    (state) => state.Squareoff.switchResponse
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.Squareoffvalues.switch
  );
  const Squareoffvalues: any = useAppSelector(
    (state) => state?.Squareoffvalues?.data
  );
  const switchResponse = useAppSelector(
    (state) => state.Squareoff.switchResponse
  );
  const isLoading = useAppSelector((state) => state?.LiveTrade?.isLoading);
  const LiveList_data_date: any = useAppSelector(
    (state) => state?.filterlive?.data
  );
  const [alignment, setAlignment] = React.useState("Individual");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const heading = (
    <ToggleButtonGroup
      color="primary"
      sx={{ marginRight: "10px" }}
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton
        value="Individual"
        title="Individual Account"
        sx={{
          textTransform: "capitalize",
          height: "39px",
          marginTop: "3px",
          width: {
            xs: 60,
            sm: 60,
            md: 60,
            lg: 75,
            xl: 80,
          },
        }}
        onClick={() => {
          if (alignment !== "Individual") {
            dispatcher(clear_open_position());
          }
        }}
      >
        Individual
      </ToggleButton>
      <ToggleButton
        value="Group"
        title="Group Accounts"
        sx={{
          textTransform: "capitalize",
          height: "39px",
          marginTop: "3px",
          width: {
            xs: 60,
            sm: 60,
            md: 60,
            lg: 60,
            xl: 80,
          },
        }}
        onClick={() => {
          if (alignment !== "Group") {
            dispatcher(clear_open_position());
          }
        }}
      >
        Group
      </ToggleButton>
    </ToggleButtonGroup>
  );

  React.useEffect(() => {
    dispatcher(
      list_LiveTrade({
        type: alignment,
        entry_type: "Strategy",
        strategy_id: LiveList_data_date.strategy_id,
        algo_id: LiveList_data_date.algo_id,
        status: LiveList_data_date.status,
      })
    );
    dispatcher(edit_filtertrade({}));
    if (switchResponsesquareoff) {
      dispatcher(false_switchSquareoffvalues());
    }
    return () => {
      dispatcher(clear_open_position());
    };
  }, [dispatcher, switchResponsesquareoff, alignment, LiveList_data_date]);

  const namelist = localStorage.getItem("name");

  const exportToExcel = (data: any) => {
    let sheetName = "open_position.xlsx";

    let workbook = new ExcelJS.Workbook();
    let sheet = workbook.addWorksheet(sheetName);

    // Add header row
    sheet.addRow(["Open Position List"]);
    sheet.addRow([
      `Generated At: ${new Date().toLocaleDateString(
        "en-IN"
      )} ${new Date().toLocaleTimeString("en-IN")}`,
    ]);
    sheet.addRow([`Generated By: ${namelist}`]); // replace 'Your Name' with the actual name
    sheet.addRow([]); // empty row
    sheet.addRow(Object.keys(data[0])); // add column headers

    // Add data rows
    data.forEach((item: any) => {
      sheet.addRow(Object.values(item));
    });

    // Set borders for all cells
    const setBorders = (cell: any) => {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    };

    sheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        setBorders(cell);
        cell.alignment = { horizontal: "center", wrapText: true };

        // Apply styling to header row
        if (rowNumber === 5) {
          cell.font = { size: 12, bold: true };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "c5d9f1" },
          };
        }
      });
    });

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
  };

  const pageName = "/openposition";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const column = [
    {
      headerName: "Strategy Name",
      field: "strategy_name",
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.strategy_name === null) {
          return "-";
        }
        const strategy_name = params.row.strategy_name;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={strategy_name}
          >
            {strategy_name}
          </div>
        );
      },
    },
    {
      sortable: true,
      headerName: "Stock Name",
      flex: 1.5,
      field: "stock_name",
    },
    {
      sortable: true,
      headerName: (
        <>{alignment === "Individual" ? "Account Name" : "Group Name"}</>
      ),

      field: alignment === "Individual" ? "account" : "group",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const group = params?.row?.group;
        const account = params?.row?.account;

        const content =
          alignment === "Individual"
            ? `${account?.broker} : ${account?.client_id}`
            : group?.name;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={content === undefined ? "-" : content}
          >
            {content === undefined ? "-" : content}
          </div>
        );
      },
    },
    {
      headerName: "Execution Type",
      field: "execution_type",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Trade Type",
      field: "paper_trade",
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row?.order?.paper_trade;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row?.order === null) {
          return "-";
        }
        const paper_trade = params.row?.order?.paper_trade;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={paper_trade === true ? "Paper Trade" : "Live Trade"}
          >
            {paper_trade === true ? "Paper Trade" : "Live Trade"}
          </div>
        );
      },
    },

    {
      headerName: "Quantity",
      field: "quantity",
      flex: 0.5,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.quantity;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        const quantity = params.row.quantity;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={quantity}
          >
            {quantity}
          </div>
        );
      },
    },
    {
      headerName: "Order Type",
      field: "order_type",
      flex: 0.5,
      valueGetter: (params: any) => {
        return params.row.order_type;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={params.row.order_type}
          >
            {params.row.order_type}
          </div>
        );
      },
      sortable: true,
    },

    {
      headerName: "Expires In",
      field: "expires_in",
      flex: 0.5,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.expires_in;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.expires_in === null) {
          return "-";
        }

        if (params.row.expires_in === -1) {
          return "Expired";
        }
        if (params.row.expires_in === -2) {
          return "Today";
        }
        const quantity = params.row.expires_in;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "red",
              fontWeight: "600",
            }}
            title={quantity}
          >
            {quantity} {quantity === 0 ? "day" : "days"}
          </div>
        );
      },
    },

    {
      headerName: "Entry Price",
      field: "entry_price",
      flex: 0.6,
      sortable: true,
      valueGetter: (params: any) => {
        return params.row?.entry_price;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object
        if (params.row.entry_price === null) {
          return "-";
        }
        const entry_price = params?.row?.entry_price;

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={entry_price}
          >
            {entry_price}
          </div>
        );
      },
    },
    {
      headerName: "Entry At",
      field: "entry_at",
      flex: 1.5,
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.entry_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.entry_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.entry_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Exit At",
      field: "exit_at",
      flex: 1,
      sortable: true,
      type: "dateTime",
      valueGetter: (params: any) => {
        return new Date(params.row?.exit_at);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row?.exit_at === null) {
          return "-";
        }
        // Convert Unix timestamp to milliseconds (multiply by 1000)
        const timestampInMilliseconds = params.row?.exit_at * 1000;
        // Create a Date object from the timestamp
        const utcDate = new Date(timestampInMilliseconds);

        if (isNaN(utcDate.getTime())) {
          return "-";
        }

        const ISTDate = new Date(utcDate.getTime());
        const formattedISTDate = ISTDate.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata", // Setting the timezone to IST
        });

        return (
          <Tooltip title={formattedISTDate}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formattedISTDate}
            </div>
          </Tooltip>
        );
      },
    },
    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: GridRenderCellParams<any>) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {params.row.is_entry === true ? (
                  <div style={{ display: "contents" }}>
                    {alignment === "Group" ? (
                      <DialogOpenposition
                        Buttondialog={["edit"]}
                        Dialogtitle={"Trade History"}
                        sx={{
                          backgroundColor: "transparent",
                          minWidth: "0",
                          padding: "0",
                          borderRadius: "50%",
                          height: "60%",
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                        edit={{
                          ...params.row,
                        }}
                        Buttonname={
                          <span
                            title="View"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              dispatcher(
                                list_TradeListGroup({
                                  strategy_id: params?.row?.id,
                                  group_id: params?.row?.group?.id,
                                })
                              );
                              const group = params.row.group.name;
                              localStorage.setItem("Openpositiongroup", group);
                            }}
                          >
                            <RemoveRedEyeIcon sx={{ fontSize: "20px" }} />
                          </span>
                        }
                      />
                    ) : null}
                    <div>
                      <Action
                        valuepopup={params.row.id}
                        editdata={params.row}
                      ></Action>
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];
  const initialColumnVisibilityModel: any = {
    strategy_name: true,
    stock_name: true,
    execution_type: false,
    paper_trade: false,
    quantity: true,
    order_type: true,
    expires_in: true,
    entry_price: true,
    entry_at: true,
    exit_at: false,
    Action: true,
  };

  // Add the conditional key to the initialColumnVisibilityModel object
  if (alignment === "Individual") {
    initialColumnVisibilityModel["account"] = false;
  } else {
    initialColumnVisibilityModel["group"] = false;
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );

  // this code  for excle
  const exportdatalist = datas?.map((data: any) => {
    const timestampInMillisecond = data?.exit_at * 1000;
    // Create a Date object from the timestamp
    const utcDate = new Date(timestampInMillisecond);

    const ISTDate = new Date(utcDate.getTime());

    const formattedISTDate = ISTDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });

    const timestampInMilliseconds = data?.entry_at * 1000;
    // Create a Date object from the timestamp
    const utcDates = new Date(timestampInMilliseconds);

    const ISTDates = new Date(utcDates.getTime());

    const formattedISTDates = ISTDates.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Setting the timezone to IST
    });
    const isIndividualAlignment = alignment === "Individual";

    return {
      Strategy_Name: data.strategy_name,
      Stock_Name: data.stock_name,
      [isIndividualAlignment ? "Account_Name" : "Group_Name"]:
        isIndividualAlignment
          ? `${data.account?.broker} : ${data.account?.client_id}`
          : data.group?.name || "-",
      Execution_Type: data.execution_type,
      Trade_Type:
        data?.order === null
          ? "-"
          : data?.order?.paper_trade === true
          ? "Paper Trade"
          : "Live Trade",
      Quantity: data.quantity,
      Order_Type: data?.order_type,
      ExpiresIn: data?.expires_in === null ? "-" : data?.expires_in,
      Entry_Price: data.entry_price,
      Entry_At:
        "1/1/1970, 5:30:00 am" === formattedISTDates
          ? "-"
          : formattedISTDates === null
          ? "-"
          : formattedISTDates,
      Exit_at:
        "1/1/1970, 5:30:00 am" === formattedISTDate
          ? "-"
          : formattedISTDate === null
          ? "-"
          : formattedISTDate,
    };
  });

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchSquareoff_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchSquareoff_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchSquareoff_response())}
          severity="success"
        >
          {squareoff?.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display: isLoading ? "block" : "none",
          }}
        />
      </div>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Grids
        row={datas ?? []}
        column={column}
        opens
        Users={heading}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        pagination
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(
                list_LiveTrade({
                  type: alignment,
                  entry_type: "Strategy",
                  strategy_id: LiveList_data_date.strategy_id,
                  algo_id: LiveList_data_date.algo_id,
                  status: LiveList_data_date.status,
                })
              );
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        Export={
          <span
            title={
              datas.length > 0
                ? "Export"
                : "No data available in the table. Please note that data export is not possible."
            }
            onClick={() => {
              if (datas.length > 0) {
                exportToExcel(exportdatalist);
              }
            }}
          >
            <SaveAltIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></SaveAltIcon>
          </span>
        }
        sideButton={
          <LiveTradefilter
            filtertype={alignment}
            entry_type={"Strategy"}
          ></LiveTradefilter>
        }
        dialog={
          <Button
            disabled={MenuOfUserpermission?.permission?.edit === false}
            sx={{
              backgroundColor: "#E10000",
              color: "white",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              textTransform: "capitalize",
              marginLeft: {
                xs: "5px",
                sm: "5px",
                md: "5px",
                lg: "5px",
                xl: "7px",
              },
              "&:hover": { backgroundColor: "#E10000" },
              fontFamily: "Roboto",
            }}
            onClick={() => {
              dispatcher(Squareoffvalues_datagrid({}));
            }}
          >
            Square Off
          </Button>
        }
        Userpermissions={undefined}
      ></Grids>

      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchSquareoffvalues())}
        Dialog_Content={"Do you want to square off ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchSquareoffvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(
                  Square_off({
                    Squareoffvalues,
                    trade_type: alignment,
                    trade_from: "Strategy",
                  })
                )
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default OpenPosition;
