import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Order,
  success_api_call_Order,
} from "./list_Order_slicer";

//list Order
const listOrderCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const loginid=localStorage.getItem("idforlogin")
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because January is 0
  const day = String(today.getDate()).padStart(2, "0");

  let list = {};
  if (a.payload.from_date) {
    list = {
      user_id: loginid,
      client_id: a.payload.client_id || a.payload.id,
      product_type: a.payload.Product,
      execution_type: a.payload.typelist,
      order_type: a.payload.OrderType,
      from_date: a.payload.from_date,
      to_date: a.payload.to_date,
    };
  } else {
    list = {
      user_id: loginid,
      client_id: a.payload.client_id || a.payload.id,
      product_type: a.payload.product_type,
      execution_type: a.payload.execution_type,
      order_type: a.payload.order_type,
      from_date: `${year}-${month}-${day}`,
      to_date: `${year}-${month}-${day}`,
    };
  }
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/account/orders`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* OrderApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listOrderCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_Order(res));
  } catch (e) {
    yield put(failure_api_call_Order(e));
    yield put(expireSignature(e));
  }
}
