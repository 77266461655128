import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_EditRole,
  success_api_call_EditRole,
} from "./edit_Role_slicer";
import {
  failure_api_call_RoleList,
  success_api_call_RoleList,
} from "./list_Role_slicer";
import {
  failure_api_call_delete_DeleteRole,
  success_api_call_delete_DeleteRole,
} from "./delete_Role_slicer";
import {
  failure_api_call_add_AddRole,
  success_api_call_add_AddRole,
} from "./add_Role_slicer";

//add role
const AddRoleCall = async (a: any) => {
  const add = {
    name: a.payload.Rolename,
    status: a.payload.is_enabled,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/role/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddRoleCall, e);
    yield put(success_api_call_add_AddRole(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddRole(e));
    yield put(expireSignature(e));
  }
}

//Edit role
const EditRoleCall = async (a: any) => {
  const get_id = localStorage.getItem("Rolefilter");

  let edit = { name: a.payload.Rolename, status: a.payload.is_enabled }; // 1 for active, 0 for inactive

  const get_token = localStorage.getItem("token");

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/role/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* EditRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditRoleCall, e);

    yield put(success_api_call_EditRole(response.data));
  } catch (e) {
    yield put(failure_api_call_EditRole(e));
    yield put(expireSignature(e));
  }
}

//list role
const RoleListCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/role/${a.payload}`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* RoleListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(RoleListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_RoleList(res));
  } catch (e) {
    yield put(failure_api_call_RoleList(e));
    yield put(expireSignature(e));
  }
}

// Delete role
export function* DeleteRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteRoleCall, e);
    yield put(success_api_call_delete_DeleteRole(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteRole(error));
  }
}
const DeleteRoleCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/role-permission/role/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
