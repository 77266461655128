import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  Alert,
  FormHelperText,
  Menu,
  MenuItem,
  Snackbar,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "../App.css";
import "./DialogForAlgoDymics.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import { DateRangePicker } from "rsuite";
import FilterListIcon from "@mui/icons-material/FilterList";
import { list_Stock } from "../Redux/Reducers/Stock/list_Stock_slicer";
// import { list_AddorEditList } from "../Redux/Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
import CancelIcon from "@mui/icons-material/Cancel";
// import { list_StrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
// import { list_MarketholidayList } from "../Redux/Reducers/Marketholiday/list_Marketholiday_slicer";
import { Switch } from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogBacktestProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogBacktest: FunctionComponent<DialogBacktestProps> = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const vertical = "top";
  const horizontal = "right";

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setstockfilter("0");
    setIsSwitchOn(false);
    setIsSwitchOne(false);
    setIsSwitchsecond(false);
    setOpen(false);
    setValidationMessage(""); // Clear the validation message
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  const { successMessage, isLoading, getValues }: any = props;
  const [alertOpen, setAlertOpen] = React.useState(false);
  const MapStocks: any = useAppSelector((state) => state?.StrategiesList?.data);
  const [validationMessage, setValidationMessage] = React.useState("");

  const snackbarhandleClose = () => {
    setAlertOpen(false);
    setSnackBarOpen(false);
  };
  const action = (
    <button
      onClick={() => {
        snackbarhandleClose();
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  const dispatcher = useAppDispatch();
  // const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const Stock = useAppSelector((state) => state?.Stock?.data);
  const data = useAppSelector((state) => state?.MarketholidayList?.data);
  const formatDate = (dateString: any) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const formattedDates = data.map((item: any) => {
    return {
      holiday_date: formatDate(item.holiday_date),
    };
  });

  const AddorEdit: any = useAppSelector((state) => state?.AddorEditList?.data);
  const filtertrade: any = useAppSelector((state) => state?.filtertrade?.data);
  // Get today's date
  const today = new Date();
  const uniqueIds = Array.from(new Set(AddorEdit.map((v: any) => v?.algo?.id)));

  const filteredStock = Stock.map((v: any) => {
    const expiryDate = new Date(v.expiry_date); // Convert to Date object
    return {
      ...v,
      expiryDate, // Add expiryDate to the stock object
    };
  }).filter((v: any) => v.expiryDate >= today); // Filter stocks by expiry date

  React.useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
    if (alertOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement != null) {
        descriptionElement.focus();
      }
    }
  }, [dispatcher, snackbarOpen, alertOpen]);

  const pageName = "/backtest";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openmenu = Boolean(anchorEl);
  const handleClickmenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosemenu = () => {
    setAnchorEl(null);
  };

  // List of holidays (format: MM/DD/YYYY)
  const holidayList = formattedDates;

  // Function to check if a given date is a working day (Monday to Friday)
  const isWorkingDay = (date: any) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // 0 = Sunday, 6 = Saturday
  };

  // Function to check if the given date is a holiday
  const isHoliday = (date: any) => {
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    // Check if any holiday date matches the formatted date
    return holidayList.some(
      (holiday) => holiday.holiday_date === formattedDate
    );
  };

  // Function to validate the selected date range
  const validateDateRange = (range: any) => {
    if (!range || range.length !== 2) return false;
    const [startDate, endDate] = range;
    let currentDate = new Date(startDate);
    let hasWorkingDay = false;

    while (currentDate <= endDate) {
      if (isWorkingDay(currentDate) && !isHoliday(currentDate)) {
        hasWorkingDay = true;
        break;
      }
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    if (!hasWorkingDay) {
      setValidationMessage(
        "Please include at least one working day in the select backtest duration, as it currently falls on a market holiday."
      );
      return false;
    }

    setValidationMessage(""); // Clear any previous validation message
    return true; // At least one working day found
  };
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);

  const handleSwitchChange = (event: any) => {
    setIsSwitchOn(event.target.checked);
  };
  const [isSwitchOne, setIsSwitchOne] = React.useState(false);

  const handleSwitchChangeone = (event: any) => {
    setIsSwitchOne(event.target.checked);
  };
  const [isSwitchsecond, setIsSwitchsecond] = React.useState(false);

  const handleSwitchChangesecond = (event: any) => {
    setIsSwitchsecond(event.target.checked);
  };
  const [stockfilter, setstockfilter] = React.useState("0");

  return (
    <>
      <Button
        disabled={
          MenuOfUserpermission?.permission?.add === false ? true : false
        }
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "1000px",
            width: "1000px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Formik
            initialValues={{
              quantity: props?.edit?.quantity,
              stoploss: props?.edit?.stoploss || "",
              TrailingStopLoss: props?.edit?.TrailingStopLoss || "",
              takeprofit: props?.edit?.takeprofit || "",
              commissionPercentage: props?.edit?.commissionPercentage || "",
              dateRange: props?.edit?.dateRange ?? null,
              Strategy: props?.edit?.strategy_id || "",
              algo_id: props?.edit?.algo_id || "",
              commission: props?.edit?.commission || "",
              Slippage: props?.edit?.Slippage || "",
              Stock:
                props?.edit?.stocks?.map((editvalue: any) => {
                  return {
                    label: editvalue.name,
                    value: editvalue.id,
                    symbol: editvalue.symbol,
                  };
                })[0] ?? [],
              id_for_filterstrategy: filtertrade?.id_for_filterstrategy ?? null,
            }}
            validationSchema={Yup.object().shape({
              stoploss: Yup.lazy((value) => {
                if (isSwitchOn === true) {
                  return Yup.string().required("Please enter the stop loss");
                }
                return Yup.string();
              }),
              TrailingStopLoss: Yup.lazy((value) => {
                if (isSwitchOne === true) {
                  return Yup.string().required("Please enter the stop loss");
                }
                return Yup.string();
              }),
              takeprofit: Yup.lazy((value) => {
                if (isSwitchsecond === true) {
                  return Yup.string().required(
                    "Please enter the  trailing stopLoss"
                  );
                }
                return Yup.string();
              }),
              quantity: Yup.number().required("Please enter a quantity"),
              Slippage: Yup.string().required("Please enter the slippage"),
              commission: Yup.string().required("Please enter the commission"),
              commissionPercentage: Yup.string().required(
                "Please select the percentage"
              ),
              Strategy: Yup.string().required("Please select the  strategy"),
              algo_id: Yup.string().required("Please select the algo"),
              Stock: Yup.object()
                .shape({
                  label: Yup.string(),
                  value: Yup.string(),
                })
                .required("Please select the stock"),
              dateRange: Yup.array().of(
                Yup.date().required("Please enter a valid date")
              ),
            })}
            onSubmit={(values) => {
              const fromDate = new Date(values.dateRange[0]);
              const year = fromDate.getFullYear();
              const month = String(fromDate.getMonth() + 1).padStart(2, "0");
              const day = String(fromDate.getDate()).padStart(2, "0");
              const formattedFromDate = `${year}-${month}-${day}`;
              const toate = new Date(values.dateRange[1]);
              const toyear = toate.getFullYear();
              const tomonth = String(toate.getMonth() + 1).padStart(2, "0");
              const today = String(toate.getDate()).padStart(2, "0");
              const formattedToDate = `${toyear}-${tomonth}-${today}`;
              if (!isSwitchOn) {
                values.stoploss = null;
              }
              if (!isSwitchOne) {
                values.TrailingStopLoss = null;
              }
              if (!isSwitchsecond) {
                values.takeprofit = null;
              }
              getValues({
                ...values,
                from_date: formattedFromDate,
                to_date: formattedToDate,
              });

              handleClose();
              setAlertOpen(true);
              setstockfilter("0");
            }}
          >
            {({
              values,
              errors,
              isValid,
              touched,
              handleBlur,
              setFieldValue,
            }) => {
              return (
                <Form id="form" autoComplete="off">
                  <div className="addalgoDymics">
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          display: "flex",
                          paddingBottom: "5px",

                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Back Test Duration
                          <span style={{ color: "red" }}> *</span>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <DateRangePicker
                          showOneCalendar
                          className="custom-date-range-picker"
                          editable={false}
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: `1px solid ${
                              errors.dateRange && touched.dateRange
                                ? "#d32f2f"
                                : "fff4f4"
                            }`,
                          }}
                          name="dateRange"
                          value={values.dateRange}
                          onChange={(selectedRange) => {
                            if (validateDateRange(selectedRange)) {
                              setFieldValue("dateRange", selectedRange);
                              setValidationMessage(""); // Clear the validation message
                            }
                          }}
                          format="yyyy-MM-dd"
                          placeholder="Select Back Test Duration"
                          placement="right"
                        />
                        {validationMessage ? null : errors.dateRange &&
                          touched.dateRange ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "5px" }}
                            error
                          >
                            Please select back test duration
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                        }}
                      >
                        Select Algo <span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ height: "80px" }}>
                        <Autocomplete
                          Listitem={uniqueIds.map((id) => {
                            const item = AddorEdit.find(
                              (v: any) => v?.algo.id === id
                            );
                            return {
                              label: item.algo.name,
                              value: item.algo.id,
                              idforfilter: item.id,
                            };
                          })}
                          variant={"outlined"}
                          name={"algo_id"}
                          value={values}
                          onBlur={handleBlur}
                          onchange={(e: any, newValue: any) => {
                            setFieldValue("algo_id", e.value);
                            setFieldValue("Strategy", ""); // or null, depending on your preference
                          }}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                          error={errors.algo_id && touched.algo_id}
                        ></Autocomplete>

                        {errors.algo_id && touched.algo_id ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {errors.algo_id as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                        }}
                      >
                        Select Strategy
                        <span style={{ color: "red" }}> *</span>
                      </div>
                      <Autocomplete
                        Listitem={AddorEdit.filter(
                          (val: any) => val.algo.id === values.algo_id
                        ).flatMap((val: any) =>
                          val.strategy.map((v: any) => ({
                            label: v.name,
                            value: v.id,
                            stock: v.segment,
                          }))
                        )}
                        variant={"outlined"}
                        name={"Strategy"}
                        value={values}
                        onBlur={handleBlur}
                        onchange={(e: any, newValue: any) => {
                          setFieldValue("Strategy", e.value);
                          dispatcher(
                            list_Stock({
                              segment: e.stock,
                            })
                          );
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={errors.Strategy && touched.Strategy}
                      ></Autocomplete>
                      {errors.Strategy && touched.Strategy ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {errors.Strategy as string}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "4px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Select Stock <span style={{ color: "red" }}>*</span>
                        </div>
                        <div>
                          <span
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickmenu}
                            style={{ cursor: "pointer" }}
                          >
                            <FilterListIcon></FilterListIcon>
                          </span>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openmenu}
                            onClose={handleClosemenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {MapStocks?.map((value: any) => {
                              // Check if the value.id matches values.Strategy
                              if (value?.id === values?.Strategy) {
                                // If there's a match, render MenuItem components for each segment
                                return value.segment.map(
                                  (segment: string, index: number) => (
                                    <MenuItem
                                      key={`${value.id}-${index}`}
                                      onClick={() => {
                                        dispatcher(
                                          list_Stock({
                                            segment: segment.split(","),
                                          })
                                        );
                                        handleClosemenu();
                                      }}
                                    >
                                      {segment}
                                    </MenuItem>
                                  )
                                );
                              }
                              return null; // If no match, return null
                            })}
                          </Menu>
                        </div>
                      </div>

                      <Autocomplete
                        Listitem={filteredStock?.map((v: any) => {
                          return {
                            label: v.stock_name,
                            value: v.id,
                            symbol: v.symbol,
                          };
                        })}
                        variant={"outlined"}
                        name={"Stock"}
                        value={values}
                        onBlur={handleBlur}
                        onchange={(value: any) => {
                          setFieldValue("Stock", value);
                          const stock_value = value;
                          const filter_stocks: any = Stock?.find(
                            (value: any) => {
                              return value.id === stock_value.value;
                            }
                          );

                          setstockfilter(filter_stocks?.lot_size);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          marginTop: "-6px",
                        }}
                        error={!!(errors.Stock && touched.Stock)}
                      ></Autocomplete>
                      {errors.Stock && touched.Stock ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please select the stock"}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          Quantity <span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "gray" }}>
                          Lot Size : {stockfilter}
                        </div>
                      </div>

                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="quantity"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          error={!!(errors.quantity && touched.quantity)}
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            // Use regex to check if the input contains only characters (letters)
                            if (/^[0-9\s]+$/.test(inputValue)) {
                              setFieldValue("quantity", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              setFieldValue(
                                "quantity",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.quantity}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />
                        {stockfilter > "0" ? (
                          <span
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                              fontSize: "11px",
                              color: "gray",
                            }}
                          >
                            Quantity multiple by lot size
                          </span>
                        ) : (
                          ""
                        )}
                        {errors.quantity && touched.quantity ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: stockfilter > "0" ? "-3px" : "10px",
                            }}
                            error
                          >
                            {errors.quantity as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Commission<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="commission"
                          type="number"
                          inputProps={{
                            step: 0.5,
                          }}
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          value={values.commission}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            setFieldValue("commission", cleanedInput);
                          }}
                          error={!!(errors.commission && touched.commission)}
                          onBlur={handleBlur}
                          style={{
                            width: "35%",
                            height: "50px",
                          }}
                        />
                        <Autocomplete
                          Listitem={[
                            {
                              label: "Percentage Fee",
                              value: "percentage fee",
                            },
                            { label: "Fixed Fee", value: "fixed fee" },
                            { label: "Per Share", value: "per share" },
                          ]}
                          variant={"outlined"}
                          name={"commissionPercentage"}
                          value={values}
                          onBlur={handleBlur}
                          onchange={(e: any) => {
                            setFieldValue("commissionPercentage", e.value);
                          }}
                          style={{
                            height: "50px",
                            marginLeft: "2%",
                          }}
                          widthsize="65%"
                          error={
                            !!(
                              errors.commissionPercentage &&
                              touched.commissionPercentage
                            )
                          }
                        ></Autocomplete>
                      </div>

                      {errors.commission && touched.commission ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {errors.commission as string}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Slippage<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="Slippage"
                          type="number"
                          inputProps={{
                            step: 0.5,
                          }}
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          value={values.Slippage}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            setFieldValue("Slippage", cleanedInput);
                          }}
                          error={!!(errors.Slippage && touched.Slippage)}
                          onBlur={handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>

                      {errors.Slippage && touched.Slippage ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {errors.Slippage as string}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <div className="addalgoDymics">
                    <div
                      style={{ height: "110px" }}
                      title={
                        isSwitchOn === true
                          ? ""
                          : "Please choose either a stop loss or a trailing stop loss"
                      }
                    >
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "1px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Stop Loss
                          <span style={{ color: "red" }}>
                            {isSwitchOn === true ? "*" : ""}
                          </span>
                        </div>
                        <div>
                          <Switch
                            title={
                              isSwitchOn === true
                                ? "Stop Loss is Enable "
                                : "Stop Loss is Disable"
                            }
                            size="small"
                            checked={isSwitchOn}
                            disabled={isSwitchOne === true}
                            onChange={handleSwitchChange}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="stoploss"
                          disabled={isSwitchOn === false}
                          type="text"
                          inputProps={{
                            step: 0.5,
                          }}
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          value={values.stoploss}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            setFieldValue("stoploss", cleanedInput);
                          }}
                          error={!!(errors.stoploss && touched.stoploss)}
                          onBlur={props.handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>

                      {isSwitchOn === true ? (
                        errors.stoploss && touched.stoploss ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {errors.stoploss as string}
                          </FormHelperText>
                        ) : null
                      ) : null}
                    </div>

                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "1px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Take Profit
                          <span style={{ color: "red" }}>
                            {" "}
                            {isSwitchsecond === true ? "*" : ""}
                          </span>
                        </div>
                        <div>
                          <Switch
                            title={
                              isSwitchsecond === true
                                ? "Take Profit is Enable"
                                : "Take Profit is Disable"
                            }
                            size="small"
                            checked={isSwitchsecond}
                            onChange={handleSwitchChangesecond}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="takeprofit"
                          type="text"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          inputProps={{
                            step: 0.5,
                          }}
                          disabled={isSwitchsecond === false}
                          autoComplete="off"
                          value={values.takeprofit}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            setFieldValue("takeprofit", cleanedInput);
                          }}
                          error={!!(errors.takeprofit && touched.takeprofit)}
                          onBlur={handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>

                      {isSwitchsecond === true ? (
                        errors.takeprofit && touched.takeprofit ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {errors.takeprofit as string}
                          </FormHelperText>
                        ) : null
                      ) : null}
                    </div>
                    <div
                      style={{ height: "110px" }}
                      title={
                        isSwitchOne === true
                          ? ""
                          : "Please choose either a stop loss or a trailing stop loss"
                      }
                    >
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "1px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Trailing Stop Loss
                          <span style={{ color: "red" }}>
                            {" "}
                            {isSwitchOne === true ? "*" : ""}
                          </span>
                        </div>
                        <div>
                          <Switch
                            title={
                              isSwitchOne === true
                                ? "Trailing StopLoss is Enable"
                                : "Trailing StopLoss is Disable"
                            }
                            size="small"
                            checked={isSwitchOne}
                            disabled={isSwitchOn === true}
                            onChange={handleSwitchChangeone}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="TrailingStopLoss"
                          type="number"
                          disabled={isSwitchOne === false}
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          inputProps={{
                            step: 0.5,
                          }}
                          autoComplete="off"
                          value={values.TrailingStopLoss}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            setFieldValue("TrailingStopLoss", cleanedInput);
                          }}
                          error={
                            !!(
                              errors.TrailingStopLoss &&
                              touched.TrailingStopLoss
                            )
                          }
                          onBlur={handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>

                      {isSwitchOne === true ? (
                        errors.TrailingStopLoss && touched.TrailingStopLoss ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {errors.TrailingStopLoss as string}
                          </FormHelperText>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  {validationMessage ? (
                    <FormHelperText
                      sx={{
                        borderRadius: "10px",
                        height: "40px",
                        backgroundColor: "#ababab22",
                      }}
                      error
                    >
                      <ReportGmailerrorredIcon
                        sx={{ margin: "10px 4px -7px 10px" }}
                      ></ReportGmailerrorredIcon>
                      {validationMessage}
                    </FormHelperText>
                  ) : null}
                  <DialogActions
                    style={{
                      justifyContent: "center",
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
      {!isLoading ? (
        // <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={alertOpen} // Use alertOpen to control the Snackbar's visibility
          autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
          onClose={snackbarhandleClose}
          key={`${vertical}-${horizontal}`}
          action={action}
        >
          <Alert onClose={snackbarhandleClose} severity="success">
            {successMessage?.message}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </>
  );
};

export default DialogBacktest;
