import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  list group account
export const ListGroupAccount = createSlice({
  name: "ListGroupAccount",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_ListGroupAccount: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_ListGroupAccount: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_ListGroupAccount: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    ListGroupAccount_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearData_group: (state) => {
      state.data = [];
    },
  },
});
export default ListGroupAccount.reducer;
export const {
  list_ListGroupAccount,
  success_api_call_ListGroupAccount,
  failure_api_call_ListGroupAccount,
  ListGroupAccount_is_first,
  clearData_group,
} = ListGroupAccount.actions;
