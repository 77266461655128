import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for token 
export const Tokens = createSlice({
  name: "Tokens",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    Token: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Token: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_Token: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    Token_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_Token_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default Tokens.reducer;
export const {
  Token,
  success_api_call_Token,
  failure_api_call_Token,
  create_Token_complete_api,
  Token_is_success,
} = Tokens.actions;
