import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  delete market holidays 
export const DeleteMarketholiday = createSlice({
  name: "DeleteMarketholiday",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_DeleteMarketholiday: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_DeleteMarketholiday: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_DeleteMarketholiday: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_DeleteMarketholiday_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchDeleteMarketholiday_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default DeleteMarketholiday.reducer;
export const {
  delete_DeleteMarketholiday,
  success_api_call_delete_DeleteMarketholiday,
  failure_api_call_delete_DeleteMarketholiday,
  delete_DeleteMarketholiday_is_success,
  false_switchDeleteMarketholiday_response
} = DeleteMarketholiday.actions;
