import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for role list 
export const RoleList = createSlice({
  name: "RoleList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_RoleList: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_RoleList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_RoleList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    RoleList_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearRoleList: (state) => {
      state.data = [];
    },
  },
});
export default RoleList.reducer;
export const {
  list_RoleList,
  success_api_call_RoleList,
  failure_api_call_RoleList,
  RoleList_is_first,
  clearRoleList,
} = RoleList.actions;
