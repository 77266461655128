import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list  strategies
export const StrategiesList = createSlice({
  name: "StrategiesList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_StrategiesList: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_StrategiesList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_StrategiesList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    StrategiesList_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearStrategiesList: (state) => {
      state.data = [];
    },
  },
});
export default StrategiesList.reducer;
export const {
  list_StrategiesList,
  success_api_call_StrategiesList,
  failure_api_call_StrategiesList,
  clearStrategiesList,
  StrategiesList_is_first,
} = StrategiesList.actions;
