import { Backdrop, CircularProgress } from "@mui/material";
import { FunctionComponent } from "react";

interface isLoadingProps {
  isLoading: any;
}

const IsLoading: FunctionComponent<isLoadingProps> = (props) => {
  const { isLoading } = props;
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.snackbar + 1 }}
        open={isLoading}
      >
        <CircularProgress style={{ color: "#EF990F" }} />
       
      </Backdrop>
    </div>
  );
};

export default IsLoading; 
