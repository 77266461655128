import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for delete usercontrol
export const DeleteUsageControl = createSlice({
  name: "DeleteUsageControl",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_DeleteUsageControl: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_DeleteUsageControl: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_DeleteUsageControl: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_DeleteUsageControl_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchDeleteUsageControl_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default DeleteUsageControl.reducer;
export const {
  delete_DeleteUsageControl,
  success_api_call_delete_DeleteUsageControl,
  failure_api_call_delete_DeleteUsageControl,
  delete_DeleteUsageControl_is_success,
  false_switchDeleteUsageControl_response,
} = DeleteUsageControl.actions;
