import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import DialogRole from "../../Component/DialogForFormRole";
import RolesMessage from "./RolesMessage";
import {
  clearRoleList,
  list_RoleList,
} from "../../Redux/Reducers/Roles/list_Role_slicer";
import {
  add_AddRole,
  false_switchAddRole_response,
} from "../../Redux/Reducers/Roles/add_Role_slicer";
import {
  edit_EditRole,
  false_switchEditRole_response,
} from "../../Redux/Reducers/Roles/edit_Role_slicer";
import Dialogs from "../../Component/Dialog";
import {
  delete_DeleteRole,
  false_switchDeleteRole_response,
} from "../../Redux/Reducers/Roles/delete_Role_slicer";
import { false_switchRolevalues } from "../../Redux/Reducers/Datagrid-values/Role-values-slicer";
import { false_switchDeleteRolevalues } from "../../Redux/Reducers/Datagrid-values/DeleteRole-values-slicer";

interface RoleProps {}

const Role: FunctionComponent<RoleProps> = () => {
  const dispatcher = useAppDispatch();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Roles and Permission
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Role
    </Typography>,
  ];

  const [alignment, setAlignment] = React.useState(true);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const heading = (
    <ToggleButtonGroup
      color="primary"
      sx={{ marginRight: "10px" }}
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton
        value={true}
        title="Active Roles"
        sx={{
          textTransform: "capitalize",
          height: "39px",
          marginTop: "3px",
          width: {
            xs: 60,
            sm: 60,
            md: 60,
            lg: 75,
            xl: 80,
          },
        }}
      >
        Active
      </ToggleButton>
      <ToggleButton
        value={false}
        title="Inactive Roles"
        sx={{
          textTransform: "capitalize",
          height: "39px",
          marginTop: "3px",
          width: {
            xs: 60,
            sm: 60,
            md: 60,
            lg: 60,
            xl: 80,
          },
        }}
      >
        Inactive
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const data = useAppSelector((state) => state?.RoleList?.data);
  const addData: any = useAppSelector((state) => state?.AddRole?.data);
  const addDataIsLoading = useAppSelector((state) => state?.AddRole?.isLoading);
  const editData: any = useAppSelector((state) => state?.EditRole?.data);
  const editDataIsLoading = useAppSelector(
    (state) => state?.EditRole?.isLoading
  );
  const deleteData: any = useAppSelector((state) => state?.DeleteRole?.data);
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteRole?.isLoading
  );
  const isLoadingdata = useAppSelector((state) => state?.RoleList?.isLoading);
  const AddResponse = useAppSelector((state) => state.AddRole.switchResponse);
  const EditResponse = useAppSelector((state) => state.EditRole.switchResponse);
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteRole.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state?.DeleteRolevalues?.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state?.DeleteRolevalues?.switch
  );
  const Uservalues: any = useAppSelector((state) => state?.Rolevalues?.data);
  const switchOpen = useAppSelector((state) => state?.Rolevalues?.switch);

  const column = [
    {
      sortable: true,
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      field: "name",
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {params.row.name}
          </div>
        );
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <RolesMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></RolesMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    name: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(list_RoleList(alignment));
    if (EditResponse) {
      dispatcher(false_switchRolevalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteRolevalues());
    }
    return () => {
      dispatcher(clearRoleList());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponsealgodelete,
    EditResponse,
    alignment,
  ]);

  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAddRole_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const deletaction = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteRole_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const editaction = (
    <button
      onClick={() => {
        dispatcher(false_switchEditRole_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddRole_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddRole_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={EditResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditRole_response())}
        key={`top-right`}
        action={editaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditRole_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteRole_response())}
        key={`top-right`}
        action={deletaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteRole_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data}
        column={column}
        gridesbutton
        Users={heading}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        // getRowHeight={() => "auto"}
        pagination
        dialog={
          <DialogRole
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            getValues={(v: any) => {
              dispatcher(add_AddRole(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Role"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpen}
        setOpen={() => dispatcher(false_switchRolevalues())}
        Dialog_Content={
          Uservalues.is_enabled !== true
            ? "Do you want to de-activate the role ?"
            : "Do you want to activate the role ?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchRolevalues())}
            >
              No
            </Button>
            <Button
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditRole(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpenalgodelete}
        setOpen={() => dispatcher(false_switchDeleteRolevalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteRolevalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_DeleteRole(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Role;
