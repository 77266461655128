import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter order
export const filterOrder = createSlice({
  name: "filterOrder",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },

  reducers: {
    data_filterOrder: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filterOrder: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filterOrder: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filterOrder_is_success: (state) => {
      state.isSuccess = false;
    },
    filterOrder_complete_api: (state) => {
      state.api_completed = false;
    },
    clearfilterOrder: (state) => {
      state.data = [];
    },
  },
});
export default filterOrder.reducer;
export const {
  data_filterOrder,
  success_api_call_filterOrder,
  filterOrder_complete_api,
  failure_api_call_filterOrder,
  filterOrder_is_success,
  clearfilterOrder
} = filterOrder.actions;
