import * as React from "react";
import { Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import { ReactComponent as Editiconforic } from "../pages/svg_image/editforcard.svg";
import Dialog from "./DialogForFormaccount";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { edit_EditCard } from "../Redux/Reducers/Card_api/edit_Card_slicer";
import { ReactComponent as Menuicons } from "../pages/svg_image/Vector.svg";
import SuccessPopUpDelete from "./SuccessPopUpDelete";
import Warning from "../pages/svg_image/Waring.gif";
import { ReactComponent as Deleteiconforic } from "../pages/svg_image/Vector (1).svg";
import { useNavigate } from "react-router-dom";
import { list_Order } from "../Redux/Reducers/Order/list_Order_slicer";
import { list_DataOrder } from "../Redux/Reducers/Order/data_order_slicer";
import { list_Holdings } from "../Redux/Reducers/Holdings/list_Holdings_slicer";
import { list_DataHoldings } from "../Redux/Reducers/Holdings/data_Holdings_slicer";

interface MenuListprops {
  editdataid?: any;
}
const MenuList: React.FunctionComponent<MenuListprops> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useAppDispatch();
  const pageName = "Accounts";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];
  const editData = useAppSelector((state) => state.EditCard.data);
  const editDataIsLoading = useAppSelector((state) => state.EditCard.isLoading);
  const navigate = useNavigate();
  return (
    <div>
      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ cursor: "pointer", padding: "12px" }}
      >
        <Menuicons></Menuicons>
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {MenuOfUserpermission?.permission?.view ? (
          <MenuItem style={{ padding: "0px" }}>
            <span
              style={{
                padding: "10px",
                textTransform: "capitalize",
                color: "#545454",
              }}
              onClick={() => {
                navigate("/orderandholding");
                dispatch(list_Order(props.editdataid));
                dispatch(
                  list_DataOrder({
                    client_id: props.editdataid.client_id,
                    id: props.editdataid.id,
                    account_name: props.editdataid.account_name,
                  })
                );
                dispatch(list_Holdings(props.editdataid));
                dispatch(
                  list_DataHoldings({
                    client_id: props.editdataid.client_id,
                    id: props.editdataid.id,
                  })
                );
                localStorage.setItem("client_id", props.editdataid.client_id);
                localStorage.setItem("id", props.editdataid.id);
                localStorage.setItem(
                  "account_name",
                  props.editdataid.account_name
                );
              }}
            >
              Orders & Holdings
            </span>
          </MenuItem>
        ) : (
          <MenuItem style={{ padding: "0px" }}>
            <span
              style={{
                padding: "10px",
                textTransform: "capitalize",
                color: "#545454",
                cursor: "not-allowed",
              }}
            >
              Orders & Holdings
            </span>
          </MenuItem>
        )}
        <Divider className="no-margin-divider" />

        {MenuOfUserpermission?.permission?.edit ? (
          <>
            <MenuItem style={{ padding: "0px" }}>
              <Dialog
                successMessage={editData}
                isLoading={editDataIsLoading}
                Buttondialog={["edit"]}
                Dialogtitle={"Edit Account"}
                getValues={(v: any) => {
                  dispatch(edit_EditCard(v));
                }}
                placeholder=""
                sx={{
                  minWidth: "0 !important",
                  padding: "6px",
                  height: "60%",
                  width: "100%",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                edit={props.editdataid}
                Buttonname={
                  <Tooltip title="Edit" arrow>
                    <span>
                      <div
                        style={{
                          transform: "scale(1.2)",
                          display: "inline-block",
                          marginTop: "4px",
                        }}
                      >
                        <Editiconforic style={{ color: "#545454" }} />
                      </div>
                      <span
                        style={{
                          padding: "7px",
                          textTransform: "capitalize",
                          color: "#545454",
                        }}
                      >
                        Edit
                      </span>
                    </span>
                  </Tooltip>
                }
              />
            </MenuItem>
            <Divider className="no-margin-divider" />
          </>
        ) : (
          <>
            <MenuItem onClick={handleClose}>
              <Tooltip title="Edit" arrow>
                <div
                  style={{
                    height: "60%",
                    width: "100%",
                    cursor: "not-allowed",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      transform: "scale(1.1)",
                      display: "inline-block",
                    }}
                  >
                    <Editiconforic />
                  </div>

                  <span
                    style={{
                      marginLeft: "10px",
                      textTransform: "capitalize",
                      color: "#545454",
                    }}
                  >
                    Edit
                  </span>
                </div>
              </Tooltip>
            </MenuItem>
            <Divider className="no-margin-divider" />
          </>
        )}

        {MenuOfUserpermission?.permission?.delete ? (
          <MenuItem
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SuccessPopUpDelete
              subtitle={"Warning"}
              Dialog_Title={
                <img src={Warning} alt="Success..." height="100px" />
              }
              button={
                <Tooltip title="Delete" arrow>
                  <>
                    <div
                      style={{
                        transform: "scale(1.2)",
                        display: "inline-block",
                      }}
                    >
                      <Deleteiconforic
                        style={{ color: "rgb(255, 86, 48)" }}
                      ></Deleteiconforic>
                    </div>
                    <span
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        color: "rgb(255, 86, 48)",
                        fontSize: "15px",
                      }}
                    >
                      Delete
                    </span>
                  </>
                </Tooltip>
              }
              dialogContentText={"Do you want to delete?"}
              successButtonName={props.editdataid.id}
              errorButtonName={<div className="cancel-button">No</div>}
            />
          </MenuItem>
        ) : (
          <MenuItem
            style={{
              padding: "6px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Delete" arrow>
              <div
                style={{
                  height: "60%",
                  cursor: "not-allowed",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    transform: "scale(1.2)",
                    display: "inline-block",
                    // display: "flex",
                  }}
                >
                  <Deleteiconforic
                    style={{ color: "rgb(255, 86, 48)" }}
                  ></Deleteiconforic>
                </div>
                <span
                  style={{
                    marginLeft: "5px",
                    textTransform: "capitalize",
                    color: "rgb(255, 86, 48)",
                    fontSize: "15px",
                  }}
                >
                  Delete
                </span>
              </div>
            </Tooltip>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
export default MenuList;
