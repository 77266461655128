import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_TradeHistory,
  success_api_call_TradeHistory,
} from "./list_TradeHistory_slicer";

//list trade history
const listTradeHistoryCall = async (a: any) => {
  let list = {};
  if (a.payload.is_enabled !== "") {
    list = { is_enabled: a.payload.is_enabled };
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/strategy/`, list, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* TradeHistoryApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listTradeHistoryCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_TradeHistory(res));
  } catch (e) {
    yield put(failure_api_call_TradeHistory(e));
    yield put(expireSignature(e));
  }
}
