import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  FormHelperText,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { clearStrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import { clearStock } from "../Redux/Reducers/Stock/list_Stock_slicer";
import Multiselect from "./Multiselect";
import CancelIcon from "@mui/icons-material/Cancel";
import { list_AddorEditList } from "../Redux/Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
import { list_AccountList } from "../Redux/Reducers/Card_api/list_slicer";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogGroupaccount: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { getValues, isLoading }: any = props;
  const account: any = useAppSelector((state) => state?.AccountList?.data);
  console.log(account);
  const dispatcher = useAppDispatch();

  React.useEffect(() => {
    dispatcher(list_AccountList(""));
  }, [dispatcher]);

  const initialValues: {
    groupname: string;
    Account: { label: string; value: string }[];
  } = {
    groupname: props?.edit?.name || "",

    Account: props?.edit?.accounts?.map((editvalue: any) => {
      return {
        label: `${editvalue.broker},${editvalue.client_id}`,
        value: editvalue.id,
      };
    }),
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatcher(clearStock());
    dispatcher(clearStrategiesList());
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const pageName = "View User";
  const menuList = JSON.parse(sessionStorage?.getItem("permission") as string);
  let MenuOfUserpermission: any;

  const foundPage = menuList
    .map((data: any) =>
      data.sub_menus.find((child: any) => child.name === pageName)
    )
    .filter((page: any) => page);

  if (foundPage.length > 0) {
    MenuOfUserpermission = foundPage[0];
  } else {
    const defaultPageName = "Accounts";
    const index = menuList.findIndex(
      (item: any) => item.name === defaultPageName
    );

    if (index !== -1) {
      MenuOfUserpermission = menuList[index];
    }
  }

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        disabled={
          MenuOfUserpermission?.permission?.add === false ||
          MenuOfUserpermission?.permission?.edit === false
        }
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: "5px",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minWidth: "200px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              groupname: Yup.string().required("Please enter the group name"),
              Account: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .min(1, "Please select a segment")
                .required("Please select a segment"),
            })}
            onSubmit={(values) => {
              getValues(values);
              handleClose();
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div style={{ paddingBottom: "20px", height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Group Name <span style={{ color: "red" }}> *</span>
                    </div>
                    {/* Specific fields with validation */}
                    <div>
                      <TextField
                        id="outlined-basic"
                        // required
                        variant="outlined"
                        name="groupname"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={
                          !!(props.errors.groupname && props.touched.groupname)
                        }
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (
                            /^[A-Za-z0-9\s]+$/.test(inputValue) &&
                            inputValue.length < 31
                          ) {
                            props.setFieldValue("groupname", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "groupname",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.groupname}
                        style={{
                          width: "25rem",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                      />

                      {props.errors.groupname && props.touched.groupname ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please enter a group name"}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ height: "110px" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                      }}
                    >
                      Select Account <span style={{ color: "red" }}>*</span>
                    </div>
                    <Multiselect
                      options={account?.data?.map((v: any) => {
                        return {
                          label: `${v.broker},${v.client_id}`,
                          value: v.id,
                        };
                      })}
                      name={"Account"}
                      value={props.values.Account}
                      onBlur={props.handleBlur}
                      getValue={(value: any) => {
                        props.setFieldValue("Account", value);
                      }}
                      sx={{
                        width: "25rem",

                        borderRadius: "0",
                      }}
                      inputSx={{
                        borderRadius: "0",
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                      }}
                      helperText={
                        props.errors.Account &&
                        props.touched.Account &&
                        props.errors.Account
                      }
                      error={!!(props.errors.Account && props.touched.Account)}
                    />
                  </div>
                  <div>
                    {" "}
                    <DialogActions
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        style={{
                          width: "100px",
                          height: "40px",
                          border: "1px solid #EF990F",
                          color: "black",
                          marginRight: "1%",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          fontFamily: "Roboto",
                          textTransform: "capitalize",
                          fontSize: "15px",
                        }}
                      >
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        style={{
                          width: "100px",
                          height: "40px",
                          color: "black",
                          background: !props.isValid
                            ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                            : "linear-gradient(to right, #FFFA02,#F2A618 )",

                          borderRadius: "5px",
                          fontFamily: "Roboto",
                          fontSize: "15px",
                        }}
                      >
                        Save
                      </button>
                    </DialogActions>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogGroupaccount;
