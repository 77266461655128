import React, { FunctionComponent, memo, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "./AddAlgocss.css";
import Autocomplete from "../../Component/Autocomplete";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../Redux/store/hooks";
import PopupMessage from "../../Component/PopupMessage";
import { useDispatch } from "react-redux";
import { add_AddAlgo } from "../../Redux/Reducers/Algo-api/add_Algo_slicer";
import { edit_EditAlgo } from "../../Redux/Reducers/Algo-api/edit_Algo_slicer";
import { updata_preValue } from "../../Redux/Reducers/Algo-api/editableValuAlgo";
import { success_api_call_AlgoDymics } from "../../Redux/Reducers/AlgoDymics";
import { useLocation } from "react-router-dom";

interface IAddAlgo {
  edit?: any;
  successMessage?: any;
}
const AddAlgo: FunctionComponent<IAddAlgo> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataPassed = location.state?.editoraddalgo;
  const datapassed_to_check = location.state?.Tradecheck;
  const pageName = "Add Algo";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];

  const dispatch = useDispatch();

  const Algodymics = useAppSelector((state) => state?.AlgoDymics?.data);
  const { preValue } = useAppSelector((state) => state?.EditableValueAlgo);
  const addisLoading = useAppSelector((state) => state?.AddAlgo?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddAlgo?.data);
  const editisLoading = useAppSelector((state) => state?.EditAlgo?.isLoading);
  const editData: any = useAppSelector((state) => state?.EditAlgo?.data);
  const editvalue: any = useAppSelector(
    (state) => state?.EditableValueAlgo?.data
  );
  const combinedData = Algodymics;

  // Assuming that Algodymics is an array with the expected structure
  const initialValues: { [key: string]: any } = {
    name: preValue.name ?? (editvalue?.name || ""),
    is_enabled: preValue.is_enabled ?? editvalue?.is_enabled ?? true,
    compainedData:
      // editvalue?.compainedData?.length === 0
      combinedData ?? [],
    // : editvalue?.compainedData ?? [],
    // Set touched property for fields you want to validate initially
    ...combinedData.reduce((values: any, field: any) => {
      values[field.key] = field.value || "";
      // Set touched property for dynamic fields you want to validate initially
      values[`${field.key}_touched`] = true; // Set it to true to indicate that it has been touched
      return values;
    }, {}),
  };

  const specificValidation = {
    name: Yup.string().required("Please enter algo name"),
    is_enabled: Yup.boolean().required("Please select the status"),
  };

  const getValidationSchemaForField = (field: any) => {
    let fieldValidation;
    if (field.unit === "inputfield" && field.inputType === "text") {
      fieldValidation = Yup.string().matches(
        /^[A-Za-z\s]+$/,
        "Please enter only text characters are allowed"
      );
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required("Please enter text field");
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      }
    } else if (
      field.unit === "inputfield" &&
      field.inputType === "textnumber"
    ) {
      fieldValidation = Yup.string().matches(
        /^[A-Za-z0-9\s]+$/,
        "Please enter only text and number  are allowed"
      );

      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required(
          "Please enter text & number field"
        );
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_length !== null &&
          field.min_length !== undefined &&
          field.min_length !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_length,
            `Please enter minimum length is ${field.min_length}`
          );
        }
        if (
          field.max_length !== null &&
          field.max_length !== undefined &&
          field.max_length !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_length,
            `Please enter maximum length is ${field.max_length}`
          );
        }
      }
    } else if (field.unit === "inputfield" && field.inputType === "number") {
      fieldValidation = Yup.number();

      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required("Please enter number field");
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_value,
            `Please enter minimum value is ${field.min_value}`
          );
        }
        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_value,
            `Please enter maximum value is ${field.max_value}`
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.min(
            field.min_value,
            `Please enter minimum value is ${field.min_value}`
          );
        }
        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.max(
            field.max_value,
            `Please enter maximum value is ${field.max_value}`
          );
        }
      }
    } else if (
      field.unit === "inputfield" &&
      field.inputType === "number_decimal"
    ) {
      fieldValidation = Yup.string().matches(
        /^[0-9]+(?:\.[0-9]+)?$/,
        "Please enter a valid number"
      );

      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = fieldValidation.required(
          "Please enter number decimal"
        );
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "min-value",
            `Please enter a value greater than or equal to ${field.min_value}`,
            (value) => parseFloat(value) >= parseFloat(field.min_value)
          );
        }

        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "max-value",
            `Please enter a value less than or equal to ${field.max_value}`,
            (value) => parseFloat(value) <= parseFloat(field.max_value)
          );
        }
      } else if (
        field.is_mandatory === "false" ||
        field.is_mandatory === false
      ) {
        if (
          field.min_value !== null &&
          field.min_value !== undefined &&
          field.min_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "min-value",
            `Please enter a value greater than or equal to ${field.min_value}`,
            (value: any) => parseFloat(value) >= parseFloat(field.min_value)
          );
        }

        if (
          field.max_value !== null &&
          field.max_value !== undefined &&
          field.max_value !== ""
        ) {
          fieldValidation = fieldValidation.test(
            "max-value",
            `Please enter a value less than or equal to ${field.max_value}`,
            (value: any) => parseFloat(value) <= parseFloat(field.max_value)
          );
        }
      }
    } else if (field.unit === "drop_down") {
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = Yup.string().required("Please select an option");
      }
    } else if (field.unit === "multi_select_dropdown") {
      if (field.is_mandatory === true || field.is_mandatory === "true") {
        fieldValidation = Yup.array()
          .min(1, "Please select at least one option")
          .required("Please select at least one option");
      }
    }

    return fieldValidation;
  };

  const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
      ...specificValidation, // Add your specific validation rules
      ...combinedData.reduce((schema: any, field: any) => {
        // Use Yup.string() for untouched fields
        schema[field.key] =
          field.key in values
            ? getValidationSchemaForField(field)
            : Yup.string();
        return schema;
      }, {}),
    });
  });

  const handleApiCall = async (values: any) => {
    try {
      if (dataPassed === "edit") {
        let formdata = combinedData.map((dyanamic_form_content: any) => {
          const key = dyanamic_form_content.key;
          const valueFromValues = values[key];
          if (valueFromValues !== undefined) {
            return {
              ...dyanamic_form_content,
              value: valueFromValues,
            };
          }
          return dyanamic_form_content;
        });

        const api_data = {
          is_enabled: values.is_enabled,
          name: values.name,
          meta_data: formdata,
        };

        dispatch(edit_EditAlgo(api_data));
      } else {
        let formdata = combinedData.map((dyanamic_form_content: any) => {
          const key = dyanamic_form_content.key;
          const valueFromValues = values[key];
          if (valueFromValues !== undefined) {
            return {
              ...dyanamic_form_content,
              value: valueFromValues,
            };
          }

          return dyanamic_form_content;
        });

        const api_data = {
          is_enabled: values.is_enabled,
          name: values.name,
          meta_data: formdata,
        };
        dispatch(add_AddAlgo(api_data));
      }
    } catch (error) {}
  };

  const [editDialog, seteditDialog] = React.useState(0);
  const editOpen = () => {
    seteditDialog(editDialog + 0.001);
  };

  const formik = useRef<any>(null);
  let isFieldEnabled = false;
  if (MenuOfUserpermission?.permission?.add) {
    isFieldEnabled = true;
  }
  if (dataPassed === "edit" && MenuOfUserpermission?.permission?.edit) {
    isFieldEnabled = true;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        editOpen();
      }}
      innerRef={formik}
    >
      {(props) => {
        dispatch(success_api_call_AlgoDymics(props.values.compainedData));
        dispatch(updata_preValue(props.values));
        return (
          <Form id="form" autoComplete="off">
            <div className="addalgo">
              <div style={{ height: "110px", paddingBottom: "20px" }}>
                <div
                  style={{
                    color: "#000000",
                    paddingBottom: "5px",
                    fontSize: "15px",
                    fontFamily: "Roboto",
                  }}
                >
                  Algo Name <span style={{ color: "red" }}> *</span>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    // required
                    variant="outlined"
                    disabled={!isFieldEnabled || datapassed_to_check === true}
                    name="name"
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                    }}
                    autoComplete="off"
                    error={!!(props.errors.name && props.touched.name)}
                    onChange={(e: any) => {
                      const inputValue = e.target.value;
                      // Use regex to check if the input contains only characters (letters)
                      if (
                        /^[A-Za-z0-9\s]+$/.test(inputValue) &&
                        inputValue.length < 31
                      ) {
                        props.setFieldValue("name", inputValue);
                      } else {
                        // If validation fails, remove the last character from the input value
                        props.setFieldValue("name", inputValue.slice(0, -1));
                      }
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    style={{
                      width: "100%",
                      height: "50px",
                      margin: "0px 0px 0px",
                    }}
                  />

                  {props.errors.name && props.touched.name ? (
                    <FormHelperText
                      style={{ marginLeft: "13px", marginTop: "10px" }}
                      error
                    >
                      {"Please enter a algo name"}
                    </FormHelperText>
                  ) : null}
                </div>
              </div>
              <div style={{ paddingBottom: "20px", height: "110px" }}>
                <div
                  style={{
                    color: "#000000",
                    paddingBottom: "5px",
                    fontSize: "15px",
                    fontFamily: "Roboto",
                  }}
                >
                  Status <span style={{ color: "red" }}> *</span>
                </div>
                <Autocomplete
                  Listitem={[
                    { label: "Active", value: true },
                    { label: "InActive", value: false },
                  ]}
                  variant={"outlined"}
                  disabled={!isFieldEnabled || datapassed_to_check === true}
                  name={"is_enabled"}
                  value={props.values}
                  onBlur={props.handleBlur}
                  onchange={(e: any) => {
                    props.setFieldValue("is_enabled", e.value);
                  }}
                  style={{
                    paddingBottom: "27px",
                    width: "100%",
                    height: "50px",
                    borderBottom: "50px",
                  }}
                  error={props.errors.is_enabled && props.touched.is_enabled}
                ></Autocomplete>

                {props.errors.is_enabled && props.touched.is_enabled ? (
                  <FormHelperText
                    style={{ marginLeft: "13px", marginTop: "10px" }}
                    error
                  >
                    {"Please select the status"}
                  </FormHelperText>
                ) : null}
              </div>
              {props.values.compainedData.map((field: any, index: number) => {
                return (
                  <div key={field.key}>
                    {field.unit === "inputfield" && (
                      <div style={{ paddingBottom: "20px", height: "110px" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          {field.name}
                          {(field.is_mandatory === true ||
                            field.is_mandatory === "true") && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </div>
                        <Field
                          name={field.key}
                          disabled={
                            !isFieldEnabled || datapassed_to_check === true
                          }
                          type={
                            field.inputType === "number_decimal"
                              ? "text"
                              : field.inputType === "number"
                              ? "number"
                              : "text"
                          }
                          label={field.label}
                          onBlur={props.handleBlur}
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "50px",
                            padding: "26px 26px 26px 15px",
                            border: "1px solid #afafaf",
                            borderRadius: "5px",
                            borderColor:
                              props.errors[field.key] &&
                              props.touched[field.key]
                                ? "#d94242"
                                : "#BCBFBF",
                          }}
                          value={field.value}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const { value } = e.target;
                            if (
                              field.inputType === "number_decimal" &&
                              (/^\d*\.?\d*$/.test(value) || value === "")
                            ) {
                              props.setFieldValue(field.key, value);
                              const copyState = props.values.compainedData;
                              const test = copyState.map(
                                (val: any, copyIndex: number) => {
                                  if (index === copyIndex) {
                                    return { ...val, value: value };
                                  } else {
                                    return { ...val };
                                  }
                                }
                              );
                              props.setFieldValue("compainedData", test);
                            } else if (
                              field.inputType === "number" &&
                              (/^\d+$/.test(value) || value === "")
                            ) {
                              props.setFieldValue(field.key, value);
                              const copyState = props.values.compainedData;
                              const test = copyState.map(
                                (val: any, copyIndex: number) => {
                                  if (index === copyIndex) {
                                    return { ...val, value: value };
                                  } else {
                                    return { ...val };
                                  }
                                }
                              );
                              props.setFieldValue("compainedData", test);
                            } else if (field.inputType === "text") {
                              props.setFieldValue(field.key, value);
                              const copyState = props.values.compainedData;
                              const test = copyState.map(
                                (val: any, copyIndex: number) => {
                                  if (index === copyIndex) {
                                    return { ...val, value: value };
                                  } else {
                                    return { ...val };
                                  }
                                }
                              );
                              props.setFieldValue("compainedData", test);
                            } else if (field.inputType === "textnumber") {
                              props.setFieldValue(field.key, value);
                              const copyState = props.values.compainedData;
                              const test = copyState.map(
                                (val: any, copyIndex: number) => {
                                  if (index === copyIndex) {
                                    return { ...val, value: value };
                                  } else {
                                    return { ...val };
                                  }
                                }
                              );
                              props.setFieldValue("compainedData", test);
                            }
                          }}
                        />

                        {props.errors[field.key] && props.touched[field.key] ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors[field.key] as string}{" "}
                          </FormHelperText>
                        ) : null}
                      </div>
                    )}
                    {field.unit === "drop_down" && (
                      <div style={{ paddingBottom: "20px", height: "110px" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          {field.name}
                          {(field.is_mandatory === true ||
                            field.is_mandatory === "true") && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </div>

                        <FormControl
                          variant="outlined"
                          style={{
                            width: "100%",
                            border: "1px solid #afafaf",
                            borderRadius: "5px",

                            borderColor:
                              props.errors[field.key] &&
                              props.touched[field.key]
                                ? "#d94242"
                                : "#BCBFBF",
                          }}
                        >
                          <InputLabel>{field.label}</InputLabel>
                          <Select
                            disabled={
                              !isFieldEnabled || datapassed_to_check === true
                            }
                            name={field.key} // Use field.key as the name
                            value={field.value} // Use props.values[field.key] to get the value
                            onBlur={props.handleBlur}
                            onChange={(e) => {
                              props.setFieldValue(field.key, e.target.value);
                              const copyState = props.values.compainedData;
                              const test = copyState.map(
                                (val: any, copyIndex: number) => {
                                  if (index === copyIndex) {
                                    return {
                                      ...val,
                                      value: e.target.value,
                                    };
                                  } else {
                                    return { ...val };
                                  }
                                }
                              );
                              props.setFieldValue("compainedData", test);

                              // Set the value using field.key
                            }}
                            label={field.label}
                            // defaultValue={field.value}
                          >
                            {field.options.map((option: any) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {props.errors[field.key] && props.touched[field.key] ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors[field.key] as string}{" "}
                          </FormHelperText>
                        ) : null}
                      </div>
                    )}

                    {field.unit === "multi_select_dropdown" && (
                      <div style={{ paddingBottom: "20px", height: "110px" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          {field.name}

                          {(field.is_mandatory === true ||
                            field.is_mandatory === "true") && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </div>
                        <FormControl
                          variant="outlined"
                          style={{
                            width: "100%",
                            border: "1px solid #afafaf",
                            borderRadius: "5px",

                            borderColor:
                              props.errors[field.key] &&
                              props.touched[field.key]
                                ? "#d94242"
                                : "#BCBFBF",
                          }}
                        >
                          <Select
                            disabled={
                              !isFieldEnabled || datapassed_to_check === true
                            }
                            name={field.key} // Use field.key as the name
                            multiple // Enable multiple selection
                            value={field.value || []} // Use props.values[field.key] to get the value
                            onBlur={props.handleBlur}
                            onChange={(e) => {
                              props.setFieldValue(field.key, e.target.value);
                              const copyState = props.values.compainedData;
                              const test = copyState.map(
                                (val: any, copyIndex: number) => {
                                  if (index === copyIndex) {
                                    return {
                                      ...val,
                                      value: e.target.value,
                                    };
                                  } else {
                                    return { ...val };
                                  }
                                }
                              );
                              props.setFieldValue("compainedData", test);

                              // Set the value using field.key
                            }}
                            // defaultValue={field.value || []}
                            renderValue={(selected: any) => (
                              <div>
                                {selected.map((value: any) => (
                                  <span key={value}>{value},</span>
                                ))}
                              </div>
                            )}
                          >
                            {field.options.map((option: any) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {props.errors[field.key] && props.touched[field.key] ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors[field.key] as string}{" "}
                          </FormHelperText>
                        ) : null}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                onClick={() => {
                  navigate("/viewalgo");
                  localStorage.removeItem("editalgodatamassage");
                }}
                style={{
                  width: "100px",
                  height: "40px",
                  border: "1px solid #EF990F",
                  color: "black",
                  marginRight: "1%",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  fontFamily: "Roboto",
                }}
              >
                Cancel
              </button>

              <button
                type="submit"
                disabled={!isFieldEnabled}
                style={{
                  width: "100px",
                  height: "40px",
                  color: "black",
                  background: !props.isValid
                    ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                    : "linear-gradient(to right, #FFFA02,#F2A618 )",

                  borderRadius: "5px",
                  fontFamily: "Roboto",
                }}
              >
                Save
              </button>
            </div>
            <PopupMessage
              subtitle={"Warning"}
              successMessage={
                Object.keys(editvalue).length !== 0 ? editData : addData
              }
              open={editDialog}
              pathnavigate={"/viewalgo"}
              Dialogtitle={"Are you sure you want to proceed?"}
              submitFunction={() => handleApiCall(props.values)}
              isLoading={
                Object.keys(editvalue).length !== 0
                  ? editisLoading
                  : addisLoading
              }
            />
          </Form>
        );
      }}
    </Formik>
    // </div>
  );
};

export default memo(AddAlgo);
