import { FunctionComponent } from "react";
import { useAppDispatch } from "../../Redux/store/hooks";
import { Square_off } from "../../Redux/Reducers/Square-off/Square-off_slicer";
import { Button } from "@mui/material";

interface ActionProps {
  valuepopup: number;
  editdata: any;
}

const Action_For_manual: FunctionComponent<ActionProps> = (props: any) => {
  const dispatcher = useAppDispatch();

  return (
    <Button
      style={{
        color: "white",
        width: "50px",
        height: "20px",
        fontFamily: "Roboto",
        fontSize: "10px",
        borderRadius: "5px",
        backgroundColor:
          props?.editdata?.order_type === "SELL" ? "green" : "#E10000",
      }}
      onClick={() => {
        dispatcher(
          Square_off({
            strategy_id: props.editdata?.id,
          })
        );
      }}
    >
      {props?.editdata?.order_type === "SELL" ? "BUY" : "SELL"}
    </Button>
  );
};

export default Action_For_manual;
