import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   TreadSetupvalues
export const TreadSetupvalues = createSlice({
  name: "TreadSetupvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    TreadSetupvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_TreadSetupvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_TreadSetupvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    TreadSetupvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_TreadSetupvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchTreadSetupvalues:(state)=>{
        state.switch = false
    }
  },
});
export default TreadSetupvalues.reducer;
export const {
  TreadSetupvalues_datagrid,
  success_api_call_TreadSetupvalues_datagrid,
  failure_api_call_TreadSetupvalues_datagrid,
  create_TreadSetupvalues_datagrid_complete_api,
  TreadSetupvalues_datagrid_is_success,false_switchTreadSetupvalues
} = TreadSetupvalues.actions;
