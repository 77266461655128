import { takeEvery } from "redux-saga/effects";
import { LoginApi } from "../Reducers/User-Register&Login/User_API";
import {
  AddAdminApi,
  editAdminApi,
  listAdminApi,
  listApi,
} from "../Reducers/Admin-api/Admin_api";
import {
  AddCardApi,
  deleteCardApi,
  editCardApi,
  editCheckboxApi,
  editSwichApi,
  listCardApi,
} from "../Reducers/Card_api/Card_api";
import { TokenApi } from "../Reducers/Token/Token_api";
import {
  AddAlgoApi,
  deletealgoApi,
  editAlgoApi,
  listAlgoApi,
} from "../Reducers/Algo-api/Algo_api";
import {
  AddStrategiesApi,
  deleteStrategiesApi,
  editStrategiesApi,
  listStrategiesApi,
} from "../Reducers/Strategies_api/Strategies_api";
import {
  TradeListApi,
  TradeListGroupApi,
} from "../Reducers/Trade-details/Trade_api";
import { TradeHistoryApi } from "../Reducers/Trade-History/TradeHistory_api";
import {
  AddOpenPositionApi,
  DeleteOpenPositionApi,
  EditOpenPositionApi,
  LiveTradeApi,
  OpenPositionListApi,
} from "../Reducers/Open-Position/LiveTrade_api";
import {
  AddMapStrategiesApi,
  deleteMapStrategiesApi,
  editMapStrategiesApi,
  listMapStrategiesApi,
} from "../Reducers/MapStrategies/MapStrategies_api";
import {
  StockApi,
  DeleteStockApi,
  editEditStockApi,
  AddStockApi,
} from "../Reducers/Stock/Stock_api";
import {
  AddTreadSetupApi,
  deleteTreadSetupApi,
  editTreadSetupApi,
  listAddorEditApi,
  listTreadSetupApi,
} from "../Reducers/Trade-setup/Trade-setup_api";
import { LoginforprofileListApi } from "../Reducers/Login.api/LoginforprofileList_api";
import { SquareoffApi } from "../Reducers/Square-off/Square-off_api";
import {
  AddMarketholidayApi,
  DeleteMarketholidayApi,
  MarketholidayListApi,
} from "../Reducers/Marketholiday/Marketholiday_api";
import {
  AddGroupAccountApi,
  DeleteGroupAccountApi,
  EditGroupAccountApi,
  ListGroupAccountApi,
} from "../Reducers/GroupAccount/GroupAccount_api";
import {
  AddMenuApi,
  DeleteMenuApi,
  EditMenuApi,
  MenuListApi,
  MenuListFilterApi,
} from "../Reducers/Menu/Menu_api";
import {
  AddRoleApi,
  DeleteRoleApi,
  EditRoleApi,
  RoleListApi,
} from "../Reducers/Roles/Role_api";
import {
  Edit_UserpermissionApi,
  User_permissionApi,
  User_permissionMenuListApi,
} from "../Reducers/User_permission/User_permission_api";
import {
  AddUsageControlApi,
  DeleteUsageControlApi,
  EditUsageControlApi,
  ListUsageControlApi,
} from "../Reducers/UsageControl/UsageControl_api";
import { OrderApi } from "../Reducers/Order/Order_api";
import { HoldingsApi } from "../Reducers/Holdings/Holdings_api";
import {
  DeleteBacktestApi,
  addbacktestApi,
  backtestApi,
} from "../Reducers/back-test/backtest_api";
import { backreportApi } from "../Reducers/back-report/backreport_api";

function* Saga() {
  //  login
  yield takeEvery("Login/login", LoginApi);
  yield takeEvery("Tokens/Token", TokenApi);
  //    Admin
  yield takeEvery("List/list_List", listApi);
  yield takeEvery("AdminList/list_AdminList", listAdminApi);
  yield takeEvery("EditAdmin/edit_EditAdmin", editAdminApi);
  yield takeEvery("AddAdmin/add_AddAdmin", AddAdminApi);
  // squar-off
  yield takeEvery("Squareoff/Square_off", SquareoffApi);
  //  Trade
  yield takeEvery("TradeListGroup/list_TradeListGroup", TradeListGroupApi);
  yield takeEvery("TradeList/list_TradeList", TradeListApi);
  // profilelogin
  yield takeEvery(
    "LoginforprofileList/list_LoginforprofileList",
    LoginforprofileListApi
  );
  // trade history
  yield takeEvery("TradeHistory/list_TradeHistory", TradeHistoryApi);
  // back test

  yield takeEvery("backtest/list_backtest", backtestApi);
  yield takeEvery("addbacktest/add_addbacktest", addbacktestApi);
  yield takeEvery("Deletebacktest/delete_backtest", DeleteBacktestApi);
  //back report
  yield takeEvery("backreport/list_backreport", backreportApi);

  // open position
  yield takeEvery(
    "DeleteOpenPosition/delete_DeleteOpenPosition",
    DeleteOpenPositionApi
  );
  yield takeEvery(
    "EditOpenPosition/edit_EditOpenPosition",
    EditOpenPositionApi
  );
  yield takeEvery(
    "OpenPositionList/list_OpenPositionList",
    OpenPositionListApi
  );
  yield takeEvery("LiveTrade/list_LiveTrade", LiveTradeApi);
  yield takeEvery("AddOpenPosition/add_AddOpenPosition", AddOpenPositionApi);
  //order
  yield takeEvery("Order/list_Order", OrderApi);
  // holdings
  yield takeEvery("Holdings/list_Holdings", HoldingsApi);
  // Algo
  yield takeEvery("AlgoList/list_AlgoList", listAlgoApi);
  yield takeEvery("EditAlgo/edit_EditAlgo", editAlgoApi);
  yield takeEvery("AddAlgo/add_AddAlgo", AddAlgoApi);
  yield takeEvery("DeleteAlgo/delete_algo", deletealgoApi);
  // Role
  yield takeEvery("RoleList/list_RoleList", RoleListApi);
  yield takeEvery("EditRole/edit_EditRole", EditRoleApi);
  yield takeEvery("AddRole/add_AddRole", AddRoleApi);
  yield takeEvery("DeleteRole/delete_DeleteRole", DeleteRoleApi);
  // Menu
  yield takeEvery("MenuListFilter/list_MenuListFilter", MenuListFilterApi);
  yield takeEvery("MenuList/list_MenuList", MenuListApi);
  yield takeEvery("EditMenu/edit_EditMenu", EditMenuApi);
  yield takeEvery("AddMenu/add_AddMenu", AddMenuApi);
  yield takeEvery("DeleteMenu/delete_DeleteMenu", DeleteMenuApi);
  // user_permission
  yield takeEvery(
    "User_permissionMenuList/list_User_permissionMenuList",
    User_permissionMenuListApi
  );
  yield takeEvery("User_permission/list_User_permission", User_permissionApi);
  yield takeEvery(
    "Edit_Userpermission/edit_Userpermission",
    Edit_UserpermissionApi
  );
  // group accound
  yield takeEvery(
    "ListGroupAccount/list_ListGroupAccount",
    ListGroupAccountApi
  );
  yield takeEvery(
    "EditGroupAccount/edit_EditGroupAccount",
    EditGroupAccountApi
  );
  yield takeEvery("AddGroupAccount/add_AddGroupAccount", AddGroupAccountApi);
  yield takeEvery(
    "DeleteGroupAccount/delete_DeleteGroupAccount",
    DeleteGroupAccountApi
  );
  // switch
  yield takeEvery("EditSwitch/edit_EditSwitch", editSwichApi);
  yield takeEvery("EditCheckbox/edit_EditCheckbox", editCheckboxApi);
  // stock
  yield takeEvery("AddStock/add_AddStock", AddStockApi);
  yield takeEvery("EditStock/edit_EditStock", editEditStockApi);
  yield takeEvery("Stock/list_Stock", StockApi);
  yield takeEvery("DeleteStock/delete_DeleteStock", DeleteStockApi);
  // map
  yield takeEvery("AddorEditList/list_AddorEditList", listAddorEditApi);
  yield takeEvery("TreadSetupList/list_TreadSetupList", listTreadSetupApi);
  yield takeEvery("EditTreadSetup/edit_EditTreadSetup", editTreadSetupApi);
  yield takeEvery("AddTreadSetup/add_AddTreadSetup", AddTreadSetupApi);
  yield takeEvery("DeleteTreadSetup/delete_TreadSetup", deleteTreadSetupApi);
  // Usage control
  yield takeEvery(
    "ListUsageControl/list_ListUsageControl",
    ListUsageControlApi
  );
  yield takeEvery(
    "EditUsageControl/edit_EditUsageControl",
    EditUsageControlApi
  );
  yield takeEvery("AddUsageControl/add_AddUsageControl", AddUsageControlApi);
  yield takeEvery(
    "DeleteUsageControl/delete_DeleteUsageControl",
    DeleteUsageControlApi
  );
  // Market holiday
  yield takeEvery(
    "MarketholidayList/list_MarketholidayList",
    MarketholidayListApi
  );
  yield takeEvery("AddMarketholiday/add_AddMarketholiday", AddMarketholidayApi);
  yield takeEvery(
    "DeleteMarketholiday/delete_DeleteMarketholiday",
    DeleteMarketholidayApi
  );
  // map
  yield takeEvery(
    "MapStrategiesList/list_MapStrategiesList",
    listMapStrategiesApi
  );
  yield takeEvery(
    "EditMapStrategies/edit_EditMapStrategies",
    editMapStrategiesApi
  );
  yield takeEvery("AddMapStrategies/add_AddMapStrategies", AddMapStrategiesApi);
  yield takeEvery(
    "DeleteMapStrategies/delete_MapStrategies",
    deleteMapStrategiesApi
  );
  // Strategies
  yield takeEvery("StrategiesList/list_StrategiesList", listStrategiesApi);
  yield takeEvery("EditStrategies/edit_EditStrategies", editStrategiesApi);
  yield takeEvery("AddStrategies/add_AddStrategies", AddStrategiesApi);
  yield takeEvery("DeleteStrategies/delete_Strategies", deleteStrategiesApi);
  //Card
  yield takeEvery("AddCard/add_AddCard", AddCardApi);
  yield takeEvery("EditCard/edit_EditCard", editCardApi);
  yield takeEvery("AccountList/list_AccountList", listCardApi);
  yield takeEvery("DeleteCard/delete_Card", deleteCardApi);
}

export default Saga;
