import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddAdmin,
  success_api_call_add_AddAdmin,
} from "./add_Admin_slicer";
import {
  failure_api_call_EditAdmin,
  success_api_call_EditAdmin,
} from "./edit_Admin_slicer";
import {
  failure_api_call_AdminList,
  success_api_call_AdminList,
} from "./list_AdminList_slicer";
import { failure_api_call_List, success_api_call_List } from "./list_slicer";

//add Admin
const AddAdminCall = async (a: any) => {
  const add = {
    name: a.payload.name,
    email_id: a.payload.email_id,
    password: a.payload.password,
    mobile_no: a.payload.mobile_no,
    role: a.payload.role,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/admin/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddAdminApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddAdminCall, e);
    yield put(success_api_call_add_AddAdmin(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddAdmin(e));
    yield put(expireSignature(e));
  }
}

//Edit Admin
const editAdminCall = async (a: any) => {
  const edit = {
    name: a.payload.name,
    email_id: a.payload.email_id,
    password: a.payload.password === "" ? null : a.payload.password,
    mobile_no: a.payload.mobile_no,
    role: a.payload.role,
    is_live: a.payload.is_live,
    status: a.payload.status,
  };
  const rolefind=localStorage.getItem("role")
  const roleBase = rolefind === "USER" ? "/v1/api/user/details" : `/v1/api/admin/details/${a.payload.id}`;
  const get_token = localStorage.getItem("token");
  return Axios.put(
    `${process.env.REACT_APP_api_url}${roleBase}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* editAdminApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editAdminCall, e);
    yield put(success_api_call_EditAdmin(response.data));
  } catch (e) {
    yield put(failure_api_call_EditAdmin(e));
    yield put(expireSignature(e));
  }
}

//list Admin
const listAdminCall = async (a: any) => {
  const list = {
    role: a.payload.role,
    from_date: a.payload.from_date,
    to_date: a.payload.to_date,
    status: a.payload.status,
    is_live: a.payload.is_live === null ? null : a.payload.is_live,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/admin/`, list, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* listAdminApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listAdminCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_AdminList(res));
  } catch (e) {
    yield put(failure_api_call_AdminList(e));
    yield put(expireSignature(e));
  }
}
//list Admin
const listCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/admin/`, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* listApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_List(res));
  } catch (e) {
    yield put(failure_api_call_List(e));
    yield put(expireSignature(e));
  }
}
