import { FunctionComponent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IsLoading from "./isLoading";
import { Alert, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../pages/Page.css";
import Warning from "../pages/svg_image/Waring.gif";
interface PopupMessageProps {
  Dialogtitle?: any;
  submitFunction?: any;
  Dialog_Title?: any;
  open?: number;
  successMessage?: any;
  isLoading?: any;
  pathnavigate?: any;
  subtitle?: any;
  algodataremove?: any;
}

const PopupMessage: FunctionComponent<PopupMessageProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [popup, setPopoup] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setPopoup(false), 100);
  };
  useEffect(() => {
    if (props.open) {
      handleClickOpen();
    }
  }, [props.open]);
  const vertical = "top";
  const horizontal = "right";
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [setSnackBarOpen, snackbarOpen]);
  const snackbarhandleClose = () => {
    setAlertOpen(false);
    setSnackBarOpen(false);
  };

  const action = (
    <button
      onClick={() => {
        snackbarhandleClose(); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  return (
    <div>
      <IsLoading isLoading={props.isLoading} />
      {!props.isLoading && !popup ? (
        <Dialog
          sx={{
            boxShadow: "none",
            " & .MuiDialog-container": {
              "& .MuiPaper-root": {
                border: "none",
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: "10px",
              "&::-webkit-scrollbar": { display: "none" },
            },
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{
              fontSize: {
                xs: "14px",
                sm: "19px",
                md: "19px",
                lg: "19px",
                xl: "19px",
              },
              textAlign: "center",
            }}
          >
            <img src={Warning} alt="Success..." className="DialogTitleimg" />
            <div style={{ textAlign: "center" }}>{props.subtitle}</div>
          </DialogTitle>

          {!popup ? (
            <DialogContent
              id="alert-dialog-title"
              sx={{
                "&::-webkit-scrollbar": { display: "none" },
                textAlign: "center",
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                  md: "16px",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              {props.Dialogtitle}
            </DialogContent>
          ) : undefined}

          {!popup && (
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "4%",
              }}
            >
              <Button
                onClick={handleClose}
                autoFocus
                sx={{
                  width: "18%",
                  color: "#F4A717",
                  borderRadius: "5px",
                  textTransform: "capitalize",
                  border: "1px solid #F4A717",
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "white" },
                }}
              >
                No
              </Button>
              <Button
                form="form"
                type="submit"
                onClick={() => {
                  props?.submitFunction();
                  setAlertOpen(true);
                  navigate(props?.pathnavigate);
                }}
                autoFocus
                sx={{
                  background: "#F4A717",
                  "&:hover": {
                    background: "#F4A717",
                  },
                  width: "18%",
                  color: "white",
                  borderRadius: "5px",
                  textTransform: "capitalize",
                  marginLeft: "5%",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          )}
        </Dialog>
      ) : (
        <>
          {!props.isLoading && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={alertOpen} // Use alertOpen to control the Snackbar's visibility
              autoHideDuration={6000} // Set the desired auto-hide duration (in milliseconds)
              onClose={snackbarhandleClose}
              key={`${vertical}-${horizontal}`}
              action={action}
            >
              <Alert onClose={snackbarhandleClose} severity="success">
                {props?.successMessage?.message}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </div>
  );
};

export default PopupMessage;
