import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for Login for profile List
export const LoginforprofileList = createSlice({
  name: "LoginforprofileList",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_LoginforprofileList: (state, action?) => {      
      state.isLoading = true;  
    },
    success_api_call_LoginforprofileList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_LoginforprofileList: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    LoginforprofileList_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default LoginforprofileList.reducer;
export const {
  list_LoginforprofileList,
  success_api_call_LoginforprofileList,
  failure_api_call_LoginforprofileList,
  LoginforprofileList_is_first,
} = LoginforprofileList.actions;
