import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list stock
export const Stock = createSlice({
  name: "Stock",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    mapStratagiesId:null,
    data: [],
  },
  reducers: {
    list_Stock: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Stock: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_Stock: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    Stock_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearStock: (state) => {
      state.data = [];
    },
    mapStratagiesId_update:(state,action)=>{
      state.mapStratagiesId = action.payload
    }
  },
});
export default Stock.reducer;
export const {
  list_Stock,
  success_api_call_Stock,
  failure_api_call_Stock,
  Stock_is_first,
  clearStock,mapStratagiesId_update
} = Stock.actions;
