import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  FormHelperText,
  Menu,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { clearStrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import {
  clearStock,
  list_Stock,
} from "../Redux/Reducers/Stock/list_Stock_slicer";
import Select from "./Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import { Switch } from "@mui/material";

// Dialog Component
type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  button_edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
  disable?: any;
}

// Main function
const DialogTradesetup: FunctionComponent<DialogsProps> = (props) => {
  const { getValues, isLoading }: any = props;

  // In the data from the redux
  const Stock: any = useAppSelector((state) => state?.Stock?.data);
  const account: any = useAppSelector((state) => state?.AccountList?.data);
  const AddorEdit: any = useAppSelector((state) => state?.AddorEditList?.data);
  const groupaccount: any = useAppSelector(
    (state) => state?.ListGroupAccount?.data
  );
  const MapStocks: any = useAppSelector((state) => state?.StrategiesList?.data);
  // const pageName = "/trade-setup";
  // const menuList: any = JSON.parse(
  //     if (valueornot === null) {
  // localStorage.getItem("permission");
  //   } else {sessionStorage.getItem("permission");
  //   }  // );

  // const test_val = menuList
  //   .map((data: any) => {
  //     return data.sub_menus.filter((child: any) => {
  //       return child.path_name === pageName;
  //     });
  //   })
  //   .filter((final: any) => {
  //     return final.length > 0;
  //   })
  //   .reduce((retus: any, currentarray: any) => {
  //     return currentarray;
  //   }, {});
  // const MenuOfUserpermission = test_val[0];

  // const betaPermission = MenuOfUserpermission?.permission?.beta;

  let filteredAccounts = account?.data;

  // If betaPermission is true and trade_status is true, filter out those accounts
  // if (betaPermission === true) {
  //   filteredAccounts = filteredAccounts?.filter(
  //     (item: any) => !item.trade_status
  //   );
  // }
  // Usestate are used

  const [stokedate, setstokedate] = React.useState("");
  const [stoke, setstoke] = React.useState("");
  const dateandstock = dayjs(new Date(stokedate));
  const maxstock = dayjs(stoke);
  const minstock = dayjs().startOf("day");

  const [open, setOpen] = React.useState(false);
  const [type, settype] = React.useState(
    (props?.edit?.accounts ? "Account" : props?.edit?.groups ? "group" : "") ||
      ""
  );

  // function for below the return  code
  const algodata = AddorEdit;

  // In the dispatcher from the redux value get using
  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    if (props?.edit?.expiry_date) {
      setstokedate(props?.edit?.expiry_date);
    }
    if (props?.edit?.original_expiry_date) {
      setstoke(props?.edit?.original_expiry_date);
    }

    const stocksdatafilter =
      props?.edit?.stocks?.map((editvalue: { id: any }) => {
        return editvalue?.id;
      })[0] ?? [];

    const filterid_stocks = Stock?.find((value: { id: any }) => {
      return value?.id === stocksdatafilter;
    });
    if (props?.edit?.stocks) {
      setstockfilter(filterid_stocks?.lot_size);
    }
  }, [
    dispatcher,
    setstokedate,
    props?.edit?.expiry_date,
    props?.edit?.original_expiry_date,
    props.edit?.stocks,
    Stock,
  ]);

  //  initialvalue for  form  field
  const initialValues: {
    algo_id: string;
    Strategy: string;
    stoploss: string;
    quantity: number;
    TrailingStopLoss: string;
    takeprofit: string;
    type: string;
    Expirydate: string;
    lot_size: string;
    original_expiry_date: string;
    id_for_filterstrategy: number;
    groupaccount: { label: string; value: string }[];
    Stock: { label: string; value: string };
    Accounts: { label: string; value: string }[];
  } = {
    Strategy: props?.edit?.strategy_id || "",
    lot_size: props?.edit?.lot_size || "",
    quantity: props?.edit?.quantity,
    stoploss: props?.edit?.stop_loss || "",
    TrailingStopLoss: props?.edit?.trailing_Stop_loss || "",
    takeprofit: props?.edit?.target || "",
    algo_id: props?.edit?.algo_id || "",
    Expirydate: props?.edit?.expiry_date || "",
    original_expiry_date: props?.edit?.original_expiry_date || "",
    id_for_filterstrategy: props?.edit?.id_for_filterstrategy ?? null,
    type:
      (props?.edit?.accounts
        ? "Account"
        : props?.edit?.groups
        ? "group"
        : "") || "",
    Stock:
      props?.edit?.stocks?.map((editvalue: any) => {
        return {
          label: editvalue.name,
          value: editvalue.id,
          symbol: editvalue.symbol,
        };
      })[0] ?? [],
    Accounts:
      props?.edit?.accounts?.map((editvalue: any) => {
        return {
          label: `${editvalue.broker},${editvalue.client_id}`,
          value: editvalue.id,
        };
      }) ?? [],
    groupaccount:
      props?.edit?.groups?.map((editvalue: any) => {
        return { label: editvalue.name, value: editvalue.id };
      }) ?? [],
  };

  // this for code for the dialog box
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);

    if (props?.button_edit === "edit") {
      const test = algodata.filter(
        (test: any) => test.algo_id === props?.edit?.algo_id
      );

      const test1 = test[0]?.strategy?.filter(
        (test1: any) => test1.id === props?.edit?.strategy?.id
      );

      if (test1?.segment?.length !== 0) {
        dispatcher(list_Stock({ segment: test1[0]?.segment }));
      }
    }
  };
  const handleClose = () => {
    setIsSwitchOn(false);
    setIsSwitchOne(false);
    setIsSwitchsecond(false);
    setOpen(false);
    dispatcher(clearStock());
    dispatcher(clearStrategiesList());
    settype("");
    if (props?.edit === undefined) {
      setstokedate("");
    }
    if (props?.edit === undefined) {
      setstoke("");
    }
    setstockfilter("0");
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openmenu = Boolean(anchorEl);
  const handleClickmenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosemenu = () => {
    setAnchorEl(null);
  };

  // Get today's date
  const today = new Date();

  const uniqueIds = Array.from(new Set(AddorEdit.map((v: any) => v?.algo?.id)));
  // Convert Unix timestamp to Date object and filter stocks by expiry date
  const filteredStock = Stock.map((v: any) => {
    const expiryDate = new Date(v.expiry_date); // Convert to Date object
    return {
      ...v,
      expiryDate, // Add expiryDate to the stock object
    };
  }).filter((v: any) => v.expiryDate >= today); // Filter stocks by expiry date

  const [isSwitchOn, setIsSwitchOn] = React.useState(
    props?.edit?.stop_loss ? true : false
  );

  const handleSwitchChange = (event: any) => {
    console.log(event.target.checked);

    setIsSwitchOn(event.target.checked);
  };
  const [isSwitchOne, setIsSwitchOne] = React.useState(
    props?.edit?.trailing_Stop_loss ? true : false
  );

  const handleSwitchChangeone = (event: any) => {
    setIsSwitchOne(event.target.checked);
  };

  const [isSwitchsecond, setIsSwitchsecond] = React.useState(
    props?.edit?.target ? true : false
  );

  const handleSwitchChangesecond = (event: any) => {
    setIsSwitchsecond(event.target.checked);
  };
  const [stockfilter, setstockfilter] = React.useState("0");

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        disabled={props.disable}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "1000px",
            width: "1000px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              type: Yup.string().required("Please enter a type"),
              // Expirydate: Yup.string().required("Please enter a expiry date"),
              Strategy: Yup.string().required("Please select the  strategy"),
              quantity: Yup.number().required("Please enter a quantity"),
              stoploss: Yup.lazy((value) => {
                if (isSwitchOn) {
                  return Yup.string().required("Please enter the stop loss");
                }
                return Yup.string();
              }),
              TrailingStopLoss: Yup.lazy((value) => {
                if (isSwitchOne) {
                  return Yup.string().required("Please enter the stop loss");
                }
                return Yup.string();
              }),
              takeprofit: Yup.lazy((value) => {
                if (isSwitchsecond) {
                  return Yup.string().required(
                    "Please enter the  trailing stopLoss"
                  );
                }
                return Yup.string();
              }),
              algo_id: Yup.string().required("Please select the algo"),
              Stock: Yup.object()
                .shape({
                  label: Yup.string(),
                  value: Yup.string(),
                })
                .required("Please select the stock"),
              Accounts: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .when("type", {
                  is: "Account",
                  then: () =>
                    Yup.array()
                      .of(
                        Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string(),
                        })
                      )
                      .min(1, "Please select a account")
                      .required("Please select a account"),
                }),
              groupaccount: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .when("type", {
                  is: "group",
                  then: () =>
                    Yup.array()
                      .of(
                        Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string(),
                        })
                      )
                      .min(1, "Please select a group account")
                      .required("Please select a  group account"),
                }),
            })}
            onSubmit={(values: any) => {
              if (!isSwitchOn) {
                values.stoploss = null;
              }
              if (!isSwitchOne) {
                values.TrailingStopLoss = null;
              }
              if (!isSwitchsecond) {
                values.takeprofit = null;
              }
              getValues(values);
              handleClose();
              setstockfilter("0");
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div className="addalgoDymics">
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                        }}
                      >
                        Select Algo <span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ height: "80px" }}>
                        <Autocomplete
                          Listitem={uniqueIds.map((id) => {
                            const item = AddorEdit.find(
                              (v: any) => v?.algo.id === id
                            );
                            return {
                              label: item.algo.name,
                              value: item.algo.id,
                              idforfilter: item.id,
                            };
                          })}
                          variant={"outlined"}
                          name={"algo_id"}
                          value={props.values}
                          onBlur={props.handleBlur}
                          onchange={(e: any, newValue: any) => {
                            props.setFieldValue("algo_id", e.value);
                            props.setFieldValue("Strategy", ""); // or null, depending on your preference
                          }}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                          error={props.errors.algo_id && props.touched.algo_id}
                        ></Autocomplete>

                        {props.errors.algo_id && props.touched.algo_id ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {typeof props.errors.algo_id === "string" &&
                              props.errors.algo_id}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                        }}
                      >
                        Select Strategy
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Autocomplete
                        Listitem={AddorEdit.filter(
                          (val: any) => val.algo.id === props.values.algo_id
                        ).flatMap((val: any) =>
                          val.strategy.map((v: any) => ({
                            label: v.name,
                            value: v.id,
                            stock: v.segment,
                          }))
                        )}
                        variant={"outlined"}
                        name={"Strategy"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any, newValue: any) => {
                          props.setFieldValue("Strategy", e.value);

                          dispatcher(
                            list_Stock({
                              segment: e.stock,
                            })
                          );
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={props.errors.Strategy && props.touched.Strategy}
                      ></Autocomplete>
                      {props.errors.Strategy && props.touched.Strategy ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {typeof props.errors.Strategy === "string" &&
                            props.errors.Strategy}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Select Stock <span style={{ color: "red" }}>*</span>
                        </div>
                        <div>
                          <span
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickmenu}
                            style={{ cursor: "pointer" }}
                          >
                            <FilterListIcon></FilterListIcon>
                          </span>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openmenu}
                            onClose={handleClosemenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {MapStocks?.map((value: any) => {
                              // Check if the value.id matches props.values.Strategy
                              if (value?.id === props?.values?.Strategy) {
                                // If there's a match, render MenuItem components for each segment
                                return value.segment.map(
                                  (segment: string, index: number) => (
                                    <MenuItem
                                      key={`${value.id}-${index}`}
                                      onClick={() => {
                                        dispatcher(
                                          list_Stock({
                                            segment: segment.split(","),
                                          })
                                        );
                                        handleClosemenu();
                                      }}
                                    >
                                      {segment}
                                    </MenuItem>
                                  )
                                );
                              }
                              return null; // If no match, return null
                            })}
                          </Menu>
                        </div>
                      </div>

                      <Autocomplete
                        Listitem={filteredStock.map((v: any) => ({
                          label: v.stock_name,
                          value: v.id,
                          symbol: v.symbol,
                          stokedates: v.expiry_date,
                        }))}
                        variant={"outlined"}
                        name={"Stock"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(value: any) => {
                          props.setFieldValue("Stock", value);
                          setstoke(value.stokedates);
                          setstokedate(value.stokedates);
                          props.setFieldValue(
                            "original_expiry_date",
                            value.stokedates
                          );
                          props.setFieldValue("Expirydate", value.stokedates);
                          const stock_value = value;
                          const filter_stocks = Stock?.find((value: any) => {
                            return value.id === stock_value.value;
                          });
                          props.setFieldValue(
                            "lot_size",
                            filter_stocks?.lot_size
                          );
                          setstockfilter(filter_stocks?.lot_size);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={!!(props.errors.Stock && props.touched.Stock)}
                      ></Autocomplete>
                      {props.errors.Stock && props.touched.Stock ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please select the stock"}
                        </FormHelperText>
                      ) : null}
                    </div>

                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "9px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Stock Expiry Date
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dateandstock}
                          maxDate={maxstock}
                          minDate={minstock}
                          format="DD/MM/YYYY"
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid LightGray",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          onChange={(v) => {
                            const value = new Date(
                              v as unknown as Date
                            ).valueOf();
                            props.setFieldValue("Expirydate", value);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          Quantity <span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "gray" }}>
                          Lot Size :{" "}
                          {props?.values?.lot_size
                            ? props?.values?.lot_size
                            : stockfilter}
                        </div>
                      </div>

                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="quantity"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          error={
                            !!(props.errors.quantity && props.touched.quantity)
                          }
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            // Use regex to check if the input contains only characters (letters)
                            if (/^[0-9\s]+$/.test(inputValue)) {
                              props.setFieldValue("quantity", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              props.setFieldValue(
                                "quantity",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.quantity}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />
                        {stockfilter > "0" ? (
                          <span
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                              fontSize: "11px",
                              color: "gray",
                            }}
                          >
                            Quantity multiple by lot size
                          </span>
                        ) : (
                          ""
                        )}
                        {props.errors.quantity && props.touched.quantity ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: stockfilter > "0" ? "-3px" : "10px",
                            }}
                            error
                          >
                            {props.errors.quantity as string}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        height: "110px",
                      }}
                    >
                      <div
                        style={{
                          color: "#000000",
                          // paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Choose Type <span style={{ color: "red" }}>*</span>
                      </div>
                      <ToggleButtonGroup
                        color="primary"
                        value={props.values.type}
                        sx={{
                          border: `1px solid 
                        ${
                          props.errors.type && props.touched.type
                            ? "red"
                            : "#DFDFDF"
                        }`,
                          width: "100%",
                          height: "57px",
                        }}
                        exclusive
                        onChange={(
                          event: React.MouseEvent<HTMLElement>,
                          newAlignment: any
                        ) => {
                          settype(newAlignment);

                          props.setFieldValue("type", newAlignment);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton
                          value="Account"
                          onClick={() => {
                            props.setFieldValue("groupaccount", []);
                          }}
                          sx={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                            width: "100%",
                            height: "57px",
                          }}
                        >
                          Account
                        </ToggleButton>
                        <ToggleButton
                          value="group"
                          onClick={() => {
                            props.setFieldValue("Accounts", []);
                          }}
                          sx={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                            width: "100%",
                            height: "57px",
                          }}
                        >
                          Group Account
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {props.errors.type && props.touched.type ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "6px" }}
                          error
                        >
                          {"Please select the type"}
                        </FormHelperText>
                      ) : null}
                    </div>
                    {type === "Account" ? (
                      <div style={{ height: "110px" }}>
                        <div
                          style={{
                            color: "#000000",
                          }}
                        >
                          Select Account <span style={{ color: "red" }}>*</span>
                        </div>

                        <Select
                          Listitem={filteredAccounts.map((v: any) => ({
                            label: `${v.broker},${v.client_id}`,
                            value: v.id,
                          }))}
                          variant={"outlined"}
                          name={"Accounts"}
                          value={props?.values?.Accounts[0] ?? null}
                          onBlur={props.handleBlur}
                          onchange={(value: any, newValue: any) => {
                            props.setFieldValue("Accounts", [newValue]);
                          }}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                          error={
                            !!(props.errors.Accounts && props.touched.Accounts)
                          }
                        />
                        {props.errors.Accounts && props.touched.Accounts ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {"Please select the account"}
                          </FormHelperText>
                        ) : null}
                      </div>
                    ) : null}

                    {type === "group" ? (
                      <div style={{ height: "110px" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                          }}
                        >
                          Select Group <span style={{ color: "red" }}>*</span>
                        </div>

                        <Select
                          Listitem={groupaccount?.map((v: any) => {
                            return {
                              label: v.name,
                              value: v.id,
                            };
                          })}
                          variant={"outlined"}
                          name={"groupaccount"}
                          value={props?.values?.groupaccount[0] ?? null}
                          onBlur={props.handleBlur}
                          onchange={(value: any, newValue: any) => {
                            props.setFieldValue("groupaccount", [newValue]);
                          }}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                          error={
                            !!(
                              props.errors.groupaccount &&
                              props.touched.groupaccount
                            )
                          }
                        ></Select>
                        {props.errors.groupaccount &&
                        props.touched.groupaccount ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {"Please select the group"}
                          </FormHelperText>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="addalgoDymics">
                    <div
                      style={{ height: "110px" }}
                      title={
                        isSwitchOn === true
                          ? ""
                          : "Please choose either a stop loss or a trailing stop loss"
                      }
                    >
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "1px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Stop Loss
                          <span style={{ color: "red" }}>
                            {isSwitchOn === true ? "*" : ""}
                          </span>
                        </div>
                        <div>
                          <Switch
                            title={
                              isSwitchOn === true
                                ? "Stop Loss is Enable "
                                : "Stop Loss is Disable"
                            }
                            size="small"
                            checked={isSwitchOn}
                            disabled={isSwitchOne === true}
                            onChange={(event) => {
                              const newValue = event.target.checked;
                              handleSwitchChange(event);
                              if (!newValue) {
                                props.setFieldValue("stoploss", "");
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="stoploss"
                          disabled={isSwitchOn === false}
                          type="text"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          value={props.values.stoploss}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            props.setFieldValue("stoploss", cleanedInput);
                          }}
                          error={
                            !!(props.errors.stoploss && props.touched.stoploss)
                          }
                          onBlur={props.handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>
                      {isSwitchOn === true ? (
                        props.errors.stoploss && props.touched.stoploss ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors.stoploss as string}
                          </FormHelperText>
                        ) : null
                      ) : null}
                    </div>
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "1px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Take Profit
                          <span style={{ color: "red" }}>
                            {" "}
                            {isSwitchsecond === true ? "*" : ""}
                          </span>
                        </div>
                        <div>
                          <Switch
                            title={
                              isSwitchsecond === true
                                ? "Take Profit is Enable"
                                : "Take Profit is Disable"
                            }
                            size="small"
                            checked={isSwitchsecond}
                            onChange={(event) => {
                              const newValue = event.target.checked;
                              handleSwitchChangesecond(event);
                              if (!newValue) {
                                props.setFieldValue("takeprofit", "");
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="takeprofit"
                          type="text"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          disabled={isSwitchsecond === false}
                          autoComplete="off"
                          value={props.values.takeprofit}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );

                            props.setFieldValue("takeprofit", cleanedInput);

                            // Set the field value to the cleaned input
                          }}
                          error={
                            !!(
                              props.errors.takeprofit &&
                              props.touched.takeprofit
                            )
                          }
                          onBlur={props.handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>
                      {isSwitchsecond === true ? (
                        props.errors.takeprofit && props.touched.takeprofit ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors.takeprofit as string}
                          </FormHelperText>
                        ) : null
                      ) : null}
                    </div>
                    <div
                      style={{ height: "110px" }}
                      title={
                        isSwitchOne === true
                          ? ""
                          : "Please choose either a stop loss or a trailing stop loss"
                      }
                    >
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "1px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Trailing Stop Loss
                          <span style={{ color: "red" }}>
                            {" "}
                            {isSwitchOne === true ? "*" : ""}
                          </span>
                        </div>
                        <div>
                          <Switch
                            title={
                              isSwitchOne === true
                                ? "Trailing StopLoss is Enable"
                                : "Trailing StopLoss is Disable"
                            }
                            size="small"
                            checked={isSwitchOne}
                            disabled={isSwitchOn === true}
                            onChange={(event) => {
                              const newValue = event.target.checked;
                              handleSwitchChangeone(event);
                              if (!newValue) {
                                props.setFieldValue("TrailingStopLoss", "");
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="TrailingStopLoss"
                          type="number"
                          disabled={isSwitchOne === false}
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          inputProps={{
                            step: 0.5,
                          }}
                          autoComplete="off"
                          value={props.values.TrailingStopLoss}
                          onChange={(e) => {
                            const input = e.target.value;
                            // Allow digits and dot (for decimal)
                            const onlyNumbers = input.replace(/[^\d.]/g, "");
                            // Make sure only one dot is present
                            const cleanedInput = onlyNumbers.replace(
                              /^(\d*\.?)|(\d*\.\d{0,2}).*/g,
                              "$1$2"
                            );
                            // Set the field value to the cleaned input
                            props.setFieldValue(
                              "TrailingStopLoss",
                              cleanedInput
                            );
                          }}
                          error={
                            !!(
                              props.errors.TrailingStopLoss &&
                              props.touched.TrailingStopLoss
                            )
                          }
                          onBlur={props.handleBlur}
                          style={{
                            width: "100%",
                            height: "50px",
                          }}
                        />
                      </div>
                      {isSwitchOne === true ? (
                        props.errors.TrailingStopLoss &&
                        props.touched.TrailingStopLoss ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors.TrailingStopLoss as string}
                          </FormHelperText>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        textTransform: "capitalize",
                        fontSize: "15px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      form="form"
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        textTransform: "capitalize",
                        fontSize: "15px",
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogTradesetup;
