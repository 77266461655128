import { createSlice } from "@reduxjs/toolkit";
 // this code for slice value for DeleteCard
export const DeleteCard = createSlice({
  name: "DeleteCard",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    delete_Card: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_Card: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_delete_Card: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
    },
    delete_Card_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default DeleteCard.reducer;
export const {
  delete_Card,
  success_api_call_delete_Card,
  failure_api_call_delete_Card,
  delete_Card_is_success,
} = DeleteCard.actions;
