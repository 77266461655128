import { FunctionComponent, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { useNavigate } from "react-router-dom";
import { expireSignature } from "../Redux/Reducers/ExpireSignature/ExpireSignature_slicer";
import { useAppDispatch } from "../Redux/store/hooks";
interface ErrorDialogProps {}

const ErrorDialog: FunctionComponent<ErrorDialogProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(expireSignature(null));
  }, [dispatch]);



  //    Logout functionality
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("app_id");
    localStorage.removeItem("role");
    localStorage.removeItem("idforfilter");
    localStorage.removeItem("name");
   
    localStorage.removeItem("client_id");
    localStorage.removeItem("viewedData");
    localStorage.removeItem("viewedDataLive");
    localStorage.clear();
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  };


  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            fontSize: "2rem",
          }}
          id="alert-dialog-title"
        >
         
          Session Expired
        </DialogTitle>
        
        <DialogActions>
          <Button onClick={logout} autoFocus sx={{ color: "#C00A30" }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorDialog;
