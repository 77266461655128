import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter TradeHistory_manual
export const filterTradeHistory_manual = createSlice({
  name: "filterTradeHistory_manual",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },
  reducers: {
    data_filterTradeHistory_manual: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filterTradeHistory_manual: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filterTradeHistory_manual: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filterTradeHistory_manual_is_success: (state) => {
      state.isSuccess = false;
    },
    filterTradeHistory_manual_complete_api: (state) => {
      state.api_completed = false;
    },
    clearfilterTradeHistory_manual: (state) => {
      state.data = [];
    },
  },
});
export default filterTradeHistory_manual.reducer;
export const {
  data_filterTradeHistory_manual,
  success_api_call_filterTradeHistory_manual,
  filterTradeHistory_manual_complete_api,
  failure_api_call_filterTradeHistory_manual,
  filterTradeHistory_manual_is_success,
  clearfilterTradeHistory_manual
} = filterTradeHistory_manual.actions;
