import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  add open position 
export const AddOpenPosition = createSlice({
  name: "AddOpenPosition",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_AddOpenPosition: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_AddOpenPosition: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_AddOpenPosition: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    add_AddOpenPosition_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_AddOpenPosition_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchAddOpenPosition_response: (state) => {
      state.switchResponse = false;
    },
   
  },
});
export default AddOpenPosition.reducer;
export const {
  add_AddOpenPosition,
  success_api_call_add_AddOpenPosition,
  failure_api_call_add_AddOpenPosition,
  create_AddOpenPosition_complete_api,
  add_AddOpenPosition_is_success,
  false_switchAddOpenPosition_response
} = AddOpenPosition.actions;
