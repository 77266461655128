import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   StockSyncvalues
export const StockSyncvalues = createSlice({
  name: "StockSyncvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    StockSyncvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_StockSyncvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_StockSyncvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    StockSyncvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_StockSyncvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchStockSyncvalues:(state)=>{
        state.switch = false
    }
  },
});
export default StockSyncvalues.reducer;
export const {
  StockSyncvalues_datagrid,
  success_api_call_StockSyncvalues_datagrid,
  failure_api_call_StockSyncvalues_datagrid,
  create_StockSyncvalues_datagrid_complete_api,
  StockSyncvalues_datagrid_is_success,false_switchStockSyncvalues
} = StockSyncvalues.actions;
