import { call, put } from "redux-saga/effects";
import { failure_api_call_login, success_api_call_login } from "./Login_slicer";
import Axios, { AxiosResponse } from "axios";

/*USER LOGIN API CALL*/
const loginCall = (a: any) => {
  const add = { email_id: a.payload };
  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/user/login`, add);
};
export function* LoginApi(e: any) {
  try {
    const response: AxiosResponse = yield call(loginCall, e);
    yield put(success_api_call_login(response.data));
    localStorage.setItem("login", response?.data?.data?.login_token);
    localStorage.setItem("idforlogin", response?.data?.data?.id);
    localStorage.setItem("token", response?.data?.data?.token);
    localStorage.setItem("name", response?.data?.data?.name);
    localStorage.setItem("app_id", response?.data?.data?.app_id);
    localStorage.setItem("role", response?.data?.data?.role.name);
    localStorage.setItem("roleIDfordatalist", response?.data?.data?.role.id);
    sessionStorage.setItem("role", response?.data?.data?.role.name);
    sessionStorage.setItem("roleIDfordatalist", response?.data?.data?.role.id);
    localStorage.setItem("phone", response?.data?.data?.mobile_no);
    localStorage.setItem("email", response?.data?.data?.email_id);
    localStorage.setItem("livetradestatus", response?.data?.data?.is_live);
    localStorage.setItem("userstatus", response?.data?.data?.status);
    yield put(success_api_call_login(response.data));
  } catch (e) {
    yield put(failure_api_call_login(e));
  }
}
