import React, { FunctionComponent } from "react";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  clearData,
  list_TreadSetupList,
} from "../../Redux/Reducers/Trade-setup/list_Trade-setup_slicer";
import RefreshIcon from "@mui/icons-material/Refresh";
import { edit_filtermap } from "../../Redux/Reducers/Filtervalue/filtermap";
import Tradesetupfilter from "../../Component/Tradesetupfilter";
import MappedMessage from "./mappedMessage";
import Dialogs from "../../Component/Dialog";
import Warning from "../svg_image/Waring.gif";
import { Alert, Button, Snackbar } from "@mui/material";
import { false_switchTreadSetupvalues } from "../../Redux/Reducers/Datagrid-values/Tradesetup-values-slicer";
import {
  edit_EditTreadSetup,
  false_switchTreadSetup_response,
} from "../../Redux/Reducers/Trade-setup/edit_Trade-setup_slicer";

const Mapped_Strategy: FunctionComponent = () => {
  const dispatcher = useAppDispatch();

  const data = useAppSelector((state) => state?.TreadSetupList?.data);
  const editData: any = useAppSelector((state) => state?.EditTreadSetup?.data);
  const switchOpen = useAppSelector((state) => state.TreadSetupvalues.switch);
  const Uservalues: any = useAppSelector(
    (state) => state.TreadSetupvalues.data
  );
  const switchResponse = useAppSelector(
    (state) => state.EditTreadSetup.switchResponse
  );
  const column = [
    {
      sortable: true,
      headerName: "Algo Name",
      flex: 1,
      field: "algo",
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const algoName = params.row.algo.name;

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      headerName: "Strategy Name",
      field: "strategy",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const algoName = params.row.strategy.name;

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      headerName: "Stock Number",
      field: "stocknumber",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "stocks" array

        const stocks = params.row.stocks; // Access the "stocks" array
        const algoName = stocks
          .map((stock: any) => `${stock.name} |`)
          .join(", "); // Map and join the "name" property

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      headerName: "Accounts",
      field: "accounts",
      flex: 1,

      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "accounts" array
        const accounts: any = params?.row?.accounts;

        // Check if accounts is not null or undefined before mapping
        const accountDetails = accounts
          ? accounts
              .map(
                (account: any) => `${account?.broker} : ${account?.client_id} |`
              )
              .join(", ")
          : "No accounts available"; // Provide a default value or handle the case where accounts is null or undefined

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={accountDetails}
          >
            {accountDetails}
          </div>
        );
      },
    },
    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <MappedMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></MappedMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    accounts: true,
    stocknumber: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(edit_filtermap({}));
    dispatcher(list_TreadSetupList(""));
    if (switchResponse) {
      dispatcher(false_switchTreadSetupvalues());
    }
    return () => {
      dispatcher(clearData());
    };
  }, [dispatcher, switchResponse]);
  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchTreadSetup_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetup_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchTreadSetup_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>
      <Grids
        row={data ?? []}
        column={column}
        opened
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        Removetheborder={"yes"}
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(list_TreadSetupList(""));
            }}
          >
            <RefreshIcon
              sx={{
                fontSize: "24px",
                marginTop: "5px",
                cursor: "pointer",
                marginLeft: "20px",
              }}
            ></RefreshIcon>
          </span>
        }
        pagination
        sideButton={<Tradesetupfilter></Tradesetupfilter>}
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        open={switchOpen}
        subtitle={"Warning"}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        setOpen={() => dispatcher(false_switchTreadSetupvalues())}
        Dialog_Content={
          Uservalues.status === 0
            ? "Do you want to activate trade setup?"
            : "Do you want to de-activate the trade setup?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                padding: "15px",
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchTreadSetupvalues())}
            >
              No
            </Button>
            <Button
              style={{
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "20px",
              }}
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
              }}
              onClick={() => dispatcher(edit_EditTreadSetup(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Mapped_Strategy;
