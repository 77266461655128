import { FunctionComponent } from "react";
import "./login.css";
import Loginvalidation from "./Loginvalidation";
import Investa from '../Image/Investa.png';


interface LoginProps {}

const Login: FunctionComponent<LoginProps> = () => {
  return (
    <div className="login_image">
    <div className="login_image_container">
  <img src={Investa} alt={"Investa"}  style={{height:"100%", width:"100%",objectFit:'cover'}}/>
</div>
        <div className="login_validation">
        <Loginvalidation></Loginvalidation>
        </div>
    
    </div>
  );
};

export default Login;
