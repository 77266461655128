import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter algo
export const filteralgo = createSlice({
  name: "filteralgo",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },
  reducers: {
    edit_filteralgo: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filteralgo: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filteralgo: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filteralgo_is_success: (state) => {
      state.isSuccess = false;
    },
    filteralgo_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default filteralgo.reducer;
export const {
  edit_filteralgo,
  success_api_call_filteralgo,
  filteralgo_complete_api,
  failure_api_call_filteralgo,
  filteralgo_is_success,
} = filteralgo.actions;
