import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddAlgo,
  success_api_call_add_AddAlgo,
} from "./add_Algo_slicer";
import {
  failure_api_call_EditAlgo,
  success_api_call_EditAlgo,
} from "./edit_Algo_slicer";
import {
  failure_api_call_AlgoList,
  success_api_call_AlgoList,
} from "./list_AlgoList_slicer";
import {
  failure_api_call_delete_algo,
  success_api_call_delete_algo,
} from "./delete_Algo_slicer";

// add api for in the algo 
const AddAlgoCall = async (a: any) => {
  const add = {
    name: a.payload.name,
    is_enabled: a.payload.is_enabled,
    meta_data: a.payload.meta_data,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/algo/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddAlgoApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddAlgoCall, e);

    yield put(success_api_call_add_AddAlgo(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddAlgo(e));
    yield put(expireSignature(e));
  }
}

//Edit algo
const editAlgoCall = async (a: any) => {
  const get_id = localStorage.getItem("filter");
  const get_token = localStorage.getItem("token");
  const edit = {
    name: a.payload.name,
    is_enabled: a.payload.is_enabled,
    meta_data: a.payload.meta_data,
  };
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/algo/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* editAlgoApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editAlgoCall, e);
    yield put(success_api_call_EditAlgo(response.data));
  } catch (e) {
    yield put(failure_api_call_EditAlgo(e));
    yield put(expireSignature(e));
  }
}

//list algo
const listAlgoCall = async (a: any) => {
  let list = {};
  if (a.payload.is_enabled !== "") {
    list = { is_enabled: a.payload.is_enabled };
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/algo/`, list, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* listAlgoApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listAlgoCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_AlgoList(res));
  } catch (e) {
    yield put(failure_api_call_AlgoList(e));
    yield put(expireSignature(e));
  }
}

// Delete algo 
export function* deletealgoApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deletealgoCall, e);
    yield put(success_api_call_delete_algo(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_algo(error));
  }
}
const deletealgoCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/algo/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
