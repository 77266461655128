import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list  trade history
export const TradeHistory = createSlice({
  name: "TradeHistory",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_TradeHistory: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_TradeHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_TradeHistory: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    TradeHistory_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default TradeHistory.reducer;
export const {
  list_TradeHistory,
  success_api_call_TradeHistory,
  failure_api_call_TradeHistory,
  TradeHistory_is_first,
} = TradeHistory.actions;
