import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Squareoff,
  success_api_call_Squareoff,
} from "./Square-off_slicer";

//list  square off
const SquareoffCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const loginid = localStorage.getItem("idforlogin");
  let add = {};
  if (a.payload.strategy_id) {    
    add = { strategy_id: a.payload.strategy_id, user_id: loginid };
  } else {    
    add = {
      user_id: loginid,
      trade_type: a.payload.trade_type,
      trade_from: a.payload.trade_from,
    };
  }
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/order/exit`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* SquareoffApi(e: any) {
  try {
    const response: AxiosResponse = yield call(SquareoffCall, e);
    yield put(success_api_call_Squareoff(response.data));
  } catch (e) {
    yield put(failure_api_call_Squareoff(e));
    yield put(expireSignature(e));
  }
}
