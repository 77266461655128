import { FunctionComponent, useEffect, useState } from "react";
import "./accountsfortab.css";
import AccountCard from "../../Component/Card";
import {
  clearDataaccount,
  list_AccountList,
} from "../../Redux/Reducers/Card_api/list_slicer";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { add_AddCard } from "../../Redux/Reducers/Card_api/add_Card_slicer";
import Dialog from "../../Component/DialogForFormaccount";
import { Button } from "@mui/material";
interface AccountProps {}

const AccountforTab: FunctionComponent<AccountProps> = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const data: any = useAppSelector((state) => state.AccountList.data);
  const addData = useAppSelector((state) => state.AddCard.data);
  const editData = useAppSelector((state) => state.EditCard.data);
  const deleteData = useAppSelector((state) => state.DeleteCard.data);
  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddCard?.isLoading
  );

  useEffect(() => {
    dispatch(list_AccountList(""));
    return () => {
      dispatch(clearDataaccount());
    };
  }, [addData, editData, deleteData, dispatch]);

  const pageName = "View User";
  const menuList = JSON.parse(sessionStorage?.getItem("permission") as string);

  let MenuOfUserpermission: any;

  const foundPage = menuList
    .map((data: any) =>
      data.sub_menus.find((child: any) => child.name === pageName)
    )
    .filter((page: any) => page);

  if (foundPage.length > 0) {
    MenuOfUserpermission = foundPage[0];
  } else {
    const defaultPageName = "Accounts";
    const index = menuList.findIndex(
      (item: any) => item.name === defaultPageName
    );

    if (index !== -1) {
      MenuOfUserpermission = menuList[index];
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <div
        className="account_container"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {MenuOfUserpermission?.permission?.add ? (
          <Dialog
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginRight: "20px",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
            getValues={(v: any) => {
              dispatch(add_AddCard(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Account"}
            Buttonname={"Add New"}
          />
        ) : (
          <Button
            disabled={MenuOfUserpermission?.permission?.add === false}
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              color: "black",
              height: "37px",
              borderRadius: "5px",
              marginRight: "20px",
              fontFamily: "Roboto",
              textTransform: "capitalize",

              "&:hover": { backgroundColor: "#E10000" },
            }}
          >
            Add New
          </Button>
        )}
      </div>

      <div
        // className="account1_titlefortab"
        style={{
          overflowX: "hidden",
          display: data.status === false ? "flex" : "grid",
          gridTemplateColumns:
            data.data?.length > 0
              ? "repeat(auto-fill,minmax(300px,1fr))"
              : "repeat(auto-fill,minmax(502px,1fr))",
          gap: "2em",
          width: "100%",
          padding: "1em",
          height: "67%",
          alignItems: data.status === false ? "center" : undefined, // Conditionally set alignItems
          justifyContent: data.status === false ? "center" : undefined, // Conditionally set justifyContent
        }}
      >
        <AccountCard setOpen={setOpen} open={open} />
      </div>
    </div>
  );
};

export default AccountforTab;
