import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   Delete Menu 
export const DeleteMenu = createSlice({
  name: "DeleteMenu",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_DeleteMenu: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_DeleteMenu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_DeleteMenu: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_DeleteMenu_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchDeleteMenu_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default DeleteMenu.reducer;
export const {
  delete_DeleteMenu,
  success_api_call_delete_DeleteMenu,
  failure_api_call_delete_DeleteMenu,
  delete_DeleteMenu_is_success,
  false_switchDeleteMenu_response,
} = DeleteMenu.actions;
