import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddUsageControl,
  success_api_call_add_AddUsageControl,
} from "./add_UsageControl_slicer";
import {
  failure_api_call_EditUsageControl,
  success_api_call_EditUsageControl,
} from "./edit_UsageControl_slicer";
import {
  failure_api_call_ListUsageControl,
  success_api_call_ListUsageControl,
} from "./list_UsageControl_slicer";
import {
  failure_api_call_delete_DeleteUsageControl,
  success_api_call_delete_DeleteUsageControl,
} from "./delete_UsageControl_slicer";

//add Admin
const AddUsageControlCall = async (a: any) => {
  const add = {
    accounts: a.payload.AccountUsage,
    open_trade: a.payload.OpenTrade,
    trade_setup: a.payload.TradeSetup,
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/admin/usage/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddUsageControlApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddUsageControlCall, e);
    yield put(success_api_call_add_AddUsageControl(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddUsageControl(e));
    yield put(expireSignature(e));
  }
}

//Edit Map
const EditUsageControlCall = async (a: any) => {
  const get_id = localStorage.getItem("UsageControlfilter");
  const get_token = localStorage.getItem("token");
  const edit = {
    accounts: a.payload.AccountUsage,
    open_trade: a.payload.OpenTrade,
    trade_setup: a.payload.TradeSetup,
    status: a.payload.status,
  };
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/admin/usage/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* EditUsageControlApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditUsageControlCall, e);
    yield put(success_api_call_EditUsageControl(response.data));
  } catch (e) {
    yield put(failure_api_call_EditUsageControl(e));
    yield put(expireSignature(e));
  }
}

//list Admin
const ListUsageControlCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/admin/usage`, {
    headers: {
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* ListUsageControlApi(e: any) {
  try {
    const response: AxiosResponse = yield call(ListUsageControlCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    console.log("Processed Response Data:", res);

    yield put(success_api_call_ListUsageControl(res));
  } catch (e) {
    yield put(failure_api_call_ListUsageControl(e));
    yield put(expireSignature(e));
  }
}

// Delete
export function* DeleteUsageControlApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteUsageControlCall, e);
    yield put(success_api_call_delete_DeleteUsageControl(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteUsageControl(error));
  }
}
const DeleteUsageControlCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/admin/usage/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
