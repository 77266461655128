import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for edit value update 
export const EditableValueStrategies = createSlice({
  name: "EditableValueStrategies",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },
  reducers: {
    edit_EditableValueStrategies: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_EditableValueStrategies: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_EditableValueStrategies: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    EditableValueStrategies_is_success: (state) => {
      state.isSuccess = false;
    },
    EditableValueStrategies_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_EditableValueStrategies: (state) => {
      state.data = [];
    },
  },
});
export default EditableValueStrategies.reducer;
export const {
  edit_EditableValueStrategies,
  success_api_call_EditableValueStrategies,
  EditableValueStrategies_complete_api,
  failure_api_call_EditableValueStrategies,
  EditableValueStrategies_is_success,
  clear_EditableValueStrategies,
} = EditableValueStrategies.actions;
