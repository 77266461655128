import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for delete stock
export const DeleteStock = createSlice({
  name: "DeleteStock",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    delete_DeleteStock: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_DeleteStock: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_delete_DeleteStock: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    delete_DeleteStock_is_success: (state, action) => {
      state.isSuccess = false;
    },
    false_switchDeleteStock_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default DeleteStock.reducer;
export const {
  delete_DeleteStock,
  success_api_call_delete_DeleteStock,
  failure_api_call_delete_DeleteStock,
  delete_DeleteStock_is_success,
  false_switchDeleteStock_response
} = DeleteStock.actions;
