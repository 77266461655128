import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Token,
  success_api_call_Token,
} from "./add_Token_slicer";

//token  api 
const TokenCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  let authCode = {};
  const roles: any = localStorage.getItem("role");
  if (roles.includes("ADMIN")){
    authCode = {
      auth_code: a.payload,
      client_id: localStorage.getItem("client_id"),
      user_id: localStorage.getItem("idforfilter"),
    };
  } else {
    authCode = {
      auth_code: a.payload,
      client_id: localStorage.getItem("client_id"),
    };
  }
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/account/authcode`,
    authCode,
    {
      headers: { Authorization: `Bearer ${get_token}`, "User-Agent":"ngrok-skip-browser-warning"},
    }
  );
};
export function* TokenApi(e: any) {
  try {
    const response: AxiosResponse = yield call(TokenCall, e);
    yield put(success_api_call_Token(response.data));
  } catch (e) {
    yield put(failure_api_call_Token(e));
    yield put(expireSignature(e));
  }
}
