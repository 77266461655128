import { FunctionComponent } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import Grids from "../../Component/Grides";
import "./holding.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import * as ExcelJS from "exceljs";
import {
  clearHoldings,
  list_Holdings,
} from "../../Redux/Reducers/Holdings/list_Holdings_slicer";
import RefreshIcon from "@mui/icons-material/Refresh";

interface HoldingProps {}

const Holding: FunctionComponent<HoldingProps> = () => {
  const dispatcher = useAppDispatch();
  const data: any = useAppSelector((state) => state?.Holdings?.data);
  const holdingdata = data.holdings?.map((x: any, i: any) => ({
    ...x,
    field_id: i + 1,
  }));
  const currentDateTime = new Date().toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const namelist = localStorage.getItem("name");

  const exportToExcel = (data: any) => {
    let sheetName = "Holdings.xlsx";
    let headerName = "Holdings Data";

    let workbook = new ExcelJS.Workbook();

    let sheet = workbook.addWorksheet(sheetName);

    let columnArr = [];
    for (let i in data[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnArr.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        showFirstColumn: true,
        // width: 200
      },
      columns: [{ name: "Holdings List" }],
      rows: [
        [
          `Generated At: ${new Date().toLocaleDateString(
            "en-IN"
          )} ${currentDateTime}`,
        ],
        [`Generated By: ${namelist}`],
      ],
    });

    sheet.addTable({
      name: headerName,
      ref: "A5",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
      },
      columns: columnArr
        ? columnArr.map((col) => ({
            name: col.name,
            filterButton: false,
            style: { alignment: { horizontal: "center" } },
          }))
        : [{ name: "" }],
      rows: data.map((e: any) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    sheet.getCell("A1").font = { size: 20, bold: true };

    sheet.columns = sheet.columns.map((e: any) => {
      const expr = e.values[5];
      switch (expr) {
        default:
          return { width: 40 };
      }
    });

    const table = sheet.getTable(headerName);
    if (table && table.columns) {
      for (let i = 0; i < table.columns.length; i++) {
        // Access table columns and rows directly
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).font = { size: 12 };
        sheet.getCell(`${String.fromCharCode(65 + i)}5`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d9f1" },
        };

        if (table.rows) {
          for (let j = 0; j < table.rows.length; j++) {
            let rowCell = sheet.getCell(
              `${String.fromCharCode(65 + i)}${j + 6}`
            );
            rowCell.alignment = { horizontal: "center", wrapText: true };
            rowCell.border = {
              bottom: {
                style: "thin",
                color: { argb: "a6a6a6" },
              },
            };
          }
        }
      }
    }

    table.commit();

    const writeFile = (fileName: any, content: any) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      writeFile(sheetName, buffer);
    });
  };
  const column = [
    {
      sortable: true,
      headerName: "Symbol",
      flex: 1,
      field: "symbol",
    },
    {
      sortable: true,
      headerName: "Net Quantity",
      flex: 1,
      field: "quantity",
    },
    {
      sortable: true,
      headerName: "T1 Quantity",
      flex: 1,
      field: "qty_t1",
    },
    {
      sortable: true,
      headerName: "Avg. Cost Price",
      flex: 1,
      field: "costPrice",
    },
    {
      sortable: true,
      headerName: "LTP",
      flex: 1,
      field: "ltp",
    },
    {
      sortable: true,
      headerName: "Invested Value",
      flex: 1,
      field: "investedValue",
    },
    {
      sortable: true,
      headerName: "Market Value",
      flex: 1,
      field: "marketVal",
    },
    {
      sortable: true,
      headerName: "P & L",
      flex: 1,
      field: "pl",
    },
  ];

  const initialColumnVisibilityModel: any = {
    symbol: true,
    quantity: true,
    qty_t1: true,
    costPrice: true,
    ltp: true,
    investedValue: true,
    marketVal: true,
    pl: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(
      list_Holdings({
        client_id: localStorage.getItem("client_id"),
        id: localStorage.getItem("id"),
      })
    );
    return () => {
      dispatcher(clearHoldings());
    };
  }, [dispatcher]);

  const textColor = data?.overall?.total_pl < 0 ? "red" : "green";
  const holdingheadingline = (
    <div className="holdingtitle">
      <div className="holdinghanding">
        <div style={{ color: "#B7B7B7" }}>Count Total </div>
        <div style={{ fontSize: "20px" }}>
          {data?.overall !== undefined || null
            ? data?.overall?.count_total
            : "0"}
        </div>
      </div>
      <div className="holdinghanding">
        <div style={{ color: "#B7B7B7" }}>Total Current Value</div>
        <div style={{ fontSize: "20px" }}>
          {data?.overall !== undefined || null
            ? data?.overall?.total_current_value
            : "0"}
        </div>
      </div>
      <div className="holdinghanding">
        <div style={{ color: "#B7B7B7" }}>Total Investment</div>
        <div style={{ fontSize: "20px" }}>
          {data?.overall !== undefined || null
            ? data?.overall?.total_investment
            : "0"}
        </div>
      </div>
      <div className="holdinghanding">
        <div style={{ color: "#B7B7B7" }}>Total P&L</div>
        <div style={{ fontSize: "20px", color: textColor }}>
          {data?.overall !== undefined || null ? data.overall?.total_pl : "0"}
        </div>
      </div>
    </div>
  );

  // this code  for excle
  const exportdatalist = holdingdata?.map((data: any) => {
    const add = data.costPrice * data.quantity;
    return {
      Symbol: data?.symbol,
      Netquantity: data?.quantity,
      T1Quantity: data?.qty_t1,
      AvgCostPrice: data?.costPrice,
      LTP: data?.ltp,
      InvestedValue: add,
      MarketValue: data?.marketVal,
      PL: data?.pl,
    };
  });

  const updatedData = holdingdata?.map((item: any) => ({
    ...item,
    investedValue: item.costPrice * item.quantity,
  }));
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Grids
        row={updatedData ?? []}
        column={column}
        Removetheborder={"yes"}
        swithoutbutton
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        holding={holdingheadingline}
        pagination
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(
                list_Holdings({
                  client_id: localStorage.getItem("client_id"),
                  id: localStorage.getItem("id"),
                })
              );
            }}
          >
            <RefreshIcon
              sx={{
                fontSize: "24px",
                marginTop: "6px",
                cursor: "pointer",
                marginRight: "5px",
              }}
            ></RefreshIcon>
          </span>
        }
        Export={
          <span
            title={
              updatedData?.length > 0
                ? "Export"
                : "No data available in the table. Please note that data export is not possible."
            }
            onClick={() => {
              if (updatedData?.length > 0) {
                exportToExcel(exportdatalist);
              }
            }}
          >
            <SaveAltIcon
              sx={{
                fontSize: "24px",
                marginTop: "5px",
                marginRight: "0px",
                cursor: "pointer",
              }}
            ></SaveAltIcon>
          </span>
        }
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default Holding;
