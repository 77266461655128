import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  Alert,
  LinearProgress,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { false_switchDeleteMarketholiday_response } from "../../Redux/Reducers/Marketholiday/delete_Marketholiday_slicer";
import CheckboxLabels from "../../Component/ChackBox";
import { list_RoleList } from "../../Redux/Reducers/Roles/list_Role_slicer";
import {
  clearUser_permission,
  list_User_permission,
} from "../../Redux/Reducers/User_permission/list_User_permission_slicer";
import { false_switchEdit_Userpermission_response } from "../../Redux/Reducers/User_permission/edit_User_permission_slicer";

interface MarketHolidayProps {}

const UserPermission: FunctionComponent<MarketHolidayProps> = () => {
  const dispatcher = useAppDispatch();

  const [portal, setportal] = React.useState("Admin");

  const handleChangeforportal = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setportal(newAlignment);
    }
  };
  const heading = (
    <ToggleButtonGroup
      color="primary"
      sx={{ marginRight: "10px" }}
      value={portal}
      exclusive
      onChange={handleChangeforportal}
      aria-label="Platform"
    >
      <ToggleButton
        value="Admin"
        title="Admin Menus"
        sx={{
          textTransform: "capitalize",
          height: "39px",
          marginTop: "3px",
          width: {
            xs: 60,
            sm: 60,
            md: 60,
            lg: 75,
            xl: 80,
          },
        }}
      >
        Admin
      </ToggleButton>
      <ToggleButton
        value="User"
        title="User Menus"
        sx={{
          textTransform: "capitalize",
          height: "39px",
          marginTop: "3px",
          width: {
            xs: 60,
            sm: 60,
            md: 60,
            lg: 60,
            xl: 80,
          },
        }}
      >
        User
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Roles and Permission
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      User Permission
    </Typography>,
  ];
  const addPermissionResponse: any = useAppSelector(
    (state) => state.Edit_Userpermission.data
  );
  const data = useAppSelector((state) => state?.User_permission?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.Edit_Userpermission?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteMarketholiday?.data
  );
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteMarketholiday?.isLoading
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.User_permission?.isLoading
  );
  const AddResponse = useAppSelector(
    (state) => state.Edit_Userpermission.switchResponse
  );
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteMarketholiday.switchResponse
  );

  const pageName = "User Permission";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const column = [
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Menus
        </p>
      ),

      field: "name",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          View
        </p>
      ),
      field: "view",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <CheckboxLabels
            sx={{ margin: "0" }}
            onChange={(e: any) => {
              const checked = e.target.checked;
              params.api.updateRows([
                {
                  ...params.row,

                  permission: {
                    ...params.row.permission,
                    view: checked,
                    add: checked && params.row.permission.add,
                    edit: checked && params.row.permission.edit,
                    delete: checked && params.row.permission.delete,
                  },
                },
              ]);
            }}
            checked={params.row.permission.view}
          ></CheckboxLabels>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Add
        </p>
      ),
      field: "add",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <CheckboxLabels
            sx={{ margin: "0" }}
            onChange={(e: any) => {
              const checked = e.target.checked;
              params.api.updateRows([
                {
                  ...params.row,
                  permission: {
                    ...params.row.permission,
                    add: checked,
                    view: checked || params.row.permission.view,
                  },
                },
              ]);
            }}
            checked={params.row.permission.add}
          ></CheckboxLabels>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Edit
        </p>
      ),
      field: "edit",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <CheckboxLabels
            sx={{ margin: "0" }}
            onChange={(e: any) => {
              const checked = e.target.checked;
              params.api.updateRows([
                {
                  ...params.row,
                  permission: {
                    ...params.row.permission,
                    edit: checked,
                    view: checked || params.row.permission.view,
                  },
                },
              ]);
            }}
            checked={params.row.permission.edit}
          ></CheckboxLabels>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Delete
        </p>
      ),
      field: "delete",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <CheckboxLabels
            sx={{ margin: "0" }}
            onChange={(e: any) => {
              const checked = e.target.checked;
              params.api.updateRows([
                {
                  ...params.row,

                  permission: {
                    ...params.row.permission,
                    delete: checked,
                    view: checked || params.row.permission.view,
                  },
                },
              ]);
            }}
            checked={params.row.permission.delete}
          ></CheckboxLabels>
        );
      },
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "white", fontWeight: "bolder" }}
        >
          Beta
        </p>
      ),
      field: "beta",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <CheckboxLabels
            sx={{ margin: "0" }}
            onChange={(e: any) => {
              const checked = e.target.checked;
              params.api.updateRows([
                {
                  ...params.row,

                  permission: {
                    ...params.row.permission,
                    beta: checked,
                    view: checked || params.row.permission.view,
                  },
                },
              ]);
            }}
            checked={params.row.permission.beta}
          ></CheckboxLabels>
        );
      },
    },
  ];

  React.useEffect(() => {
    dispatcher(list_RoleList(true));
    const fetchDataAsync = async () => {
      try {
        if (portal) {
          await dispatcher(
            list_User_permission([
              {
                status: true,
                portal: portal,
                roleId: localStorage.getItem("roleIdforuserpermission"),
              },
            ])
          );
        }
      } catch (error) {}
    };

    fetchDataAsync(); // Call the fetchDataAsync function
    return () => {
      dispatcher(clearUser_permission());
    };
  }, [addPermissionResponse, dispatcher, portal]);

  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteMarketholiday_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchEdit_Userpermission_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const initialColumnVisibilityModel: any = {
    name: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    beta: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEdit_Userpermission_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchEdit_Userpermission_response())}
          severity="success"
        >
          {addPermissionResponse.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteMarketholiday_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteMarketholiday_response())}
          severity="success"
        >
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataIsLoading || DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data !== undefined && data}
        column={column}
        UserPermission
        UserPermissionenbale
        UserPermissionFordropdown={portal}
        Users={heading}
        Userpermissions={
          MenuOfUserpermission?.permission.add ||
          MenuOfUserpermission?.permission.edit
        }
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      ></Grids>
    </div>
  );
};

export default UserPermission;
