import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for ProfileValue
export const ProfileValue = createSlice({
  name: "ProfileValue",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    edit_ProfileValue: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_ProfileValue: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_ProfileValue: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    ProfileValue_is_success: (state) => {
      state.isSuccess = false;
    },
    ProfileValue_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default ProfileValue.reducer;
export const {
  edit_ProfileValue,
  success_api_call_ProfileValue,
  ProfileValue_complete_api,
  failure_api_call_ProfileValue,
  ProfileValue_is_success,
} = ProfileValue.actions;
