import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for Uservalues
export const Uservalues = createSlice({
  name: "Uservalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    Uservalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_Uservalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_Uservalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    Uservalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_Uservalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switch:(state)=>{
        state.switch = false
    }
  },
});
export default Uservalues.reducer;
export const {
    Uservalues_datagrid,
  success_api_call_Uservalues_datagrid,
  failure_api_call_Uservalues_datagrid,
  create_Uservalues_datagrid_complete_api,
  Uservalues_datagrid_is_success,false_switch
} = Uservalues.actions;
