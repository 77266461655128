import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../App.css";
import { DateRangePicker } from "rsuite";
import "./Daterangefilter.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { clearStrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { list_TradeList } from "../Redux/Reducers/Trade-details/list_TradeList_slicer";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { edit_filtertrade } from "../Redux/Reducers/Filtervalue/filtertrade";
import { list_AddorEditList } from "../Redux/Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
import { Paper } from "@mui/material";

type Anchor = "top" | "left" | "bottom" | "right";

export default function TradeDetailsfiltermanual(props: any) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const dispatcher = useAppDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // filter the aglo name
  const AddorEdit: any = useAppSelector((state) => state?.AddorEditList?.data);
  const filtertrade: any = useAppSelector((state) => state?.filtertrade?.data);
  const uniqueIds = Array.from(new Set(AddorEdit.map((v: any) => v?.algo?.id)));

  const [dateRange, setDateRange] = React.useState<
    DateRange | null | undefined
  >(null);

  // Calculate the start date (7 days ago) and end date (today)
  React.useEffect(() => {
    dispatcher(list_AddorEditList(""));
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 7);
    // Format the dates as ISO strings (yyyy-MM-dd)
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    // Set the date range
    setDateRange([new Date(formattedStartDate), new Date(formattedEndDate)]);

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "text";
    hiddenInput.style.display = "none";
    hiddenInput.setAttribute("autocomplete", "off");
    hiddenInput.setAttribute("id", "hiddenInput");
    // Append the input to the body or another appropriate container
    document.body.appendChild(hiddenInput);
    // Remove the input when the component is unmounted
    return () => {
      document.body.removeChild(hiddenInput);
    };
  }, [dispatcher]);

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <span title="Filter">
            <FilterListIcon
              onClick={toggleDrawer(anchor, true)}
              sx={{
                marginTop: "5px",
                fontSize: "24px",
                marginRight: "5px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            ></FilterListIcon>
          </span>

          <Drawer anchor={anchor} open={state[anchor]}>
            <Paper
              sx={{
                width: {
                  xs: 200,
                  sm: 200,
                  md: 300,
                  lg: 360,
                  xl: 400,
                },
                zIndex: "1",
                height: "100%",
              }}
              role="presentation"
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#2C8E14",
                  justifyContent: "space-between",
                  height: "64px",
                }}
              >
                <span
                  style={{
                    padding: "17px",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                >
                  Filter
                </span>
                <div style={{ padding: "20px", cursor: "pointer" }}>
                  <CancelIcon
                    sx={{ color: "white" }}
                    onClick={toggleDrawer(anchor, false)}
                  ></CancelIcon>
                </div>
              </div>
              <Formik
                initialValues={{
                  status: filtertrade?.status ?? null, // Set the initial value here
                  algo_id: filtertrade?.algo_id ?? null,
                  strategy_id: filtertrade?.strategy_id ?? null,
                  dateRange: filtertrade?.dateRange ?? null,
                  id_for_filterstrategy:
                    filtertrade?.id_for_filterstrategy ?? null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values) => {
                  if (values.dateRange != null) {
                    const fromDate = new Date(values.dateRange[0]);
                    const year = fromDate.getFullYear();
                    const month = String(fromDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const day = String(fromDate.getDate()).padStart(2, "0");
                    const formattedFromDate = `${year}-${month}-${day}`;
                    const toate = new Date(values.dateRange[1]);
                    const toyear = toate.getFullYear();
                    const tomonth = String(toate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const today = String(toate.getDate()).padStart(2, "0");
                    const formattedToDate = `${toyear}-${tomonth}-${today}`;
                    dispatcher(
                      list_TradeList({
                        ...values,

                        from_date: formattedFromDate,
                        to_date: formattedToDate,
                        type: props.filtertype,
                        entry_type: props.entry_type,
                      })
                    );
                  }
                  dispatcher(edit_filtertrade(values));
                }}
              >
                {({ values, handleSubmit, handleBlur, setFieldValue }) => {
                  return (
                    <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Status
                      </div>
                      <Autocomplete
                        Listitem={[
                          { label: "All", value: null },
                          { label: "Entry", value: 0 },
                          { label: "Signal", value: 1 },
                          { label: "SL", value: 2 },
                          { label: "Target", value: 3 },
                          { label: "TSL", value: 4 },
                          { label: "Exit", value: 5 },
                        ]}
                        // required
                        variant={"outlined"}
                        name={"status"}
                        value={values}
                        onBlur={handleBlur}
                        onchange={(e: any) => {
                          setFieldValue("status", e.value);
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>

                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Algo
                      </div>
                      <Autocomplete
                        Listitem={uniqueIds.map((id) => {
                          const item = AddorEdit.find(
                            (v: any) => v?.algo.id === id
                          );
                          return {
                            label: item.algo.name,
                            value: item.algo.id,
                          };
                        })}
                        variant={"outlined"}
                        name={"algo_id"}
                        value={values}
                        onBlur={handleBlur}
                        onchange={(e: any, newValue: any) => {
                          setFieldValue("algo_id", e.value);
                          setFieldValue("id_for_filterstrategy", e.value);
                          setFieldValue("strategy_id", null);
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>
                      <div
                        style={{
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Strategy
                      </div>
                      <Autocomplete
                        Listitem={AddorEdit.filter(
                          (val: any) =>
                            val?.algo?.id === values.id_for_filterstrategy
                        ).flatMap((val: any) =>
                          val.strategy.map((v: any) => ({
                            label: v.name,
                            value: v.id,
                          }))
                        )}
                        variant={"outlined"}
                        name={"strategy_id"}
                        value={values}
                        onBlur={handleBlur}
                        onchange={(e: any, newValue: any) => {
                          setFieldValue("strategy_id", e.value);
                        }}
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                        }}
                      ></Autocomplete>
                      {/* <div style={{ padding: "10px 20px 0px 20px" }}> */}
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "10px 20px 0px 20px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Trading Dates
                      </div>

                      <div
                        style={{
                          padding: "5px 20px 0px 20px ",
                          width: "100%",
                          overflowY: isCalendarOpen ? "auto" : "hidden",
                        }}
                        onWheel={(e) => {
                          if (isCalendarOpen) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <DateRangePicker
                          showOneCalendar
                          className="custom-date-range-picker"
                          defaultValue={dateRange}
                          editable={false}
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "0.5px solid gray",
                          }}
                          name="dateRange"
                          value={values.dateRange}
                          onChange={(selectedRange: any) => {
                            setFieldValue("dateRange", selectedRange);
                          }}
                          onFocus={() => {
                            // Focus on the hidden input to prevent autofill
                            const hiddenInput =
                              document.getElementById("hiddenInput");
                            if (hiddenInput) {
                              hiddenInput.focus();
                            }
                          }}
                          onClose={() => {
                            setIsCalendarOpen(false);
                          }}
                          format="yyyy-MM-dd"
                          placeholder="Select Date Range"
                          placement="left"
                          // Disable future dates
                          disabledDate={(date: Date) => {
                            const today = new Date();
                            today.setHours(0, 0, 0, 0); // Set time to 00:00:00 to compare only the date part
                            const selectedDate = new Date(date);
                            selectedDate.setHours(0, 0, 0, 0); // Ensure the selected date is also only compared by the date part
                            return selectedDate > today; // Disable dates after today
                          }}
                        />
                      </div>
                      {/* </div> */}
                      <div
                        className="bottom_div"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#2C8E14",
                          width: "100%",
                          gap: "1em",
                          height: "60px",
                          padding: "10px",
                        }}
                      >
                        <div>
                          <span onClick={toggleDrawer(anchor, false)}>
                            <button
                              type="button"
                              onClick={() => {
                                dispatcher(
                                  edit_filtertrade({
                                    status: null, // Set the initial value here
                                    algo_id: null,
                                    strategy_id: null,
                                    dateRange: null,
                                    type: props.filtertype,
                                    entry_type: props.entry_type,
                                  })
                                );
                                dispatcher(clearStrategiesList());
                              }}
                              style={{
                                width: "100px",
                                height: "40px",
                                color: "black",
                                backgroundColor: "white",
                                borderRadius: "5px",
                                fontFamily: "Roboto",
                                border: "1px solid #F2A618",
                              }}
                            >
                              Reset
                            </button>
                          </span>
                        </div>
                        <div>
                          <span onClick={toggleDrawer(anchor, false)}>
                            <button
                              type="submit"
                              style={{
                                width: "100px",
                                height: "40px",
                                color: "black",
                                background: !props.isValid
                                  ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                                  : "linear-gradient(to right, #FFFA02,#F2A618 )",

                                borderRadius: "5px",
                                fontFamily: "Roboto",
                              }}
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
