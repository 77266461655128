import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";

interface MultiSelectProps {
  defaultValue?: {
    label: string | number;
    value: string | number;
  }[];
  options: {
    label: string | number;
    value: string | number;
  }[];
  label?: string | number;
  name?: string;
  value?: {
    label: string | number;
    value: string | number;
  }[];
  getValue: (value: any) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  sx?: React.CSSProperties;
  inputSx?: React.CSSProperties | any;
  helperText?: React.ReactNode | any;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = (props) => {
  const {
    defaultValue,
    options = [],
    label,
    name,
    value,
    getValue,
    onBlur,
    sx,
    helperText,
    error,
    inputSx,
    placeholder,
    disabled,
  } = props;

  return (
    <Autocomplete
      multiple
      freeSolo
      limitTags={2}
      filterSelectedOptions
      sx={sx}
      disabled={disabled}
      options={options?.filter((option) => {
        return !value
          ?.map(
            (value: { label: string | number; value: string | number }) =>
              value?.value
          )
          .includes(option?.value);
      })}
      getOptionLabel={(option: any) => option?.label}
      defaultValue={defaultValue}
      value={value}
      onChange={(_, value: any) => {
        // setTest(value);
        getValue!(value);
      }}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          sx={inputSx}
        />
      )}
    />
  );
};

export default React.memo(MultiSelect);
