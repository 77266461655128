import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_add_AddCard,
  success_api_call_add_AddCard,
} from "./add_Card_slicer";
import {
  failure_api_call_EditCard,
  success_api_call_EditCard,
} from "./edit_Card_slicer";
import {
  failure_api_call_AccountList,
  success_api_call_AccountList,
} from "./list_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_delete_Card,
  success_api_call_delete_Card,
} from "./delete_Card_slicer";
import {
  failure_api_call_EditSwitch,
  success_api_call_EditSwitch,
} from "./switch_slicer";
import {
  failure_api_call_EditCheckbox,
  success_api_call_EditCheckbox,
} from "./checkbox_slicer";

//Add Card //
const AddCardCall = async (a: any) => {
  const idfor = localStorage.getItem("idforfilter");
  const rolename: any = localStorage.getItem("role");
  var add = {};
  if (rolename.includes("ADMIN")) {
    add = {
      account_name: a.payload.account,
      user_id: idfor,
      broker: a.payload.broker,
      client_id: a.payload.client_id,
      api_key: a.payload.api_key,
      api_secret: a.payload.api_secret,
      trade_status: a.payload.trade_status,
    };
  } else {
    add = {
      account_name: a.payload.account,
      broker: a.payload.broker,
      client_id: a.payload.client_id,
      api_key: a.payload.api_key,
      api_secret: a.payload.api_secret,
      trade_status: a.payload.trade_status,
    };
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/account/create`,
    add,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddCardApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddCardCall, e);
    yield put(success_api_call_add_AddCard(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddCard(e));
    yield put(expireSignature(e));
  }
}

// Edit Card
const editCardCall = async (a: any) => {
  const edit = {
    account_name: a.payload.account,
    broker: a.payload.broker,
    client_id: a.payload.client_id,
    api_key: a.payload.api_key,
    api_secret: a.payload.api_secret,
    trade_status: a.payload.trade_status,
  };
  const get_token = localStorage.getItem("token");
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/account/${a.payload.id}`,
    edit,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + get_token,
      },
    }
  );
};

export function* editCardApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editCardCall, e);
    yield put(success_api_call_EditCard(response.data));
  } catch (e) {
    yield put(failure_api_call_EditCard(e));
    yield put(expireSignature(e));
  }
}

// switch Card
const editSwitchCall = async (a: any) => {
  const editswitch = {
    trade_status: a.payload.trade_status === false ? true : false,
  };
  const get_token = localStorage.getItem("token");
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/account/update-status/${a.payload.id}`,
    editswitch,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + get_token,
      },
    }
  );
};

export function* editSwichApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editSwitchCall, e);
    yield put(success_api_call_EditSwitch(response.data));
  } catch (e) {
    yield put(failure_api_call_EditSwitch(e));
    yield put(expireSignature(e));
  }
}
// check box Card

const editChackboxCall = async (a: any) => {
  const editchackbox = {
    is_feed_data: a.payload.is_feed_data,
  };
  const get_token = localStorage.getItem("token");
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/account/update-feed-status/${a.payload.id}`,
    editchackbox,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + get_token,
      },
    }
  );
};

export function* editCheckboxApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editChackboxCall, e);
    yield put(success_api_call_EditCheckbox(response.data));
  } catch (e) {
    yield put(failure_api_call_EditCheckbox(e));
    yield put(expireSignature(e));
  }
}

//List Card For Admin
const listCardCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  const idfor = localStorage.getItem("idforfilter");
  let url: string;
  const valueornot: any = sessionStorage.getItem("role");
  console.log(valueornot);

  let roles: any;

  if (valueornot === null) {
    roles = localStorage.getItem("role");
  } else {
    roles = sessionStorage.getItem("role");
  }

  if (roles.includes("ADMIN")) {
    url = `${process.env.REACT_APP_api_url}/v1/api/account/?user_id=${idfor}`;
  } else {
    url = `${process.env.REACT_APP_api_url}/v1/api/account/`;
  }
  return Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + get_token,
    },
  });
};
export function* listCardApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listCardCall, e);
    yield put(success_api_call_AccountList(response.data));
  } catch (e) {
    yield put(failure_api_call_AccountList(e));
    yield put(expireSignature(e));
  }
}

//Delete Card
export function* deleteCardApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteCardCall, e);
    yield put(success_api_call_delete_Card(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_Card(error));
  }
}
const deleteCardCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/account/${a.payload.id}`,
    { data: formData, headers: { Authorization: `Bearer ${token}` } }
  );
};
