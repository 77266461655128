import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../App.css";
import { Formik, Form } from "formik";
import "./Daterangefilter.css";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import { DateRangePicker } from "rsuite";
import FilterListIcon from "@mui/icons-material/FilterList";
import { list_AdminList } from "../Redux/Reducers/Admin-api/list_AdminList_slicer";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { edit_filteruser } from "../Redux/Reducers/Filtervalue/filteruser";

type Anchor = "top" | "left" | "bottom" | "right";

export default function Clientfilter(props: any) {
  const filteruser: any = useAppSelector((state) => state?.filteruser?.data);

  const initialValues = {
    status: filteruser?.status ?? null,
    is_live: filteruser?.is_live ?? null,
    dateRange: filteruser?.dateRange ?? null,
    role: filteruser?.role ?? null,
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const dispatcher = useAppDispatch();

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <span title={"Filter"}>
            <FilterListIcon
              onClick={toggleDrawer(anchor, true)}
              sx={{
                marginTop: "5px",
                fontSize: "24px",
                marginRight: "5px",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            ></FilterListIcon>
          </span>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
          >
            <Box
              sx={{
                width: {
                  xs: 200,
                  sm: 200,
                  md: 300,
                  lg: 360,
                  xl: 400,
                },
                zIndex: "1",
              }}
              role="presentation"
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#2C8E14",
                  justifyContent: "space-between",
                  height: "64px",
                }}
              >
                <h4
                  style={{
                    padding: "17px",
                    color: "white",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                  }}
                >
                  Filter
                </h4>
                <div style={{ padding: "20px", cursor: "pointer" }}>
                  <CancelIcon
                    sx={{ color: "white" }}
                    onClick={toggleDrawer(anchor, false)}
                  ></CancelIcon>
                </div>
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values) => {
                  if (values.dateRange != null) {
                    const fromDate = new Date(values.dateRange[0]);
                    const year = fromDate.getFullYear();
                    const month = String(fromDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const day = String(fromDate.getDate()).padStart(2, "0");
                    const formattedFromDate = `${year}-${month}-${day}`;
                    const toate = new Date(values.dateRange[1]);
                    const toyear = toate.getFullYear();
                    const tomonth = String(toate.getMonth() + 1).padStart(
                      2,
                      "0"
                    );
                    const today = String(toate.getDate()).padStart(2, "0");
                    const formattedToDate = `${toyear}-${tomonth}-${today}`;

                    dispatcher(
                      list_AdminList({
                        ...values,

                        from_date: formattedFromDate,
                        to_date: formattedToDate,
                      })
                    );
                  } else {
                    dispatcher(
                      list_AdminList({
                        ...values,
                        from_date: null,
                        to_date: null,
                      })
                    );
                  }
                  dispatcher(edit_filteruser(values));
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  touched,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div
                      style={{
                        padding: "10px 0px 0px 20px",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                      }}
                    >
                      Select Role
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "All", value: null },
                        { label: "User", value: "USER" },
                        { label: "Admin", value: "ADMIN" },
                        { label: "Super Admin", value: "SUPER ADMIN" },
                      ]}
                      variant={"outlined"}
                      name={"role"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any) => {
                        setFieldValue("role", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                      error={errors.role && touched.role}
                    ></Autocomplete>
                    <div
                      style={{
                        padding: "10px 0px 0px 20px",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                      }}
                    >
                      Select Status
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "All", value: null },
                        { label: "Active", value: 1 },
                        { label: "Inactive", value: 0 },
                      ]}
                      variant={"outlined"}
                      name={"status"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any) => {
                        setFieldValue("status", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                      error={errors.status && touched.status}
                    ></Autocomplete>

                    <div style={{ padding: "10px 20px 0px 20px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "0px 0px 0px 0px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Joining Date
                      </div>

                      <div
                        style={{
                          marginTop: "5px",
                          width: "100%",
                        }}
                      >
                        <DateRangePicker
                          showOneCalendar
                          className="custom-date-range-picker" // Add a custom class
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "0.5px solid #BCBFBF",
                          }}
                          name="dateRange"
                          editable={false}
                          value={values.dateRange}
                          onChange={(selectedRange: any) => {
                            setFieldValue("dateRange", selectedRange);
                          }}
                          format="yyyy-MM-dd"
                          placeholder="Select Date Range"
                          placement="left"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px 0px 0px 20px",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                      }}
                    >
                      Trade Status
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "All", value: null },
                        { label: "Is Live", value: true },
                        { label: "Live Off", value: false },
                      ]}
                      variant={"outlined"}
                      name={"is_live"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any) => {
                        setFieldValue("is_live", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                      error={errors.is_live && touched.is_live}
                    ></Autocomplete>

                    <div
                      className="bottom_div"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#2C8E14",
                        width: "100%",
                        gap: "1em",
                        height: "60px",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="button"
                            onClick={() => {
                              dispatcher(list_AdminList({}));
                              dispatcher(edit_filteruser({}));
                              toggleDrawer(anchor, false);
                            }}
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontFamily: "Roboto",
                              border: "1px solid #F2A618",
                            }}
                          >
                            Reset
                          </button>
                        </span>
                      </div>
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="submit"
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              background: !props.isValid
                                ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                                : "linear-gradient(to right, #FFFA02,#F2A618 )",

                              borderRadius: "5px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
