import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   DeleteTreadSetupvalues
export const DeleteTreadSetupvalues = createSlice({
  name: "DeleteTreadSetupvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    DeleteTreadSetupvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_DeleteTreadSetupvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_DeleteTreadSetupvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    DeleteTreadSetupvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_DeleteTreadSetupvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchDeleteTreadSetupvalues:(state)=>{
        state.switch = false
    }
  },
});
export default DeleteTreadSetupvalues.reducer;
export const {
  DeleteTreadSetupvalues_datagrid,
  success_api_call_DeleteTreadSetupvalues_datagrid,
  failure_api_call_DeleteTreadSetupvalues_datagrid,
  create_DeleteTreadSetupvalues_datagrid_complete_api,
  DeleteTreadSetupvalues_datagrid_is_success,false_switchDeleteTreadSetupvalues
} = DeleteTreadSetupvalues.actions;
