import { FunctionComponent } from "react";
import Autocompletes from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

//  Autocomplete component

type variant = "outlined" | "standard" | "filled" | undefined;
type color = "primary" | "secondary" | "success" | "error" | "info" | "warning";

type size = "small" | "medium" | undefined;
interface ListitemsOptions {
  label: string | number;
  value: any;
}
interface AutocompleteProps {
  Listitem: ListitemsOptions[];
  width?: any;
  height?: any;
  variant?: variant;
  disabled?: boolean;
  color?: color;
  size?: size;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  label?: any;
  multiple?: boolean; // filter?: boolean;
  onchange?: any;
  bordercolor?: any;
  defaultValue?: ListitemsOptions | ListitemsOptions[] | null;
  name?: string;
  helperText?: any;
  error?: boolean;
  onChange?: any;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  value?: any;
  fullWidth?: boolean;
  labelColor?: string;
  inputColor?: string;
  filter?: boolean;
  checkBox?: boolean;
  disableClearable?: boolean;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
}

const Select: FunctionComponent<AutocompleteProps> = (props) => {
  const {
    Listitem,

    variant,
    disabled,
    color,
    size,
    style,
    bordercolor,
    label,
    multiple, // filter,
    helperText,
    name,
    onchange,
    defaultValue,
    error,
    onChange,
    onBlur,
    fullWidth,
    sx,
    labelColor,
    inputColor,
    filter,
    disableClearable,
    onFocus,
    value,
  } = props;

  return (
    <Autocompletes
      clearOnBlur={false}
      disablePortal
      disableClearable={disableClearable}
      autoHighlight
      filterSelectedOptions={filter}
      onChange={onchange} //getting value on 2nd parameter (e,value)=>{value}
      defaultValue={defaultValue}
      value={value}
      options={Listitem}
      disabled={disabled}
      getOptionLabel={(option: any) => option.label}
      onFocus={onFocus}
      sx={sx}
      onBlur={onBlur}
      loading
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          sx={{
            width: "100%",
            height: "100%",
            "& fieldset": {
              borderRadius: "5px",
              paddingBottom: "10px",
              border: bordercolor,
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: { color: inputColor ?? "black" },
          }}
          InputLabelProps={{
            style: { color: labelColor ?? "black" },
          }}
          label={label}
          variant={variant}
          color={color}
          disabled={disabled}
          size={size}
          style={style}
          helperText={helperText}
          error={error}
          onChange={onChange}
          fullWidth={fullWidth}
        />
      )}
      multiple={multiple}
      disableCloseOnSelect={multiple}
    />
  );
};

export default Select;
