import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Autocomplete from "./Autocomplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { clearStrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { list_OpenPositionList } from "../Redux/Reducers/Open-Position/list_OpenPosition_slicer";
import { edit_filterlive } from "../Redux/Reducers/Filtervalue/filterlive";
import { useState } from "react";
import { list_Stock } from "../Redux/Reducers/Stock/list_Stock_slicer";

type Anchor = "top" | "left" | "bottom" | "right";

export default function LiveTradefiltermanual(props: any) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const dispatcher = useAppDispatch();
  const Filterlive: any = useAppSelector((state) => state?.filterlive?.data);
  const stock: any = useAppSelector((state) => state?.Stock?.data);
  const segmentOptions = [
    { label: "NFO Future", value: "NFO,FUTURE" },
    { label: "NFO Option", value: "NFO,OPTION" },
  ];

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <span title="Filter">
            <FilterListIcon
              onClick={toggleDrawer(anchor, true)}
              sx={{
                marginTop: "5px",
                fontSize: "24px",
                marginRight: "5px",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            ></FilterListIcon>
          </span>

          <Drawer anchor={anchor} open={state[anchor]}>
            <Box
              sx={{
                width: {
                  xs: 200,
                  sm: 200,
                  md: 300,
                  lg: 360,
                  xl: 400,
                },
                zIndex: "1",
              }}
              role="presentation"
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#2C8E14",
                  justifyContent: "space-between",
                  height: "64px",
                }}
              >
                <span
                  style={{
                    padding: "17px",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                >
                  Filter
                </span>
                <div style={{ padding: "20px", cursor: "pointer" }}>
                  <CancelIcon
                    sx={{ color: "white" }}
                    onClick={toggleDrawer(anchor, false)}
                  ></CancelIcon>
                </div>
              </div>
              <Formik
                initialValues={{
                  Segmentopenposition: Filterlive?.Segmentopenposition ?? null, // Set the initial value here
                  Stocks: Filterlive?.Stocks ?? null,
                  Product: Filterlive?.Product ?? null,
                  typelist: Filterlive?.typelist ?? null,
                  Status: Filterlive?.Status ?? null,
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values) => {
                  dispatcher(
                    list_OpenPositionList({
                      ...values,
                      type: props.filtertype,
                      entry_type: props.entry_type,
                    })
                  );
                  dispatcher(edit_filterlive(values));
                }}
              >
                {({ values, handleSubmit, handleBlur, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Segment
                    </div>

                    <Autocomplete
                      Listitem={segmentOptions}
                      variant={"outlined"}
                      name={"Segmentopenposition"}
                      value={values}
                      onBlur={props.handleBlur}
                      onchange={(value: any, newValue: any) => {
                        setFieldValue("Segmentopenposition", [value.value]);
                        dispatcher(
                          list_Stock({
                            segment: [value?.value],
                          })
                        );
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Stock
                    </div>
                    <Autocomplete
                      Listitem={stock?.map((v: any) => {
                        return {
                          label: v.stock_name,
                          value: v.id,
                        };
                      })}
                      variant={"outlined"}
                      name={"Stocks"}
                      value={values}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("Stocks", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>
                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Product
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "Intraday - MIS", value: "MIS" },
                        { label: "Longterm  - CNC", value: "CNC" },
                      ]}
                      variant={"outlined"}
                      name={"Product"}
                      value={values}
                      size={"medium"}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("Product", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>

                    <div
                      style={{
                        color: "#000000",
                        padding: "10px 0px 0px 20px",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Select Type
                    </div>
                    <Autocomplete
                      Listitem={[
                        { label: "MARKET", value: "MARKET" },
                        { label: "LIMIT", value: "LIMIT" },
                        { label: "SL-L", value: "SL-L" },
                        { label: "SL-M", value: "SL-M" },
                      ]}
                      variant={"outlined"}
                      name={"typelist"}
                      value={values}
                      size={"medium"}
                      onBlur={handleBlur}
                      onchange={(e: any, newValue: any) => {
                        setFieldValue("typelist", e.value);
                      }}
                      style={{
                        padding: "5px 20px 0px 20px ",
                        width: "100%",
                      }}
                    ></Autocomplete>
                    {props.protal !== "open_position" ? (
                      <>
                        <div
                          style={{
                            color: "#000000",
                            padding: "10px 0px 0px 20px",
                            fontSize: "14px",
                            fontFamily: "Roboto",
                          }}
                        >
                          Select Status
                        </div>
                        <Autocomplete
                          Listitem={[
                            { label: "Traget", value: 1 },
                            { label: "SL", value: 2 },
                            { label: "TSL", value: 3 },
                            { label: "Manual Exit", value: 4 },
                            { label: "Square Off", value: 5 },
                          ]}
                          variant={"outlined"}
                          name={"Status"}
                          value={values}
                          size={"medium"}
                          onBlur={handleBlur}
                          onchange={(e: any, newValue: any) => {
                            setFieldValue("Status", e.value);
                          }}
                          style={{
                            padding: "5px 20px 0px 20px ",
                            width: "100%",
                          }}
                        ></Autocomplete>
                      </>
                    ) : null}
                    <div
                      className="bottom_div"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#2C8E14",
                        width: "100%",
                        gap: "1em",
                        height: "60px",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="button"
                            onClick={() => {
                              dispatcher(
                                list_OpenPositionList({
                                  type: props.filtertype,
                                  entry_type: props.entry_type,
                                })
                              );
                              dispatcher(
                                edit_filterlive({
                                  Status: null,
                                  type: null,
                                  Product: null,
                                  stocks: null,
                                  Segmentopenposition: null,
                                })
                              );
                              dispatcher(clearStrategiesList());

                              toggleDrawer(anchor, false);
                            }}
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              fontFamily: "Roboto",
                              border: "1px solid #F2A618",
                            }}
                          >
                            Reset
                          </button>
                        </span>
                      </div>
                      <div>
                        <span onClick={toggleDrawer(anchor, false)}>
                          <button
                            type="submit"
                            style={{
                              width: "100px",
                              height: "40px",
                              color: "black",
                              background: !props.isValid
                                ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                                : "linear-gradient(to right, #FFFA02,#F2A618 )",

                              borderRadius: "5px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
