import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  edit group account
export const EditGroupAccount = createSlice({
  name: "EditGroupAccount",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    edit_EditGroupAccount: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditGroupAccount: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditGroupAccount: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditGroupAccount_is_success: (state) => {
      state.isSuccess = false;  
    },
    EditGroupAccount_complete_api: (state) => {
      state.api_completed = false;
    },
    false_switchEditGroupAccount_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditGroupAccount.reducer;
export const {
  edit_EditGroupAccount,
  success_api_call_EditGroupAccount,
  EditGroupAccount_complete_api,
  failure_api_call_EditGroupAccount,
  EditGroupAccount_is_success,
  false_switchEditGroupAccount_response
} = EditGroupAccount.actions;
