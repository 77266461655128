import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import Dialogs from "../../Component/Dialog";
import { false_switchDeleteMepvalues } from "../../Redux/Reducers/Datagrid-values/DeleteMap-values-slicer";
import MarketholidayMessage from "./MarketholidayMessage";
import DialogMarketHoliday from "../../Component/DialogForFormMarketholiday";
import {
  clearDataMarketholidayList,
  list_MarketholidayList,
} from "../../Redux/Reducers/Marketholiday/list_Marketholiday_slicer";
import {
  delete_DeleteMarketholiday,
  false_switchDeleteMarketholiday_response,
} from "../../Redux/Reducers/Marketholiday/delete_Marketholiday_slicer";
import { false_switchDeleteHolidayvalues } from "../../Redux/Reducers/Datagrid-values/DeleteHoliday-values-slicer";
import {
  add_AddMarketholiday,
  false_switchAddMarketholiday_response,
} from "../../Redux/Reducers/Marketholiday/add_Marketholiday_slicer";
import Warning from "../svg_image/Waring.gif";

interface MarketHolidayProps {}

const MarketHoliday: FunctionComponent<MarketHolidayProps> = () => {
  const dispatcher = useAppDispatch();
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      General Configuration
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Market Holiday
    </Typography>,
  ];

  const data = useAppSelector((state) => state?.MarketholidayList?.data);
  const addData: any = useAppSelector((state) => state?.AddMarketholiday?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddMarketholiday?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteMarketholiday?.data
  );
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteMarketholiday?.isLoading
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.MarketholidayList?.isLoading
  );
  const AddResponse = useAppSelector(
    (state) => state.AddMapStrategies.switchResponse
  );
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteMarketholiday.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state.DeleteHolidayvalues.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.DeleteHolidayvalues.switch
  );

  const column = [
    {
      sortable: true,
      headerName: "Date",
      flex: 1,
      field: "holiday_date",
      valueGetter: (params: any) => {
        return params.row.holiday_date;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "algo" object

        const originalDate = params.row.holiday_date; // Date in "YYYY-MM-DD" format
        const [year, month, day] = originalDate.split("-");
        const formattedDate = `${day}-${month}-${year}`; // Rearranging date parts

        return <div title={formattedDate}>{formattedDate}</div>;
      },
    },
    {
      headerName: "Segment",
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.segment.map((strategy: any) => strategy);
      },
      field: "segment", // Assuming "strategy" is the property in your data
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "strategy" object
        const strategyNames = params.row.segment.map((strategy: any) => {
          return `${strategy} |  `;
        });

        return (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            title={strategyNames}
          >
            {strategyNames}
          </div>
        );
      },
      flex: 1,
    },
    {
      sortable: true,
      headerName: "Reason",
      flex: 1,
      field: "reason",
    },
    true
      ? {
          headerName: " Action",
          field: "Actions",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <MarketholidayMessage
                valuepopup={params.row.id}
              ></MarketholidayMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    segment: true,
    reason: true,
    holiday_date: true,
    Actions: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );

  React.useEffect(() => {
    dispatcher(list_MarketholidayList(""));

    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteMepvalues());
    }
    return () => {
      dispatcher(clearDataMarketholidayList());
    };
  }, [addData, deleteData, dispatcher, switchResponsealgodelete]);

  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteMarketholiday_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAddMarketholiday_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddMarketholiday_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddMarketholiday_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {addData.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteMarketholiday_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteMarketholiday_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataIsLoading || DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data}
        column={column}
        gridesbutton
        pagination
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        dialog={
          <DialogMarketHoliday
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
            getValues={(v: any) => {
              dispatcher(add_AddMarketholiday(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Market Holiday"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
      ></Grids>

      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        open={switchOpenalgodelete}
        setOpen={() => dispatcher(false_switchDeleteMepvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteHolidayvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => {
                dispatcher(delete_DeleteMarketholiday(Uservaluesalgodelete));
                dispatcher(false_switchDeleteHolidayvalues());
              }}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default MarketHoliday;
