import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperText, SxProps, TextField, Theme } from "@mui/material";
import { useAppDispatch } from "../Redux/store/hooks";
import { list_AlgoList } from "../Redux/Reducers/Algo-api/list_AlgoList_slicer";
import { clearStrategiesList } from "../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "../pages/Page.css";
import IsLoading from "./isLoading";
import "./DialogForAlgoDymics.css";
import { clearStock } from "../Redux/Reducers/Stock/list_Stock_slicer";
import { MuiChipsInput } from "mui-chips-input";
import { edit_AlgoDymics, edit_reducer } from "../Redux/Reducers/AlgoDymics";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  // error?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;

  // errors?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
  check_trade?: any;
}

const Algo: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const { isLoading }: any = props;

  type MetaDataModel = {
    key: string;
    name: string;
    value: string | null;
    unit: string;
    inputType: string;
    is_mandatory: boolean;
    min_value: number;
    max_value: number;
    min_length: number;
    max_length: number;
    options: string[];
  };

  const initialValues: MetaDataModel = {
    key: props?.edit?.key || "",
    name: props?.edit?.name || "",
    value: null,
    unit: props?.edit?.unit || "",
    inputType: props?.edit?.inputType || null,
    is_mandatory: props?.edit?.is_mandatory,
    min_value: props?.edit?.min_value || null,
    max_value: props?.edit?.max_value || null,
    min_length: props?.edit?.min_length || null,
    max_length: props?.edit?.max_length || null,
    options: props?.edit?.options || [],
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatcher(clearStock());
    dispatcher(clearStrategiesList());
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement != null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    dispatcher(list_AlgoList(""));
  }, [dispatcher]);

  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const pageName = "Add Algo";
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        disabled={
          MenuOfUserpermission?.permission?.add === false ||
          MenuOfUserpermission?.permission?.edit === false ||
          props?.check_trade === true
        }
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "1000px",
            width: "1000px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              key: Yup.string().required("Please enter the  name"),
              name: Yup.string().required("Please enter the field name"),
              unit: Yup.string().required("Please select the  unit"),
              inputType: Yup.string()
                .nullable()
                .when("unit", {
                  is: "inputfield",
                  then: () =>
                    Yup.string().required("Please select the  input field"),
                }),

              is_mandatory: Yup.string().required(
                "Please select the  is mandatory"
              ),

              options: Yup.array().when("unit", {
                is: "drop_down" && "multi_select_dropdown",
                then: () =>
                  Yup.array()
                    .min(1, "Please enter the dropdown ")
                    .required("Please enter the dropdown"),
                otherwise: () => Yup.array(),
              }),
            })}
            onSubmit={(values) => {
              props.Buttondialog === "edit"
                ? dispatcher(edit_reducer({ ...values, id: props?.edit?.id }))
                : dispatcher(edit_AlgoDymics(values));
              handleClose(); // Close the dialog box
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div className="addalgoDymics">
                    <div style={{ height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Field Name <span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="name"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          helperText={
                            props.errors.name &&
                            props.touched.name &&
                            props.errors.name
                          }
                          error={!!(props.errors.name && props.touched.name)}
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            // Use regex to check if the input contains only characters (letters)
                            if (
                              /^[A-Za-z0-9\s_-]+$/.test(inputValue) &&
                              inputValue.length < 31
                            ) {
                              props.setFieldValue("name", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              props.setFieldValue(
                                "name",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.name}
                          style={{
                            width: "100%",
                            height: "50px",
                            margin: " 0px 0px 40px ",
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ paddingBottom: "20px", height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Key Name <span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="key"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          helperText={
                            props.errors.key &&
                            props.touched.key &&
                            props.errors.key
                          }
                          error={!!(props.errors.key && props.touched.key)}
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            // Use regex to check if the input contains only characters (letters)
                            if (
                              /^[A-Za-z0-9\s-_]+$/.test(inputValue) &&
                              inputValue.length < 31
                            ) {
                              props.setFieldValue("key", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              props.setFieldValue(
                                "key",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.key}
                          style={{
                            width: "100%",
                            height: "50px",
                            margin: "0px 0px 40px ",
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ height: "110px", paddingBottom: "20px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Select Unit
                        <span style={{ color: "red" }}> *</span>
                      </div>
                      <Autocomplete
                        Listitem={[
                          { label: "Input Field", value: "inputfield" },
                          { label: "Dropdown", value: "drop_down" },
                          {
                            label: "Multiselect dropdown",
                            value: "multi_select_dropdown",
                          },
                        ]}
                        variant={"outlined"}
                        name={"unit"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any, newValue: any) => {
                          props.setFieldValue("unit", e.value);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={props.errors.unit && props.touched.unit}
                      ></Autocomplete>
                      {props.errors.unit && props.touched.unit ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {props.errors.unit}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ paddingBottom: "20px", height: "110px" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Is Mandatory <span style={{ color: "red" }}>*</span>
                      </div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={props.values.is_mandatory}
                          onChange={(event) => {
                            props.setFieldValue(
                              "is_mandatory",
                              event.target.value
                            );
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {props.errors.is_mandatory &&
                      props.touched.is_mandatory ? (
                        <FormHelperText
                          style={{
                            marginLeft: "13px",
                            marginTop: "10px",
                          }}
                          error
                        >
                          {props.errors.is_mandatory}
                        </FormHelperText>
                      ) : null}
                    </div>

                    {props.values.unit === "inputfield" && (
                      <div style={{ height: "110px" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          Select Input Type
                          <span style={{ color: "red" }}> *</span>
                        </div>
                        <Autocomplete
                          Listitem={[
                            { label: "Text", value: "text" },
                            { label: "Number", value: "number" },
                            {
                              label: "Text & Number",
                              value: "textnumber",
                            },

                            {
                              label: "Number decimal",
                              value: "number_decimal",
                            },
                          ]}
                          variant={"outlined"}
                          name={"inputType"}
                          value={props.values}
                          onBlur={props.handleBlur}
                          onchange={(e: any, newValue: any) => {
                            props.setFieldValue("inputType", e.value);
                          }}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                          error={
                            props.errors.inputType && props.touched.inputType
                          }
                        ></Autocomplete>

                        {props.errors.inputType && props.touched.inputType ? (
                          <FormHelperText
                            style={{
                              marginLeft: "13px",
                              marginTop: "10px",
                            }}
                            error
                          >
                            {props.errors.inputType}
                          </FormHelperText>
                        ) : null}
                      </div>
                    )}

                    {props.values.unit === "drop_down" ? (
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          Dropdown Value
                          <span style={{ color: "red" }}> *</span>
                        </div>
                        <MuiChipsInput
                          InputProps={{
                            placeholder: "", // Set placeholder to an empty string
                          }}
                          helperText={
                            props.errors.options &&
                            props.touched.options &&
                            props.errors.options
                          }
                          clearInputOnBlur
                          style={{
                            borderColor:
                              props.errors.options && props.touched.options
                                ? "#d94242  "
                                : "gray",
                            width: "100%",
                          }}
                          sx={{
                            "& fieldset": {
                              width: "100%",
                            },
                          }}
                          value={props.values.options}
                          error={
                            !!(props.errors.options && props.touched.options)
                          }
                          onChange={(newValue: any) => {
                            props.setFieldValue("options", newValue);
                          }}
                        />
                      </div>
                    ) : (
                      props.values.unit === "multi_select_dropdown" && (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Multiselect Dropdown Value
                            <span style={{ color: "red" }}> *</span>
                          </div>

                          <MuiChipsInput
                            InputProps={{
                              placeholder: "", // Set placeholder to an empty string
                            }}
                            helperText={
                              props.errors.options &&
                              props.touched.options &&
                              props.errors.options
                            }
                            clearInputOnBlur
                            style={{
                              borderColor:
                                props.errors.options && props.touched.options
                                  ? "#d94242  "
                                  : "gray",
                              width: "100%",
                            }}
                            sx={{
                              "& fieldset": {
                                width: "100%",
                              },
                            }}
                            value={props.values.options}
                            error={
                              !!(props.errors.options && props.touched.options)
                            }
                            onChange={(newValue: any) => {
                              props.setFieldValue("options", newValue);
                            }}
                          />
                        </div>
                      )
                    )}
                    {props.values.unit === "inputfield" &&
                      (props.values.inputType === "number" ||
                        props.values.inputType === "text_number" ||
                        props.values.inputType === "number_decimal") && (
                        <div style={{ paddingBottom: "20px", height: "110px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Minimum Value
                          </div>
                          <TextField
                            variant="outlined"
                            name="min_value"
                            value={props.values.min_value}
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              // Use regex to check if the input contains only characters (letters)
                              if (
                                /^[0-9 .\s]+$/.test(inputValue) &&
                                inputValue.length < 31
                              ) {
                                props.setFieldValue("min_value", inputValue);
                              } else {
                                // If validation fails, remove the last character from the input value
                                props.setFieldValue(
                                  "min_value",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            helperText={
                              props.errors.min_value &&
                              props.touched.min_value &&
                              props.errors.min_value
                            }
                            error={
                              !!(
                                props.errors.min_value &&
                                props.touched.min_value
                              )
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: " 0px 0px 30px ",
                            }}
                          />
                        </div>
                      )}
                    {props.values.unit === "inputfield" &&
                      (props.values.inputType === "text" ||
                        props.values.inputType === "textnumber") && (
                        <div style={{ paddingBottom: "20px", height: "110px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Minimum Length
                          </div>
                          <TextField
                            variant="outlined"
                            name="min_length"
                            value={props.values.min_length}
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              // Use regex to check if the input contains only characters (letters)
                              if (
                                /^[0-9\s]+$/.test(inputValue) &&
                                inputValue.length < 31
                              ) {
                                props.setFieldValue("min_length", inputValue);
                              } else {
                                // If validation fails, remove the last character from the input value
                                props.setFieldValue(
                                  "min_length",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            helperText={
                              props.errors.min_length &&
                              props.touched.min_length &&
                              props.errors.min_length
                            }
                            error={
                              !!(
                                props.errors.min_length &&
                                props.touched.min_length
                              )
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: "0px 0px 30px",
                            }}
                          />
                        </div>
                      )}
                    {props.values.unit === "inputfield" &&
                      (props.values.inputType === "number" ||
                        props.values.inputType === "text_number" ||
                        props.values.inputType === "number_decimal") && (
                        <div style={{ paddingBottom: "20px", height: "110px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Maximum Value
                          </div>
                          <TextField
                            variant="outlined"
                            name="max_value"
                            value={props.values.max_value}
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              // Use regex to check if the input contains only characters (letters)
                              if (
                                /^[0-9 .\s]+$/.test(inputValue) &&
                                inputValue.length < 31
                              ) {
                                props.setFieldValue("max_value", inputValue);
                              } else {
                                // If validation fails, remove the last character from the input value
                                props.setFieldValue(
                                  "max_value",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            helperText={
                              props.errors.max_value &&
                              props.touched.max_value &&
                              props.errors.max_value
                            }
                            error={
                              !!(
                                props.errors.max_value &&
                                props.touched.max_value
                              )
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: "0px 0px 30px ",
                            }}
                          />
                        </div>
                      )}
                    {props.values.unit === "inputfield" &&
                      (props.values.inputType === "text" ||
                        props.values.inputType === "textnumber") && (
                        <div style={{ paddingBottom: "20px", height: "110px" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "15px",
                              fontFamily: "Roboto",
                            }}
                          >
                            Maximum Length
                          </div>
                          <TextField
                            variant="outlined"
                            name="max_length"
                            value={props.values.max_length}
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              // Use regex to check if the input contains only characters (letters)
                              if (
                                /^[0-9\s]+$/.test(inputValue) &&
                                inputValue.length < 31
                              ) {
                                props.setFieldValue("max_length", inputValue);
                              } else {
                                // If validation fails, remove the last character from the input value
                                props.setFieldValue(
                                  "max_length",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            helperText={
                              props.errors.max_length &&
                              props.touched.max_length &&
                              props.errors.max_length
                            }
                            error={
                              !!(
                                props.errors.max_length &&
                                props.touched.max_length
                              )
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: " 0px 0px 30px ",
                            }}
                          />
                        </div>
                      )}
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                      }}
                    >
                      Save
                    </button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default Algo;
