import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation } from "react-router-dom";
import { LinearProgress, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import AccountforTab from "../pages/accounts/AccountsforTab";
import ViewGroupaccount from "../pages/GroupAccount/ViewGroupaccount";

export default function TabforGroupAccount() {
  const location = useLocation();
  const [value, setValue] = React.useState("1");
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (location.pathname === "/Userdetails") {
      setValue("1");
    } else if (location.pathname === "/tradingaccount") {
      setValue("3");
    }
  }, [location, dispatch]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Accounts
    </Typography>,
  ];
  const isLoadingdata = useAppSelector((state) => state.AccountList.isLoading);
  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddCard?.isLoading
  );
  const isLoadingEditData = useAppSelector((state) => state.EditCard.isLoading);
  const isLoadingGroup = useAppSelector(
    (state) => state?.ListGroupAccount?.isLoading
  );
  const editGroupDataIsLoading = useAppSelector(
    (state) => state?.EditGroupAccount?.isLoading
  );
  const addGroupDataLoading: any = useAppSelector(
    (state) => state?.AddGroupAccount?.isLoading
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              isLoadingEditData ||
              isLoadingGroup ||
              editGroupDataIsLoading ||
              addGroupDataLoading
                ? "block"
                : "none",
          }}
        />
      </div>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div
        style={{
          backgroundColor: "white",
          flex: "1",
          height: "90%",
          display: "flex",
          borderRadius: "10px",
          boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            typography: "body1",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#338514",
                    height: "3px",
                    borderRadius: "5px",
                  },
                }}
              >
                <Tab
                  label="Accounts"
                  value="1"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "1" ? "#338514" : "black" }}
                />
                <Tab
                  label="Group Accounts"
                  value="2"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "2" ? "#338514" : "black" }}
                />
              </TabList>
            </Box>

            <TabPanel
              value="1"
              sx={{ flex: "1", height: "97%", padding: "10px" }}
            >
              <AccountforTab></AccountforTab>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{ flex: "1", height: "97%", padding: "10px" }}
            >
              <ViewGroupaccount></ViewGroupaccount>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}
