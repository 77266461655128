import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  data holding 
export const DataHoldings = createSlice({
  name: "DataHoldings",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    mapStratagiesId: null,
    data: [],
  },
  reducers: {
    list_DataHoldings: (state, action) => {
      state.isLoading = true;
      state.data = action.payload;      
    },
    success_api_call_DataHoldings: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_DataHoldings: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    DataHoldings_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearDataHoldings: (state) => {
      state.data = [];
    },
    mapStratagiesId_update: (state, action) => {
      state.mapStratagiesId = action.payload;
    },
  },
});
export default DataHoldings.reducer;
export const {
  list_DataHoldings,
  success_api_call_DataHoldings,
  failure_api_call_DataHoldings,
  DataHoldings_is_first,
  clearDataHoldings,
  mapStratagiesId_update,
} = DataHoldings.actions;
