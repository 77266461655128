import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for  filter user
export const filteruser = createSlice({
  name: "filteruser",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },

  reducers: {
    edit_filteruser: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_filteruser: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_filteruser: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    filteruser_is_success: (state) => {
      state.isSuccess = false;
    },
    filteruser_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default filteruser.reducer;
export const {
  edit_filteruser,
  success_api_call_filteruser,
  filteruser_complete_api,
  failure_api_call_filteruser,
  filteruser_is_success,
} = filteruser.actions;
