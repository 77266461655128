import { FunctionComponent, useEffect, useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  Alert,
  FormHelperText,
  Snackbar,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppDispatch } from "../Redux/store/hooks";
import { list_AlgoList } from "../Redux/Reducers/Algo-api/list_AlgoList_slicer";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import { list_AccountList } from "../Redux/Reducers/Card_api/list_slicer";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const Dialog: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const vertical = "top";
  const horizontal = "right";
  const { getValues, successMessage, isLoading }: any = props;
  const initialValues: {
    id: string;
    account: string;
    broker: string;
    trade_status: boolean;
    client_id: string;
    api_key: string;
    api_secret: string;
  } = {
    account: props?.edit?.account_name || "",
    trade_status: props?.edit?.trade_status,
    id: props?.edit?.id,
    broker: props?.edit?.broker || "",
    client_id: props?.edit?.client_id || "",
    api_key: props?.edit?.api_key || "",

    api_secret: props?.edit?.api_secret || "",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [snackbarOpen]);
  const snackbarhandleClose = () => {
    setAlertOpen(false);
    setSnackBarOpen(false);
  };
  const action = (
    <button
      onClick={() => {
        snackbarhandleClose();
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (alertOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement != null) {
        descriptionElement.focus();
      }
    }
  }, [alertOpen]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatcher = useAppDispatch();

  React.useEffect(() => {
    dispatcher(list_AlgoList(""));
    dispatcher(list_AccountList(""));
  }, [dispatcher]);

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "1000px",
            width: "1000px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minWidth: "200px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              account: Yup.string().required("Please enter the account name"),

              broker: Yup.string().required(
                "Please select the trading platform"
              ),
              client_id: Yup.string().when("trade_status", {
                is: true,
                then: () => Yup.string().required("Please enter the client id"),
                otherwise: () => Yup.string(),
              }),
              api_key: Yup.string().when("trade_status", {
                is: true,
                then: () => Yup.string().required("Please enter the API key"),
                otherwise: () => Yup.string(),
              }),
              api_secret: Yup.string().when("trade_status", {
                is: true,
                then: () =>
                  Yup.string().required("Please enter the API secret'"),
                otherwise: () => Yup.string(),
              }),
              trade_status: Yup.boolean().required(
                "Please enter the trade status"
              ),
            })}
            onSubmit={(values) => {
              getValues(values);
              handleClose();
              setAlertOpen(true);
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div
                    style={{
                      display: "grid",
                      gap: "1em",
                      gridTemplateColumns:
                        "repeat(auto-fill,minmax(15rem,1fr))",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Account Name <span style={{ color: "red" }}> *</span>
                      </div>
                      {/* Specific fields with validation */}
                      <div>
                        <TextField
                          id="outlined-basic"
                          // required
                          variant="outlined"
                          name="account"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                            },
                          }}
                          autoComplete="off"
                          error={
                            !!(props.errors.account && props.touched.account)
                          }
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            // Use regex to check if the input contains only characters (letters)
                            if (
                              /^[A-Za-z0-9\s]+$/.test(inputValue) &&
                              inputValue.length < 31
                            ) {
                              props.setFieldValue("account", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              props.setFieldValue(
                                "account",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.account}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />

                        {props.errors.account && props.touched.account ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {"Please enter a Account name"}
                            {/* {props.errors.is_enabled} */}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Select Trading Platform
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ height: "80px" }}>
                        <Autocomplete
                          Listitem={[
                            { label: "Fyers", value: "Fyers" },
                            { label: "Zerodha", value: "Zerodha" },
                          ]}
                          variant={"outlined"}
                          name={"broker"}
                          value={props.values}
                          onBlur={props.handleBlur}
                          onchange={(e: any, newValue: any) => {
                            props.setFieldValue("broker", e.value);
                          }}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                          error={
                            !!(props.errors.broker && props.touched.broker)
                          }
                        ></Autocomplete>

                        {props.errors.broker && props.touched.broker ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {props.errors.broker}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>

                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Select Trade Status
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Autocomplete
                        Listitem={[
                          { label: "Paper Trade", value: false },
                          { label: "Live Trade", value: true },
                        ]}
                        variant={"outlined"}
                        name={"trade_status"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any, newValue: any) => {
                          props.setFieldValue("trade_status", e.value);
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={
                          !!(
                            props.errors.trade_status &&
                            props.touched.trade_status
                          )
                        }
                      ></Autocomplete>
                      {props.errors.trade_status &&
                      props.touched.trade_status ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {props.errors.trade_status}
                        </FormHelperText>
                      ) : null}
                    </div>

                    {props.values.trade_status && (
                      <>
                        <div style={{ height: "100px", width: "100%" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "14px",
                            }}
                          >
                            Client Id <span style={{ color: "red" }}>*</span>
                          </div>
                          <TextField
                            variant="outlined"
                            name="client_id"
                            value={props.values.client_id}
                            onChange={(e) =>
                              props.setFieldValue("client_id", e.target.value)
                            }
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            helperText={
                              props.errors.client_id &&
                              props.touched.client_id &&
                              props.errors.client_id
                            }
                            error={
                              !!(
                                props.errors.client_id &&
                                props.touched.client_id
                              )
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: " 0px 0px 40px ",
                            }}
                          />
                        </div>

                        <div style={{ height: "100px", width: "100%" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "14px",
                            }}
                          >
                            Api Key<span style={{ color: "red" }}>*</span>
                          </div>
                          <TextField
                            variant="outlined"
                            name="api_key"
                            value={props.values.api_key}
                            onChange={(e) =>
                              props.setFieldValue("api_key", e.target.value)
                            }
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            helperText={
                              props.errors.api_key &&
                              props.touched.api_key &&
                              props.errors.api_key
                            }
                            error={
                              !!(props.errors.api_key && props.touched.api_key)
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: " 0px 0px 40px ",
                            }}
                          />
                        </div>
                        <div style={{ height: "100px", width: "100%" }}>
                          <div
                            style={{
                              color: "#000000",
                              paddingBottom: "5px",
                              fontSize: "14px",
                            }}
                          >
                            Api Secret <span style={{ color: "red" }}>*</span>
                          </div>
                          <TextField
                            variant="outlined"
                            name="api_secret"
                            value={props.values.api_secret}
                            onChange={(e) =>
                              props.setFieldValue("api_secret", e.target.value)
                            }
                            onBlur={props.handleBlur}
                            helperText={
                              props.errors.api_secret &&
                              props.touched.api_secret &&
                              props.errors.api_secret
                            }
                            error={
                              !!(
                                props.errors.api_secret &&
                                props.touched.api_secret
                              )
                            }
                            style={{
                              width: "100%",
                              height: "50px",
                              margin: " 0px 0px 40px ",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>

      {!isLoading ? (
        // <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={alertOpen} // Use alertOpen to control the Snackbar's visibility
          autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
          onClose={snackbarhandleClose}
          key={`${vertical}-${horizontal}`}
          action={action}
        >
          <Alert onClose={snackbarhandleClose} severity="success">
            {successMessage.message}
          </Alert>
        </Snackbar>
      ) : (
        // </div>
        ""
      )}
    </>
  );
};

export default Dialog;
