import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for selectvalue dropdown value store
export const Selectvalue = createSlice({
  name: "Selectvalue",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: {},
  },

  reducers: {
    edit_Selectvalue: (state, action) => {
      state.data = action.payload;
    },
    success_api_call_Selectvalue: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
    },
    failure_api_call_Selectvalue: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
    },
    Selectvalue_is_success: (state) => {
      state.isSuccess = false;
    },
    Selectvalue_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default Selectvalue.reducer;
export const {
  edit_Selectvalue,
  success_api_call_Selectvalue,
  Selectvalue_complete_api,
  failure_api_call_Selectvalue,
  Selectvalue_is_success,
} = Selectvalue.actions;
