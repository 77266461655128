import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_LoginforprofileList,
  success_api_call_LoginforprofileList,
} from "./list_Loginforprofile_slicer";

//list Loginforprofile
const listLoginforprofileCall = async (a: any) => {
  let list = {
    login_token:localStorage.getItem("login")
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/user/login-session`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* LoginforprofileListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listLoginforprofileCall, e);
    let res = response?.data   
    yield put(success_api_call_LoginforprofileList(res));
  } catch (e) {
    yield put(failure_api_call_LoginforprofileList(e));
    yield put(expireSignature(e));
  }
}
