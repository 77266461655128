import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import Dialogs from "../../Component/Dialog";
import DialogUsageControl from "../../Component/DialogForFormUsage_Control";
import UsageControlMessage from "./Usage_ControlMessage";
import { false_switchDeleteUsageControlvalues } from "../../Redux/Reducers/Datagrid-values/DeleteUsageControl-values-slicer";
import {
  edit_EditUsageControl,
  false_switchEditUsageControl_response,
} from "../../Redux/Reducers/UsageControl/edit_UsageControl_slicer";
import { false_switchUsageControlvalues } from "../../Redux/Reducers/Datagrid-values/UsageControl-values-slicer";
import {
  add_AddUsageControl,
  false_switchAddUsageControl_response,
} from "../../Redux/Reducers/UsageControl/add_UsageControl_slicer";
import {
  clearDataListUsageControl,
  list_ListUsageControl,
} from "../../Redux/Reducers/UsageControl/list_UsageControl_slicer";
import {
  delete_DeleteUsageControl,
  false_switchDeleteUsageControl_response,
} from "../../Redux/Reducers/UsageControl/delete_UsageControl_slicer";

interface UsageControlProps {}

const UsageControl: FunctionComponent<UsageControlProps> = () => {
  const dispatcher = useAppDispatch();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Usage Control
    </Typography>,
  ];

  const data = useAppSelector((state) => state?.ListUsageControl?.data);
  const addData: any = useAppSelector((state) => state?.AddUsageControl?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddUsageControl?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteUsageControl?.data
  );
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteUsageControl?.isLoading
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.ListUsageControl?.isLoading
  );
  const AddResponse = useAppSelector(
    (state) => state?.AddUsageControl?.switchResponse
  );
  const switchResponse = useAppSelector(
    (state) => state?.EditUsageControl?.switchResponse
  );
  const Uservalues: any = useAppSelector(
    (state) => state?.UsageControlvalues?.data
  );
  const switchOpen = useAppSelector(
    (state) => state?.UsageControlvalues?.switch
  );
  const switchResponsealgodelete = useAppSelector(
    (state) => state?.DeleteUsageControl?.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state?.DeleteUsageControlvalues?.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state?.DeleteUsageControlvalues?.switch
  );
  const editData: any = useAppSelector(
    (state) => state?.EditUsageControl?.data
  );
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditUsageControl?.isLoading
  );

  const column = [
    {
      sortable: true,
      headerName: "Account",
      flex: 1,
      field: "accounts",
      valueGetter: (params: any) => {
        return params?.row?.accounts;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const algoName = params?.row?.accounts;

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Open Trade",
      flex: 1,
      field: "open_trade",
      valueGetter: (params: any) => {
        return params?.row?.open_trade;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const algoName = params?.row?.open_trade;

        return <div title={algoName}>{algoName}</div>;
      },
    },
    {
      sortable: true,
      headerName: "Trade Setup ",
      flex: 1,
      field: "trade_setup",
      valueGetter: (params: any) => {
        return params?.row?.trade_setup;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const tradeName = params?.row?.trade_setup;

        return <div title={tradeName}>{tradeName}</div>;
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <UsageControlMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></UsageControlMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    accounts: true,
    open_trade: true,
    trade_setup: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(list_ListUsageControl(""));
    if (switchResponse) {
      dispatcher(false_switchUsageControlvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteUsageControlvalues());
    }
    return () => {
      dispatcher(clearDataListUsageControl());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponse,
    switchResponsealgodelete,
  ]);

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchEditUsageControl_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteUsageControl_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAddUsageControl_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddUsageControl_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddUsageControl_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditUsageControl_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditUsageControl_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteUsageControl_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteUsageControl_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data}
        column={column}
        gridesbutton
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        pagination
        // getRowHeight={() => "auto"}
        // sideButton={<MapStrategyfilter></MapStrategyfilter>}
        dialog={
          <DialogUsageControl
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              marginTop: "0px",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            placeholder="Role"
            getValues={(v: any) => {
              dispatcher(add_AddUsageControl(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Usage Control"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpen}
        setOpen={() => dispatcher(false_switchUsageControlvalues())}
        Dialog_Content={
          Uservalues.status === 0
            ? "Do you want to de-activate status?"
            : "Do you want to activate the status?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchUsageControlvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditUsageControl(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        subtitle={"Warning"}
        open={switchOpenalgodelete}
        setOpen={() => dispatcher(false_switchDeleteUsageControlvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteUsageControlvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_DeleteUsageControl(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default UsageControl;
