import { FunctionComponent, useEffect, useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  FormHelperText,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import Autocomplete from "./Autocomplete";
import {
  clearMenuListFilter,
  list_MenuListFilter,
} from "../Redux/Reducers/Menu/list_MenuFilter_slicer";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogMenuProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  editlist?: any;
  DialogtitleForSuccessMessage?: any;
}

const DialogMenu: FunctionComponent<DialogMenuProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const { getValues, isLoading }: any = props;
  const data = useAppSelector((state) => state?.MenuListFilter?.data);

  const initialValues: {
    Icon: string;
    menuname: string;
    Pathname: string;
    MainMenu: string;
    is_enabled: boolean;
    Portal: string;
  } = {
    Icon: props.edit?.icon || "",
    menuname: props.edit?.name || "",
    Pathname: props.edit?.path_name || "",
    MainMenu: props.edit?.main_menu?.name || "",
    is_enabled: props.edit?.status || true,
    Portal: props.edit?.portal || "",
  };
  const dispatcher = useAppDispatch();

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatcher(clearMenuListFilter());
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [snackbarOpen]);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (alertOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement != null) {
        descriptionElement.focus();
      }
    }
  }, [alertOpen]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const pageName = "Menu";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        disabled={MenuOfUserpermission?.permission?.add === false}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        fullScreen={fullScreen}
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "1000px",
            width: "1000px",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              Icon: Yup.string().required("Please enter a icon"),
              menuname: Yup.string().required("Please enter a name"),
              is_enabled: Yup.boolean().required("Please select the status"),
              Portal: Yup.string().required("Please enter a Portal"),
            })}
            onSubmit={(values) => {
              getValues(values);
              handleClose();
              setAlertOpen(true);
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div
                    style={{
                      display: "grid",
                      gap: "1em",
                      gridTemplateColumns:
                        "repeat(auto-fill,minmax(15rem,1fr))",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          fontSize: "15px",
                          color: "#000000",
                          fontFamily: "Roboto",
                          paddingBottom: "7px",
                        }}
                      >
                        Icon <span style={{ color: "red" }}>*</span>
                      </div>

                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="Icon"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={!!(props.errors.Icon && props.touched.Icon)}
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (
                            /^[A-Za-z0-9\s]+$/.test(inputValue) &&
                            inputValue.length < 31
                          ) {
                            props.setFieldValue("Icon", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "Icon",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.Icon}
                        style={{
                          width: "100%",
                          height: "50px",
                          margin: "0px 0px 0px",
                        }}
                      />
                      {props.errors.Icon && props.touched.Icon ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please enter a icon"}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          fontSize: "15px",
                          color: "#000000",
                          fontFamily: "Roboto",
                          paddingBottom: "7px",
                        }}
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </div>

                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="menuname"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={
                          !!(props.errors.menuname && props.touched.menuname)
                        }
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (
                            /^[A-Za-z0-9\s]+$/.test(inputValue) &&
                            inputValue.length < 31
                          ) {
                            props.setFieldValue("menuname", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "menuname",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.menuname}
                        style={{
                          width: "100%",
                          height: "50px",
                          margin: "0px 0px 0px",
                        }}
                      />
                      {props.errors.menuname && props.touched.menuname ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please enter a menuname"}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          fontSize: "15px",
                          color: "#000000",
                          fontFamily: "Roboto",
                          paddingBottom: "7px",
                        }}
                      >
                        Path Name
                      </div>

                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="Pathname"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (
                            /^[A-Za-z0-9-/\s]+$/.test(inputValue) &&
                            inputValue.length < 31
                          ) {
                            props.setFieldValue("Pathname", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "Pathname",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.Pathname}
                        style={{
                          width: "100%",
                          height: "50px",
                          margin: "0px 0px 0px",
                        }}
                      />
                    </div>
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Portal <span style={{ color: "red" }}>*</span>
                      </div>

                      <Autocomplete
                        Listitem={[
                          { label: "Admin Portal", value: "Admin" },
                          { label: "User Portal", value: "User" },
                        ]}
                        variant={"outlined"}
                        name={"Portal"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          props.setFieldValue("Portal", e.value);
                          dispatcher(
                            list_MenuListFilter([
                              { status: true },
                              { Pagemethod: e.value },
                            ])
                          );
                        }}
                        style={{
                          paddingBottom: "27px",
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={!!(props.errors.Portal && props.touched.Portal)}
                      ></Autocomplete>
                      {props.errors.Portal && props.touched.Portal ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please select the Portal"}
                        </FormHelperText>
                      ) : null}
                    </div>
                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Main Menu
                      </div>

                      <Autocomplete
                        Listitem={data?.map((v: any) => {
                          return {
                            label: v.name,
                            value: v.id,
                          };
                        })}
                        variant={"outlined"}
                        name={"MainMenu"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          props.setFieldValue("MainMenu", e.value);
                        }}
                        style={{
                          paddingBottom: "27px",
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                      ></Autocomplete>
                    </div>

                    <div style={{ height: "110px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Status <span style={{ color: "red" }}>*</span>
                      </div>

                      <Autocomplete
                        Listitem={[
                          { label: "Active", value: true },
                          { label: "InActive", value: false },
                        ]}
                        variant={"outlined"}
                        name={"is_enabled"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          props.setFieldValue("is_enabled", e.value);
                        }}
                        style={{
                          paddingBottom: "27px",
                          width: "100%",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                        error={
                          props.errors.is_enabled && props.touched.is_enabled
                        }
                      ></Autocomplete>
                      {props.errors.is_enabled && props.touched.is_enabled ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please select a status"}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogMenu;
