import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for   Menuvalues
export const Menuvalues = createSlice({
  name: "Menuvalues",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    switch: false,
    data: {},
  },
  reducers: {
    Menuvalues_datagrid: (state, a) => {
      state.data = a.payload;
      state.switch = true
    },
    success_api_call_Menuvalues_datagrid: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
    },
    failure_api_call_Menuvalues_datagrid: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    Menuvalues_datagrid_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_Menuvalues_datagrid_complete_api: (state, action) => {
      state.api_completed = false;
    },
    false_switchMenuvalues:(state)=>{
        state.switch = false
    }
  },
});
export default Menuvalues.reducer;
export const {
  Menuvalues_datagrid,
  success_api_call_Menuvalues_datagrid,
  failure_api_call_Menuvalues_datagrid,
  create_Menuvalues_datagrid_complete_api,
  Menuvalues_datagrid_is_success,false_switchMenuvalues
} = Menuvalues.actions;
