import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddMarketholiday,
  success_api_call_add_AddMarketholiday,
} from "./add_Marketholiday_slicer";
import {
  failure_api_call_MarketholidayList,
  success_api_call_MarketholidayList,
} from "./list_Marketholiday_slicer";
import {
  failure_api_call_delete_DeleteMarketholiday,
  success_api_call_delete_DeleteMarketholiday,
} from "./delete_Marketholiday_slicer";

//add Marketholiday
const AddMarketholidayCall = async (a: any) => {
  const transformedSegment = a.payload.Segmentdate.map((item:any) => item.value);
  const  add =a.payload.Datemultiselect.map((date:any)=>{
    return{
      holiday_date:date.format(),
      reason:a.payload.reason,
      segment: transformedSegment,
    }
  }) 
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/admin/holiday/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddMarketholidayApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddMarketholidayCall, e);
    yield put(success_api_call_add_AddMarketholiday(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddMarketholiday(e));
    yield put(expireSignature(e));
  }
}

//list Marketholiday
const MarketholidayListCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/admin/holiday`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* MarketholidayListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(MarketholidayListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_MarketholidayList(res));
  } catch (e) {
    yield put(failure_api_call_MarketholidayList(e));
    yield put(expireSignature(e));
  }
}

// Delete marketholiday
export function* DeleteMarketholidayApi(e: any) {
  try {
    const response: AxiosResponse = yield call(DeleteMarketholidayCall, e);
    yield put(success_api_call_delete_DeleteMarketholiday(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_DeleteMarketholiday(error));
  }
}
const DeleteMarketholidayCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/admin/holiday/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
