import { createSlice } from "@reduxjs/toolkit";
// this code for slice   mean value get and pass  the value
export const Login = createSlice({
  name: "Login",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    apiRespone: false,
    data: [],
  },
  reducers: {
    login: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_login: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.apiRespone = true;
    },
    failure_api_call_login: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
    },
    login_is_success: (state) => {
      state.isSuccess = false;
    },
    create_user_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_data: (state) => {
      state.data = [];
    },
    false_compleation: (state) => {
      state.api_completed = false;
      state.data = [];
    },
    false_apiRespone: (state) => {
      state.apiRespone = false;
    },
  },
});
export default Login.reducer;
export const {
  login,
  success_api_call_login,
  failure_api_call_login,
  create_user_complete_api,
  login_is_success,
  clear_data,
  false_compleation,
  false_apiRespone,
} = Login.actions;
