import { FunctionComponent } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../Redux/store/hooks";
import { DeleteGroupAccountvalues_datagrid } from "../../Redux/Reducers/Datagrid-values/DeletegroupAccount-values-slicer";

interface GroupAccountMessageprops {
  valuepopup: number;
}

const GroupAccountMessage: FunctionComponent<GroupAccountMessageprops> = (
  props: any
) => {
  const dispatcher = useAppDispatch();


  
  return (
    <div style={{ display: "flex" }}>
    
      <div style={{ marginTop: "6px" }}>
        <DeleteIcon
          sx={{
            color: "rgb(239, 153, 15)",
            cursor: "pointer",
            marginLeft: "10px",
          }}
          onClick={(e) => {
            dispatcher(
              DeleteGroupAccountvalues_datagrid({
                id: props.valuepopup,
              })
            );
          }}
        ></DeleteIcon>
      </div>
    </div>
  );
};

export default GroupAccountMessage;
