import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddMapStrategies,
  success_api_call_add_AddMapStrategies,
} from "./add_MapStrategies_slicer";
import {
  failure_api_call_EditMapStrategies,
  success_api_call_EditMapStrategies,
} from "./edit_MapStrategies_slicer";
import {
  failure_api_call_MapStrategiesList,
  success_api_call_MapStrategiesList,
} from "./list_MapStrategies_slicer";
import {
  failure_api_call_delete_MapStrategies,
  success_api_call_delete_MapStrategies,
} from "./delete_MapStrategies_slicer";

//add Map strategies
const AddMapStrategiesCall = async (a: any) => {
  const add = {
    user_id: a.payload.user,
    algo_id: a.payload.algo_id,
    strategy: a.payload.Stock.map((val:any)=>({id:val.value,name:val.label,segment:val.segment})) 
  };
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/map/create`,
    add,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* AddMapStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(AddMapStrategiesCall, e);
    yield put(success_api_call_add_AddMapStrategies(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddMapStrategies(e));
    yield put(expireSignature(e));
  }
}

//Edit Map strategies
const editMapStrategiesCall = async (a: any) => {
  const get_id = localStorage.getItem("mapstrategiesfilter");
  let edit = {};
  if (a.payload.status !== undefined) {
    edit = { status: a.payload.status }; // 1 for active, 0 for inactive
  } else {
    edit = {
      user_id: a.payload.user,
      algo_id: a.payload.algo_id,
      strategy: a.payload.Stock.map((val:any)=>({id:val.value,name:val.label,segment:val.segment})) 
    };
  }
  const get_token = localStorage.getItem("token");
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/map/${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* editMapStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editMapStrategiesCall, e);
    yield put(success_api_call_EditMapStrategies(response.data));
  } catch (e) {
    yield put(failure_api_call_EditMapStrategies(e));
    yield put(expireSignature(e));
  }
}

//list map strategies
const listMapStrategiesCall = async (a: any) => {
  let list: any = {};
  if (a.payload !== "undefined") {
    list = {
      ...list,
      algo_id: a.payload.algo_id,
      strategy_id: a.payload.strategy_id,
      user_id:a.payload.user_id
    };
  }
  const get_token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/map`,
    list,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* listMapStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listMapStrategiesCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_MapStrategiesList(res));
  } catch (e) {
    yield put(failure_api_call_MapStrategiesList(e));
    yield put(expireSignature(e));
  }
}

// Delete map strategies
export function* deleteMapStrategiesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteMapStrategiesCall, e);
    yield put(success_api_call_delete_MapStrategies(response.data));
  } catch (error) {
    yield put(failure_api_call_delete_MapStrategies(error));
  }
}
const deleteMapStrategiesCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/map/${a.payload.id}`,
    {
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
