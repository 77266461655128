import React, { FunctionComponent } from "react";
import "./dashboard.css";
import { ReactComponent as Lossicons } from "../svg_image/loss_trade_icon.svg";
import { ReactComponent as Losstrade } from "../svg_image/loss_trade.svg";
import { ReactComponent as Gainicons } from "../svg_image/gain_trade_icon.svg";
import { ReactComponent as Gaintrade } from "../svg_image/gain_trade.svg";
import Dashboardtable from "../Dashboard_table/Dashboard_table";
import DashboardChart from "./Dashboard_charts";
import { ReactComponent as Export } from "../svg_image/Exports.svg";
import { Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface DashboardProps {}
const Dashboard: FunctionComponent<DashboardProps> = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [alignment, setAlignment] = React.useState("Individual");
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  // const pageName = "Dashboard";
  // const menuList: any = JSON.parse(
  //     if (valueornot === null) {
  // localStorage.getItem("permission");
  // } else {sessionStorage.getItem("permission");
  // }  // );
  // const index: number = menuList.findIndex((indexId: any) => {
  //   return indexId.name === pageName;
  // });
  // const MenuOfUserpermission = menuList[index];

  return (
    <>
      <div className="heading_of_dashboard">
        <div className="dashboard-heading">Account wise P & L</div>
        <div>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Individual"
              sx={{
                textTransform: "capitalize",
                height: "39px",
                marginTop: "3px",
                width: {
                  xs: 60,
                  sm: 60,
                  md: 105,
                  lg: 105,
                  xl: 110,
                },
              }}
            >
              Paper Trade
            </ToggleButton>
            <ToggleButton
              value="Group"
              sx={{
                textTransform: "capitalize",
                height: "39px",
                marginTop: "3px",
                width: {
                  xs: 60,
                  sm: 60,
                  md: 100,
                  lg: 100,
                  xl: 110,
                },
              }}
            >
              Live Trade
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="dashboard-container">
        {/* First line */}
        <div className="line-container">
          <div className="first-div">
            <div className="data_dashboard">
              <div className="card_of_dashboard">
                <div className="card_of_concent">
                  <Gainicons />
                  <div className="card_heading_text">
                    Heading Here
                    <div className="card_subheading_text">Fyers : XK26790</div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Gaintrade />
                  <div style={{ color: "#2C8E14", fontSize: "13px" }}>
                    +11.03%
                  </div>
                </div>
              </div>
              <div className="card_of_dashboard">
                <div className="card_of_concent">
                  <Switch
                    {...label}
                    size="small" // Add this line to set the switch size to small
                  />
                  <div className="card_heading_second">
                    343<div className="card_text">Live Trade</div>
                  </div>
                </div>
                <div style={{ color: "#2C8E14" }}>
                  + 66,980.76
                  <div className="card_text">Overall P & L</div>
                </div>
              </div>
            </div>
            <div
              className="data_dashboard"
              style={{ backgroundColor: "#FFF0F3" }}
            >
              <div className="card_of_dashboard">
                <div className="card_of_concent">
                  <Lossicons />
                  <div className="card_heading_text">
                    Heading Here
                    <div className="card_subheading_text">Fyers : XK26790</div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Losstrade />
                  <div style={{ color: "#980000", fontSize: "13px" }}>
                    +11.03%
                  </div>
                </div>
              </div>
              <div className="card_of_dashboard">
                <div className="card_of_concent">
                  <Switch
                    {...label}
                    size="small" // Add this line to set the switch size to small
                  />
                  <div className="card_heading_second">
                    343<div className="card_text">Live Trade</div>
                  </div>
                </div>
                <div style={{ color: "#980000" }}>
                  + 66,980.76
                  <div className="card_text">Overall P & L</div>
                </div>
              </div>
            </div>
          </div>
          <div className="second-div">
            <DashboardChart></DashboardChart>
          </div>
        </div>

        {/* Second line */}
        <div className="line-container">
          <div className="table-div_with_heading">
            <div className="heading_of_dashboard_second">
              <div className="dashboard-heading">Strategies wise P & L</div>
              <div>
                <Export
                  style={{
                    cursor: "pointer",
                    background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                    borderRadius: "5px",
                  }}
                ></Export>
              </div>
            </div>
            <div className="table-div">
              <Dashboardtable></Dashboardtable>
            </div>
          </div>

          <div className="table-graph-div">
            <DashboardChart></DashboardChart>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
