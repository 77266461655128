import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_backreport,
  success_api_call_backreport,
} from "./list_backreport_slicer";

//list back test
const listbackreportCall = async (a: any) => {
  const backreport = localStorage.getItem("backreport");
  const get_token = localStorage.getItem("token");
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/backtest/report?backtest_id=${backreport}`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* backreportApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listbackreportCall, e);
    localStorage.setItem(
      "backreportsummary",
      JSON.stringify(response?.data?.data)
    );
    localStorage.setItem("backreportmessage", JSON.stringify(response));

    let res = response?.data?.data?.backtest_report.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_backreport(res));
  } catch (e) {
    yield put(failure_api_call_backreport(e));
    yield put(expireSignature(e));
  }
}
