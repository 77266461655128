import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddAlgo from "../pages/Add Algo/AddAlgo";
import ViewAlgoAdd from "../pages/View Algo/ViewAlgoAdd";
import { clearDataAlgoDymics } from "../Redux/Reducers/AlgoDymics";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { clear_EditableValueAlgo } from "../Redux/Reducers/Algo-api/editableValuAlgo";
import { useLocation } from "react-router-dom";
import "../pages/Add Algo/AddAlgocss.css";
import "./Addalgostyle.css";
import { Typography } from "@mui/material";

export default function TabforAddalgo() {
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();

  // const dataPassed = location.state?.editoradd;
  const location = useLocation();
  const dataPassed = location.state?.editoraddalgo;
  const datapassed_to_check = location.state?.Tradecheck;
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Algo
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      {dataPassed === "edit" ? "Edit" : "Add"} Algo
    </Typography>,
  ];
  useEffect(() => {
    return () => {
      dispatch(clearDataAlgoDymics());

      dispatch(clear_EditableValueAlgo());
    };
  }, [dispatch]);
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div
        style={{
          backgroundColor: "white",
          flex: "1",
          display: "flex",
          borderRadius: "10px",
          boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            typography: "body1",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#338514",
                    height: "3px",
                    borderRadius: "5px",
                  },
                }}
              >
                <Tab
                  label={dataPassed === "edit" ? "Edit Algo" : "Add Algo"}
                  value="1"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "1" ? "#338514" : "black" }}
                />
                <Tab
                  label={
                    dataPassed === "edit"
                      ? "Edit Input Field"
                      : "Add Input Field"
                  }
                  value="2"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                  }}
                  style={{ color: value === "2" ? "#338514" : "black" }}
                />
              </TabList>
            </Box>

            <TabPanel
              value="1"
              className="addalgoform"
              sx={{
                flex: "1",
                height: "48rem",
                overflowY: "auto",
                maxHeight: "calc(100vh - 200px)", // Adjust as needed
              }}
            >
              <AddAlgo></AddAlgo>
            </TabPanel>
            <TabPanel value="2" sx={{ flex: "1" }}>
              <ViewAlgoAdd trade_check={datapassed_to_check}></ViewAlgoAdd>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}
