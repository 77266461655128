import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import {
  FormHelperText,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogUsageControlProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogUsageControl: FunctionComponent<DialogUsageControlProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const { getValues, isLoading }: any = props;

  const initialValues: {
    AccountUsage: string;
    OpenTrade: string;
    TradeSetup: string;
  } = {
    AccountUsage: props?.edit?.accounts || "",
    OpenTrade: props?.edit?.open_trade || "",
    TradeSetup: props?.edit?.trade_setup || "",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const pageName = "Usage Control";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        disabled={MenuOfUserpermission?.permission?.add === false}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: "5px",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minWidth: "200px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              AccountUsage: Yup.string().required("Please enter the account"),
              OpenTrade: Yup.string().required("Please enter the open trade"),
              TradeSetup: Yup.string().required("Please enter the trade setup"),
            })}
            onSubmit={(values) => {
              getValues(values);
              handleClose();
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div style={{ height: "110px", width: "100%" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      No.Of.Accounts <span style={{ color: "red" }}> *</span>
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="AccountUsage"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={
                          !!(
                            props.errors.AccountUsage &&
                            props.touched.AccountUsage
                          )
                        }
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (/^[0-9.\s]+$/.test(inputValue)) {
                            props.setFieldValue("AccountUsage", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "AccountUsage",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.AccountUsage}
                        style={{
                          width: "25rem",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                      />

                      {props.errors.AccountUsage &&
                      props.touched.AccountUsage ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please enter a account"}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>{" "}
                  <div style={{ height: "110px", width: "100%" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      No.Of.OpenTrade <span style={{ color: "red" }}> *</span>
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="OpenTrade"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={
                          !!(props.errors.OpenTrade && props.touched.OpenTrade)
                        }
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (/^[0-9.\s]+$/.test(inputValue)) {
                            props.setFieldValue("OpenTrade", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "OpenTrade",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.OpenTrade}
                        style={{
                          width: "25rem",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                      />

                      {props.errors.OpenTrade && props.touched.OpenTrade ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please enter a open trade"}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ height: "110px", width: "100%" }}>
                    <div
                      style={{
                        color: "#000000",
                        paddingBottom: "5px",
                        fontSize: "15px",
                        fontFamily: "Roboto",
                      }}
                    >
                      No.Of.TradeSetup
                      <span style={{ color: "red" }}> *</span>
                    </div>
                    <div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="price"
                        sx={{
                          "& fieldset": {
                            borderRadius: "5px",
                          },
                        }}
                        autoComplete="off"
                        error={
                          !!(
                            props.errors.TradeSetup && props.touched.TradeSetup
                          )
                        }
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          if (/^[0-9.\s]+$/.test(inputValue)) {
                            props.setFieldValue("TradeSetup", inputValue);
                          } else {
                            props.setFieldValue(
                              "TradeSetup",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.TradeSetup}
                        style={{
                          width: "25rem",
                          height: "50px",
                          borderBottom: "50px",
                        }}
                      />

                      {props.errors.TradeSetup && props.touched.TradeSetup ? (
                        <FormHelperText
                          style={{ marginLeft: "13px", marginTop: "10px" }}
                          error
                        >
                          {"Please enter a trade setup"}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      style={{
                        width: "100px",
                        height: "40px",
                        border: "1px solid #EF990F",
                        color: "black",
                        marginRight: "1%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel
                    </Button>
                    <button
                      type="submit"
                      style={{
                        width: "100px",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                      }}
                    >
                      Save
                    </button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogUsageControl;
