import { FunctionComponent, useEffect, useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../pages/Page.css";
import { FormHelperText, SxProps, TextField, Theme } from "@mui/material";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IsLoading from "./isLoading";
import CancelIcon from "@mui/icons-material/Cancel";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogOpenPoistionmanualeditoptionProps {
  sx?: SxProps<Theme>;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties;
  getValues?: any;
  closeDio?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogOpenPoistionmanualeditoption: FunctionComponent<
  DialogOpenPoistionmanualeditoptionProps
> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { getValues, isLoading }: any = props;
  const quantitydisable = props?.edit?.is_entry;
  const initialValues: {
    quantity: number;
    price: number;
    target: number;
    triggerprice: number;
  } = {
    quantity: props?.edit?.quantity,
    price: props?.edit?.entry,
    target: props?.edit?.target,
    triggerprice: props?.edit?.sl,
  };
  const [alignments, setalignments] = React.useState("BUY");
  const [typeenbale, settypeenbale] = React.useState(null);
  const [lotsize, setlotsize] = React.useState(null);
  const [orderedit, setorderedit] = React.useState(null);
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setalignments(props.edit.order_type);
    settypeenbale(props.edit.execution_type);
    setlotsize(props.edit.lot_size);
    setorderedit(props.edit.stock_id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [snackbarOpen, setSnackBarOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (snackbarOpen) {
      setSnackBarOpen(false);
    }
  }, [snackbarOpen]);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (alertOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement != null) {
        descriptionElement.focus();
      }
    }
  }, [alertOpen]);

  const pageName = "Open Position";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];

  const validationSchema = () => {
    let baseSchema = Yup.object({
      quantity: Yup.number().required("Please enter a quantity"),
      target: Yup.number().required("Please enter a target"),
    });

    if (typeenbale === "LIMT" || typeenbale === "SL-L") {
      baseSchema = baseSchema.shape({
        price: Yup.number().required("Please enter a price"),
      });
    }

    if (typeenbale === "SL-L" || typeenbale === "SL-M") {
      baseSchema = baseSchema.shape({
        triggerprice: Yup.number().required("Please enter a trigger price"),
      });
    }
    return baseSchema;
  };
  return (
    <>
      <Button
        disabled={MenuOfUserpermission?.permission?.add === false}
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "5px",
            width: "100%",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            backgroundColor: "green",
            color: "white",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {props.Dialogtitle}
          <CancelIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          ></CancelIcon>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const values_of_data = { ...values, stock_id: orderedit };
              getValues(values_of_data);
              handleClose();
              setAlertOpen(true);
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  <div
                    style={{
                      display: "grid",
                      gap: "1em",
                      gridTemplateColumns:
                        "repeat(auto-fill,minmax(15rem,1fr))",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          Quantity <span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "gray" }}>
                          Lot Size : {lotsize}
                        </div>
                      </div>

                      <div>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="quantity"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                              border: `1.5px solid ${
                                alignments === "BUY" ? "green" : "orange"
                              }`,
                            },
                          }}
                          disabled={quantitydisable === true}
                          autoComplete="off"
                          error={
                            !!(props.errors.quantity && props.touched.quantity)
                          }
                          onChange={(e: any) => {
                            const inputValue = e.target.value;

                            if (/^[0-9\s]+$/.test(inputValue)) {
                              props.setFieldValue("quantity", inputValue);
                            } else {
                              props.setFieldValue(
                                "quantity",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.quantity}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />

                        {props.errors.quantity && props.touched.quantity ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {"Please enter a quantity"}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>
                    {typeenbale === "LIMT" || typeenbale === "SL-L" ? (
                      <div style={{ height: "100px", width: "100%" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          Price <span style={{ color: "red" }}> *</span>
                        </div>
                        <div>
                          <TextField
                            id="outlined-basic"
                            // required
                            variant="outlined"
                            name="price"
                            sx={{
                              "& fieldset": {
                                borderRadius: "5px",
                                border: `1.5px solid ${
                                  alignments === "BUY" ? "green" : "orange"
                                }`,
                              },
                            }}
                            autoComplete="off"
                            disabled={quantitydisable === true}
                            error={
                              !!(props.errors.price && props.touched.price)
                            }
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              // Use regex to check if the input contains only characters (letters)
                              if (/^[0-9.\s]+$/.test(inputValue)) {
                                props.setFieldValue("price", inputValue);
                              } else {
                                props.setFieldValue(
                                  "price",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            value={props.values.price}
                            style={{
                              width: "100%",
                              height: "50px",
                              borderBottom: "50px",
                            }}
                          />

                          {props.errors.price && props.touched.price ? (
                            <FormHelperText
                              style={{ marginLeft: "13px", marginTop: "10px" }}
                              error
                            >
                              {"Please enter a price"}
                            </FormHelperText>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    <div style={{ height: "100px", width: "100%" }}>
                      <div
                        style={{
                          color: "#000000",
                          paddingBottom: "5px",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Target <span style={{ color: "red" }}> *</span>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          // required
                          variant="outlined"
                          name="target"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px",
                              border: `1.5px solid ${
                                alignments === "BUY" ? "green" : "orange"
                              }`,
                            },
                          }}
                          autoComplete="off"
                          error={
                            !!(props.errors.target && props.touched.target)
                          }
                          onChange={(e: any) => {
                            const inputValue = e.target.value;
                            if (/^[0-9.\s]+$/.test(inputValue)) {
                              props.setFieldValue("target", inputValue);
                            } else {
                              // If validation fails, remove the last character from the input value
                              props.setFieldValue(
                                "target",
                                inputValue.slice(0, -1)
                              );
                            }
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.target}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderBottom: "50px",
                          }}
                        />

                        {props.errors.target && props.touched.target ? (
                          <FormHelperText
                            style={{ marginLeft: "13px", marginTop: "10px" }}
                            error
                          >
                            {"Please enter a target"}
                          </FormHelperText>
                        ) : null}
                      </div>
                    </div>

                    {typeenbale === "SL-L" || typeenbale === "SL-M" ? (
                      <div style={{ height: "100px", width: "100%" }}>
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontSize: "15px",
                            fontFamily: "Roboto",
                          }}
                        >
                          SL Price <span style={{ color: "red" }}> *</span>
                        </div>
                        <div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="triggerprice"
                            sx={{
                              "& fieldset": {
                                borderRadius: "5px",
                                border: `1.5px solid ${
                                  alignments === "BUY" ? "green" : "orange"
                                }`,
                              },
                            }}
                            autoComplete="off"
                            error={
                              !!(
                                props.errors.triggerprice &&
                                props.touched.triggerprice
                              )
                            }
                            onChange={(e: any) => {
                              const inputValue = e.target.value;
                              if (/^[0-9.\s]+$/.test(inputValue)) {
                                props.setFieldValue("triggerprice", inputValue);
                              } else {
                                props.setFieldValue(
                                  "triggerprice",
                                  inputValue.slice(0, -1)
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            value={props.values.triggerprice}
                            style={{
                              width: "100%",
                              height: "50px",
                              borderBottom: "50px",
                            }}
                          />

                          {props.errors.triggerprice &&
                          props.touched.triggerprice ? (
                            <FormHelperText
                              style={{ marginLeft: "13px", marginTop: "10px" }}
                              error
                            >
                              {"Please enter a trigger price"}
                            </FormHelperText>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{
                        width: "100%",
                        height: "40px",
                        color: "black",
                        background: !props.isValid
                          ? "linear-gradient(to right, #FFFA02,#F2A618 )"
                          : "linear-gradient(to right, #FFFA02,#F2A618 )",

                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default DialogOpenPoistionmanualeditoption;
