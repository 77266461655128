import { FunctionComponent } from "react";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import "../Page.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Grids from "../../Component/Grides";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  clearDataadminlist,
  list_AdminList,
} from "../../Redux/Reducers/Admin-api/list_AdminList_slicer";
import Clientfilter from "../../Component/Clientuser";
import UserMessage from "./UserMessage";
import {
  edit_EditAdmin,
  false_switch_response,
} from "../../Redux/Reducers/Admin-api/edit_Admin_slicer";
import Dialogs from "../../Component/Dialog";
import { false_switch } from "../../Redux/Reducers/Datagrid-values/User-values";
import { edit_filteruser } from "../../Redux/Reducers/Filtervalue/filteruser";
import { false_switchAdmin_response } from "../../Redux/Reducers/Admin-api/add_Admin_slicer";

interface ViewAdminProps {}

const ViewAdmin: FunctionComponent<ViewAdminProps> = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  const pageName = "View User";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});

  const MenuOfUserpermission = test_val[0];

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Manage Users
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      View User
    </Typography>,
  ];

  //    datas of user role
  const AddAdminResponse = useAppSelector(
    (state) => state.AddAdmin.switchResponse
  );
  const data = useAppSelector((state) => state?.AdminList?.data);
  const isLoadingdata = useAppSelector((state) => state?.AdminList?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddAdmin?.data);
  const addDataLoading: any = useAppSelector(
    (state) => state?.AddAdmin?.isLoading
  );
  const editData: any = useAppSelector((state) => state?.EditAdmin?.data);
  const editDataLoading: any = useAppSelector(
    (state) => state?.EditAdmin?.isLoading
  );
  const switchResponse = useAppSelector(
    (state) => state.EditAdmin.switchResponse
  );
  const Uservalues: any = useAppSelector((state) => state.Uservalues.data);
  const switchOpen = useAppSelector((state) => state.Uservalues.switch);
  const column = [
    {
      sortable: true,
      headerName: "Name ",
      flex: 1,
      field: "name",
    },
    {
      headerName: "Email",
      sortable: true,

      field: "email_id",
      flex: 1,
    },
    {
      headerName: "Phone Number",
      field: "mobile_no",
      flex: 1,
      sortable: true,
    },

    {
      headerName: "Role",
      field: "role",
      flex: 1,
      sortable: true,
      cellClass: "capitalize-text",
      cellStyle: { textTransform: "capitalize" },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          headerAlign: "center",

          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <UserMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></UserMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    name: true,
    email_id: true,
    mobile_no: true,
    role: true,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );

  React.useEffect(() => {
    dispatcher(list_AdminList({}));
    dispatcher(edit_filteruser({}));

    if (switchResponse) {
      dispatcher(false_switch());
    }
    return () => {
      dispatcher(clearDataadminlist());
    };
  }, [addData, editData, dispatcher, switchResponse]);
  const action = (
    <button
      onClick={() => {
        dispatcher(false_switch_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const AddAdminaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAdmin_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switch_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switch_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddAdminResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAdmin_response())}
        key={`top-right`}
        action={AddAdminaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAdmin_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataLoading || editDataLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data}
        column={column}
        open
        pagination
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        Export={
          <SaveAltIcon
            sx={{ fontSize: "30px", cursor: "pointer" }}
          ></SaveAltIcon>
        }
        sideButton={<Clientfilter></Clientfilter>}
        dialog={
          <Button
            disabled={MenuOfUserpermission?.permission?.add === false}
            onClick={() => {
              navigate("/adduser", {
                state: { editoradd: "add", successMessage: { addData } },
              });
            }}
            sx={{
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              marginLeft: "10px",
              marginRight: "-20px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#EF990F" },
            }}
          >
            Add User
          </Button>
        }
        Userpermissions={undefined}
      ></Grids>

      <Dialogs
        maxWidth={"xs"}
        open={switchOpen}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switch())}
        Dialog_Content={
          Uservalues.is_live === true
            ? "Do you want to activate trade status?"
            : "Do you want to de-activate trade status?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switch())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditAdmin(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default ViewAdmin;
